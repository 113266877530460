import React, {Component} from 'react';
import {Form} from '@formio/react';
import {Templates} from 'formiojs';

Templates.framework = 'bootstrap';

class LeopardHtmlFormBuilderViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSchema: [],
            defaultFormData: []
        };
        this.dataViewId = "";
        this.parentComp = null;
    }

    updateFormSchemaAndData(formSchema, defaultFormData) {
        this.setState({formSchema: [], defaultFormData: []}, function () {
            this.setState({
                formSchema: formSchema,
                defaultFormData: defaultFormData
            });
        });
    }

    updateFormSchema(formSchema) {
        this.setState({formSchema: []}, function () {
            this.setState({
                formSchema: formSchema
            });
        });
    }

    componentDidMount() {
        this.dataViewId = this.props.dataViewId;
        this.parentComp = this.props.parentComp;
    }

    render() {
        let that = this;
        return (
            <React.Fragment>
                <Form
                    form={{components: that.state.formSchema}}
                    submission={{data: that.state.defaultFormData}}
                    onChange={(data) => that.props.updateFormData(data)}
                    onCustomEvent={e => {
                        that.props.customEventOnTrigger(e, this.dataViewId, this.parentComp);
                    }}
                />
            </React.Fragment>
        );
    }
}

export default LeopardHtmlFormBuilderViewer;