import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextArea, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";
import LDPH from "../helpers/LeopardDropdownHelper";
import {RequiredRule, Validator} from "devextreme-react/validator";

class LeopardParametersPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionParameters: null,
            autoApplyParameterFilter: true,
            topMenuJustify: "left",
            globalVariablesInit: ""
        };
        this.uiObjectInstances = [];
    }

    componentDidMount() {
        let autoApply = this.props.autoApplyParameterFilter;
        let topMenuJustify = this.props.topMenuJustify;
        if (LDH.IsObjectNull(topMenuJustify)) {
            topMenuJustify = "left";
        }

        let globalVariablesInit = this.props.globalVariablesInit;
        if (LDH.IsObjectNull(globalVariablesInit)) {
            globalVariablesInit = "";
        }

        if (!LDH.IsObjectNull(this.props.definitionParameters)) {
            let data = LDH.DeepClone(this.props.definitionParameters);
            for (let i = 0; i < data.length; i++) {
                if (LDH.IsObjectNull(data[i].minSearchLength) ||
                    LDH.IsValueEmpty(data[i].minSearchLength)) {
                    data[i].minSearchLength = 1;
                }
                if (LDH.IsObjectNull(data[i].autoCompleteOperation) ||
                    LDH.IsValueEmpty(data[i].autoCompleteOperation)) {
                    data[i].autoCompleteOperation = "contains";
                }
                if (LDH.IsObjectNull(data[i].allowTyping) ||
                    LDH.IsValueEmpty(data[i].allowTyping)) {
                    data[i].allowTyping = true;
                }
            }
            this.setState({
                definitionParameters: data,
                autoApplyParameterFilter: autoApply,
                topMenuJustify: topMenuJustify,
                globalVariablesInit: globalVariablesInit
            });
        } else {
            this.setState({
                definitionParameters: [],
                autoApplyParameterFilter: autoApply,
                topMenuJustify: topMenuJustify,
                globalVariablesInit: globalVariablesInit
            });
        }
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null ||
            data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.input] = data.e.instance;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    updateValueForParameters = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        for (let i = 0; i < clonedParameters.length; i++) {
            let currentColumn = clonedParameters[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                currentColumn[pName] = data.e.value;
            }
        }
        this.setState({definitionParameters: clonedParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    parameterDeleteOnClick = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        let filteredParameters = [];
        for (let i = 0; i < clonedParameters.length; i++) {
            if (clonedParameters[i].id !== data.id) {
                filteredParameters.push(clonedParameters[i]);
            }
        }
        let keys = Object.keys(this.uiObjectInstances);
        for (let j = 0; j < keys.length; j++) {
            if (keys[j].startsWith("parameters_" + this.props.dataViewId +
                "_uiControl_" + data.index)) {
                delete this.uiObjectInstances[keys[j]];
            }
        }
        this.setState({definitionParameters: filteredParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    parametersAddOnClick = () => {
        let that = this;
        this.setState({
            definitionParameters: [
                ...this.state.definitionParameters, {
                    id: LDH.GenerateGuid(),
                    autoCompleteOperation: "contains",
                    minSearchLength: 1,
                    displayOrder: 0,
                    controlWidth: 120,
                    allowTyping: true
                }
            ]
        }, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    autoApplyParameterFilterValueOnChange = (e) => {
        let that = this;
        this.setState({autoApplyParameterFilter: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    }

    topMenuJustifyValueOnChange = (e) => {
        let that = this;
        this.setState({topMenuJustify: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    }

    globalVariablesInitValueOnChange = (e) => {
        let that = this;
        this.setState({globalVariablesInit: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    }

    render() {
        let that = this;
        if (LDH.IsObjectNull(this.state.definitionParameters)) return null;

        let result = this.state.definitionParameters.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.definitionParameters[i].parameterName)
                                ? "Parameter " + (i + 1)
                                : that.state.definitionParameters[i].parameterName}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.parameterDeleteOnClick({
                                   e, id: that.state.definitionParameters[i].id, index: i
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"parameterEditorParameterName_" + i + "_help"}
                                                        title={"Parameter name"}
                                                        text={"Give a name for the parameter."}/>
                                <span>Parameter name:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].parameterName}
                                         maxLength={20}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_parameterEditorParameterName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "parameterName", item: item, index: i
                                         })}>
                                    <Validator>
                                        <RequiredRule
                                            validationCallback={
                                                (e) => that.customValidationRuleCallback({
                                                    e,
                                                    input: "parameters_" + that.props.dataViewId +
                                                        "_uiControl_" + i +
                                                        "_parameterEditorParameterName",
                                                    rules: [{rule: "required"}]
                                                })
                                            } type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorVisibility_" + i + "_help"}
                                    title={"Visibility"}
                                    text={"Specify the visibility of the parameter."}/>
                                <span>Visibility:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorVisibility"
                                           })}
                                           value={that.state.definitionParameters[i].parameterVisibility}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "parameterVisibility", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterType_" + i + "_help"}
                                    title={"Parameter type"}
                                    text={"Specify a type for the parameter."}/>
                                <span>Parameter type:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionParameterType}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorParameterType"
                                           })}
                                           value={that.state.definitionParameters[i].parameterType}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "parameterType", item: item, index: i
                                           })}>
                                    <Validator>
                                        <RequiredRule
                                            validationCallback={
                                                (e) => that.customValidationRuleCallback({
                                                    e,
                                                    input: "parameters_" + that.props.dataViewId +
                                                        "_uiControl_" + i +
                                                        "_parameterEditorParameterType",
                                                    rules: [{rule: "required"}]
                                                })
                                            } type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorPredefinedLogic_" + i + "_help"}
                                    title={"Pre-defined logic"}
                                    text={"Specify a pre-defined logic for the parameter."}/>
                                <span>Pre-defined logic:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionParameterPredefinedLogic}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorPredefinedLogic"
                                           })}
                                           value={that.state.definitionParameters[i].predefinedLogic}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "predefinedLogic", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        {
                            (that.state.definitionParameters[i].parameterType === "datetime" ||
                                that.state.definitionParameters[i].parameterType === "date" ||
                                that.state.definitionParameters[i].parameterType === "numberbox") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorValueFormat_" + i + "_help"}
                                            title={"Value format"}
                                            text={"Specify a value format for the parameter."}/>
                                        <span>Value format:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.definitionParameters[i].valueFormat}
                                                 onValueChanged={(e) => that.updateValueForParameters({
                                                     e, id: that.state.definitionParameters[i].id,
                                                     propertyName: "valueFormat", item: item, index: i
                                                 })}>
                                        </TextBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorDataSourceForAC_" + i + "_help"}
                                            title={"Data source URL"}
                                            text={"Specify a data source URL for enabling the auto-complete feature of Textbox or Dropdown control."}/>
                                        <span>Auto-complete data source URL:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.definitionParameters[i].dataSource}
                                                 onValueChanged={(e) => that.updateValueForParameters({
                                                     e, id: that.state.definitionParameters[i].id,
                                                     propertyName: "dataSource", item: item, index: i
                                                 })}>
                                        </TextBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorDataKeyForAC_" + i + "_help"}
                                            title={"Data key"}
                                            text={"Specify a data key for the data source."}/>
                                        <span>Auto-complete data key:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.definitionParameters[i].dataSourceKey}
                                                 onValueChanged={(e) => that.updateValueForParameters({
                                                     e, id: that.state.definitionParameters[i].id,
                                                     propertyName: "dataSourceKey", item: item, index: i
                                                 })}>
                                        </TextBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorDataValueForAC_" + i + "_help"}
                                            title={"Data value"}
                                            text={"Specify a data value for the data source."}/>
                                        <span>Auto-complete data value:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.definitionParameters[i].dataSourceValue}
                                                 onValueChanged={(e) => that.updateValueForParameters({
                                                     e, id: that.state.definitionParameters[i].id,
                                                     propertyName: "dataSourceValue", item: item, index: i
                                                 })}>
                                        </TextBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorAutoCompleteOperation_" + i + "_help"}
                                            title={"Search method"}
                                            text={"Specify a search method for the auto-complete feature."}/>
                                        <span>Auto-complete search method:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionAutoCompleteOperations}
                                                   displayExpr={'text'} valueExpr={'value'}
                                                   ref={(e) => that.setInputFieldInstance({
                                                       e,
                                                       input: "parameters_" + that.props.dataViewId +
                                                           "_uiControl_" + i +
                                                           "_parameterEditorAutoCompleteOperation"
                                                   })}
                                                   value={that.state.definitionParameters[i].autoCompleteOperation}
                                                   onValueChanged={(e) => that.updateValueForParameters({
                                                       e, id: that.state.definitionParameters[i].id,
                                                       propertyName: "autoCompleteOperation", item: item, index: i
                                                   })}>
                                        </SelectBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorMinimumSearchLength_" + i + "_help"}
                                            title={"Minimum search length"}
                                            text={"Specify a minimum search length for the auto-complete feature."}/>
                                        <span>Minimum search length:</span>
                                    </div>
                                    <div>
                                        <NumberBox value={that.state.definitionParameters[i].minSearchLength}
                                                   min={1} max={99} showSpinButtons={true} format={"#0"}
                                                   onValueChanged={(e) => that.updateValueForParameters({
                                                       e, id: that.state.definitionParameters[i].id,
                                                       propertyName: "minSearchLength", item: item, index: i
                                                   })}>
                                        </NumberBox>
                                    </div>
                                </div> : null
                        }
                        {
                            that.state.definitionParameters[i].parameterType === "numberbox" ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorMinimumValue_" + i + "_help"}
                                            title={"Minimum value"}
                                            text={"Specify a minimum value for the UI control."}/>
                                        <span>Minimum value:</span>
                                    </div>
                                    <div>
                                        <NumberBox value={that.state.definitionParameters[i].minValue}
                                                   showSpinButtons={true} showClearButton={true}
                                                   onValueChanged={(e) => that.updateValueForParameters({
                                                       e, id: that.state.definitionParameters[i].id,
                                                       propertyName: "minValue", item: item, index: i
                                                   })}>
                                        </NumberBox>
                                    </div>
                                </div> : null
                        }
                        {
                            that.state.definitionParameters[i].parameterType === "numberbox" ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorMaximumValue_" + i + "_help"}
                                            title={"Maximum value"}
                                            text={"Specify a maximum value for the UI control."}/>
                                        <span>Maximum value:</span>
                                    </div>
                                    <div>
                                        <NumberBox value={that.state.definitionParameters[i].maxValue}
                                                   showSpinButtons={true} showClearButton={true}
                                                   onValueChanged={(e) => that.updateValueForParameters({
                                                       e, id: that.state.definitionParameters[i].id,
                                                       propertyName: "maxValue", item: item, index: i
                                                   })}>
                                        </NumberBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "dropdown") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorAllowTyping_" + i + "_help"}
                                            title={"Enable searching"}
                                            text={"Specify whether to enable searching."}/>
                                        <span>Enable searching:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                                   displayExpr={'name'} valueExpr={'id'}
                                                   ref={(e) => that.setInputFieldInstance({
                                                       e,
                                                       input: "parameters_" + that.props.dataViewId +
                                                           "_uiControl_" + i +
                                                           "_parameterEditorAllowTyping"
                                                   })}
                                                   value={that.state.definitionParameters[i].allowTyping}
                                                   onValueChanged={(e) => that.updateValueForParameters({
                                                       e, id: that.state.definitionParameters[i].id,
                                                       propertyName: "allowTyping", item: item, index: i
                                                   })}>
                                        </SelectBox>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorParameterDataShapingForRemote_" + i + "_help"}
                                            title={"Auto-complete data shaping"}
                                            text={"Write a JavaScript code to shape the data before being used by the auto-complete feature of the UI control."}/>
                                        <span>Auto-complete data shaping:</span>
                                    </div>
                                    <div>
                                        <TextArea value={that.state.definitionParameters[i].dataShapingForRemote}
                                                  height={100}
                                                  placeholder={"Use the following variables to get started: data."}
                                                  onValueChanged={(e) => that.updateValueForParameters({
                                                      e, id: that.state.definitionParameters[i].id,
                                                      propertyName: "dataShapingForRemote", item: item, index: i
                                                  })}>
                                        </TextArea>
                                    </div>
                                </div> : null
                        }
                        {
                            (that.state.definitionParameters[i].parameterType === "textbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown" ||
                                that.state.definitionParameters[i].parameterType === "date" ||
                                that.state.definitionParameters[i].parameterType === "datetime" ||
                                that.state.definitionParameters[i].parameterType === "numberbox" ||
                                that.state.definitionParameters[i].parameterType === "dropdown-multiselect") ?
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon
                                            elementId={"parameterEditorPlaceholder_" + i + "_help"}
                                            title={"Placeholder"}
                                            text={"Specify a placeholder for the UI control."}/>
                                        <span>Placeholder:</span>
                                    </div>
                                    <div>
                                        <TextBox value={that.state.definitionParameters[i].placeholder}
                                                 onValueChanged={(e) => that.updateValueForParameters({
                                                     e, id: that.state.definitionParameters[i].id,
                                                     propertyName: "placeholder", item: item, index: i
                                                 })}>
                                        </TextBox>
                                    </div>
                                </div> : null
                        }
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterDefaultValue_" + i + "_help"}
                                    title={"Default value"}
                                    text={"Specify a default value for the parameter."}/>
                                <span>Default value:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].defaultValue}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "defaultValue", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterDataShaping_" + i + "_help"}
                                    title={"Data shaping for query"}
                                    text={"Write a JavaScript code to shape the data before being used by the UI control."}/>
                                <span>Data shaping for query:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].dataShapingForQuery} height={100}
                                          placeholder={"Use the following variables to get started: value."}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "dataShapingForQuery", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterInitalLogic_" + i + "_help"}
                                    title={"Control enablement logic"}
                                    text={"Write a JavaScript code to initialise the UI control."}/>
                                <span>Control enablement logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].controlEnablementLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "controlEnablementLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterVisibilityLogic_" + i + "_help"}
                                    title={"Control visibility logic"}
                                    text={"Write a JavaScript code to initialise the UI control."}/>
                                <span>Control visibility logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].controlVisibilityLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "controlVisibilityLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterDefaultValueLogic_" + i + "_help"}
                                    title={"Control default value logic"}
                                    text={"Write a JavaScript code to initialise the UI control."}/>
                                <span>Control default value logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].controlDefaultValueLogic} height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "controlDefaultValueLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorTriggerOnChange_" + i + "_help"}
                                    title={"Trigger on value change"}
                                    text={"Specify the value on change event."}/>
                                <span>Trigger on value change:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorTriggerOnChange"
                                           })}
                                           value={that.state.definitionParameters[i].parameterTriggerOnChange}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "parameterTriggerOnChange", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterLabel_" + i + "_help"}
                                    title={"Label"}
                                    text={"Specify a label for the parameter."}/>
                                <span>Parameter label:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].parameterLabel}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "parameterLabel", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorDisplayOrder_" + i + "_help"}
                                    title={"Display order"}
                                    text={"Specify a display order for the parameter."}/>
                                <span>Display order:</span>
                            </div>
                            <div>
                                <NumberBox value={that.state.definitionParameters[i].displayOrder}
                                           min={0} max={99} showSpinButtons={true} format={"#0"}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "displayOrder", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorControlWidth_" + i + "_help"}
                                    title={"Control width"}
                                    text={"Specify a size of width for the UI control."}/>
                                <span>Control width:</span>
                            </div>
                            <div>
                                <NumberBox value={that.state.definitionParameters[i].controlWidth}
                                           min={10} max={9999} showSpinButtons={true} format={"#0"}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "controlWidth", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.parametersAddOnClick(e)}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"parameterEditorAutoApplyParameterFilter_help"}
                                                        title={"Auto-apply filter"}
                                                        text={"Apply the filter automatically after a value of Parameter has been changed by the user."}/>
                                <span>Auto-apply filter on value changed:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_parameterEditorAutoApplyParameterFilter"
                                           })}
                                           value={that.state.autoApplyParameterFilter}
                                           onValueChanged={(e) => that.autoApplyParameterFilterValueOnChange(e)}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"parameterEditorTopMenuJustifyFilter_help"}
                                                        title={"Top menu justify"}
                                                        text={"Specify the justify setting for the top menu."}/>
                                <span>Top menu justify:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionTopMenuJustify}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_parameterEditorTopMenuJustify"
                                           })}
                                           value={that.state.topMenuJustify}
                                           onValueChanged={(e) => that.topMenuJustifyValueOnChange(e)}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorGlobalVariableInit_help"}
                                    title={"Initialise global variables"}
                                    text={"Write a JavaScript code to initialise global variables."}/>
                                <span>Initialise global variables:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.globalVariablesInit}
                                          height={100}
                                          placeholder={"Example: window[\"globalvar_abc\"]=\"hello world\";."}
                                          onValueChanged={(e) => that.globalVariablesInitValueOnChange(e)}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardParametersPanel;
