import React, {Component} from 'react';
import LDH from '../helpers/LeopardDataHelper';
import {TextBox} from 'devextreme-react';
import {RequiredRule, Validator} from "devextreme-react/validator";
import LRH from "../helpers/LeopardReactHelper";

class LeopardRegexTesterEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regexInValue: null,
            regexInTestValue: null,
            regexOutValue: null,
            regexOutTestValue: null,
            regexInTestResult: null
        };
        this.regexDefinition = [];
        this.dataViewId = null;
        this.uiObjectInstances = [];
    }

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.instance) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref.instance;
        return true;
    };

    setRegexInTestValue = () => {
        let that = this;
        let regex = new RegExp("");
        let test = that.state.regexInTestValue;

        try {
            regex = new RegExp(that.state.regexInValue);
            if (LDH.IsValueEmpty(regex) || LDH.IsValueEmpty(test)) {
                that.setState({regexInTestResult: null}, function () {
                    that.setRegexOutTestValue();
                });
                return;
            }
        } catch (ex) {
            that.setState({regexInTestResult: null}, function () {
                that.setRegexOutTestValue();
            });
            return;
        }

        try {
            let result = regex.test(test);
            that.setState({regexInTestResult: result}, function () {
                that.setRegexOutTestValue();
            });
        } catch (ex) {
            that.setState({regexInTestResult: null}, function () {
                that.setRegexOutTestValue();
            });
        }
    };

    setRegexOutTestValue = () => {
        let that = this;
        let regexOutValue = that.state.regexOutValue;
        let regexInTestValue = that.state.regexInTestValue;

        if (LDH.IsValueEmpty(regexInTestValue) ||
            LDH.IsValueEmpty(regexOutValue)) {
            return;
        }
        try {
            let regex = new RegExp(regexOutValue);
            let values = regex.exec(regexInTestValue);
            if (!LDH.IsValueEmpty(values) && values.length > 0) {
                this.setState({regexOutTestValue: values[0]});
            } else {
                this.setState({regexOutTestValue: ""});
            }
        } catch (ex) {
            this.setState({regexOutTestValue: ""});
        }
    };

    regexInValueOnChanged = (e) => {
        let that = this;
        this.setState({regexInValue: e.value}, function () {
            that.setRegexInTestValue();
        });
    };

    regexOutValueOnChanged = (e) => {
        let that = this;
        this.setState({regexOutValue: e.value}, function () {
            that.setRegexOutTestValue();
        });
    };

    regexInTestValueOnChanged = (e) => {
        let that = this;
        this.setState({regexInTestValue: e.value}, function () {
            that.setRegexInTestValue();
        });
    };

    render() {
        return (
            <React.Fragment>
                <div id={"leopard-regex-tester-container-" + this.props.dataViewId}
                     className={"leopard-regex-tester-container"} style={{padding: "10px"}}>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex", marginRight: "20px", width: "100%"}}>
                            <div className={"leopard-doc-editor-label leopard-regex-tester-label"}>
                                RegEx In:
                            </div>
                            <TextBox width={"100%"} defaultValue={this.state.regexInValue} valueChangeEvent={"keyup"}
                                     onValueChanged={this.regexInValueOnChanged}
                                     ref={(e) => this.setObjectInstance({ref: e, controlName: "regexInValueInput"})}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => this.customValidationRuleCallback({
                                            e,
                                            input: "regexInValueInput",
                                            rules: [
                                                {rule: "regex"}
                                            ]
                                        })
                                    }></RequiredRule>
                                </Validator>
                            </TextBox>
                        </div>
                        <div style={{display: "flex", marginRight: "5px", width: "100%"}}>
                            <div className={"leopard-doc-editor-label leopard-regex-tester-label"}>
                                Value:
                            </div>
                            <TextBox width={"100%"} defaultValue={this.state.regexInTestValue}
                                     valueChangeEvent={"keyup"}
                                     onValueChanged={this.regexInTestValueOnChanged}
                                     ref={(e) => this.setObjectInstance({
                                         ref: e,
                                         controlName: "regexInTestValueInput"
                                     })}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => this.customValidationRuleCallback({
                                            e,
                                            input: "regexInTestValueInput",
                                            values: {regexIn: this.state.regexInValue},
                                            rules: [
                                                {rule: "regex-in-test"}
                                            ]
                                        })
                                    }></RequiredRule>
                                </Validator>
                            </TextBox>
                        </div>
                        <div style={{display: "flex", marginRight: "20px", width: "100%"}}>
                            <div className={"leopard-doc-editor-label leopard-regex-tester-label"}>
                                RegEx Out:
                            </div>
                            <TextBox width={"100%"} defaultValue={this.state.regexOutValue} valueChangeEvent={"keyup"}
                                     onValueChanged={this.regexOutValueOnChanged}
                                     ref={(e) => this.setObjectInstance({ref: e, controlName: "regexOutValueInput"})}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => this.customValidationRuleCallback({
                                            e,
                                            input: "regexOutValueInput",
                                            rules: [
                                                {rule: "regex"}
                                            ]
                                        })
                                    }></RequiredRule>
                                </Validator>
                            </TextBox>
                        </div>
                        <div style={{display: "flex", marginRight: "5px", width: "100%"}}>
                            <div className={"leopard-doc-editor-label leopard-regex-tester-label"}>
                                Result:
                            </div>
                            <TextBox width={"100%"} value={this.state.regexOutTestValue} readOnly={true}></TextBox>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardRegexTesterEngine;