import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocDateTimeControl} from './LeopardDocDateTimeControl';

const LeopardDocDateTimeRendererCell = (props) => {
    return React.createElement(LeopardDocDateTimeControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocDateTimeRendererCell);