import React, {Component} from 'react';
import LeopardSecurity from '../security/LeopardSecurity';
import {connect} from "react-redux";

class LeopardLoginUserInfoPanel extends Component {
    logoutButtonOnClick = () => {
        LeopardSecurity.UserLogout();
    };

    render() {
        return (
            <React.Fragment>
                <div className="m-dropdown__body leopard-userinfo-panel">
                    <div className="orange-arrow-top"></div>
                    <div className="m-dropdown__content">
                        <div className={"leopard-leftmenu-category"} style={{padding: "10px"}}>
                            ACCOUNT
                        </div>
                        <div className={"leopard-leftmenu-item"}>
                            <div className={"leopard-leftmenu-item-text"}
                                 style={{padding: "5px 10px 5px 10px", cursor: "pointer"}}
                                 onClick={(e) => this.logoutButtonOnClick(e)}>
                                Logout
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardLoginUserInfoPanel);
