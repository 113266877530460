import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocDropdownControl} from './LeopardDocDropdownControl';

const LeopardDocDropdownRendererCell = (props) => {
    return React.createElement(LeopardDocDropdownControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocDropdownRendererCell);