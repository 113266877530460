import React, {Component} from 'react';
import LeopardAnalyticsDashboardEngine from "./LeopardAnalyticsDashboardEngine";

class LeopardChildAnalyticsDashboard extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardAnalyticsDashboardEngine
                    analyticsDefinition={this.props.definition}
                    dashboardLevel={this.props.dashboardLevel}
                    useStateStore={this.props.useStateStore}
                    relationships={this.props.relationships}
                    setAnalyticsInstance={this.props.setAnalyticsInstance}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                    isDataView={this.props.isDataView}
                    dataViewId={this.props.dataViewId}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildAnalyticsDashboard;
