import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardTimelineEngine from '../foundation/LeopardTimelineEngine';
import LeopardTimelineDesigner from '../datashaping/LeopardTimelineDesigner';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardSecurity from "../security/LeopardSecurity";

class LeopardTimelineConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false
        }
    }

    timelineDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    initializeTimelineDesignerPanel() {
        return (
            <div className="leopard-timeline-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardTimelineDesigner
                    dataViewId={this.props.dataViewId} definition={this.props.definition}
                    timelineDesignerButtonOnClick={(e) => this.timelineDesignerButtonOnClick(e)}>
                </LeopardTimelineDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar() {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"Timeline_Admin_ConfigureTimeline_" + this.props.dataViewId}
                        labelText={"Configure Timeline"} width={250} title={"Configure Timeline"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.timelineDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the timeline settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    render() {
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;
        return (
            <React.Fragment>
                {!(showGridViewAdminToolbar && !isExpanded) ? "" : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeTimelineDesignerPanel()}
                <LeopardTimelineEngine
                    definition={this.props.definition}
                    dataInitializedOnControls={this.props.dataInitializedOnControls}
                    dataInitializedOnControlsUpdateRequest={this.props.dataInitializedOnControlsUpdateRequest}
                    setTimelineInstance={this.props.setTimelineInstance} useStateStore={false}
                    dataViewId={this.props.dataViewId}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardTimelineConfiguration);
