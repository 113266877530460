import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import {Button} from "devextreme-react";
import LDH from "../helpers/LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardTooltipWithLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    onMouseEnter = () => {
        this.setState({visible: true});
    };

    onMouseLeave = () => {
        this.setState({visible: false});
    };

    render() {
        let additionalClass = "";
        if (!LDH.IsObjectNull(this.props.additionalClass)) {
            additionalClass = this.props.additionalClass;
        }

        let attr = {};
        if (LDH.IsObjectNull(this.props.refName) === false &&
            LDH.IsValueEmpty(this.props.refName) === false) {
            attr = {
                ref: (e) => this.props.refFunc({
                    e: (LDH.IsObjectNull(e) || LDH.IsObjectNull(e.instance)) ? null : e.instance,
                    name: this.props.refName
                })
            };
        }

        let useSubmitBehavior = !LDH.IsObjectNull(this.props.useSubmitBehavior) && this.props.useSubmitBehavior;
        return (
            <React.Fragment>
                    <span onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>
                        <Button text={this.props.labelText} className={"leopard-buttonlink " + additionalClass}
                                id={this.props.elementId} {...attr} useSubmitBehavior={useSubmitBehavior}
                                onClick={(e) => this.props.onClick({e, gridViewId: this.props.gridViewId})}
                                visible={(LDH.IsObjectNull(this.props.controlVisible) || LDH.IsValueEmpty(this.props.controlVisible))
                                    ? true : this.props.controlVisible}
                        ></Button>
                    </span>
                {
                    LDH.IsValueEmpty(this.props.text) ? null :
                        <div style={{position: "absolute"}}>
                            <Popover target={"#" + this.props.elementId} position={'top'}
                                     showTitle={true} title={this.props.title}
                                     width={this.props.width} visible={this.state.visible}
                                     animation={LeopardStaticUIConfig.TooltipAnimationConfig}>
                                {this.props.text}
                            </Popover>
                        </div>
                }
            </React.Fragment>);
    }
}

export default LeopardTooltipWithLink;
