import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import ColorBox from 'devextreme-react/color-box';
import {Button as TextBoxButton, TextBox} from 'devextreme-react/text-box';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {RequiredRule, Validator} from 'devextreme-react/validator';
import $ from "jquery";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardParametersPanel from "./LeopardParametersPanel";

class LeopardTimelineDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewDataButtonText: "View Data",
            selectedIndex: 0,
            timelineDefinition: {}
        };

        this.definitionParameters = [];
        this.autoApplyParameterFilter = true;
        this.topMenuJustify = "left";
        this.globalVariablesInit = "";
        this.uiObjectInstance = [];
    }

    componentDidMount = () => {
        let definition = this.props.definition.timelineDefinition;

        this.definitionParameters = definition.parameters;
        if (LDH.IsObjectNull(definition.parameters)) {
            this.definitionParameters = [];
        }

        this.autoApplyParameterFilter = definition.autoApplyParameterFilter;
        if (LDH.IsObjectNull(definition.autoApplyParameterFilter)) {
            this.autoApplyParameterFilter = true;
        }

        this.topMenuJustify = definition.topMenuJustify;
        if (LDH.IsObjectNull(definition.topMenuJustify)) {
            this.topMenuJustify = "left";
        }

        this.globalVariablesInit = definition.globalVariablesInit;
        if (LDH.IsObjectNull(definition.globalVariablesInit)) {
            this.globalVariablesInit = "";
        }
        this.setState({timelineDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    updateTimelineDefinition = (data) => {
        let inputValue = data.e.value;
        let timelineDef = this.state.timelineDefinition;
        let clonedTimelineDef = LDH.DeepClone(timelineDef);
        clonedTimelineDef[data.prop] = inputValue;

        if (data.tabInfo.settingsName === "enableAutoRefresh") {
            if (data.e.value === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }
        }

        this.setState({timelineDefinition: clonedTimelineDef}, function () {
            timelineDef = this.state.timelineDefinition;
        });
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.timelineDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.timelineDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        return true;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    previewButtonOnClick = (callback) => {

    };

    saveDataButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let definition = LDH.DeepClone(that.state.timelineDefinition);
        definition.parameters = that.definitionParameters;
        definition.autoApplyParameterFilter = that.autoApplyParameterFilter;
        definition.topMenuJustify = that.topMenuJustify;
        definition.globalVariablesInit = that.globalVariablesInit;

        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configuretimeline").css("visibility", "visible");

        that.uiObjectInstance["designer_save_button"].option("disabled", true);
        that.uiObjectInstance["designer_close_button"].option("disabled", true);

        setTimeout(function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId,
                dataViewId, definition, function () {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                    $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LRH.ShowToast("Your timeline configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configuretimeline").css("visibility", "hidden");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, "timeline");
        }, 100);
    };

    onSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    };

    cancelButtonOnClick = (e) => {
        this.props.timelineDesignerButtonOnClick(e);
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    initializeSettingsTextBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                         onValueChanged={(e) => this.updateTimelineDefinition({
                                             e, tabInfo: data, prop: data.settingsName,
                                             supportPreview
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule validationCallback={(e) => this.customValidationRuleCallback({
                                            e, input: data.settingsName, rules: [{rule: ""}]
                                        })} type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextAreaWithButtonField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        let height = LDH.IsObjectNull(data.height) ? "140px" : data.height;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={height}
                                          onValueChanged={(e) => this.updateTimelineDefinition({
                                              e, tabInfo: data, prop: data.settingsName,
                                              supportPreview
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule
                                                    validationCallback={(e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: data.settingsName,
                                                        rules: [{rule: ""}]
                                                    })} type="custom"/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton location={'after'} options={{
                                                icon: data.buttonIcon, type: 'default',
                                                onClick: () => {
                                                    data.onClick();
                                                }
                                            }} name={data.settingsName + "_MiniButton"}>
                                            </TextBoxButton>
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsSelectBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateTimelineDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    numberOfSeriesOnTimelineTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    initializeSettingsNumberBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateTimelineDefinition({
                                               e, tabInfo: data, prop: data.settingsName,
                                               supportPreview
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsColorBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <ColorBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          onValueChanged={(e) => this.updateTimelineDefinition({
                                              e, tabInfo: data, prop: data.settingsName,
                                              supportPreview
                                          })}>
                                </ColorBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    definitionParametersOnChanged = (data) => {
        this.definitionParameters = data.definitionParameters;
        this.autoApplyParameterFilter = data.autoApplyParameterFilter;
        this.topMenuJustify = data.topMenuJustify;
        this.globalVariablesInit = data.globalVariablesInit;
    };

    tabItemContent = (data) => {
        if (data.tabId === 0) {

            let definition = this.state.timelineDefinition;
            setTimeout(function () {
                if (!LDH.IsObjectNull(definition.enableAutoRefresh) && definition.enableAutoRefresh === true) {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
                } else {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
                }
            }, 100);

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "timelineStartDayHour",
                                            defaultValue: 8,
                                            displayName: "Start day hour:",
                                            minValue: 0,
                                            maxValue: 24
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "timelineEndDayHour",
                                            defaultValue: 20,
                                            displayName: "End day hour:",
                                            minValue: 0,
                                            maxValue: 24
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "timelineCellDuration",
                                            defaultValue: 30,
                                            displayName: "Cell duration (minutes):",
                                            minValue: 1,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "timelineFirstDayOfWeek",
                                            defaultValue: 0,
                                            displayName: "First day of week:",
                                            minValue: 0,
                                            maxValue: 6
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "timelineDefaultTimelineView",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionDefaultTimeline[0].id,
                                            displayName: "Default timeline view:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionDefaultTimeline
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineViewTypes",
                                            defaultValue: "timelineDay,timelineWeek,timelineWorkWeek,timelineMonth",
                                            displayName: "Types of timeline view:",
                                            placeholder: "timelineDay,timelineMonth"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineMaxAppointmentsPerCell",
                                            defaultValue: "unlimited",
                                            displayName: "Maximum appointments per cell:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "timelineCellHeight",
                                            defaultValue: 80,
                                            displayName: "Appointment cell height:",
                                            minValue: 5,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineSchedulerTimezone",
                                            defaultValue: "Australia/Sydney",
                                            displayName: "Timezone:",
                                            placeholder: "Australia/Sydney"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Data Keys & Fields</div>
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineOwnerFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Color definition data key:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineDataKeyForResources",
                                            defaultValue: undefined,
                                            displayName: "Resource data key:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineDateFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Timeline date field name:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineGroupFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Timeline group field name:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineStartDateFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Timeline start date field name:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "timelineEndDateFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Timeline end date field name:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "parentStartDateFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Parent start date field name:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "parentEndDateFieldInResources",
                                            defaultValue: undefined,
                                            displayName: "Parent end date field name:",
                                            placeholder: undefined
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell" style={{marginTop: "1px"}}>
                                    <div className="leopard-settings-panel-title">
                                        <span>Real-time update</span>
                                    </div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "enableAutoRefresh",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[0].id,
                                            displayName: "Enable auto-refresh:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "autoRefreshInterval",
                                            defaultValue: 30,
                                            displayName: "Auto-refresh interval (seconds):",
                                            minValue: 5,
                                            maxValue: 9999,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showAutoRefreshSwitch",
                                            defaultValue: LeopardDropdownHelper.DropdownSelectionYesNo[1].id,
                                            displayName: "Show auto-refresh switch:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={600}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Server-Side Query Editor</span>
                                    </div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "timelineResourceDefinition",
                                            defaultValue: undefined,
                                            displayName: "Color definition for resources:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            validator: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "timelineOdataQueryForGrouping",
                                            defaultValue: undefined,
                                            displayName: "OData query for grouping:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            validator: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "timelinePostDataProcessingForGrouping",
                                            defaultValue: undefined,
                                            displayName: "Post data processing for grouping:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            validator: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "timelineOdataQueryForResources",
                                            defaultValue: undefined,
                                            displayName: "OData query for timeline view:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            validator: false
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "timelinePostDataProcessingForResources",
                                            defaultValue: undefined,
                                            displayName: "Post data processing for timeline view:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            validator: false
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Workspace Interactive Features</span>
                                    </div>
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "oDataQueryForLinkedView",
                                            defaultValue: undefined,
                                            displayName: "OData paramters:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            height: "311px",
                                            placeholder: "Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "oDataQueryNonSelectionForLinkedView",
                                            defaultValue: undefined,
                                            displayName: "OData paramters for non-selection:",
                                            supportPreview: false,
                                            hasMiniButton: false,
                                            height: "311px",
                                            placeholder: "Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 2) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <LeopardParametersPanel
                            dataViewId={this.props.dataViewId} autoApplyParameterFilter={this.autoApplyParameterFilter}
                            definitionParameters={this.definitionParameters} topMenuJustify={this.topMenuJustify}
                            globalVariablesInit={this.globalVariablesInit}
                            definitionParametersOnChanged={(e) => this.definitionParametersOnChanged(e)}
                        />
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.saveDataButtonOnClick}>
                    <div style={{width: "100%", overflowX: "auto"}}>
                        <div className={"leopard-editgridview-title"}>Configure Timeline</div>
                        <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                            <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionTimelineTabPanel}
                                      onOptionChanged={this.onSelectionChanged}
                                      loop={true} itemTitleRender={this.initializeTabItemTitle}
                                      itemComponent={this.tabItemContent} animationEnabled={true} swipeEnabled={false}
                            ></TabPanel>
                        </div>
                    </div>
                    <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_save_button"})}
                                    useSubmitBehavior={true}>
                            </Button>
                        </span>
                        <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_close_button"})}
                                    onClick={(e) => this.cancelButtonOnClick({e})}>
                            </Button>
                        </span>
                        <span className={"loading-progress-configuretimeline"}>
                            <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                        </span>
                    </div>
                </form>
                <br/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardTimelineDesigner);
