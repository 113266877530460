import React from 'react';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LDH from "../helpers/LeopardDataHelper";
import LeopardDataHelper from "../helpers/LeopardDataHelper";
import $ from 'jquery';
import {boundingExtent} from 'ol/extent';
import olOpenStreetMap from 'ol/source/OSM';
import {defaults} from 'olgm/interaction.js';
import olGoogleMaps from 'olgm/OLGoogleMaps.js';
import olGoogleLayer from 'olgm/layer/Google.js';
import olMap from "ol/Map";
import olView from "ol/View";
import olTile from "ol/layer/Tile";
import * as olProj from 'ol/proj';
import GeoJSON from "ol/format/GeoJSON";
import {Stroke, Style} from "ol/style";
import olOverlay from "ol/Overlay";
import {DragPan, MouseWheelZoom} from "ol/interaction";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";

class LeopardOpenLayersHelper extends React.Component {
    static InitializeMap(provider, viewOptions, elemName) {
        switch (provider) {
            case LeopardStaticUIConfig.MapProviderGoogle: {
                return new olGoogleMaps({
                    map: new olMap({
                        interactions: defaults({
                            dragPan: false,
                            mouseWheelZoom: false
                        }).extend([
                            new DragPan({kinetic: false}),
                            new MouseWheelZoom({duration: 0})
                        ]),
                        view: new olView(viewOptions),
                        layers: [new olGoogleLayer()],
                        target: elemName
                    }),
                    gmapOptions: {
                        mapId: LeopardStaticUIConfig.GoogleMapId,
                        animatedZoom: false
                    }
                });
                break;
            }
            case LeopardStaticUIConfig.MapProviderOpenStreetMap: {
                let source = new olOpenStreetMap();
                return new olMap({
                    interactions: defaults({
                        dragPan: false,
                        mouseWheelZoom: false
                    }).extend([
                        new DragPan({kinetic: false}),
                        new MouseWheelZoom({duration: 0})
                    ]),
                    view: new olView(viewOptions),
                    layers: [new olTile({source: source})],
                    target: elemName
                });
                break;
            }
        }
        return null;
    };

    static GetStyles(mapDefinition) {
        let routeStrokeColor = LeopardStaticUIConfig.DefaultMapRouteStrokeColor;
        if (!LDH.IsObjectNull(mapDefinition.routeStrokeColor) &&
            !LDH.IsValueEmpty(mapDefinition.routeStrokeColor)) {
            routeStrokeColor = mapDefinition.routeStrokeColor;
        }
        let routeStrokeWidth = LeopardStaticUIConfig.DefaultMapRouteStrokeWidth;
        if (!LDH.IsObjectNull(mapDefinition.routeStrokeWidth) &&
            !LDH.IsValueEmpty(mapDefinition.routeStrokeWidth)) {
            routeStrokeWidth = mapDefinition.routeStrokeWidth;
        }
        return {
            routeStyle: new Style({
                stroke: new Stroke({width: routeStrokeWidth, color: routeStrokeColor})
            })
        };
    };

    static CenterMapByCoords(mapControl, coords3857) {
        let mapView = mapControl.getView();
        mapView.setCenter(coords3857);
        mapView.setZoom(18);
        mapControl.updateSize();
    };

    static CenterMapByMultipleCoords(mapControl, coords3857List) {
        let mapView = mapControl.getView();
        mapView.fit(boundingExtent(coords3857List),{
            constrainResolution: false,
            padding: [5, 5, 5, 5]
        });
        mapControl.updateSize();
    };

    static AddMapLayerByGeoJSON(jsonData, mapControl, mapDefinition) {
        let features = LeopardOpenLayersHelper.GetFeatureByGeoJSON(jsonData, "EPSG:3857");
        let styles = LeopardOpenLayersHelper.GetStyles(mapDefinition);
        features[0].setStyle(styles.routeStyle);

        mapControl.addLayer(new VectorLayer({
            source: new VectorSource({features: features, style: styles}),
            id: "layer_vector_route___" + LDH.GenerateGuid()
        }));
    };

    static ClearRoutesOnMap(mapControl) {
        let layers = mapControl.getLayers().getArray();
        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i];
            if (LDH.IsObjectNull(layer.values_) ||
                LDH.IsObjectNull(layer.values_.id)) {
                continue;
            }
            let id = layer.values_.id;
            if (id.startsWith("layer_vector_route_")) {
                mapControl.removeLayer(layer);
                i--;
            }
        }
    };

    static ClearOverlaysOnMap(mapControl) {
        let overlays = mapControl.getOverlays().getArray();
        for (let i = 0; i < overlays.length; i++) {
            let overlay = overlays[i];
            let id = overlay.getId();
            if (LDH.IsValueEmpty(id)) continue;
            if (id.startsWith("overlay_marker_")) {
                mapControl.removeOverlay(overlay);
                i--;
            }
        }
    };

    static SetMapExtentByFeature(mapControl, feature, mapDefinition) {
        let top = 50;
        if (!LDH.IsObjectNull(mapDefinition.routeExtentPaddingTop) &&
            !LDH.IsValueEmpty(mapDefinition.routeExtentPaddingTop)) {
            top = mapDefinition.routeExtentPaddingTop;
        }
        let right = 50;
        if (!LDH.IsObjectNull(mapDefinition.routeExtentPaddingRight) &&
            !LDH.IsValueEmpty(mapDefinition.routeExtentPaddingRight)) {
            right = mapDefinition.routeExtentPaddingRight;
        }
        let bottom = 50;
        if (!LDH.IsObjectNull(mapDefinition.routeExtentPaddingBottom) &&
            !LDH.IsValueEmpty(mapDefinition.routeExtentPaddingBottom)) {
            bottom = mapDefinition.routeExtentPaddingBottom;
        }
        let left = 50;
        if (!LDH.IsObjectNull(mapDefinition.routeExtentPaddingLeft) &&
            !LDH.IsValueEmpty(mapDefinition.routeExtentPaddingLeft)) {
            left = mapDefinition.routeExtentPaddingLeft;
        }

        let mapView = mapControl.getView();
        mapView.fit(feature.getGeometry().getExtent(), {
            constrainResolution: false,
            padding: [top, right, bottom, left]
        });
        mapControl.updateSize();
    }

    static CreateMarker(definition, coords, mapControl, dataViewId, index, totalLength,
                        uiObjectInstances, parentData, thisComp, pointData) {
        let markerBgColor = LeopardStaticUIConfig.DefaultMapMarkerBgColor;
        if (!LDH.IsObjectNull(definition.markerBgColor) &&
            !LDH.IsValueEmpty(definition.markerBgColor)) {
            markerBgColor = definition.markerBgColor;
        }

        let markerTextColor = LeopardStaticUIConfig.DefaultMapMarkerTextColor;
        if (!LDH.IsObjectNull(definition.markerTextColor) &&
            !LDH.IsValueEmpty(definition.markerTextColor)) {
            markerTextColor = definition.markerTextColor;
        }

        let markerSize = LeopardStaticUIConfig.DefaultMapMarkerSize;
        if (!LDH.IsObjectNull(definition.markerSize) &&
            !LDH.IsValueEmpty(definition.markerSize)) {
            markerSize = definition.markerSize;
        }

        let markerCustomText = "";
        if (!LDH.IsObjectNull(definition.markerCustomText) &&
            !LDH.IsValueEmpty(definition.markerCustomText)) {
            markerCustomText = definition.markerCustomText;
        }

        let markerFirstBgColor = LeopardStaticUIConfig.DefaultMapMarkerBgColor;
        if (!LDH.IsObjectNull(definition.markerFirstBgColor) &&
            !LDH.IsValueEmpty(definition.markerFirstBgColor)) {
            markerFirstBgColor = definition.markerFirstBgColor;
        }

        let markerFirstTextColor = LeopardStaticUIConfig.DefaultMapMarkerTextColor;
        if (!LDH.IsObjectNull(definition.markerFirstTextColor) &&
            !LDH.IsValueEmpty(definition.markerFirstTextColor)) {
            markerFirstTextColor = definition.markerFirstTextColor;
        }

        let markerFirstSize = LeopardStaticUIConfig.DefaultMapMarkerSize;
        if (!LDH.IsObjectNull(definition.markerFirstSize) &&
            !LDH.IsValueEmpty(definition.markerFirstSize)) {
            markerFirstSize = definition.markerFirstSize;
        }

        let markerFirstCustomText = "";
        if (!LDH.IsObjectNull(definition.markerFirstCustomText) &&
            !LDH.IsValueEmpty(definition.markerFirstCustomText)) {
            markerFirstCustomText = definition.markerFirstCustomText;
        }

        let markerLastBgColor = LeopardStaticUIConfig.DefaultMapMarkerBgColor;
        if (!LDH.IsObjectNull(definition.markerLastBgColor) &&
            !LDH.IsValueEmpty(definition.markerLastBgColor)) {
            markerLastBgColor = definition.markerLastBgColor;
        }

        let markerLastTextColor = LeopardStaticUIConfig.DefaultMapMarkerTextColor;
        if (!LDH.IsObjectNull(definition.markerLastTextColor) &&
            !LDH.IsValueEmpty(definition.markerLastTextColor)) {
            markerLastTextColor = definition.markerLastTextColor;
        }

        let markerLastSize = LeopardStaticUIConfig.DefaultMapMarkerSize;
        if (!LDH.IsObjectNull(definition.markerLastSize) &&
            !LDH.IsValueEmpty(definition.markerLastSize)) {
            markerLastSize = definition.markerLastSize;
        }

        let markerLastCustomText = "";
        if (!LDH.IsObjectNull(definition.markerLastCustomText) &&
            !LDH.IsValueEmpty(definition.markerLastCustomText)) {
            markerLastCustomText = definition.markerLastCustomText;
        }

        if (!LDH.IsObjectNull(pointData["Color"]) &&
            !LDH.IsValueEmpty(pointData["Color"])) {
            markerBgColor = pointData["Color"];
            markerFirstBgColor = pointData["Color"];
            markerLastBgColor = pointData["Color"];
        }

        if (!LDH.IsObjectNull(pointData["Size"]) &&
            !LDH.IsValueEmpty(pointData["Size"])) {
            markerSize = pointData["Size"];
            markerFirstSize = pointData["Size"];
            markerLastSize = pointData["Size"];
        }

        if (!LDH.IsObjectNull(pointData["Label"]) &&
            !LDH.IsValueEmpty(pointData["Label"])) {
            markerCustomText = pointData["Label"];
            markerFirstCustomText = pointData["Label"];
            markerLastCustomText = pointData["Label"];
        }

        if (!LDH.IsObjectNull(pointData["TextColor"]) &&
            !LDH.IsValueEmpty(pointData["TextColor"])) {
            markerTextColor = pointData["TextColor"];
            markerFirstTextColor = pointData["TextColor"];
            markerLastTextColor = pointData["TextColor"];
        }

        let $markerDom = $("#map_marker_template_" + dataViewId).clone();
        let $temp = $("#map_elements_cache_" + dataViewId);
        let tempId = LeopardDataHelper.GenerateGuid();
        $markerDom.attr("id", tempId);
        $temp.append($markerDom);

        let $tempMarker = $("#" + tempId);
        let $marker = $(".leopard-map-marker-container", $tempMarker);
        let $markerText = $(".leopard-map-marker-text", $marker);

        $marker.css("color", markerBgColor);
        $marker.css("font-size", markerSize);

        if (LDH.IsValueEmpty(markerCustomText)) {
            $markerText.text(index.toString());
        } else if (markerCustomText === "-") {
            $markerText.text("");
        } else {
            $markerText.text(markerCustomText);
        }

        $markerText.css("color", markerTextColor);
        $markerText.css("font-size", (12 + (markerSize - 30) / 2) + "px");
        $markerText.css("top", (5 + (markerSize - 30) / 5) + "px");

        if (index === 0) {
            $marker.css("color", markerFirstBgColor);
            $marker.css("font-size", markerFirstSize);

            if (LDH.IsValueEmpty(markerFirstCustomText)) {
                if (LDH.IsValueEmpty(markerCustomText)) {
                    $markerText.text(index.toString());
                } else if (markerCustomText === "-") {
                    $markerText.text("");
                } else {
                    $markerText.text(markerCustomText);
                }
            } else {
                $markerText.text(markerFirstCustomText);
            }
            $markerText.css("color", markerFirstTextColor);
            $markerText.css("font-size", (12 + (markerFirstSize - 30) / 2) + "px");
            $markerText.css("top", (5 + (markerFirstSize - 30) / 5) + "px");
        }

        if (index + 1 === totalLength && index !== 0) {
            $marker.css("color", markerLastBgColor);
            $marker.css("font-size", markerLastSize);

            if (LDH.IsValueEmpty(markerLastCustomText)) {
                if (LDH.IsValueEmpty(markerCustomText)) {
                    $markerText.text(index.toString());
                } else if (markerCustomText === "-") {
                    $markerText.text("");
                } else {
                    $markerText.text(markerCustomText);
                }
            } else {
                $markerText.text(markerLastCustomText);
            }
            $markerText.css("color", markerLastTextColor);
            $markerText.css("font-size", (12 + (markerLastSize - 30) / 2) + "px");
            $markerText.css("top", (5 + (markerLastSize - 30) / 5) + "px");
        }

        let $tempMarkerCloned = $tempMarker.clone();

        let attr_id = "";
        if (!LDH.IsObjectNull(pointData["Id"]) &&
            !LDH.IsValueEmpty(pointData["Id"])) {
            attr_id = pointData["Id"];
        }

        $tempMarkerCloned.attr("id", "html_marker_" + tempId);
        $tempMarkerCloned.attr("attr_id", attr_id);

        $tempMarkerCloned.off("click").on("click", function () {
            let popover = uiObjectInstances["popoverControl"].instance;
            popover.option("target", $tempMarkerCloned);
            popover.option("pointData", pointData);
            popover.option("parentData", parentData);
            popover.option("visible", true);
            return false;
        });

        $tempMarkerCloned.off("mousewheel").on("mousewheel", function () {
            return false;
        });

        let overlay = new olOverlay({
            position: coords,
            positioning: 'bottom-center',
            element: $tempMarkerCloned[0],
            id: "overlay_marker___" + LDH.GenerateGuid()
        });
        mapControl.addOverlay(overlay);

        return overlay;
    };

    static GetFeatureByGeoJSON(jsonData, projection) {
        let geoJSON = new GeoJSON({featureProjection: projection});
        return geoJSON.readFeatures(jsonData);
    };

    static ConvertCoordsToEPSG3857(coords) {
        return olProj.transform(coords, "EPSG:4326", "EPSG:3857");
    };
}

export default LeopardOpenLayersHelper;
