import React, {Component} from 'react';
import {FormBuilder} from '@formio/react';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";

class LeopardHtmlFormBuilderDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSchema: []
        };
        this.dataViewId = "";
        this.parentComp = null;
    }

    updateFormSchema(formSchema) {
        this.setState({formSchema: []}, function(){
            this.setState({
                formSchema: formSchema
            });
        });
    }

    componentDidMount() {
        this.dataViewId = this.props.dataViewId;
        this.parentComp = this.props.parentComp;
    }

    render() {
        let that = this;
        return (
            <React.Fragment>
                <FormBuilder
                    form={{components: that.state.formSchema}}
                    onChange={(schema) => that.props.updateFullSchema(schema)}
                    options={{
                        builder: LeopardStaticUIConfig.FormBuilderConfiguration
                    }}
                />
            </React.Fragment>
        );
    }
}

export default LeopardHtmlFormBuilderDesigner;