import React from 'react';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {LeopardDocColorPickerControl} from './LeopardDocColorPickerControl';

const LeopardDocColorPickerRenderer = (props) => {
    return React.createElement(LeopardDocColorPickerControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsControlProps(LeopardDocColorPickerRenderer);