import React, {Component} from 'react';
import LDH from '../helpers/LeopardDataHelper';
import LRH from "../helpers/LeopardReactHelper";
import Diagram, {Nodes, AutoLayout, Edges, Toolbox, PropertiesPanel} from 'devextreme-react/diagram';
import ArrayStore from 'devextreme/data/array_store';

class LeopardDiagramEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.diagramDefinition = [];
        this.dataViewId = null;
        this.uiObjectInstances = [];
        this.orgItems = [
            {
                id: '106',
                text: 'Development',
                type: 'ellipse',
            },
            {
                id: '107',
                text: 'WinForms\nTeam',
                type: 'ellipse',
            },
            {
                id: '108',
                text: 'WPF\nTeam',
                type: 'ellipse',
            },
            {
                id: '109',
                text: 'Javascript\nTeam',
                type: 'ellipse',
            },
            {
                id: '110',
                text: 'ASP.NET\nTeam',
                type: 'ellipse',
            },
            {
                id: '112',
                text: 'Ken Samuelson',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '113',
                text: 'Terry Bradley',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '115',
                text: 'Nat Maguiree',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '116',
                text: 'Gabe Jones',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '117',
                text: 'Lucy Ball',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '119',
                text: 'Bart Arnaz',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '120',
                text: 'Leah Simpson',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '122',
                text: 'Hannah Brookly',
                type: 'cardWithImageOnLeft'
            },
            {
                id: '123',
                text: 'Arnie Schwartz',
                type: 'cardWithImageOnLeft'
            },
        ];
        this.orgLinks = [
            {
                id: '124',
                from: '106',
                to: '108',
            },
            {
                id: '125',
                from: '106',
                to: '109',
            },
            {
                id: '126',
                from: '106',
                to: '107',
            },
            {
                id: '127',
                from: '106',
                to: '110',
            },
            {
                id: '129',
                from: '110',
                to: '112',
            },
            {
                id: '130',
                from: '110',
                to: '113',
            },
            {
                id: '132',
                from: '107',
                to: '115',
            },
            {
                id: '133',
                from: '107',
                to: '116',
            },
            {
                id: '134',
                from: '107',
                to: '117',
            },
            {
                id: '136',
                from: '108',
                to: '119',
            },
            {
                id: '137',
                from: '108',
                to: '120',
            },
            {
                id: '139',
                from: '109',
                to: '122',
            },
            {
                id: '140',
                from: '109',
                to: '123',
            },
        ];
    }

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.instance) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref.instance;
        return true;
    };

    render() {
        let orgItemsDataSource = new ArrayStore({key: 'id', data: this.orgItems});
        let orgLinksDataSource = new ArrayStore({key: 'id', data: this.orgLinks});

        return (
            <React.Fragment>
                <div id={"leopard-diagram-container-" + this.props.dataViewId}
                     className={"leopard-diagram-container"} style={{padding: "0px", height: "calc(100% - 30px)", width: "100%"}}>
                    <Diagram id="diagram" style={{height: "100%", width: "100%"}} readOnly={true} simpleView={true}>
                        <Nodes dataSource={orgItemsDataSource} imageUrlExpr="picture">
                            <AutoLayout orientation="horizontal" type="tree"/>
                        </Nodes>
                        <Edges dataSource={orgLinksDataSource}/>
                        <Toolbox visibility="disabled" />
                        <PropertiesPanel visibility="disabled" />
                    </Diagram>
                </div>
            </React.Fragment>
        );
    }
}

export default LeopardDiagramEngine;