import React, {Component} from 'react';
import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

import LDH from "../helpers/LeopardDataHelper";
import LRH from "../helpers/LeopardReactHelper";

class LeopardDocumentPreviewerEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentDataString: "",
            readOnly: true
        }

        this.uiObjectInstances = [];
        this.controlId = "LeopardDocPreviewer_" + LDH.GenerateGuid();
    }

    componentDidMount() {
        this.setState({
            documentDataString: JSON.stringify(this.props.documentDataJSON, null, 2)
        });
    }

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    updateValuesInState = (data) => {
        this.setState(data);
    }

    onChange = (e) => {
        let that = this;
        this.setState({documentDataString: e}, function () {
            that.props.documentDataPreviewerValueOnChanged(e);
        });
    }

    onValidate = (e) => {
        if (e.length === 0) {
            this.props.onDataPreviewerValidated({isDataValid: true});
        } else {
            this.props.onDataPreviewerValidated({isDataValid: false});
        }
    }

    render() {
        let definition = this.props.documentDefinition;

        return (
            <AceEditor
                mode={definition.documentEditorDataFormat} name={this.controlId} theme="github"
                onChange={this.onChange} showGutter={true} showPrintMargin={true}
                highlightActiveLine={true}
                enableBasicAutocompletion={true} showLineNumbers={true} wrapEnabled={true}
                readOnly={this.state.readOnly} value={this.state.documentDataString}
                height={"100%"} width={"100%"} onValidate={this.onValidate}
                ref={(e) => this.setUIInstance({e: e, name: this.controlId})}>
            </AceEditor>
        );
    }
}

export default LeopardDocumentPreviewerEngine;