import React, {Component} from 'react';
import LeopardHtmlFormBuilderEngine from './LeopardHtmlFormBuilderEngine';

class LeopardChildHtmlFormBuilder extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardHtmlFormBuilderEngine
                    formDefinition={this.props.formDefinition} dashboardLevel={this.props.dashboardLevel}
                    useStateStore={this.props.useStateStore} relationships={this.props.relationships}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                    setTimelineInstance={this.props.setHtmlFormBuilderInstance} isDataView={false}
                    dataViewId={this.props.dataViewId}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildHtmlFormBuilder;
