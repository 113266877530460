import {rankWith, schemaMatches} from '@jsonforms/core';

export default rankWith(1000, schemaMatches(schema => {
    if (schema.hasOwnProperty('uiControl')) {
        return schema['uiControl'] === "date";
    }
    if (!schema.hasOwnProperty('uiControl')) {
        return schema['type'] === "date";
    }
    return false;
}));