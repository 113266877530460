import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocDateControl} from './LeopardDocDateControl';

const LeopardDocDateRendererCell = (props) => {
    return React.createElement(LeopardDocDateControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocDateRendererCell);