import React from 'react';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {LeopardDocDropdownControl} from './LeopardDocDropdownControl';

const LeopardDocDropdownRenderer = (props) => {
    return React.createElement(LeopardDocDropdownControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsControlProps(LeopardDocDropdownRenderer);