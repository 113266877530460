import 'babel-polyfill/dist/polyfill';
import 'whatwg-fetch/fetch';
import 'devextreme/data/odata/store';
import './resources/css/fontawesome/css/all.min.css';
import 'devextreme/dist/css/dx.common.css';
import './resources/css/devextreme/dx.diagram-leopard-theme.css';
import './resources/css/devextreme/dx.generic.leopard-theme.css';

import React from 'react';
import {CookiesProvider} from 'react-cookie';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';
import LeopardReducer from './foundation/LeopardReducer';
import {Analytics, Amplify} from 'aws-amplify';
import LeopardAuthenticator from './LeopardAuthenticator';
import LeopardCognitoConfig from "./foundation/LeopardCognitoConfig";

const store = createStore(LeopardReducer);

Analytics.configure({disabled: true});
Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

ReactDOM.render(
    <div style={{overflow: "hidden"}}>
        <CookiesProvider>
            <LeopardAuthenticator store={store}/>
        </CookiesProvider>
    </div>
    , document.getElementById('root'));
