import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextArea, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";
import LDPH from "../helpers/LeopardDropdownHelper";
import {RequiredRule, Validator} from "devextreme-react/validator";

class LeopardTabDesignPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionParameters: null,
            enableTabSelection: true,
            tabSelectionLogic: null
        };
        this.uiObjectInstances = [];
    }

    componentDidMount() {
        let definition = this.props.definition;
        let enableTabSelection = definition.enableTabSelection;
        let tabSelectionLogic = definition.tabSelectionLogic;
        let definitionParameters = LDH.DeepClone(definition.tabParameters);

        if (LDH.IsObjectNull(definitionParameters)) {
            definitionParameters = [];
        }

        this.setState({
            definitionParameters: definitionParameters,
            enableTabSelection: enableTabSelection,
            tabSelectionLogic: tabSelectionLogic
        });
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null ||
            data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.input] = data.e.instance;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    updateValueForParameters = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        for (let i = 0; i < clonedParameters.length; i++) {
            let currentColumn = clonedParameters[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                currentColumn[pName] = data.e.value;
            }
        }
        this.setState({definitionParameters: clonedParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    parameterDeleteOnClick = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        let filteredParameters = [];
        for (let i = 0; i < clonedParameters.length; i++) {
            if (clonedParameters[i].id !== data.id) {
                filteredParameters.push(clonedParameters[i]);
            }
        }
        let keys = Object.keys(this.uiObjectInstances);
        for (let j = 0; j < keys.length; j++) {
            if (keys[j].startsWith("parameters_" + this.props.dataViewId +
                "_uiControl_" + data.index)) {
                delete this.uiObjectInstances[keys[j]];
            }
        }
        this.setState({definitionParameters: filteredParameters}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    parametersAddOnClick = () => {
        let that = this;
        this.setState({
            definitionParameters: [
                ...this.state.definitionParameters, {
                    id: LDH.GenerateGuid(),
                    autoCompleteOperation: "contains",
                    minSearchLength: 1,
                    displayOrder: 0,
                    controlWidth: 120,
                    allowTyping: true
                }
            ]
        }, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    enableTabSelectionOnChange = (e) => {
        let that = this;
        this.setState({enableTabSelection: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    tabSelectionLogicOnChange = (e) => {
        let that = this;
        this.setState({tabSelectionLogic: e.value}, function () {
            that.props.definitionParametersOnChanged(that.state);
        });
    };

    render() {
        let that = this;
        if (LDH.IsObjectNull(this.state.definitionParameters)) return null;

        let result = this.state.definitionParameters.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.definitionParameters[i].parameterName)
                                ? "Parameter " + (i + 1)
                                : that.state.definitionParameters[i].parameterName}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.parameterDeleteOnClick({
                                   e, id: that.state.definitionParameters[i].id, index: i
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorTabId_" + i + "_help"}
                                                        title={"Tab name"}
                                                        text={"Specify a tab name for this widget."}/>
                                <span>Tab name:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].tabName}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "tabparameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_tabParameterEditorParameterName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "tabName", item: item, index: i
                                         })}>
                                    <Validator>
                                        <RequiredRule
                                            validationCallback={
                                                (e) => that.customValidationRuleCallback({
                                                    e,
                                                    input: "tabparameters_" + that.props.dataViewId +
                                                        "_uiControl_" + i +
                                                        "_tabParameterEditorParameterName",
                                                    rules: [{rule: "required"}]
                                                })
                                            } type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorTabTitle_" + i + "_help"}
                                                        title={"Tab name"}
                                                        text={"Specify a tab title for this widget."}/>
                                <span>Tab title:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].tabTitle}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "tabparameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_tabParameterEditorTabTitle"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "tabTitle", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorDataViewIdForTabPage_" + i + "_help"}
                                                        title={"Data view ID"}
                                                        text={"Specify a Data View ID (not a Persistent ID) for this widget."}/>
                                <span>Data view ID:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].dataViewIdForTabPage}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "tabparameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_tabParameterEditorDataViewIdForTabPage"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "dataViewIdForTabPage", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorTabPageOrder_" + i + "_help"}
                                                        title={"Tab page order"}
                                                        text={"Specify a tab page order for this widget."}/>
                                <span>Tab page order:</span>
                            </div>
                            <div>
                                <NumberBox value={that.state.definitionParameters[i].tabPageOrder}
                                           min={1} max={99} showSpinButtons={true} format={"#0"}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "tabparameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_tabParameterEditorTabPageOrder"
                                           })}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "tabPageOrder", item: item, index: i
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorDataReceivedFromParentLogic_" + i + "_help"}
                                                        title={"Data received from parent logic"}
                                                        text={"Write a javascript logic for data processing."}/>
                                <span>Data received from parent logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].dataReceivedFromParentLogic}
                                          height={100}
                                          ref={(e) => that.setInputFieldInstance({
                                              e,
                                              input: "tabparameters_" + that.props.dataViewId +
                                                  "_uiControl_" + i +
                                                  "_tabParameterEditorDataReceivedFromParentLogic"
                                          })}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "dataReceivedFromParentLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.parametersAddOnClick(e)}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"tabParameterEditorEnableTabSelection_help"}
                                    title={"Enable tab selection"}
                                    text={"Enable tab selection by user."}/>
                                <span>Enable tab selection:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_tabParameterEditorEnableTabSelection"
                                           })}
                                           value={that.state.enableTabSelection}
                                           onValueChanged={(e) => that.enableTabSelectionOnChange(e)}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"tabParameterEditorTabSelectionLogic_help"}
                                                        title={"Tab selection logic"}
                                                        text={"Write a javascript logic for tab selection."}/>
                                <span>Tab selection logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.tabSelectionLogic} height={100}
                                          ref={(e) => that.setInputFieldInstance({
                                              e,
                                              input: "tabparameters_" + that.props.dataViewId +
                                                  "_tabParameterEditorTabSelectionLogic"
                                          })}
                                          onValueChanged={(e) => that.tabSelectionLogicOnChange(e)}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardTabDesignPanel;
