import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardDiagramEngine from "../foundation/LeopardDiagramEngine";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardSecurity from "../security/LeopardSecurity";
import LeopardDiagramDesigner from "../datashaping/LeopardDiagramDesigner";

class LeopardDiagramConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false
        }
    }

    diagramDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    initializeDiagramDesignerPanel() {
        return (
            <div className="leopard-diagram-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardDiagramDesigner
                    dataViewId={this.props.dataViewId} diagramDefinition={this.props.diagramDefinition}
                    diagramDesignerButtonOnClick={(e) => this.diagramDesignerButtonOnClick(e)}>
                </LeopardDiagramDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar() {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"Diagram_Admin_ConfigureDiagram_" + this.props.dataViewId}
                        labelText={"Configure Diagram"} width={250} title={"Configure Diagram"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.diagramDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the diagram settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    render() {
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;
        return (
            <React.Fragment>
                {!(showGridViewAdminToolbar && !isExpanded) ? "" : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeDiagramDesignerPanel()}
                <LeopardDiagramEngine
                    diagramDefinition={this.props.diagramDefinition}
                    dataViewId={this.props.dataViewId}
                    isDataView={this.props.isDataView}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardDiagramConfiguration);
