import React from 'react';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {LeopardDocDateControl} from './LeopardDocDateControl';

const LeopardDocDateRenderer = (props) => {
    return React.createElement(LeopardDocDateControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsControlProps(LeopardDocDateRenderer);