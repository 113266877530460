import React, {Component} from 'react';
import LeopardGridView from '../foundation/LeopardGridViewEngine';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LDH from '../helpers/LeopardDataHelper';
import AdministrativeTools from "./LeopardGridViewAdministrativeTools";
import {connect} from "react-redux";

class LeopardGridViewConfiguration extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            dataViewLayouts: this.props.dataViewLayouts,
            dataSourceUrl: null,
            allowEditingGridView: false,
            allowDraggingGridView: false,
            allowDroppingGridView: false,
            explicitFilterOption: LeopardStaticUIConfig.GridView_ExplicitFilterOption,
            filterBuilderValueGridView: "",
            enableAutoRefresh: this.props.gridViewDefinition.enableAutoRefresh,
            autoRefreshInterval: this.props.gridViewDefinition.autoRefreshInterval,
            columnResizingModeGridView: LeopardStaticUIConfig.GridView_ColumnResizingMode,
            customColumnOverallWidth: this.props.gridViewDefinition.customColumnOverallWidth
        }
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    applyGridViewConfigurationOnDataView = (data) => {
        this.setState({
            columnResizingModeGridView: data.columnResizingMode,
            filterBuilderValueGridView: data.filterBuilderValue,
            allowDraggingGridView: data.allowDragging,
            allowDroppingGridView: data.allowDropping,
            explicitFilterOption: data.explicitFilterOption,
            enableAutoRefresh: data.enableAutoRefresh,
            autoRefreshInterval: data.autoRefreshInterval,
            customColumnOverallWidth: data.customColumnOverallWidth
        });
        this.props.updateWindowDimensionsRequired();
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeGridViewAdministrativeToolbar() {
        return (
            <AdministrativeTools
                gridViewDefinition={this.props.gridViewDefinition}
                gridViewId={this.props.gridViewId}
                parentComp={this}
                styleDefinition={this.props.styleDefinition}
                dataSourceUrl={this.props.dataSourceUrl}
                columnFieldList={this.props.columnFieldList}
                applyGridViewConfigurationOnDataView={
                    this.applyGridViewConfigurationOnDataView
                }
                updateGridViewDefinitionOnSingleDataView={
                    this.props.updateGridViewDefinitionOnSingleDataView
                }>
            </AdministrativeTools>
        );
    };

    initializeGridViewConfigurationPanel() {
        return (
            <RenderLeopardGridViewConfiguration
                gridViewId={this.props.gridViewId}
                dashboardId={this.props.dashboardId}
                gridViewName={this.props.gridViewName}
                viewOptionsText={this.props.viewOptionsText}
                dataSourceUrl={this.props.dataSourceUrl}
                useStateStore={false}
                isGridViewJSONEngine={this.props.isGridViewJSONEngine}
                staticJSONData={this.props.staticJSONData}
                dashboardLayoutDataViewMapping={this.props.dashboardLayoutDataViewMapping}
                explicitFilterOption={this.state.explicitFilterOption}
                gridViewHeight={this.props.windowHeight - 165}
                dataInitializedOnControls={this.props.dataInitializedOnControls}
                dataInitializedOnControlsUpdateRequest={
                    (e) => this.props.dataInitializedOnControlsUpdateRequest(e)
                }
                gridViewDefinition={this.props.gridViewDefinition}
                isLoadForTabbedView={this.props.isLoadForTabbedView}
                columnResizingMode={this.state.columnResizingModeGridView}
                filterBuilderValue={this.state.filterBuilderValueGridView}
                allowDragging={this.state.allowDraggingGridView}
                allowDropping={this.state.allowDroppingGridView}
                columnFieldList={this.props.columnFieldList}
                setGridViewInstance={this.props.setGridViewInstance}
                enableAutoRefresh={this.state.enableAutoRefresh}
                autoRefreshInterval={this.state.autoRefreshInterval}
                customColumnOverallWidth={this.state.customColumnOverallWidth}
                dataViewLayouts={this.state.dataViewLayouts}
                applyGridViewConfigurationOnDataView={
                    (e) => this.applyGridViewConfigurationOnDataView(e)
                }>
            </RenderLeopardGridViewConfiguration>
        );
    }

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                {this.initializeGridViewAdministrativeToolbar()}
                {this.initializeGridViewConfigurationPanel()}
            </React.Fragment>
        );
    };
}

const RenderLeopardGridViewConfiguration = (
    {
        gridViewId, gridViewName, dataSourceUrl, viewOptionsText, gridViewHeight, useStateStore, allowEditing,
        allowDragging, allowDropping, columnFieldList, windowHeight, setGridViewInstance, columnResizingMode,
        gridViewDefinition, filterBuilderValue, applyGridViewConfigurationOnDataView, dataInitializedOnControls,
        dataInitializedOnControlsUpdateRequest, explicitFilterOption, enableAutoRefresh, autoRefreshInterval,
        customColumnOverallWidth, dataViewLayouts, dashboardId, dashboardLayoutDataViewMapping,
        isGridViewJSONEngine, staticJSONData, isLoadForTabbedView
    }) => {
    if (LDH.IsValueEmpty(dataSourceUrl) && !isLoadForTabbedView) return null;
    return (
        <React.Fragment>
            <LeopardGridView
                GridViewId={gridViewId} GridViewName={gridViewName} GetDataFromUrl={dataSourceUrl}
                viewOptionsText={viewOptionsText} gridViewHeight={gridViewHeight} dashboardId={dashboardId}
                useStateStore={useStateStore} minHeightToolbar={"30px"} allowEditing={allowEditing}
                columnResizingMode={columnResizingMode} explicitFilterOption={explicitFilterOption}
                minHeightAdvancedFilter={"31px"} filterBuilderValue={filterBuilderValue}
                dataInitializedOnControls={dataInitializedOnControls} dataViewLayouts={dataViewLayouts}
                allowDragging={allowDragging} allowDropping={allowDropping} columnFieldList={columnFieldList}
                dataInitializedOnControlsUpdateRequest={(e) => dataInitializedOnControlsUpdateRequest(e)}
                setGridViewInstance={setGridViewInstance} gridDefinition={gridViewDefinition}
                enableAutoRefresh={enableAutoRefresh} autoRefreshInterval={autoRefreshInterval}
                applyGridViewConfigurationOnDataView={(e) => applyGridViewConfigurationOnDataView(e)}
                dashboardLayoutDataViewMapping={dashboardLayoutDataViewMapping}
                isGridViewJSONEngine={isGridViewJSONEngine} staticJSONData={staticJSONData}
                windowHeight={windowHeight} customColumnOverallWidth={customColumnOverallWidth}/>
        </React.Fragment>
    );
};

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardGridViewConfiguration);
