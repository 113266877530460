import React, {Component} from 'react';
import {connect} from 'react-redux';

class LeopardMenuTemplateList extends Component {
    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        let that = this;
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let result = this.props.menuItems.map(function (item, i) {
            let selectedClass = that.props.selectedId === item.menuItemId ? "selected" : "";

            return (
                <React.Fragment key={"LeopardMenuTemplateList_" + i}>
                    <div id={"menuitem_dashboard_" + item.menuItemId} parentid={item.menuItemParentId}
                         className={"leopard-leftmenu-item " + (that.props.categoryVisible ?
                             "" : "hidden") + " " + selectedClass}>
                        <div className={"leopard-leftmenu-item-text"} title={item.menuItemName}
                             searchabletext={item.menuItemName}
                             onClick={() => that.props.menuItemOnClick({
                                 item, selected: selectedClass
                             })} custom_attr_url={item.dataSourceUrl}>
                            {item.menuItemName}
                        </div>
                        {
                            showGridViewAdminToolbar === false ? "" :
                                <div className={"leopard-leftmenu-item-options fas fa-ellipsis-h"}
                                     onClick={
                                         () => that.props.menuItemSettingsButtonOnClick({
                                             item, option: "edit_dashboard_options"
                                         })
                                     }
                                />
                        }
                    </div>
                </React.Fragment>
            );
        });
        return (<React.Fragment>{result}</React.Fragment>);
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardMenuTemplateList);
