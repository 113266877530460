import React from 'react';
import {NumberBox} from 'devextreme-react';
import LDH from "../helpers/LeopardDataHelper";
import LRH from "../helpers/LeopardReactHelper";

export class LeopardDocNumberControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldValue: LDH.IsValueEmpty(props.rootProps.data) ? null : props.rootProps.data.toString(),
            displayName: (!LDH.IsObjectNull(props.rootProps.schema) &&
                !LDH.IsObjectNull(props.rootProps.schema.field))
                ? props.rootProps.schema.field.displayName
                : props.rootProps.label,
            disabled: !LDH.IsObjectNull(props.rootProps.schema) &&
                !LDH.IsObjectNull(props.rootProps.schema.field) &&
                !LDH.IsObjectNull(props.rootProps.schema.field.disabled) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.disabled) &&
                props.rootProps.schema.field.disabled === true,
            readOnly: !LDH.IsObjectNull(props.rootProps.schema) &&
                !LDH.IsObjectNull(props.rootProps.schema.field) &&
                !LDH.IsObjectNull(props.rootProps.schema.field.readOnly) &&
                !LDH.IsValueEmpty(props.rootProps.schema.field.readOnly) &&
                props.rootProps.schema.field.readOnly === true
        };
        this.controlId = "LeopardDocEditor_" + LDH.GenerateGuid();
        this.uiObjectInstances = [];
    }

    fieldValueOnChange = (data) => {
        this.setState({fieldValue: data.e.value}, function () {
            data.props.onValueChanged({value: data.e.value});
        });
    }

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        this.uiObjectInstances[data.name] = data.e;
    };

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    render() {
        return (
            <React.Fragment>
                <label className={"leopard-doc-editor-label"} htmlFor={this.controlId}>
                    {this.state.displayName}
                </label>
                <NumberBox defaultValue={this.state.fieldValue}
                           onValueChanged={(e) => this.fieldValueOnChange({e: e, props: this.props})}
                           style={{width: "100%"}} id={this.controlId} name={this.controlId}
                           showSpinButtons={true} format={"#"}
                           disabled={this.state.disabled} readOnly={this.state.readOnly}
                           ref={(e) => this.setUIInstance({e: e, name: this.controlId})}
                           className={"leopard-doc-editor-number-control"}>
                </NumberBox>
            </React.Fragment>
        )
    }
}

LeopardDocNumberControl.getDerivedStateFromProps = (nextProps, prevState) => {
    return null;
};