import React from 'react';
import Chart, {
    Animation,
    ArgumentAxis,
    Crosshair,
    Export,
    Label,
    Legend,
    Margin,
    Point,
    ScrollBar,
    Series,
    Title,
    Tooltip,
    ValueAxis,
    ZoomAndPan
} from 'devextreme-react/chart';
import LDH from "../helpers/LeopardDataHelper";

export const InitializeBarChart = (
    genericChartProps, chartDefinition, genericZoomAndPanProps, genericValueAxisProps,
    genericArgumentAxisProps, genericTooltipProps, genericScrollbarProps,
    genericSeriesBasicProps, genericSeriesLabelProps, genericSeriesPointProps,
    genericLegendBasicProps, genericExportProps, thisComp) => {
    let displayValue = !LDH.IsObjectNull(chartDefinition.hideContentInWorkspace) &&
    chartDefinition.hideContentInWorkspace ? "none" : "block";

    let disableChart = !LDH.IsObjectNull(chartDefinition.hideContentInWorkspace) &&
    chartDefinition.hideContentInWorkspace ? true : false;

    return (
        <div style={{width: "100%", height: "100%", display: displayValue}}>
            <Chart {...genericChartProps} onDrawn={(e) => thisComp.onDrawn(e)}
                   onLegendClick={(e) => thisComp.onLegendClick(e)} onPointClick={(e) => thisComp.onPointClick(e)}
                   onPointSelectionChanged={(e) => thisComp.onPointSelectionChanged(e)}
                   disabled={disableChart}
                   onZoomEnd={(e) => thisComp.onZoomEnd(e)}
                   title={{
                       text: chartDefinition.chartTitle,
                       font: {size: chartDefinition.chartTitleFontSize}
                   }}>
                {thisComp.renderCommonSeriesSettingsForBarChart(chartDefinition)}
                <ZoomAndPan {...genericZoomAndPanProps} argumentAxis={"both"}/>
                <Animation enabled={false} duration={1000}></Animation>
                <Margin top={chartDefinition.chartMarginTop} right={chartDefinition.chartMarginRight}
                        left={chartDefinition.chartMarginLeft} bottom={chartDefinition.chartMarginBottom}>
                </Margin>
                <Crosshair enabled={chartDefinition.chartShowCrosshair} color={chartDefinition.chartCrosshairColor}
                           width={chartDefinition.chartCrosshairWidth} dashStyle={chartDefinition.chartCrosshairStyle}>
                    <Label visible={true} backgroundColor={"#949494"} font={{color: "#fff", size: 12}}></Label>
                </Crosshair>
                <ValueAxis {...genericValueAxisProps} visualRangeUpdateMode={"keep"}>
                    <Title text={chartDefinition.valueAxisTitle}/>
                    <Label customizeText={(e) => thisComp.genericChart_axisCustomizeText(e,
                        genericValueAxisProps.customizeText)}/>
                    {thisComp.renderSeriesHighAverageForBarChart(chartDefinition)}
                    {thisComp.renderSeriesLowAverageForBarChart(chartDefinition)}
                </ValueAxis>
                <ArgumentAxis {...genericArgumentAxisProps} visualRangeUpdateMode={"keep"} defaultVisualRange={
                    LDH.IsObjectNull(chartDefinition.defaultVisualRange) || chartDefinition.defaultVisualRange <= 0
                        ? null : {length: chartDefinition.defaultVisualRange}}>
                    <Title text={chartDefinition.argAxisTitle}/>
                    <Label overlappingBehavior={chartDefinition.argAxisOverlappingBehavior}
                           customizeText={(e) => thisComp.genericChart_axisCustomizeText(e,
                               genericArgumentAxisProps.customizeText)} wordWrap={"none"}/>
                </ArgumentAxis>
                <Tooltip {...genericTooltipProps} customizeTooltip={
                    (e) => thisComp.genericChart_customizeTooltip({e, chartDefinition})
                }/>
                <ScrollBar {...genericScrollbarProps} />
                {
                    (!LDH.IsObjectNull(chartDefinition.numberOfSeriesOnChart) &&
                        chartDefinition.numberOfSeriesOnChart.length > 0) ?
                        chartDefinition.numberOfSeriesOnChart.map(function (item, index) {
                            let valueFieldSplit = chartDefinition.seriesValueField.split("|");
                            let argumentFieldSplit = chartDefinition.seriesArgumentField.split("|");
                            return (
                                <Series {...genericSeriesBasicProps} key={item.id} name={item.name}
                                        valueField={LDH.IsObjectNull(valueFieldSplit[index]) ? ""
                                            : valueFieldSplit[index].toString().trim()}
                                        argumentField={LDH.IsObjectNull(argumentFieldSplit[index]) ? ""
                                            : argumentFieldSplit[index].toString().trim()}>
                                    <Label {...genericSeriesLabelProps} customizeText={
                                        (e) => thisComp.genericChart_customizeLabel({
                                            e, chartDefinition
                                        })}>
                                    </Label>
                                    <Point {...genericSeriesPointProps} />
                                </Series>
                            )
                        }) :
                        (!LDH.IsObjectNull(thisComp.state.chartDataResponse) &&
                            !LDH.IsObjectNull(thisComp.state.chartDataResponse.chartSeriesNameList) &&
                            thisComp.state.chartDataResponse.chartSeriesNameList.length > 0) ?
                            thisComp.state.chartDataResponse.chartSeriesNameList.map(function (item) {
                                return (
                                    <Series {...genericSeriesBasicProps} key={LDH.GenerateGuid()} name={item}
                                            valueField={item}
                                            argumentField={chartDefinition.seriesArgumentField}>
                                        <Label {...genericSeriesLabelProps} customizeText={
                                            (e) => thisComp.genericChart_customizeLabel({
                                                e, chartDefinition
                                            })}>
                                        </Label>
                                        <Point {...genericSeriesPointProps} />
                                    </Series>)
                            }) : null
                }
                <Legend {...genericLegendBasicProps} />
                <Export {...genericExportProps} />
            </Chart>
        </div>
    );
};
