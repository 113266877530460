import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardSecurity from "../security/LeopardSecurity";
import LeopardMapEngine from "../foundation/LeopardMapEngine";
import LeopardMapDesigner from "../datashaping/LeopardMapDesigner";

class LeopardMapConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false
        }
    }

    mapDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    initializeMapDesignerPanel() {
        return (
            <div className="leopard-map-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardMapDesigner
                    dataViewId={this.props.dataViewId} definition={this.props.mapDefinition}
                    mapDesignerButtonOnClick={(e) => this.mapDesignerButtonOnClick(e)}>
                </LeopardMapDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar() {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"Map_Admin_ConfigureMap_" + this.props.dataViewId}
                        labelText={"Configure Map"} width={250} title={"Configure Map"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.mapDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the map settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    render() {
        let definition = this.props.mapDefinition;
        let showGridViewAdminToolbar = false;

        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;
        return (
            <React.Fragment>
                {!(showGridViewAdminToolbar && !isExpanded) ? "" : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeMapDesignerPanel()}
                <LeopardMapEngine mapDefinition={definition.mapDefinition} dataViewId={this.props.dataViewId}
                                  isDataView={this.props.isDataView}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardMapConfiguration);
