import {Component} from 'react';
import LDH from "../helpers/LeopardDataHelper";

class LeopardAPIGatewayConfig extends Component {
    static ProfileAPI_ConfigId = "23D47947-35A2-4669-80EE-D70C469176B4";

    static ProfileAPI_BodyTemplate() {
        return LDH.DeepClone({
            specversion: "1.0",
            type: "leopardsystems.config.retrieve",
            source: "urn:{0}:tbd:loadprofile",
            id: "4391864b-c4b8-4691-beba-8d8c79be73cc",
            time: (new Date()).toISOString(),
            data: []
        });
    };
}

export default LeopardAPIGatewayConfig;
