import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocAutoCompleteControl} from './LeopardDocAutoCompleteControl';

const LeopardDocAutoCompleteRendererCell = (props) => {
    return React.createElement(LeopardDocAutoCompleteControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocAutoCompleteRendererCell);