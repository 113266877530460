import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, TextArea} from 'devextreme-react';
import {CheckBox} from 'devextreme-react/check-box';
import $ from 'jquery';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

class LeopardStyleBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridViewId: null,
            cssValue: "",
            defaultStyleEnabled: true
        };
    }

    componentDidMount() {
        let that = this;
        let definition = this.props.styleDefinition;
        if (definition !== undefined && definition !== null && definition !== "") {
            this.setState({cssValue: definition, gridViewId: this.props.gridViewId}, function () {
                that.previewButtonOnClick();
            });
        } else {
            this.setState({cssValue: "", gridViewId: this.props.gridViewId});
        }
    }

    previewButtonOnClick = () => {
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        let defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href=' + version + '"/src/resources/css/leopard-theme-colors.css?v=" />';
        let customStyle = '<style id="leopard-custom-style" type="text/css">' + this.state.cssValue + '</style>';

        $("#leopard-default-style").remove();
        if (this.state.defaultStyleEnabled) {
            $("head").append(defaultStyle);
        }
        $("#leopard-custom-style").remove();
        $("head").append(customStyle);
    };

    saveButtonOnClick = () => {
        let that = this;
        $(".leopard-styledesigner-loading-progress").css("visibility", "visible");

        let definition = this.state.cssValue;
        let gridViewId = this.state.gridViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        setTimeout(() => {
            that.setState({showLoadingProgress: true}, function () {
                LeopardAjaxHelper.UpdateGridViewStyle(userId, organizationId, gridViewId, definition, function () {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                    $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LRH.ShowToast("Your Grid View configuration has been successfully saved.", "success", 5000);
                    $(".leopard-styledesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else if (!LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message.error) &&
                        error.message.error.indexOf("size has exceeded") > -1) {
                        LRH.ShowToast("Failed to save your configuration. The data has exceeded the maximum size of 400 KB.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".leopard-styledesigner-loading-progress").css("visibility", "hidden");
                });
            });
        }, 100);
    };

    cancelButtonOnClick = () => {
        let that = this;
        $(".leopard-styledesigner-loading-progress").css("visibility", "visible");

        setTimeout(function () {
            that.props.styleBuilderCancelButtonOnClick();
        }, 100);
    };

    aceEditorOnValueChanged = (e) => {
        this.setState({cssValue: e.value});
    };

    overrideOptionOnChange = (e) => {
        this.setState({defaultStyleEnabled: e.value});
    };

    render() {
        if (this.props.styleBuilderVisible === false) return "";

        return (
            <React.Fragment>
                <div className={"leopard-editgridview-title"} style={{marginBottom: "10px"}}>
                    Edit Style
                </div>
                <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                    <TextArea value={this.state.cssValue} height={"200px"}
                              onValueChanged={(e) => this.aceEditorOnValueChanged(e)}>
                    </TextArea>
                </div>
                <div className={'dx-fieldset'} style={{width: "380px", marginTop: "0px", display: "none"}}>
                    <div className={'dx-field'}>
                        <div className={'dx-field-label'}>Leopard Base Style</div>
                        <div className={'dx-field-value'}>
                            <CheckBox style={{float: "left"}} value={this.state.defaultStyleEnabled}
                                      onValueChanged={(e) => this.overrideOptionOnChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px"}}>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className={"leopard-standard-button"} style={{minWidth: "100px"}} text={"Preview"}
                                onClick={this.previewButtonOnClick}/>
                    </span>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className={"leopard-standard-button"} style={{minWidth: "100px"}} text={"Save"}
                                onClick={(e) => this.saveButtonOnClick(e)}/>
                    </span>
                    <span style={{padding: "0 0 0 0"}}>
                        <Button className={"leopard-standard-button"} style={{minWidth: "100px"}} text={"Close"}
                                onClick={(e) => this.cancelButtonOnClick({e, state: this.state})}/>
                    </span>
                    <span className="leopard-styledesigner-loading-progress"
                          style={{
                              marginRight: "15px",
                              marginLeft: "30px",
                              position: "relative",
                              top: "5px",
                              visibility: "hidden"
                          }}>
                        <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                    </span>
                </div>
                <hr/>
                <div className={"leopard-editgridview-title"} style={{marginBottom: "10px", marginTop: "20px"}}>
                    Preview Layout
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardStyleBuilder);
