import React from 'react';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {LeopardDocAutoCompleteControl} from './LeopardDocAutoCompleteControl';

const LeopardDocAutoCompleteRenderer = (props) => {
    return React.createElement(LeopardDocAutoCompleteControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsControlProps(LeopardDocAutoCompleteRenderer);