import React, {Component} from 'react';
import $ from 'jquery';
import {Button, TextBox} from 'devextreme-react';
import LRH from "../helpers/LeopardReactHelper";
import {Popover} from "devextreme-react/popover";
import LDH from "../helpers/LeopardDataHelper";
import {connect} from "react-redux";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardLayoutSelectionPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            layoutButtonMode: "normal",
            layoutNameText: "",
            layoutConfig: null,
            layoutIdToRename: null
        };
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
        this.dashboardId = null;
        this.isDashboardLayout = false;
        this.dashboardLayoutDataViewMapping = [];
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    addUIObjectInstance = (data, addToParent) => {
        this.uiObjectInstances[data.name] = data.instance;

        if (addToParent === true) {
            this.props.setComponentInstance({
                componentName: data.name,
                e: data.instance
            });
        }
    };

    addLayoutOnClick = () => {
        let that = this;
        this.setState({layoutButtonMode: "addlayout"}, function () {
            let className = ".leopard-layout-popup-layoutname-" + that.props.dataViewId;
            $(className + " .dx-texteditor-input").focus();
        });
    };

    renameLayoutOnClick = (data) => {
        let that = this;
        this.setState({
            layoutButtonMode: "renamelayout",
            layoutNameText: data.layoutName,
            layoutIdToRename: data.layoutId
        }, function () {
            let className = ".leopard-layout-popup-layoutname-" + that.props.dataViewId;
            $(className + " .dx-texteditor-input").focus();
        });
    };

    renameConfirmLayoutOnClick = (data) => {
        if (LDH.IsObjectNull(this.state.layoutNameText) ||
            LDH.IsValueEmpty(this.state.layoutNameText.toString().trim())) {
            let className = ".leopard-layout-popup-layoutname-" + this.props.dataViewId;
            $(className + " .dx-texteditor-input").focus();
            return;
        }
        data["layoutName"] = this.state.layoutNameText;
        data["layoutId"] = this.state.layoutIdToRename;
        this.props.renameLayoutOnClick(data);

        let control = this.uiObjectInstances["layoutPopoverControl"];
        control.instance.option("visible", false);
    };

    createLayoutOnClick = (data) => {
        if (!LDH.IsObjectNull(this.state.layoutConfig.layouts) &&
            this.state.layoutConfig.layouts.length >= 8) {
            LRH.ShowToast("You have reached the maximum of 8 layouts per Data View or Workspace.", "error", 5000);
            return;
        }
        if (LDH.IsObjectNull(this.state.layoutNameText) ||
            LDH.IsValueEmpty(this.state.layoutNameText.toString().trim())) {
            let className = ".leopard-layout-popup-layoutname-" + this.props.dataViewId;
            $(className + " .dx-texteditor-input").focus();
            return;
        }
        data["layoutName"] = this.state.layoutNameText;
        this.props.createLayoutOnClick(data);
        let control = this.uiObjectInstances["layoutPopoverControl"];
        control.instance.option("visible", false);
    };

    cancelLayoutOnClick = () => {
        this.setState({layoutButtonMode: "normal", layoutNameText: ""});
    };

    saveLayoutOnClick = (data) => {
        this.props.saveLayoutOnClick(data);
        let control = this.uiObjectInstances["layoutPopoverControl"];
        control.instance.option("visible", false);
    };

    deleteLayoutOnClick = (data) => {
        this.props.deleteLayoutOnClick(data);
        let control = this.uiObjectInstances["layoutPopoverControl"];
        control.instance.option("visible", false);
    };

    layoutNameOnValueChanged = (e) => {
        this.setState({layoutNameText: e.value});
    };

    popupOnHiding = () => {
        this.setState({
            popupVisible: false,
            layoutConfig: null,
            layoutButtonMode: "normal",
            layoutNameText: ""
        });
    };

    popupOnShowing = (e) => {
        this.dashboardId = this.props.dashboardId;
        this.isDashboardLayout = this.props.isDashboardLayout;
        this.dashboardLayoutDataViewMapping = this.props.dashboardLayoutDataViewMapping;
    };

    popupOnShown = () => {
        let that = this;
        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
        let saveButtonControl = this.uiObjectInstances["layoutPopoverSaveButton"];
        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let dataViewPersistentId = this.props.dataViewPersistentId;
        let dataViewId = that.props.dataViewId;

        LeopardAjaxHelper.GetDataViewLayoutById(userId, dataViewPersistentId, function (response) {
            if (that.isDashboardLayout) {
                // Selected a workspace layout.

                let showGridViewAdminToolbar = false;
                if (that.props.state.permissions !== undefined && that.props.state.permissions !== null) {
                    showGridViewAdminToolbar = that.props.state.permissions.ShowGridViewAdminToolbar;
                }
                if (!showGridViewAdminToolbar && LDH.IsValueEmpty(response.selectedLayoutId)) {
                    saveButtonControl.instance.option("disabled", true);
                } else if (!LDH.IsObjectNull(saveButtonControl)) {
                    saveButtonControl.instance.option("disabled", false);
                }
            } else if (LDH.IsObjectNull(response) || LDH.IsValueEmpty(response.selectedLayoutId)) {
                // Selected default layout in data view and loaded in workspace.
                if (!LDH.IsObjectNull(that.dashboardLayoutDataViewMapping) &&
                    that.dashboardLayoutDataViewMapping.length > 0 &&
                    !LDH.IsValueEmpty(that.dashboardId)) {
                    let foundLayout = false;
                    for (let i = 0; i < that.dashboardLayoutDataViewMapping.length; i++) {
                        let mapping = that.dashboardLayoutDataViewMapping[i];
                        if (mapping.dataViewId !== dataViewId) continue;

                        for (let j = 0; j < response.layouts.length; j++) {
                            if (mapping.layoutId === response.layouts[j].layoutId) {
                                response.selectedLayoutId = mapping.layoutId;
                                foundLayout = true;
                                break;
                            }
                        }
                        if (foundLayout) break;
                    }
                }

                if (!LDH.IsObjectNull(saveButtonControl)) {
                    saveButtonControl.instance.option("disabled", true);
                }
            } else if (!LDH.IsObjectNull(that.dashboardLayoutDataViewMapping) &&
                that.dashboardLayoutDataViewMapping.length > 0) {
                // Selected a custom layout in workspace.
                let foundLayout = false;
                for (let i = 0; i < that.dashboardLayoutDataViewMapping.length; i++) {
                    let mapping = that.dashboardLayoutDataViewMapping[i];
                    if (mapping.dataViewId !== dataViewId) continue;

                    for (let j = 0; j < response.layouts.length; j++) {
                        if (mapping.layoutId === response.layouts[j].layoutId) {
                            response.selectedLayoutId = mapping.layoutId;
                            foundLayout = true;
                            break;
                        }
                    }
                    if (foundLayout) break;
                }
                if (!foundLayout) response.selectedLayoutId = null;
            } else if (!LDH.IsValueEmpty(that.dashboardId)) {
                response.selectedLayoutId = null;
                if (!LDH.IsObjectNull(saveButtonControl)) {
                    saveButtonControl.instance.option("disabled", true);
                }
            } else {
                let foundLayout = false;
                for (let i = 0; i < response.layouts.length; i++) {
                    let layout = response.layouts[i];
                    if (layout.layoutId === response.selectedLayoutId &&
                        !LDH.IsValueEmpty(response.selectedLayoutId)) {
                        foundLayout = true;
                    }
                }
                if (!LDH.IsObjectNull(saveButtonControl)) {
                    if (foundLayout) {
                        saveButtonControl.instance.option("disabled", false);
                    } else {
                        saveButtonControl.instance.option("disabled", true);
                    }
                }
            }
            that.setState({layoutConfig: response}, function () {
                let popup = that.uiObjectInstances["layoutPopoverControl"];
                popup.instance.repaint();
            });
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            }
        });
    };

    itemOnClick = (data) => {
        let that = this;
        let layoutConfigToSave = LDH.DeepClone(this.state.layoutConfig);
        if (LDH.IsObjectNull(layoutConfigToSave)) return;
        if (LDH.IsObjectNull(layoutConfigToSave.layouts)) return;

        let userId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
        layoutConfigToSave.selectedLayoutId = data.layoutId;

        let layoutToLoad = null;
        for (let i = 0; i < layoutConfigToSave.layouts.length; i++) {
            let layout = layoutConfigToSave.layouts[i];
            if (layout.layoutId === layoutConfigToSave.selectedLayoutId &&
                !LDH.IsValueEmpty(layoutConfigToSave.selectedLayoutId)) {
                layoutToLoad = layout.layoutData;
            }
        }
        that.setState({layoutConfig: layoutConfigToSave}, function () {
            let popup = that.uiObjectInstances["layoutPopoverControl"];
            popup.instance.option("visible", false);
        });

        if (!LDH.IsValueEmpty(this.dashboardId) && !this.isDashboardLayout) {
            let dataViewPersistentId = that.props.dataViewPersistentId;
            let dataViewId = that.props.dataViewId;

            that.props.requireReloadDataView({
                layoutToLoad: layoutToLoad,
                dataViewPersistentId: dataViewPersistentId,
                dataViewId: dataViewId,
                dashboardId: that.dashboardId,
                isDashboardLayout: that.isDashboardLayout,
                layoutId: data.layoutId
            });
        } else {
            LeopardAjaxHelper.UpdateDataViewLayoutById(userId, organizationId, data.dataViewPersistentId,
                layoutConfigToSave, function () {
                    let dataViewPersistentId = that.props.dataViewPersistentId;
                    let dataViewId = that.props.dataViewId;

                    that.props.requireReloadDataView({
                        layoutToLoad: layoutToLoad,
                        dataViewPersistentId: dataViewPersistentId,
                        dataViewId: dataViewId,
                        dashboardId: that.dashboardId,
                        isDashboardLayout: that.isDashboardLayout,
                        layoutId: data.layoutId
                    });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your layout.", "error", 5000);
                    }
                });
        }
    };

    renderPopupItem = (layoutConfig) => {
        let that = this;
        if (LDH.IsObjectNull(layoutConfig)) {
            return null;
        }
        if (LDH.IsObjectNull(layoutConfig.layouts)) {
            layoutConfig.layouts = [];
        }
        let dataViewPersistentId = that.props.dataViewPersistentId;
        let dataViewId = that.props.dataViewId;

        return layoutConfig.layouts.map(function (item, i) {
            return (
                <div key={"layout-item-" + LDH.GenerateGuid()}
                     className={"layout-popup-link" + " " + (layoutConfig.selectedLayoutId === item.layoutId ? "selected" : "") +
                         " " + (LDH.IsValueEmpty(that.dashboardId) || that.props.isDashboardLayout ? "" : "readonly")}>
                    <div className={"option-text"} title={item.layoutName} layoutid={item.layoutId}
                         layoutversion={item.layoutVersion}
                         onClick={() => that.itemOnClick({
                             layoutId: item.layoutId,
                             dataViewPersistentId: dataViewPersistentId
                         })}>{item.layoutName}</div>

                    {
                        (LDH.IsValueEmpty(that.dashboardId) || that.props.isDashboardLayout) ?
                            <React.Fragment>
                                <i className="option-action-icon fas fa-edit" title={"Rename layout"}
                                   style={{visibility: "visible"}} onClick={() => that.renameLayoutOnClick({
                                    layoutName: item.layoutName,
                                    layoutId: item.layoutId
                                })}></i>
                                <i className="option-action-icon fas fa-minus" title={"Delete layout"}
                                   style={{visibility: "visible"}} onClick={() => that.deleteLayoutOnClick({
                                    layoutId: item.layoutId,
                                    layoutConfig: that.state.layoutConfig,
                                    dataViewPersistentId: dataViewPersistentId,
                                    dataViewId: dataViewId,
                                    layoutName: item.layoutName
                                })}></i>
                            </React.Fragment> : null
                    }
                </div>
            )
        });
    };

    render() {
        if (this.disposingAllInstances) return null;
        let dataViewId = this.props.dataViewId;
        let layoutConfig = LDH.DeepClone(this.state.layoutConfig);
        let isLayoutNull = LDH.IsObjectNull(layoutConfig);
        let dataViewPersistentId = this.props.dataViewPersistentId;

        return (
            <Popover target={"#GridView_TopBar_Layouts_" + dataViewId} position="bottom" width={"auto"}
                     onHiding={(e) => this.popupOnHiding(e)} onShowing={(e) => this.popupOnShowing(e)}
                     ref={(e) => this.addUIObjectInstance({name: "layoutPopoverControl", instance: e}, true)}
                     onShown={(e) => this.popupOnShown(e)} shading={false} animation={null}>

                <div className={"layout-popup-panel-container"} style={{display: isLayoutNull ? "flex" : "none"}}>
                    <span className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse"
                           style={{color: "rgb(255, 128, 0)", fontSize: "30px"}}></i>
                    </span>
                </div>
                <div style={{visibility: !isLayoutNull ? "visible" : "hidden"}}>
                    <div>
                        <div
                            className={"layout-popup-link" + " " +
                                (isLayoutNull || LDH.IsValueEmpty(layoutConfig.selectedLayoutId) ? "selected" : "") +
                                " " + (LDH.IsValueEmpty(this.props.dashboardId) || this.props.isDashboardLayout ? "" : "readonly")}>
                            <div className={"option-text"} title={"Default Layout"}
                                 onClick={() => this.itemOnClick({
                                     layoutId: null,
                                     dataViewPersistentId: dataViewPersistentId
                                 })} style={{minWidth: "100%", maxWidth: "100%"}}>
                                Default Layout
                            </div>
                        </div>
                        {this.renderPopupItem(layoutConfig)}
                    </div>
                    {
                        ((LDH.IsValueEmpty(this.props.dashboardId) || this.props.isDashboardLayout) &&
                            this.state.layoutButtonMode === "normal") ?
                            <div style={{marginTop: "10px", marginBottom: "5px"}}>
                                <div style={{display: "flex"}}>
                                    <Button className="leopard-button-dynamic-size" text={'Save'} width={125}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverSaveButton", instance: e
                                            }, false)}
                                            onClick={() => this.saveLayoutOnClick({
                                                layoutId: this.state.layoutConfig.selectedLayoutId,
                                                layoutConfig: this.state.layoutConfig,
                                                dataViewPersistentId: dataViewPersistentId,
                                                dataViewId: dataViewId
                                            })}></Button>
                                    <div style={{width: "100%"}}></div>
                                    <Button className="leopard-button-dynamic-size" text={'Add'} width={125}
                                            onClick={(e) => this.addLayoutOnClick(e)}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverAddLayoutButton", instance: e
                                            }, false)}></Button>
                                </div>
                            </div> : null
                    }
                    {
                        this.state.layoutButtonMode === "addlayout" ?
                            <div style={{marginTop: "10px", marginBottom: "5px"}}>
                                <div style={{marginBottom: "10px"}}>
                                    <TextBox placeholder={"Enter a layout name..."} value={this.state.layoutNameText}
                                             className={"leopard-layout-popup-layoutname-" + dataViewId}
                                             onValueChanged={(e) => this.layoutNameOnValueChanged(e)}
                                             maxLength={50}></TextBox>
                                </div>
                                <div style={{display: "flex"}}>
                                    <Button className="leopard-button-dynamic-size" text={'Create'} width={125}
                                            onClick={() => this.createLayoutOnClick({
                                                layoutId: LDH.GenerateGuid(),
                                                layoutConfig: this.state.layoutConfig,
                                                dataViewPersistentId: dataViewPersistentId,
                                                dataViewId: dataViewId
                                            })}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverCreateLayoutButton", instance: e
                                            }, false)}></Button>
                                    <div style={{width: "100%"}}></div>
                                    <Button className="leopard-button-dynamic-size" text={'Cancel'} width={125}
                                            onClick={(e) => this.cancelLayoutOnClick(e)}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverCancelLayoutButton", instance: e
                                            }, false)}></Button>
                                </div>
                            </div> : null
                    }
                    {
                        this.state.layoutButtonMode === "renamelayout" ?
                            <div style={{marginTop: "10px", marginBottom: "5px"}}>
                                <div style={{marginBottom: "10px"}}>
                                    <TextBox placeholder={"Enter a layout name..."} value={this.state.layoutNameText}
                                             className={"leopard-layout-popup-layoutname-" + dataViewId}
                                             onValueChanged={(e) => this.layoutNameOnValueChanged(e)}
                                             maxLength={50}></TextBox>
                                </div>
                                <div style={{display: "flex"}}>
                                    <Button className="leopard-button-dynamic-size" text={'Rename'} width={125}
                                            onClick={() => this.renameConfirmLayoutOnClick({
                                                layoutConfig: this.state.layoutConfig,
                                                dataViewPersistentId: dataViewPersistentId,
                                                dataViewId: dataViewId
                                            })}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverCreateLayoutButton", instance: e
                                            }, false)}></Button>
                                    <div style={{width: "100%"}}></div>
                                    <Button className="leopard-button-dynamic-size" text={'Cancel'} width={125}
                                            onClick={(e) => this.cancelLayoutOnClick(e)}
                                            ref={(e) => this.addUIObjectInstance({
                                                name: "layoutPopoverCancelLayoutButton", instance: e
                                            }, false)}></Button>
                                </div>
                            </div> : null
                    }
                </div>
            </Popover>
        )
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};
export default connect(RetrieveDataFromReducer, null)(LeopardLayoutSelectionPopup);
