import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import {off, on} from "devextreme/events";
import Scheduler, {Resource} from 'devextreme-react/scheduler';
import {InitTimelineData, InitCustomStoreForTimeline} from './LeopardActionCreators';
import LeopardCountdownSwitch from "../datashaping/LeopardCountdownSwitch";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import $ from "jquery";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import ScrollView from 'devextreme-react/scroll-view';
import {Style} from 'react-style-tag';

class LeopardTimelineEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // ---- For "UseStateStore => True" Only ----
            timelineData: null,
            // ------------------------------------------
        };

        this.uiObjectInstance = {
            timelineInstance: null
        };
        this.retryConnectionCount = 0;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.disposingAllInstances = false;
        this.globalVariableInitialized = false;
        this.selectedTimelineData = null;
        this.allowRefreshDataSource = true;
        this.dateFromParent = null;
        this.dateToParent = null;
        this.timelineViewDateIntervalOverride = null;
        this.scrollToDate = null;
        this.updateScrollPosition = false;
        this.firstStartDateInTimeline = null;
        this.lastEndDateInTimeline = null;
    }

    setTimelineInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;
        if (!LDH.IsObjectNull(this.uiObjectInstance.timelineInstance)) {
            return;
        }

        let requireRefresh = false;
        if (LDH.IsObjectNull(this.uiObjectInstance.timelineInstance)) {
            requireRefresh = true;
        }
        this.uiObjectInstance.timelineInstance = ref.instance;

        this.props.setTimelineInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "timeline",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.timelineInstance.option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    if (!LDH.IsObjectNull(that.props.definition.timelineDefinition.parameters) &&
                        !LDH.IsValueEmpty(that.props.definition.timelineDefinition.parameters)) {
                        let parametersCloned = LDH.DeepClone(that.props.definition.timelineDefinition.parameters);

                        for (let x = 0; x < parametersCloned.length; x++) {
                            let item = parametersCloned[x];
                            if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName])) {
                                let controlId = window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName];
                                data.dataFromSource[item.parameterName] = controlId;
                            }
                        }
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let timelineDefinition = this.props.definition.timelineDefinition;
                            let params = timelineDefinition.oDataQueryForLinkedView;
                            that.selectedParentViewData = data.dataFromSource;
                            that.dateFromParent = null;
                            that.dateToParent = null;

                            if (!LDH.IsObjectNull(data.dataFromSource)) {
                                let startDateExp = "startDate";
                                if (!LDH.IsObjectNull(timelineDefinition.parentStartDateFieldInResources) &&
                                    !LDH.IsValueEmpty(timelineDefinition.parentStartDateFieldInResources)) {
                                    startDateExp = timelineDefinition.parentStartDateFieldInResources;
                                    that.dateFromParent = LDH.ConvertArrayMacroToString(startDateExp, data.dataFromSource, null);

                                    if (!LDH.IsValueEmpty(that.dateFromParent) && typeof that.dateFromParent === "string") {
                                        that.dateFromParent = new Date(that.dateFromParent);
                                    }
                                }

                                let endDateExp = "endDate";
                                if (!LDH.IsObjectNull(timelineDefinition.parentEndDateFieldInResources) &&
                                    !LDH.IsValueEmpty(timelineDefinition.parentEndDateFieldInResources)) {
                                    endDateExp = timelineDefinition.parentEndDateFieldInResources;
                                    that.dateToParent = LDH.ConvertArrayMacroToString(endDateExp, data.dataFromSource, null);

                                    if (!LDH.IsValueEmpty(that.dateToParent) && typeof that.dateToParent === "string") {
                                        that.dateToParent = new Date(that.dateToParent);
                                    }
                                }
                            }

                            if (!LDH.IsObjectNull(data.dataFromSource) &&
                                !LDH.IsObjectNull(data.dataFromSource["CallChildViewOnNonSelection"]) &&
                                data.dataFromSource["CallChildViewOnNonSelection"] === true &&
                                !LDH.IsObjectNull(timelineDefinition.oDataQueryNonSelectionForLinkedView) &&
                                !LDH.IsValueEmpty(timelineDefinition.oDataQueryNonSelectionForLinkedView)) {
                                params = timelineDefinition.oDataQueryNonSelectionForLinkedView;
                            }

                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);
                                    if (that.props.useStateStore === null || that.props.useStateStore === false) {
                                        that.initializeCustomStore(true, false);
                                    } else {
                                        that.initializeCustomStore(false, false);
                                    }
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params,
                                        data.dataFromSource, null);
                                    this.instance.option("dataFromSource", data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);
                                    if (that.props.useStateStore === null || that.props.useStateStore === false) {
                                        that.initializeCustomStore(true, false);
                                    } else {
                                        that.initializeCustomStore(false, false);
                                    }
                                }
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                !LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("dataFromSource", data.dataFromSource);
                                this.instance.option("setBlankPostProcess", false);
                                this.instance.option("selectFirstRow", true);
                                if (that.props.useStateStore === null || that.props.useStateStore === false) {
                                    that.initializeCustomStore(true, false);
                                } else {
                                    that.initializeCustomStore(false, false);
                                }
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("setBlankPostProcess", true);
                                if (that.props.useStateStore === null || that.props.useStateStore === false) {
                                    that.initializeCustomStore(true, false);
                                } else {
                                    that.initializeCustomStore(false, false);
                                }
                            }
                        }
                    }
                }
            });
        }

        if (requireRefresh === true && that.allowRefreshDataSource === true) {
            if (that.props.useStateStore === null || that.props.useStateStore === false) {
                that.initializeCustomStore(true, false);
            } else {
                that.initializeCustomStore(false, false);
            }
        }
        if (!that.allowRefreshDataSource) that.allowRefreshDataSource = true;
    };

    componentWillUnmount = () => {
        let that = this;
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstance);

        let timelineDefinition = this.props.definition.timelineDefinition;
        let dataViewId = this.props.dataViewId;
        if (!LDH.IsObjectNull(timelineDefinition.parameters) && timelineDefinition.parameters.length > 0) {
            for (let k = 0; k < timelineDefinition.parameters.length; k++) {
                let controlId = timelineDefinition.parameters[k].parameterName;
                let controlName = "dataViewParam_" + dataViewId + "_control_" + controlId;
                if (!LDH.IsObjectNull(window[controlName])) {
                    delete window[controlName];
                }
            }
        }

        this.props.setTimelineInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "timeline",
            isDataView: true
        });

        that.props.InitTimelineData(null, dataViewId);
        that.setState({timelineData: null});
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
        this.allowRefreshDataSource = false;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStore(true, true);
        } else {
            this.initializeCustomStore(false, true);
        }
    };

    refreshChildViewOnNonSelection = () => {

    };

    initializeCustomStore = (isDataView, initializeCustomStore) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let postDataScript = "";
        let timelineDefinition = this.props.definition.timelineDefinition;
        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(timelineDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(timelineDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        if (!LDH.IsObjectNull(timelineDefinition.oDataQueryNonSelectionForLinkedView) &&
            !LDH.IsValueEmpty(timelineDefinition.oDataQueryNonSelectionForLinkedView) &&
            LDH.IsObjectNull(that.selectedTimelineData)) {
            hasCustomQueryParams = true;
        }

        let timelineOdataQueryForGrouping = "";
        if (!LDH.IsObjectNull(timelineDefinition.timelineOdataQueryForGrouping) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineOdataQueryForGrouping)) {
            timelineOdataQueryForGrouping = timelineDefinition.timelineOdataQueryForGrouping;
        }

        let timelineOdataQueryForResources = "";
        if (!LDH.IsObjectNull(timelineDefinition.timelineOdataQueryForResources) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineOdataQueryForResources)) {
            timelineOdataQueryForResources = timelineDefinition.timelineOdataQueryForResources;
        }

        let postDataForGrouping = "";
        if (!LDH.IsObjectNull(timelineDefinition.timelinePostDataProcessingForGrouping) &&
            !LDH.IsValueEmpty(timelineDefinition.timelinePostDataProcessingForGrouping)) {
            postDataForGrouping = timelineDefinition.timelinePostDataProcessingForGrouping;
        }

        let postDataForResources = "";
        if (!LDH.IsObjectNull(timelineDefinition.timelinePostDataProcessingForResources) &&
            !LDH.IsValueEmpty(timelineDefinition.timelinePostDataProcessingForResources)) {
            postDataForResources = timelineDefinition.timelinePostDataProcessingForResources;
        }

        LRH.InitCustomStoreForTimelineView("get", dataViewId,
            timelineOdataQueryForGrouping, null, postDataForGrouping, function (groupingData) {
                that.retryConnectionCount = 0;

                if (!LDH.IsObjectNull(groupingData) && that.disposingAllInstances === false) {
                    LRH.InitCustomStoreForTimelineView("get", dataViewId,
                        timelineOdataQueryForResources, null, postDataForResources, function (resourcesData) {
                            that.retryConnectionCount = 0;

                            let instance = that.uiObjectInstance.timelineInstance;
                            let scrollableOffset = null;
                            if (!LDH.IsObjectNull(instance)) {
                                let scrollable = instance._workSpace.getScrollable();
                                scrollableOffset = scrollable.scrollOffset();
                            }

                            $("#gridViewToobarWarmingUp_" + dataViewId).hide();
                            $("#gridViewToobar_" + dataViewId).hide();
                            $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");

                            if (!LDH.IsObjectNull(resourcesData) && that.disposingAllInstances === false) {
                                let allData = {resourcesData: resourcesData, groupingData: groupingData};

                                if (isDataView === true) {
                                    that.props.InitTimelineData(allData, dataViewId);
                                }
                                that.setState({timelineData: allData}, function () {
                                    if (scrollableOffset !== null) {
                                        setTimeout(function () {
                                            let scrollable = instance._workSpace.getScrollable();
                                            scrollable.scrollTo(scrollableOffset);
                                        }, 100);
                                    }
                                });

                                // if (!LDH.IsObjectNull(instance) && resourcesData !== null && resourcesData.length > 0) {
                                //     let dateField = timelineDefinition.timelineDateFieldInResources;
                                //     let dateToSelect = new Date(resourcesData[0][dateField]);
                                //     //instance.option("currentDate", dateToSelect);
                                // }
                                that.props.updateWindowDimensionsRequired();

                                setTimeout(function () {
                                    if (LDH.IsObjectNull(instance) === false) {
                                        off(document.getElementById(that.props.dataViewId),
                                            "dxmousewheel");

                                        on(document.getElementById(that.props.dataViewId),
                                            "dxmousewheel", function (e) {
                                                e.preventDefault();
                                            });
                                    }
                                }, 100);
                            }
                        }, function (error, errorText) {
                            if (that.disposingAllInstances === true) {
                                return;
                            }
                            $(".dataview_" + dataViewId).show();
                            $(".leopard-right-panel-container .leopard-loading-icon").hide();
                            that.props.updateWindowDimensionsRequired();

                            LRH.ShowToast("Unable to retrieve the data for the timeline.", "error", 5000);
                            $("#gridViewToobar_" + dataViewId).hide();
                            $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        }, that, timelineDefinition);
                }
            }, function (error, errorText) {
                if (that.disposingAllInstances === true) {
                    return;
                }
                $(".dataview_" + dataViewId).show();
                $(".leopard-right-panel-container .leopard-loading-icon").hide();
                that.props.updateWindowDimensionsRequired();

                if (that.retryConnectionCount < 10) {
                    setTimeout(function () {
                        if (!LDH.IsObjectNull(errorText) && errorText === "retry") {
                            let instance = that.uiObjectInstance.timelineInstance;
                            if (!LDH.IsObjectNull(instance)) {
                                let $parent = $("#gridViewToobar_" + dataViewId);
                                $(".toolbar-warming-up-text", $parent).show();
                                that.retryConnectionCount += 1;
                                if (that.props.useStateStore === null || that.props.useStateStore === false) {
                                    that.initializeCustomStore(true, initializeCustomStore);
                                } else {
                                    that.initializeCustomStore(false, initializeCustomStore);
                                }
                            }
                        }
                    }, 100);
                } else {
                    LRH.ShowToast("Unable to retrieve the data for the timeline.", "error", 5000);
                    $("#gridViewToobar_" + dataViewId).hide();
                    $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                }
            }, that, timelineDefinition);

        if (initializeCustomStore) {
            if (isDataView) {
                this.props.InitCustomStoreForTimeline(null, dataViewId);
            } else {
                this.setState({timelineData: null});
            }

            if (!LDH.IsObjectNull(that.props.dataInitializedOnControls) &&
                !that.props.dataInitializedOnControls) {
                that.props.dataInitializedOnControlsUpdateRequest();
            }
        }
    };

    refreshOnClick = (data) => {
        let id = this.props.dataViewId;
        if ($("#Timeline_TopBar_Refresh_" + id).hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.timelineInstance;
        if (!LDH.IsObjectNull(instance)) {
            if (this.props.useStateStore === null || this.props.useStateStore === false) {
                this.initializeCustomStore(true, false);
            } else {
                this.initializeCustomStore(false, false);
            }
        }

        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.refreshChildViews) &&
            data.refreshChildViews) {
            //let pointData = this.selectedChartPointData;
            //this.onPointSelectionChanged(pointData, true);
        }
    };

    autoRefreshCountdownOnEnd = () => {
        let dataViewId = this.props.dataViewId;
        $("#Timeline_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");

        this.refreshOnClick();
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.name] = data.e;
    };

    timelineToolbar = (timelineDefinition) => {
        let that = this;

        if (!LDH.IsObjectNull(timelineDefinition.globalVariablesInit) && !LDH.IsValueEmpty(timelineDefinition.globalVariablesInit) &&
            !that.globalVariableInitialized) {
            let javascript = timelineDefinition.globalVariablesInit;
            let dataName = "data";
            let dataValue = "";
            LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, that.props.dataViewId, null);
            that.globalVariableInitialized = true;
        }

        let enableAutoRefresh = false;
        if (!LDH.IsObjectNull(timelineDefinition.enableAutoRefresh) &&
            !LDH.IsValueEmpty(timelineDefinition.enableAutoRefresh)) {
            enableAutoRefresh = timelineDefinition.enableAutoRefresh;
        }

        let autoRefreshInterval = 30;
        if (!LDH.IsObjectNull(timelineDefinition.autoRefreshInterval) &&
            !LDH.IsValueEmpty(timelineDefinition.autoRefreshInterval)) {
            autoRefreshInterval = timelineDefinition.autoRefreshInterval;
        }

        let showAutoRefreshSwitch = true;
        if (!LDH.IsObjectNull(timelineDefinition.showAutoRefreshSwitch) &&
            !LDH.IsValueEmpty(timelineDefinition.showAutoRefreshSwitch)) {
            showAutoRefreshSwitch = timelineDefinition.showAutoRefreshSwitch;
        }

        return (
            <React.Fragment>
                <ScrollView scrollByContent={true} direction="horizontal" height={"auto"}
                            bounceEnabled={false} showScrollbar={'onHover'} scrollByThumb={true}>
                    <div className={"leopard-timeline-toolbar"} style={{minHeight: "30px"}}>
                        {
                            timelineDefinition.enableAutoRefresh === false ? "" :
                                <span id={"autoRefresh_" + that.props.dataViewId}
                                      className={"leopard-autorefresh-button_wrapper"}
                                      style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                                     <div id={"autoRefreshCountdownControl_" + that.props.dataViewId}>
                                        <LeopardCountdownSwitch
                                            autoRefreshCountdownOnEnd={that.autoRefreshCountdownOnEnd}
                                            tooltip={"The timeline will be refreshed automatically when timer counts down to 0."}
                                            autoRefreshInterval={autoRefreshInterval}
                                            fieldValue={enableAutoRefresh}
                                            gridViewId={that.props.dataViewId}/>
                                     </div>
                                </span>
                        }
                        {
                            (!LDH.IsObjectNull(timelineDefinition.topMenuJustify) && timelineDefinition.topMenuJustify === "right") ?
                                <div style={{width: "100%"}}></div> : <div></div>
                        }
                        {
                            LRH.RenderDataViewParameters(timelineDefinition.parameters, that.props.dataViewId,
                                timelineDefinition, function (data) {
                                    if (data.parameterTriggerOnChange === false) {
                                        return;
                                    }
                                    window[data.controlName] = data.control.value;
                                    let value = parseInt(data.control.value);
                                    that.timelineViewDateIntervalOverride = value;

                                    if (!LDH.IsObjectNull(that.uiObjectInstance.timelineInstance)) {
                                        that.uiObjectInstance.timelineInstance.option("cellDuration", value);
                                        if (!data.autoApplyParameterFilter) {
                                            that.refreshOnClick({
                                                gridViewId: that.props.dataViewId,
                                                refreshChildViews: false
                                            });
                                        }
                                    }

                                    if (data.autoApplyParameterFilter) {
                                        that.refreshOnClick({
                                            gridViewId: that.props.dataViewId,
                                            refreshChildViews: true
                                        });
                                    }
                                }, function (data) {
                                }, null, null)
                        }
                        {
                            (LDH.IsObjectNull(timelineDefinition.topMenuJustify) || timelineDefinition.topMenuJustify === "left") ?
                                <div style={{width: "100%"}}></div> : <div></div>
                        }
                        <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{
                            color: "rgb(255,128,0)", fontSize: "18px"
                        }}></i>
                        <span id={"gridViewToobarWarmingUp_" + that.props.dataViewId}
                              className={"toolbar-warming-up-text"}>
                            Warming up backend process...
                        </span>
                    </span>
                        <span id={"Timeline_TopBar_WarningIcon_" + that.props.dataViewId}
                              className={"leopard-maximum-data-reached-warning"}>
                         <LeopardTooltipWithIcon
                             elementId={"Timeline_TopBar_WarningIcon_" + that.props.dataViewId + "_help"}
                             title={"Maximum data size reached"}
                             text={"The data retrieved from the server has reached its maximum size limit. " +
                                 "This timeline only shows a portion of that data to improve the performance of Control Centre."}
                             customIcon={"fas fa-exclamation-triangle"}/>
                        </span>
                        <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Timeline_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({
                                    e: e,
                                    gridViewId: that.props.dataViewId,
                                    refreshChildViews: !LDH.IsObjectNull(e) &&
                                    !LDH.IsObjectNull(e.refreshChildViews) ? e.refreshChildViews : false
                                })}
                                text={"The Refresh button allows you to refresh data and repaint the timeline."}/>
                        </span>
                    </div>
                </ScrollView>
            </React.Fragment>
        )
    };

    onAppointmentFormOpening = e => {
        e.cancel = true;
    }

    resourceCellComponent = (e) => {
        if (!LDH.IsObjectNull(e.htmlText) && !LDH.IsValueEmpty(e.htmlText)) {
            return (
                <React.Fragment>
                    <div dangerouslySetInnerHTML={{__html: e.htmlText}}/>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div className={"leopard-timeline-resourceview-text"}>
                    {e.text}
                </div>
            </React.Fragment>
        )
    }

    appointmentComponent = (e) => {
        if (!LDH.IsObjectNull(e.appointmentData.htmlText) && !LDH.IsValueEmpty(e.appointmentData.htmlText)) {
            return (
                <React.Fragment>
                    <div dangerouslySetInnerHTML={{__html: e.appointmentData.htmlText}}/>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div>{e.appointmentData.text}</div>
            </React.Fragment>
        )
    }

    appointmentTooltipComponent = (e) => {
        return (
            <React.Fragment>
                <div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: e.appointmentData.tooltipText}}/>
            </React.Fragment>
        )
    }

    setStartEndDayHoursInTimelineAndScrollToPosition = () => {
        let that = this;
        if (LDH.IsObjectNull(that.uiObjectInstance.timelineInstance)) {
            return;
        }

        let timelineDefinition = this.props.definition.timelineDefinition;
        let defaultStartDayHour = 0;
        if (!LDH.IsObjectNull(timelineDefinition.timelineStartDayHour) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineStartDayHour)) {
            defaultStartDayHour = timelineDefinition.timelineStartDayHour;
        }

        let defaultEndDayHour = 24;
        if (!LDH.IsObjectNull(timelineDefinition.timelineEndDayHour) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineEndDayHour)) {
            defaultEndDayHour = timelineDefinition.timelineEndDayHour;
        }

        let currentDate = that.uiObjectInstance.timelineInstance.option("currentDate");
        let startDayHourFromParent = null;
        let endDayHourFromParent = null;

        let currentDateMatchesStartDate = false;
        let currentDateMatchesEndDate = false;
        let currentDateMatchesEndDateParameter = false;

        let startDateFromParent = new Date();
        let endDateFromParent = new Date();
        endDateFromParent.setHours(endDateFromParent.getHours() + 24);

        if (!LDH.IsObjectNull(that.firstStartDateInTimeline) && !LDH.IsObjectNull(that.lastEndDateInTimeline)) {
            if (that.firstStartDateInTimeline instanceof Date && !isNaN(that.firstStartDateInTimeline) &&
                currentDate.getDate() === that.firstStartDateInTimeline.getDate() &&
                currentDate.getMonth() === that.firstStartDateInTimeline.getMonth() &&
                currentDate.getYear() === that.firstStartDateInTimeline.getYear()) {
                currentDateMatchesStartDate = true;
            }
            startDateFromParent = that.firstStartDateInTimeline;

            if (that.lastEndDateInTimeline instanceof Date && !isNaN(that.lastEndDateInTimeline) &&
                currentDate.getDate() === that.lastEndDateInTimeline.getDate() &&
                currentDate.getMonth() === that.lastEndDateInTimeline.getMonth() &&
                currentDate.getYear() === that.lastEndDateInTimeline.getYear()) {
                currentDateMatchesEndDate = true;
            }
            endDateFromParent = that.lastEndDateInTimeline;
        } else {
            if (!LDH.IsObjectNull(that.dateFromParent) && !LDH.IsObjectNull(that.dateToParent)) {
                if (currentDate.getDate() === that.dateFromParent.getDate() &&
                    currentDate.getMonth() === that.dateFromParent.getMonth() &&
                    currentDate.getYear() === that.dateFromParent.getYear()) {
                    currentDateMatchesStartDate = true;
                }
                startDateFromParent = that.dateFromParent;

                if (currentDate.getDate() === that.dateToParent.getDate() &&
                    currentDate.getMonth() === that.dateToParent.getMonth() &&
                    currentDate.getYear() === that.dateToParent.getYear()) {
                    currentDateMatchesEndDate = true;
                    currentDateMatchesEndDateParameter = true;
                }
                endDateFromParent = that.dateToParent;
            }
        }

        let useDatesFromParent = false;
        if (!LDH.IsObjectNull(startDateFromParent) && !LDH.IsObjectNull(endDateFromParent) &&
            startDateFromParent < endDateFromParent) {
            useDatesFromParent = true;
        }

        if (currentDateMatchesStartDate && useDatesFromParent) {
            startDayHourFromParent = startDateFromParent.getHours();
            that.scrollToDate = startDateFromParent;
        } else {
            startDayHourFromParent = defaultStartDayHour;
            that.scrollToDate = null;
        }

        if (currentDateMatchesEndDate && useDatesFromParent) {
            if (currentDateMatchesEndDateParameter) {
                endDayHourFromParent = that.dateToParent.getHours() + 1;
            } else {
                endDayHourFromParent = endDateFromParent.getHours() + 1;
            }
        } else {
            endDayHourFromParent = defaultEndDayHour;
        }

        if (startDayHourFromParent >= endDayHourFromParent) {
            startDayHourFromParent = 0;
            if (endDayHourFromParent === 0) {
                endDayHourFromParent = defaultEndDayHour;
            }
        }

        if (startDateFromParent instanceof Date && !isNaN(startDateFromParent)) {
            if (!LDH.IsObjectNull(that.dateFromParent) && startDateFromParent < that.dateFromParent) {
                that.uiObjectInstance.timelineInstance.option("min", that.dateFromParent);
            } else {
                that.uiObjectInstance.timelineInstance.option("min", startDateFromParent);
            }

            if (!LDH.IsObjectNull(that.dateToParent) && endDateFromParent > that.dateToParent) {
                that.uiObjectInstance.timelineInstance.option("max", that.dateToParent);
            } else {
                that.uiObjectInstance.timelineInstance.option("max", endDateFromParent);
            }
        } else {
            if (!LDH.IsObjectNull(that.dateFromParent) && !LDH.IsObjectNull(that.dateToParent)) {
                that.uiObjectInstance.timelineInstance.option("min", that.dateFromParent);
                that.uiObjectInstance.timelineInstance.option("max", that.dateToParent);
            } else {
                that.uiObjectInstance.timelineInstance.option("min", new Date());
                that.uiObjectInstance.timelineInstance.option("max", new Date().setHours(new Date().getHours() + 24));
            }
        }

        // ****** A workaround to prevent startDayHour > endDayHour issue.
        that.uiObjectInstance.timelineInstance.option("startDayHour", 0);
        that.uiObjectInstance.timelineInstance.option("endDayHour", 24);

        that.uiObjectInstance.timelineInstance.option("startDayHour", startDayHourFromParent);
        that.uiObjectInstance.timelineInstance.option("endDayHour", endDayHourFromParent);
        // ******

        if (that.updateScrollPosition && !LDH.IsObjectNull(that.scrollToDate)) {
            that.updateScrollPosition = false;

            setTimeout(function () {
                that.uiObjectInstance.timelineInstance.scrollTo(that.scrollToDate);
            }, 500);
        }
    }

    onOptionChanged = (e) => {
        let that = this;
        if (e.name !== "currentDate") return;
        that.setStartEndDayHoursInTimelineAndScrollToPosition();
    }

    render() {
        if (this.disposingAllInstances) return null;

        let that = this;
        let timelineState = this.props.state.timelineState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = timelineState.filter(c => {
                return c.dataViewId === this.props.dataViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        }

        if (currentState === undefined || currentState === null || currentState.length === 0 ||
            currentState.timelineData === undefined || currentState.timelineData === null ||
            currentState.timelineData.groupingData === undefined ||
            currentState.timelineData.resourcesData === undefined) {
            return (
                <div className={"leopard-dataview-retrievingdata-text"}>
                    Retrieving data, please wait...
                </div>
            );
        }

        let timelineDefinition = this.props.definition.timelineDefinition;
        let resourceDefinition = timelineDefinition.timelineResourceDefinition;
        let javascript = resourceDefinition;
        let jsonResult = LDH.EvaluateJavaScriptForDataShaping(javascript, "data", "", this.props.dataViewId);

        let groupingData = currentState.timelineData.groupingData;
        let baseDataSource = currentState.timelineData.resourcesData;

        let timelineViews = [];
        if (!LDH.IsObjectNull(timelineDefinition.timelineViewTypes) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineViewTypes)) {
            let value = timelineDefinition.timelineViewTypes;
            timelineViews = value.split(",");
        }

        let groupField = [timelineDefinition.timelineGroupFieldInResources];
        let ownerField = timelineDefinition.timelineOwnerFieldInResources;
        let resourcesDataKey = timelineDefinition.timelineDataKeyForResources;

        let cellDuration = 30;
        if (!LDH.IsObjectNull(timelineDefinition.timelineCellDuration) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineCellDuration)) {
            cellDuration = timelineDefinition.timelineCellDuration;
        }
        if (that.timelineViewDateIntervalOverride !== null) {
            cellDuration = that.timelineViewDateIntervalOverride;
        }

        let firstDayOfWeek = 0;
        if (!LDH.IsObjectNull(timelineDefinition.timelineFirstDayOfWeek) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineFirstDayOfWeek)) {
            firstDayOfWeek = timelineDefinition.timelineFirstDayOfWeek;
        }

        let defaultTimelineView = "timelineDay";
        if (!LDH.IsObjectNull(timelineDefinition.timelineDefaultTimelineView) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineDefaultTimelineView)) {
            defaultTimelineView = timelineDefinition.timelineDefaultTimelineView;
        }

        let startDateExp = "startDate";
        if (!LDH.IsObjectNull(timelineDefinition.timelineStartDateFieldInResources) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineStartDateFieldInResources)) {
            startDateExp = timelineDefinition.timelineStartDateFieldInResources;
        }

        let endDateExp = "endDate";
        if (!LDH.IsObjectNull(timelineDefinition.timelineEndDateFieldInResources) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineEndDateFieldInResources)) {
            endDateExp = timelineDefinition.timelineEndDateFieldInResources;
        }

        let timelineCellHeight = 80;
        if (!LDH.IsObjectNull(timelineDefinition.timelineCellHeight) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineCellHeight)) {
            timelineCellHeight = timelineDefinition.timelineCellHeight;
        }

        let schedulerTimezone = "Australia/Sydney";
        if (!LDH.IsObjectNull(timelineDefinition.timelineSchedulerTimezone) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineSchedulerTimezone)) {
            let timezone = timelineDefinition.timelineSchedulerTimezone;
            timezone = LDH.ConvertArrayMacroToString(timezone, that.selectedParentViewData, null);
            timezone = LDH.FilterMacro(timezone);
            schedulerTimezone = timezone;
        }
        if (schedulerTimezone.indexOf("{") > -1 && schedulerTimezone.indexOf("}") > -1) {
            schedulerTimezone = "Australia/Sydney";
        }

        let maxAppointmentsPerCell = "unlimited";
        if (!LDH.IsObjectNull(timelineDefinition.timelineMaxAppointmentsPerCell) &&
            !LDH.IsValueEmpty(timelineDefinition.timelineMaxAppointmentsPerCell)) {
            maxAppointmentsPerCell = timelineDefinition.timelineMaxAppointmentsPerCell;
            if (!isNaN(parseInt(maxAppointmentsPerCell))) {
                maxAppointmentsPerCell = parseInt(maxAppointmentsPerCell);
            }
        }

        let controlHeight = "";
        if (that.props.useStateStore === null || that.props.useStateStore === false) {
            controlHeight = "100%";
        } else {
            controlHeight = "calc(100% - 30px)";
        }

        let style = ".dataview_" + this.props.dataViewId + " .dx-scheduler-cell-sizes-vertical {height: " +
            timelineCellHeight + "px !important;}";

        that.scrollToDate = null;
        if (!LDH.IsObjectNull(currentState.timelineData.resourcesData)) {
            let startDateList = currentState.timelineData.resourcesData.sort(function (a, b) {
                return Date.parse(a[startDateExp]) - Date.parse(b[startDateExp]);
            });
            startDateList.filter(d => !LDH.IsObjectNull(d[startDateExp]) && !LDH.IsValueEmpty(d[startDateExp]));

            let datesStart = [];
            for (let v = 0; v < startDateList.length; v++) {
                if (new Date(startDateList[v][startDateExp]).getFullYear() === 1970) {
                    continue;
                }
                datesStart.push(new Date(startDateList[v][startDateExp]));
            }
            that.firstStartDateInTimeline = new Date(Math.min.apply(null, datesStart));

            let endDateList = currentState.timelineData.resourcesData.sort(function (a, b) {
                return Date.parse(b[endDateExp]) - Date.parse(a[endDateExp]);
            });
            endDateList.filter(d => !LDH.IsObjectNull(d[endDateExp]) && !LDH.IsValueEmpty(d[endDateExp]));

            let datesEnd = [];
            for (let v = 0; v < endDateList.length; v++) {
                if (new Date(endDateList[v][endDateExp]).getFullYear() === 1970) {
                    continue;
                }
                datesEnd.push(new Date(endDateList[v][endDateExp]));
            }
            that.lastEndDateInTimeline = new Date(Math.max.apply(null, datesEnd));
        }

        let defaultDate = new Date();

        that.updateScrollPosition = false;
        if (!LDH.IsObjectNull(that.uiObjectInstance.timelineInstance) &&
            !LDH.IsObjectNull(that.scrollToDate) && !LDH.IsValueEmpty(that.scrollToDate)) {
            defaultDate = that.scrollToDate;
            that.updateScrollPosition = true;
        }
        if (!LDH.IsObjectNull(that.uiObjectInstance.timelineInstance) &&
            LDH.IsObjectNull(that.scrollToDate) && !LDH.IsObjectNull(that.dateFromParent)) {
            defaultDate = that.dateFromParent;
            that.scrollToDate = that.dateFromParent;
            that.updateScrollPosition = true;
        }
        if (that.firstStartDateInTimeline instanceof Date && !isNaN(that.firstStartDateInTimeline)) {
            defaultDate = that.firstStartDateInTimeline;
        }

        setTimeout(function () {
            that.setStartEndDayHoursInTimelineAndScrollToPosition();
        }, 100);

        return (
            <React.Fragment>
                <Style>{style}</Style>
                {that.timelineToolbar(timelineDefinition)}
                <Scheduler timeZone={schedulerTimezone} dataSource={baseDataSource} views={timelineViews}
                           adaptivityEnabled={false} defaultCurrentView={defaultTimelineView}
                           crossScrollingEnabled={true} startDateExpr={startDateExp}
                           resourceCellComponent={this.resourceCellComponent}
                           appointmentComponent={this.appointmentComponent}
                           appointmentTooltipComponent={this.appointmentTooltipComponent}
                           endDateExpr={endDateExp} maxAppointmentsPerCell={maxAppointmentsPerCell}
                           focusStateEnabled={true} defaultCurrentDate={defaultDate}
                           currentDate={defaultDate}
                           activeStateEnabled={true} hoverStateEnabled={false}
                           onAppointmentFormOpening={(e) => that.onAppointmentFormOpening(e)}
                           onOptionChanged={that.onOptionChanged}
                           editing={{
                               allowAdding: false, allowDeleting: false, allowResizing: false,
                               allowDragging: false, allowUpdating: false,
                           }}
                           height={controlHeight} groups={groupField} cellDuration={cellDuration}
                           firstDayOfWeek={firstDayOfWeek} ref={(e) => that.setTimelineInstance(e)}>
                    <Resource fieldExpr={resourcesDataKey} allowMultiple={true} dataSource={groupingData}/>
                    <Resource fieldExpr={ownerField} allowMultiple={true} dataSource={jsonResult}
                              useColorAsDefault={true}/>
                </Scheduler>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitTimelineData: (data, id) => {
            dispatch(InitTimelineData(data, id));
        },
        InitCustomStoreForTimeline: (store, id) => {
            dispatch(InitCustomStoreForTimeline(store, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardTimelineEngine);
