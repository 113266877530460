import React, {Component} from 'react';
import LeopardTimelineEngine from './LeopardTimelineEngine';

class LeopardChildTimeline extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardTimelineEngine
                    definition={this.props.definition} dashboardLevel={this.props.dashboardLevel}
                    useStateStore={this.props.useStateStore} relationships={this.props.relationships}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                    setTimelineInstance={this.props.setTimelineInstance}
                    dataViewId={this.props.dataViewId}
                />

            </React.Fragment>
        );
    }
}

export default LeopardChildTimeline;
