import $ from "jquery";
const FieldComponent = window.Formio.Components.components.field;

class LeopardHtmlFormBuilderPhoto extends FieldComponent {
    constructor(component, options, data) {
        super(component, options, data);

        this.componentKey = "";
        this.controlGuid = "";
        this.data = [];
        this.platform = "browser";
        this.controlData = [];
        this.maxPhotos = 4;
    }

    static schema() {
        return FieldComponent.schema({
            type: 'leopardPhoto'
        });
    }

    static builderInfo() {
        return {
            title: 'Photo',
            schema: LeopardHtmlFormBuilderPhoto.schema()
        };
    }

    init() {
        super.init();
    }

    showOrHideImages = ($parent, thisComp) => {
        var data = thisComp.controlData;
        $(".htmlformbuilder_leopardphoto_images", $parent).empty();

        for (var i = 0; i < data.length; i++) {
            var imageValue = "\"" + data[i].value + "\"";

            var html =
                "<div class='imageCtrl' id='" + data[i].id + "' style='background-image: url(" + imageValue + ")'>" +
                "   <button class='imageDel'>Delete</button>" +
                "</div>";

            $(".htmlformbuilder_leopardphoto_images", $parent).append(html);

            $(".imageDel", $parent).off("click").on("click", function () {
                var id = $(this).closest(".imageCtrl").attr("id");
                for (var v = 0; v < thisComp.controlData.length; v++) {
                    if (thisComp.controlData[v].id === id) {
                        thisComp.controlData.splice(v, 1);
                        break;
                    }
                }
                $(this).closest(".imageCtrl").remove();
                thisComp.updateValue({data: thisComp.controlData});

                if (thisComp.controlData.length < thisComp.maxPhotos) {
                    $(".htmlformbuilder_leopardphoto", $parent).show();
                } else {
                    $(".htmlformbuilder_leopardphoto", $parent).hide();
                }
                return false;
            });
        }
    }

    onFileSelect = (data, $parent) => {
        var that = this;
        var reader = new FileReader();
        reader.onload = function (event) {
            var result = event.target.result;
            var imageId = that.generateGuid();

            that.controlData.push({
                value: result, id: imageId
            });
            that.showOrHideImages($parent, that);
            that.updateValue({data: that.controlData});

            if (that.controlData.length < that.maxPhotos) {
                $(".htmlformbuilder_leopardphoto", $parent).show();
            } else {
                $(".htmlformbuilder_leopardphoto", $parent).hide();
            }
        };
        if (data.target.files.length > 0) {
            reader.readAsDataURL(data.target.files[0]);
        }
    };

    generateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    render(children) {
        this.componentKey = this.component.key;
        this.controlGuid = this.generateGuid();
        this.data = this._data;

        for (var i = 0; i < this.root.components.length; i++) {
            var comp = this.root.components[i];
            if (comp.path !== "__formConfig") {
                continue;
            }
            if (typeof comp.component.properties["maxPhotos"] === "undefined") {
                continue;
            }
            var photos = comp.component.properties["maxPhotos"];
            this.maxPhotos = parseInt(photos);
        }

        var key = this.componentKey + " " + this.controlGuid;

        var html =
            "<div class='htmlformbuilder_leopardphoto_container " + key + "'>" +
            "   <div>" +
            "       <a href='#_' class='htmlformbuilder_leopardphoto " + key + "'>Take a photo</a>" +
            "       <input class='fileuploader' type='file' multiple='' style='display: none' />" +
            "   </div>" +
            "   <div class='htmlformbuilder_leopardphoto_images " + key + "'></div>" +
            "</div>";
        return super.render(html);
    }

    attach(element) {
        var that = this;
        var attachElem = super.attach(element);

        $(".htmlformbuilder_leopardphoto." + that.controlGuid).each(function () {
            if ($(this).hasClass("control-init")) return;

            var $parent = $(this).closest(".htmlformbuilder_leopardphoto_container");
            $(".fileuploader", $parent).off("change").on("change", function (e) {
                that.onFileSelect(e, $parent);
            });

            $(this).off("click").on("click", function () {
                if (that.platform === "browser") {
                    $(".fileuploader", $parent).val("").trigger("click");
                } else {
                    window.leopardPhotoAddButtonOnClick(this, $parent, that);
                }
                return false;
            });

            $(this).addClass("control-init");
        });

        if (typeof that.data["__dataSubmitted"] !== "undefined" &&
            that.data["__dataSubmitted"] === true) {
            var $parent = $(".htmlformbuilder_leopardphoto_container." + that.controlGuid);
            $(".imageDel", $parent).remove();
            $(".htmlformbuilder_leopardphoto", $parent).remove();
        }
        return attachElem;
    }

    getValue() {
        return super.getValue();
    }

    setValue(value, flags = {}) {
        var that = this;

        if (that.controlGuid !== "" && value !== null) {
            var $parent = $(".htmlformbuilder_leopardphoto_container." + that.controlGuid);
            that.controlData = value.data;
            that.showOrHideImages($parent, that);

            if (typeof that.data["__dataSubmitted"] !== "undefined" &&
                that.data["__dataSubmitted"] === true) {
                $(".imageDel", $parent).remove();
                $(".htmlformbuilder_leopardphoto", $parent).remove();
            }
        }

        return super.setValue(value);
    }

    updateValue(value, flags = {}) {
        return super.updateValue(value, flags);
    }
}

export default LeopardHtmlFormBuilderPhoto;