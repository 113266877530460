import React, {Component} from 'react';
import List from 'devextreme-react/list';
import LDH from '../helpers/LeopardDataHelper';
import $ from "jquery";
import {Button} from "devextreme-react";
import LeopardAPIGatewayConfig from "../foundation/LeopardAPIGatewayConfig";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LRH from "../helpers/LeopardReactHelper";

class LeopardNotificationPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    setObjectInstance = (e) => {
        if (typeof e.ref === "undefined" || e.ref === null) {
            return;
        }
        e.thisComp.props.setNotificationPanelInstance(e.ref);
    };

    dismissOnClick = (e, data, thisComp) => {
        thisComp.updateNotificationStatusOnClick(data, thisComp, function () {
            let instance = thisComp.props.appInstance.notificationPanelInstance;
            let datasource = instance.option("dataSource");
            if (typeof datasource === "undefined" || datasource === null) {
                datasource = [];
            }
            let clonedDS = LDH.DeepClone(datasource);
            for (let i = 0; i < clonedDS.length; i++) {
                if (data.id !== clonedDS[i].id) {
                    continue;
                }
                clonedDS[i].isHidden = true;
            }

            let hasItemUnclicked = false;
            for (let i = 0; i < clonedDS.length; i++) {
                if (!clonedDS[i].isClicked && !clonedDS[i].isHidden) {
                    hasItemUnclicked = true;
                }
            }
            let $indicator = $(".leopard-topmenu-bar-notification-indicator");
            if (hasItemUnclicked) {
                $indicator.addClass("shown");
            } else {
                $indicator.removeClass("shown");
            }

            let filteredDS = clonedDS.filter((m) => !m.isHidden);
            if (filteredDS.length === 0) {
                $indicator.removeClass("shown");
                $(".leopard-notification-panel-container .no-message-container").addClass("shown");
            }
            instance.option("dataSource", filteredDS);
        });
        e.event.stopPropagation();
    };

    updateNotificationStatusOnClick = (data, thisComp, callbackSuccess) => {
        let template = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
        template.id = LDH.GenerateGuid();
        template.type = "leopardsystems.report.modify.metadata";
        let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let dataCloned = LDH.DeepClone(data.responseData);

        let metadataToUpdate = null;
        let notification = [];
        if (typeof dataCloned.reportMetadata.metadataToUpdate !== "undefined") {
            notification = dataCloned.reportMetadata.metadataToUpdate.notification;
        } else if (typeof dataCloned.reportMetadata.documentMetadata !== "undefined" &&
            typeof dataCloned.reportMetadata.documentMetadata.event_json !== "undefined") {
            notification = dataCloned.reportMetadata.documentMetadata.event_json.metadata.notification;
        }
        for (let i = 0; i < notification.notificationStatus.length; i++) {
            let status = notification.notificationStatus[i];
            if (status.userId !== loginUserId) continue;
            status.notified = true;
            break;
        }
        let rowId = dataCloned.reportMetadata.documentMetadata.id;

        if (typeof dataCloned.reportMetadata.metadataToUpdate !== "undefined") {
            dataCloned.reportMetadata.metadataToUpdate.rowId = rowId;
            metadataToUpdate = dataCloned.reportMetadata.metadataToUpdate;
        } else if (typeof dataCloned.reportMetadata.documentMetadata !== "undefined" &&
            typeof dataCloned.reportMetadata.documentMetadata.event_json !== "undefined") {
            dataCloned.reportMetadata.documentMetadata.event_json.metadata.rowId = rowId;
            metadataToUpdate = dataCloned.reportMetadata.documentMetadata.event_json.metadata;
        }
        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            callbackSuccess(response);
        }, function () {
            return;
        }, template, metadataToUpdate);
    };

    itemRenderTemplate = (data) => {
        return (
            <div id={"notification_" + data.id} className={"leopard-notification-item"}>
                {
                    data.isClicked ?
                        <i className={"leopard-notification-item-icon clicked fas fa-check fa-fw"}></i> :
                        <i className={"leopard-notification-item-icon fas fa-bell fa-fw"}></i>
                }
                <div className={"leopard-notification-item-text-wrap"}>
                    <div className={"leopard-notification-item-text"}>
                        {data.text}
                    </div>
                    <div className={"leopard-notification-item-time"}>
                        {data.time}
                    </div>
                </div>
                <Button className="leopard-button" text={'Dismiss'}
                        onClick={(e) => this.dismissOnClick(e, data, this)}/>
            </div>
        );
    };

    onItemClick = (data, thisComp) => {
        let instance = thisComp.props.appInstance.notificationPanelInstance;
        let datasource = instance.option("dataSource");
        if (typeof datasource === "undefined" || datasource === null) {
            datasource = [];
        }
        let clonedDS = LDH.DeepClone(datasource);
        let actionType = null;
        let dataViewId = null;

        for (let i = 0; i < clonedDS.length; i++) {
            if (data.itemData.id !== clonedDS[i].id) {
                continue;
            }
            clonedDS[i].isClicked = true;
            if (clonedDS[i].notificationType === "report") {
                if (clonedDS[i].actionType === "preview") {
                    dataViewId = data.itemData.dataViewId;
                } else if (clonedDS[i].actionType === "download") {
                    thisComp.updateNotificationStatusOnClick(data.itemData, thisComp, function () {
                        let urlToSign = data.itemData.s3ReportPathInternal;
                        if (typeof urlToSign === "undefined" || LDH.IsValueEmpty(urlToSign)) {
                            let docMetadata = data.itemData.responseData.reportMetadata.documentMetadata;
                            let ownerId = docMetadata.owner;
                            let docId = docMetadata.documentid;
                            let version = docMetadata.version;
                            let fileName = ownerId + "_" + docId + "_" + version;
                            let path = "___report_rendered/";
                            urlToSign = path + fileName + ".pdf";
                        }
                        LeopardAjaxHelper.SendEventToReportService(urlToSign, function (response) {
                            let url = response.body.data.data;
                            if (url !== null) window.open(url);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                            }
                        }, "leopardsystems.report.signreporturl");
                    });
                }
                actionType = clonedDS[i].actionType;
            }
        }
        instance.option("dataSource", clonedDS);

        let hasItemUnclicked = false;
        for (let i = 0; i < clonedDS.length; i++) {
            if (!clonedDS[i].isClicked && !clonedDS[i].isHidden) {
                hasItemUnclicked = true;
            }
        }
        let $indicator = $(".leopard-topmenu-bar-notification-indicator");
        if (hasItemUnclicked) {
            $indicator.addClass("shown");
        } else {
            $indicator.removeClass("shown");
        }

        if (actionType === "preview" && dataViewId !== null) {
            let menuView = thisComp.props.appInstance.leftMenuTreeViewInstance;
            menuView.expandItem(data.itemData.menuFolderId);
            window.generateReportFromNotification = data.itemData;

            setTimeout(function () {
                let $item = $(".menuitem_dataview_dvid_report_" + dataViewId);
                $(".leopard-leftmenu-item-text", $item).trigger("click");
            }, 100);
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className={"no-message-container shown"}>
                    <div className={"no-message-text-wrap"}>
                        <i className="no-message-icon far fa-bell-slash fa-fw"></i>
                        <div className={"no-message-text"}>No Messages</div>
                    </div>
                </div>
                <List
                    selectionMode={"single"} selectByClick={false} visible={false}
                    showSelectionControls={false} focusStateEnabled={false}
                    repaintChangesOnly={true}
                    onItemClick={(e) => this.onItemClick(e, this)} width={"100%"}
                    ref={(e) => this.setObjectInstance({
                        controlName: "appNotificationPanel", ref: e, thisComp: this
                    })}
                    height="100%" itemRender={this.itemRenderTemplate}
                />
            </React.Fragment>
        );
    }
}

export default LeopardNotificationPanel;
