import 'bootstrap/dist/css/bootstrap.css';
import 'formiojs/dist/formio.full.min.css';
import './resources/css/global.css';
import './resources/css/devextreme/dx.diagram-leopard-theme.css';
import './resources/css/devextreme/dx.generic.leopard-theme.css';
import './resources/css/leopard-theme-colors.css';
import './resources/css/leopard-theme-style.css';

import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import LeopardSecurity from './security/LeopardSecurity';
import LRH from './helpers/LeopardReactHelper';
import {
    SetAuthenticatedUser,
    SetLeopardAdminMode,
    SetUserPermissions,
    SetWebAppInitialized
} from './foundation/LeopardActionCreators';

import {Button, TextBox} from 'devextreme-react';
import LeopardTopMenuBar from './components/LeopardTopMenuBar';
import LeopardMasterLeftMenu from './components/LeopardMasterLeftMenu';
import LeopardStaticUIConfig from './foundation/LeopardStaticUIConfig';
import LeopardMasterContentPanel from './components/LeopardMasterContentPanel';
import LeopardAjaxHelper from './helpers/LeopardAjaxHelper';
import LDH from './helpers/LeopardDataHelper';
import config from 'devextreme-react/core/config';
import LWH from './helpers/LeopardWebsocketHelper';
import LeopardNotificationPanel from './components/LeopardNotificationPanel';
import LeopardAPIGatewayConfig from "./foundation/LeopardAPIGatewayConfig"
import momenttz from "moment-timezone";

require("bootstrap");
config({useLegacyTemplateEngine: true});
window.jQuery = $;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUserName: "",
            loggedInUserRoles: "",
            authState: null,
            masterPageOnLoadComplete: false
        };

        this.gridViewInstance = null;
        this.chartInstance = null;
        this.timelineInstance = null;
        this.setTabbedViewInstance = null;
        this.notepadInstance = null;
        this.documentEditorInstance = null;
        this.photoInstance = null;
        this.reportInstance = null;
        this.leftMenuTreeViewInstance = null;
        this.showLeftMenuContent = true;
        this.notificationPanelInstance = null;
    }

    updateWindowDimensionsOnResizing = (optimizePagerForLargeDataset) => {
        let that = this;

        let showGridViewAdminToolbar = false;
        if (!LDH.IsObjectNull(this.props.state.permissions)) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let height = window.innerHeight - 153;
        let width = window.innerWidth;
        width = width - (that.showLeftMenuContent ? 305 : 0);

        if (!LDH.IsObjectNull(this.gridViewInstance)) {
            let heightForGridView = window.innerHeight;
            heightForGridView = heightForGridView - 153;

            let adjustment = 0;
            if ((LDH.IsValueEmpty(optimizePagerForLargeDataset) === false &&
                    optimizePagerForLargeDataset === true) ||
                $(".leopard-page-container").length > 0) {
                adjustment = -33;
            }

            if (showGridViewAdminToolbar === true) {
                this.gridViewInstance.option("height", heightForGridView + adjustment - 3);
            } else {
                this.gridViewInstance.option("height", heightForGridView + adjustment + 27);
            }

            if (that.gridViewInstance.getVisibleRows().length > 0) {
                that.gridViewInstance.repaint();
            }
        }

        if (!LDH.IsObjectNull(this.chartInstance)) {
            let heightForChart = window.innerHeight - 128;
            if (showGridViewAdminToolbar === true) {
                this.chartInstance.option("size", {height: heightForChart - 30});
            } else {
                this.chartInstance.option("size", {height: heightForChart});
            }
        }

        if (!LDH.IsObjectNull(this.timelineInstance)) {
            this.timelineInstance.repaint();
        }

        if (!LDH.IsObjectNull(this.mapInstance)) {
            let heightForMap = window.innerHeight - 95;
            this.mapInstance.option("height", heightForMap);
        }

        let winHeight = height + 68;
        $(".leopard-right-panel-container").css("height", winHeight);
        $(".leopard-left-panel-container").css("height", winHeight);
        $(".leopard-leftmenu-option-panel").css("height", winHeight);
        $(".leopard-option-panel-content").css("height", winHeight - 110);
        $(".leopard-screen-cover").css("height", winHeight);

        $(".leopard-table-with-fixedcolumn table").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn thead").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn tbody").css("width", width - 50);

        let $reportDesigner = $("#LeopardReportDesigner");
        if ($reportDesigner.length > 0 && $reportDesigner.css("z-index") !== "1000000") {
            $reportDesigner.css("height", winHeight - 10);
        }
    };

    setGridViewInstance = (e) => {
        if (e.isDataView === true) {
            this.gridViewInstance = e.instance;
            this.updateWindowDimensionsOnResizing(e.optimizePagerForLargeDataset);
        }
    };

    setLeftMenuTreeViewInstance = (e) => {
        this.leftMenuTreeViewInstance = e.instance;
    };

    setChartInstance = (e) => {
        if (e.isDataView === true) {
            this.chartInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setTimelineInstance = (e) => {
        if (e.isDataView === true) {
            this.timelineInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setTabbedViewInstance = (e) => {
        if (e.isDataView === true) {
            this.tabbedViewInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setPhotoInstance = (e) => {
        if (e.isDataView === true) {
            this.photoInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setReportInstance = (e) => {
        if (e.isDataView === true) {
            this.reportInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setNotepadInstance = (e) => {
        if (e.isDataView === true) {
            this.notepadInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setDocumentEditorInstance = (e) => {
        if (e.isDataView === true) {
            this.documentEditorInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setNotificationPanelInstance = (e) => {
        this.notificationPanelInstance = e.instance;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensionsOnResizing);
        this.gridViewInstance = null;
        this.showLeftMenuContent = true;
    }

    leftMenuExpandAll = (thisComp) => {
        thisComp.leftMenuTreeViewInstance.expandAll();
    }

    leftMenuExpandOnSelection = (thisComp) => {
        for (let i = 0; i < window.leftMenuUIState.length; i++) {
            let key = window.leftMenuUIState[i].key;

            if (window.leftMenuUIState[i].state === "collapsed") {
                thisComp.leftMenuTreeViewInstance.collapseItem(key);
            }
            if (window.leftMenuUIState[i].state === "expanded") {
                thisComp.leftMenuTreeViewInstance.expandItem(key);
            }
        }
        $("#dxMenuTreeView").show();
    }

    componentDidMount() {
        window.leftMenuUIState = [{key: "directory_predefined_workspaces", state: "expanded"}];
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        $("#leopard-default-style").remove();
        $("head").append('<link rel="stylesheet" type="text/css" href="/js/mobileapp/custom_styles/custom_theme.css?v=' + version + '" />');
        $("head").append('<script src="/js/jquery.highlight.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/mobileapp/custom_plugins/plugin_signaturepad.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/mobileapp/custom_interface/custom_events.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/webconfig.js?v=' + version + '"></script>');

        let themeObj = localStorage.getItem('theme_manager');
        if (!LDH.IsObjectNull(themeObj)) {
            let themeColors = JSON.parse(themeObj);
            LDH.SetDefaultThemeColors(themeColors);
            $("head").append(
                "<style style='text/css'>" +
                ":root {" +
                "   --mainColor: " + themeColors.mainColor + "; " +
                "   --shadeColor: " + themeColors.shadeColor + "; " +
                "   --hoverColor: " + themeColors.hoverSelectionColor + "; " +
                "   --secondaryColor: " + themeColors.topBarColor + "; " +
                "}" +
                "</style>");
        }
        window.addEventListener("resize", this.updateWindowDimensionsOnResizing);

        $("body").on("contextmenu", function (e) {
            if (!LDH.IsObjectNull(e.target) && !LDH.IsValueEmpty(e.target.className) &&
                e.target.className === "leopard-photogallery-enlarged-image") {
                return true;
            }
            return false;
        });

        $(document).off("click").on("click", function () {
            if ($(".leopard-login-userinfo-panel").is(":visible")) {
                $(".leopard-login-userinfo-panel").hide();
            }
        });

        window.currentMousePos = {};
        $(document).mousemove(function (event) {
            window.currentMousePos["x"] = event.pageX;
            window.currentMousePos["y"] = event.pageY;
        });

        let that = this;
        this.setState({masterPageOnLoadComplete: true}, function () {
            that.updateWindowDimensionsOnResizing();

            let storageName = "cc_organisation_applogo";
            if (localStorage.getItem(storageName) !== null && localStorage.getItem(storageName) !== "") {
                let applogo = localStorage.getItem(storageName);
                $(".leopard-right-panel-container").css("background-image", "url(" + applogo + ")");
            }

            storageName = "cc_organisation_applogo_size";
            if (localStorage.getItem(storageName) !== null && localStorage.getItem(storageName) !== "") {
                let applogoSize = localStorage.getItem(storageName);
                $(".leopard-right-panel-container").css("background-size", applogoSize);
            }

            storageName = "cc_organisation_applogo_banner";
            if (localStorage.getItem(storageName) !== null && localStorage.getItem(storageName) !== "") {
                let bannerLogo = localStorage.getItem(storageName);
                $(".leopard-top-logo.customer-logo").css("background-image", "url(" + bannerLogo + ")");
            }

            storageName = "cc_organisation_applogo_banner_size";
            if (localStorage.getItem(storageName) !== null && localStorage.getItem(storageName) !== "") {
                let bannerLogoSize = localStorage.getItem(storageName);
                $(".leopard-top-logo.customer-logo").css("background-size", bannerLogoSize);
            }
        });
        LeopardAjaxHelper.AddSecurityHeadersToAjaxRequestForOdata();
        this.initializeAuthenticatedUserAndProfile();
    }

    loadPersistNotificationMessagesAsync = (userId, thisComp) => {
        LeopardAjaxHelper.ListDocumentToS3(userId, function (documentListResponse) {
            let documentList = documentListResponse.body.data;
            let messageList = [];

            if (documentList === null || documentList === "") documentList = [];
            for (let i = 0; i < documentList.length; i++) {
                let document = documentList[i];
                if (typeof document.event_json === "undefined" ||
                    LDH.IsObjectNull(document.event_json)) {
                    continue;
                }
                if (typeof document.event_json.metadata === "undefined" ||
                    LDH.IsObjectNull(document.event_json.metadata)) {
                    continue;
                }

                let metadata = document.event_json.metadata;
                if (typeof metadata.notification === "undefined" ||
                    LDH.IsObjectNull(metadata.notification)) {
                    continue;
                }
                if (typeof metadata.notification === "string") {
                    metadata.notification = JSON.parse(metadata.notification);
                }

                if (typeof metadata.notification.notificationEnabled === "undefined" ||
                    !metadata.notification.notificationEnabled) {
                    continue;
                }
                if (typeof metadata.notification.notificationStatus === "undefined" ||
                    LDH.IsObjectNull(metadata.notification.notificationStatus)) {
                    continue;
                }

                if (typeof metadata.interaction === "undefined" ||
                    LDH.IsObjectNull(metadata.interaction)) {
                    continue;
                }
                if (typeof metadata.interaction === "string") {
                    metadata.interaction = JSON.parse(metadata.interaction);
                }

                for (let k = 0; k < metadata.notification.notificationStatus.length; k++) {
                    let status = metadata.notification.notificationStatus[k];
                    if (status.userId !== userId || status.notified) continue;
                    let jsonData = {data: {reportMetadata: metadata.interaction}};
                    jsonData.data.reportMetadata.reportArgs = metadata.reportArgs;
                    jsonData.data.reportMetadata.documentMetadata = {
                        documentid: document.documentid,
                        id: document.id,
                        owner: document.owner,
                        version: document.version,
                        type: document.type,
                        name: document.name
                    };

                    if (typeof metadata.interaction.timestamp !== "undefined") {
                        jsonData.time = metadata.interaction.timestamp;
                    } else {
                        metadata.interaction.timestamp = LDH.GetNowLocal();
                        jsonData.time = LDH.GetNowLocal();
                    }
                    jsonData.data.reportMetadata.metadataToUpdate = LDH.DeepClone(metadata);

                    messageList.push(LDH.DeepClone(jsonData));
                    break;
                }
            }

            let format = "DD/MM/YYYY HH:mm:ss";
            messageList.sort(function (a, b) {
                return new Date(a.time) - new Date(b.time);
            });

            for (let i = 0; i < messageList.length; i++) {
                let reportMetadata = messageList[i].data.reportMetadata;
                if (reportMetadata.renderingMethod === "browser") {
                    LWH.SendAdHocReportNotification(messageList[i], thisComp, format, false);
                } else if (reportMetadata.renderingMethod === "stimulsoft") {
                    LWH.SendGeneratedReportNotification(messageList[i], thisComp, format, false);
                }
            }
        }, null, "leopardsystems.report.list");
    }

    initializeAuthenticatedUserAndProfile = () => {
        let that = this;
        if (that.props.authState === "signedIn") {
            LeopardSecurity.GetCurrentAuthenticatedUser(function (user) {
                let roles = user.attributes["custom:role"];

                that.props.SetAuthenticatedUser({data: user});
                window.currentUserName = user.username;

                that.setState({
                    loggedInUserName: user.username,
                    authState: that.props.authState,
                    loggedInUserRoles: roles
                });

                if (roles === LeopardStaticUIConfig.UserRoleCCAdmin) {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: true,
                        AllowReportDesigner: true
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                } else {
                    that.props.SetUserPermissions({
                        ShowGridViewAdminToolbar: false,
                        AllowReportDesigner: false
                    });
                    that.props.SetLeopardAdminMode({enabled: false});
                }

                let userId = user.attributes.sub;
                window.userRoles = roles;

                if (roles === LeopardStaticUIConfig.UserRoleAppUser) {
                    setTimeout(function () {
                        $(".leopard-left-panel-container .leopard-loading-icon").hide();
                        LRH.ShowToast("You're logged in as an \"App User\" which do not have access to Control Centre. " +
                            "Please contact your administrator for details.", "error", 8000);
                    }, 100);
                    setTimeout(function () {
                        LeopardSecurity.UserLogout();
                    }, 5000);
                    return;
                }
                that.loadPersistNotificationMessagesAsync(userId, that);

                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    window.userProfile = profile;

                    that.props.SetWebAppInitialized({data: true});
                    let orgId = LDH.GetOrganizationIdFromUserProfile(profile);
                    that.getUserAttributes(profile, 0);

                    LeopardAjaxHelper.RetrieveDocumentFromS3(orgId, "theme_management.json", "cc_dataview_config", function (documentData) {
                        let documentJson = new Object();
                        try {
                            // Add a try...catch here for backward compatibility.
                            documentJson = JSON.parse(documentData);
                        } catch (ex) {
                            documentJson = new Object();
                            documentData = null;
                        }
                        LDH.SetDefaultThemeColors(documentJson);

                        LeopardStaticUIConfig.LeopardDashboardColorBars = [
                            documentJson.workspaceColor1, documentJson.workspaceColor2, documentJson.workspaceColor3,
                            documentJson.workspaceColor4, documentJson.workspaceColor5, documentJson.workspaceColor6,
                            documentJson.workspaceColor7, documentJson.workspaceColor8, documentJson.workspaceColor9,
                            documentJson.workspaceColor10, documentJson.workspaceColor11, documentJson.workspaceColor12,
                            documentJson.workspaceColor13, documentJson.workspaceColor14, documentJson.workspaceColor15,
                            documentJson.workspaceColor16, documentJson.workspaceColor17, documentJson.workspaceColor18
                        ];
                        $("head").append(
                            "<style style='text/css'>" +
                            ":root {" +
                            "   --mainColor: " + documentJson.mainColor + "; " +
                            "   --shadeColor: " + documentJson.shadeColor + "; " +
                            "   --hoverColor: " + documentJson.hoverSelectionColor + "; " +
                            "   --secondaryColor: " + documentJson.topBarColor + "; " +
                            "}" +
                            "</style>");
                        if (documentData !== null) localStorage.setItem('theme_manager', documentData);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve the theme config.", "error", 5000);
                        }
                    });

                    let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
                    templateList.id = LDH.GenerateGuid();
                    templateList.type = "leopardsystems.document.retrieve";

                    let requestListData = {
                        name: "cc_global_config.json",
                        type: "global",
                        owner: orgId
                    };

                    LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                        let request = new XMLHttpRequest();
                        let url = response.body.data[0].url;
                        request.open("GET", url);
                        request.send();

                        request.onreadystatechange = function () {
                            if (request.readyState == 4) {
                                let $centeredLogo = $(".leopard-right-panel-container");
                                let $bannerLogo = $(".leopard-top-logo.customer-logo");

                                if (!LDH.IsJsonString(request.responseText)) {
                                    let defaultLogo = LeopardStaticUIConfig.LeopardCubeLogoBase64;
                                    $centeredLogo.css("background-image", "url(" + defaultLogo + ")");
                                    $centeredLogo.css("background-size", "300px 300px");
                                    return;
                                }
                                let resultJSON = JSON.parse(request.responseText);

                                if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo)) {
                                    LeopardAjaxHelper.RetrieveDocumentFromS3(orgId,
                                        resultJSON.cc_organisation_applogo,
                                        requestListData.type, function (documentData) {
                                            $centeredLogo.css("background-image", "url(" + documentData + ")");
                                            localStorage.setItem('cc_organisation_applogo', documentData);
                                        }, function () {
                                        });
                                }

                                if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_width) &&
                                    !LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_height)) {
                                    let sizeData = resultJSON.cc_organisation_applogo_width + " " +
                                        resultJSON.cc_organisation_applogo_height;
                                    $centeredLogo.css("background-size", sizeData);
                                    localStorage.setItem('cc_organisation_applogo_size', sizeData);
                                }

                                if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_banner)) {
                                    LeopardAjaxHelper.RetrieveDocumentFromS3(orgId,
                                        resultJSON.cc_organisation_applogo_banner,
                                        requestListData.type, function (documentData) {
                                            $bannerLogo.css("background-image", "url(" + documentData + ")");
                                            localStorage.setItem('cc_organisation_applogo_banner', documentData);
                                        }, function () {
                                        });
                                }

                                if (!LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_banner_width) &&
                                    !LDH.IsValueEmpty(resultJSON.cc_organisation_applogo_banner_height)) {
                                    let sizeData = resultJSON.cc_organisation_applogo_banner_width + " " +
                                        resultJSON.cc_organisation_applogo_banner_height;
                                    $bannerLogo.css("background-size", sizeData);
                                    localStorage.setItem('cc_organisation_applogo_banner_size', sizeData);
                                }
                            }
                        }
                    }, function (error, sessionTimeout) {}, templateList, requestListData);

                    LeopardAjaxHelper.GetControlCentreVersion(userId, function (data) {
                        if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.currentVersion)) {
                            if (LDH.IsUpdateControlCentreVersionRequired(data)) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        } else {
                            if (LDH.IsUpdateControlCentreVersionRequired(data)) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        }
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            if (LDH.IsUpdateControlCentreVersionRequired({currentVersion: "1.0.0.0"})) {
                                LeopardAjaxHelper.SetControlCentreVersion(orgId, null, null);
                            }
                        }
                    });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve the user profile.", "error", 5000);
                    }
                }, null, "config", null);

                let roleUrl = LDH.GetODataAPIGatewayUrl(window.APIGatewayRoleUrl);
                window.userRolesLookup = [];
                LeopardAjaxHelper.GenericHttpRequest("get", roleUrl, null, function (response) {
                    for (let v = 0; v < response.value.length; v++) {
                        window.userRolesLookup.push({
                            value: response.value[v].RoleCode,
                            text: response.value[v].Name,
                        });
                    }
                }, null);
            }, function () {
                localStorage.setItem("authState", "signIn");
                window.location.reload();
            });
            that.getCurrentUserCredentials(function () {
                if (LeopardStaticUIConfig.WebsocketCommunicationEnabled) {
                    LWH.OpenConnection(that);
                    window.pendingWebsocketEventKeys = [];
                }
            });

            setInterval(function () {
                that.getCurrentUserCredentials(null);
            }, 600000);
        } else {
            localStorage.setItem("authState", "signIn");
            window.location.reload();
        }
    };

    getUserAttributes = (userProfile, retryIndex) => {
        let that = this;
        let attributeUrl = "/reports/view/user_attribute?$filter=id eq '" + userProfile.ID + "'&$orderby=name&$top=1&$count=false";
        LeopardAjaxHelper.SimpleHttpGetRequest(attributeUrl, function (attrData) {
            if (LDH.IsObjectNull(attrData.value) || typeof attrData.value !== "object") {
                if (retryIndex < 5) {
                    that.getUserAttributes(userProfile, retryIndex + 1);
                } else {
                    console.log("Failed to retrieve user attributes. Refresh your browser and try again.");
                }
                return;
            }
            userProfile["UserAttributes"] = attrData.value;
            window.userProfile = userProfile;
        }, function (error, sessionTimeout) {
            if (retryIndex < 5) {
                that.getUserAttributes(userProfile, retryIndex + 1);
            } else {
                console.log("Failed to retrieve user attributes. Refresh your browser and try again.");
            }
        })
    };

    getCurrentUserCredentials = (callback) => {
        LeopardSecurity.GetCurrentUserCredentials(function (credential) {
            if (!LDH.IsObjectNull(credential.name) &&
                credential.name === "NotAuthorizedException") {
                LeopardSecurity.UserLogout();
                return;
            }
            window.userCredential = credential;
            if (!LDH.IsObjectNull(callback)) callback();
        }, function (ex) {
            LeopardSecurity.UserLogout();
            console.log(ex);
        });
    };

    navHideButtonOnClick = () => {
        let that = this;

        if (that.showLeftMenuContent === false) {
            that.showLeftMenuContent = true;
            $(".leopard-left-panel-container").show(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();
                that.resizeDashboardItems();
            });
            $("#RootWindowContainer").removeClass("left-panel-hidden")
                .addClass("left-panel-shown");
        } else {
            that.showLeftMenuContent = false;
            $(".leopard-left-panel-container").hide(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();
                that.resizeDashboardItems();
            });
            $("#RootWindowContainer").removeClass("left-panel-shown")
                .addClass("left-panel-hidden");
        }
    };

    resizeDashboardItems = () => {
        let that = this;
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
        setTimeout(function () {
            for (let i = 0; i < listeners.length; i++) {
                if (LDH.IsObjectNull(listeners[i].instance) || listeners[i].instance === "blank") {
                    continue;
                }
                if (listeners[i].instance.NAME !== "dxTileView") {
                    if (LDH.IsObjectNull(listeners[i].instance.option)) {
                        continue;
                    }
                    let size = listeners[i].instance.option("size");
                    listeners[i].instance.option("size", size);
                    if (listeners[i].instance.NAME === "dxDataGrid") {
                        if (listeners[i].instance.getVisibleRows().length > 0) {
                            listeners[i].instance.repaint();
                        }
                    }
                }
            }

            if (LDH.IsObjectNull(window.leopardMapInstances)) {
                window.leopardMapInstances = [];
            }
            for (let i = 0; i < window.leopardMapInstances.length; i++) {
                if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                    continue;
                }
                window.leopardMapInstances[i].instance.updateSize();
            }
        }, 500);
    };

    refreshBrowserTab = () => {
        window.location.reload();
    };

    render() {
        if (this.state.masterPageOnLoadComplete === true) {
            return (
                <React.Fragment>
                    <div className="App">
                        <div className={"dashboard-settings-version"} settingsversion=""></div>
                        <div className={"dataview-settings-version"} settingsversion=""></div>
                        <div className="leopard-static-toast toast" role="alert" aria-live="assertive"
                             aria-atomic="true">
                            <div className="toast-header">
                                <strong className="me-auto toast-title"></strong>
                                <button type="button" className="btn-close" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                            </div>
                            <div className="toast-body toast-description"></div>
                        </div>
                        <div className={"leopard-add-autocomplete-blocker"}>
                            <TextBox mode={"text"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                            <TextBox mode={"password"} style={{height: "0px", width: "0px", border: "none"}}></TextBox>
                        </div>
                        <div className={"leopard-application-loading-cover"}>
                             <span className={"leopard-loading-icon leopard-centre-box"}>
                                 <i className="fas fa-spinner fa-pulse"
                                    style={{fontSize: "45px"}}></i>
                             </span>
                        </div>
                        <div className={"cc-update-notice-text"}>
                            <h3 style={{marginBottom: "30px"}}>Your Control Centre is outdated</h3>
                            <div>We have just released a version of the Control Centre.</div>
                            <div style={{marginBottom: "30px"}}>
                                Please refresh your browser to update the local cache.
                            </div>
                            <Button className="leopard-button refresh-browser" text={'Refresh Browser'}
                                    onClick={(e) => this.refreshBrowserTab(e)}/>
                        </div>
                        <LeopardTopMenuBar loggedInUserName={this.state.loggedInUserName}
                                           leftMenuExpandOnSelection={() => this.leftMenuExpandOnSelection(this)}
                                           leftMenuExpandAll={() => this.leftMenuExpandAll(this)}
                                           navHideButtonOnClick={this.navHideButtonOnClick}>
                        </LeopardTopMenuBar>
                        <div className="leopard-master-leftmenu-container">
                            <LeopardMasterLeftMenu
                                setLeftMenuTreeViewInstance={(e) => this.setLeftMenuTreeViewInstance(e)}
                                updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                            </LeopardMasterLeftMenu>
                            <div className={"leopard-right-panel-container"}>
                                <LeopardMasterContentPanel
                                    setGridViewInstance={(e) => this.setGridViewInstance(e)}
                                    setChartInstance={(e) => this.setChartInstance(e)}
                                    setTimelineInstance={(e) => this.setTimelineInstance(e)}
                                    setPhotoInstance={(e) => this.setPhotoInstance(e)}
                                    setReportInstance={(e) => this.setReportInstance(e)}
                                    setNotepadInstance={(e) => this.setNotepadInstance(e)}
                                    setDocumentEditorInstance={(e) => this.setDocumentEditorInstance(e)}
                                    windowHeight={0}
                                    setMapInstance={(e) => this.setMapInstance(e)}
                                    updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}>
                                </LeopardMasterContentPanel>
                            </div>
                        </div>
                        <div className={"leopard-notification-panel-container"}>
                            <LeopardNotificationPanel
                                appInstance={this}
                                setNotificationPanelInstance={(e) => this.setNotificationPanelInstance(e)}>

                            </LeopardNotificationPanel>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetUserPermissions: (data) => {
            dispatch(SetUserPermissions(data));
        },
        SetLeopardAdminMode: (data) => {
            dispatch(SetLeopardAdminMode(data));
        },
        SetAuthenticatedUser: (data) => {
            dispatch(SetAuthenticatedUser(data));
        },
        SetWebAppInitialized: (data) => {
            dispatch(SetWebAppInitialized(data));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(App);
