import React, {Component} from 'react';
import LDH from "../helpers/LeopardDataHelper";

import {JsonForms} from '@jsonforms/react';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';

import LeopardDocAutoCompleteRendererCell from '../foundation/LeopardDocAutoCompleteRendererCell';
import LeopardDocAutoCompleteRenderer from '../foundation/LeopardDocAutoCompleteRenderer';
import LeopardDocAutoCompleteTester from '../foundation/LeopardDocAutoCompleteTester';

import LeopardDocTextRendererCell from '../foundation/LeopardDocTextRendererCell';
import LeopardDocTextRenderer from '../foundation/LeopardDocTextRenderer';
import LeopardDocTextTester from '../foundation/LeopardDocTextTester';

import LeopardDocColorPickerRendererCell from '../foundation/LeopardDocColorPickerRendererCell';
import LeopardDocColorPickerRenderer from '../foundation/LeopardDocColorPickerRenderer';
import LeopardDocColorPickerTester from '../foundation/LeopardDocColorPickerTester';

import LeopardDocNumberRendererCell from '../foundation/LeopardDocNumberRendererCell';
import LeopardDocNumberRenderer from '../foundation/LeopardDocNumberRenderer';
import LeopardDocNumberTester from '../foundation/LeopardDocNumberTester';

import LeopardDocDropdownRendererCell from '../foundation/LeopardDocDropdownRendererCell';
import LeopardDocDropdownRenderer from '../foundation/LeopardDocDropdownRenderer';
import LeopardDocDropdownTester from '../foundation/LeopardDocDropdownTester';

import LeopardDocDateRendererCell from '../foundation/LeopardDocDateRendererCell';
import LeopardDocDateRenderer from '../foundation/LeopardDocDateRenderer';
import LeopardDocDateTester from '../foundation/LeopardDocDateTester';

import LeopardDocDateTimeRendererCell from '../foundation/LeopardDocDateTimeRendererCell';
import LeopardDocDateTimeRenderer from '../foundation/LeopardDocDateTimeRenderer';
import LeopardDocDateTimeTester from '../foundation/LeopardDocDateTimeTester';
import {Generate} from "@jsonforms/core";
import LRH from "../helpers/LeopardReactHelper";

class LeopardDocumentEditorEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentData: null
        }
        this.uiObjectInstances = [];
        this.documentDataJSON = null;
        this.onChangeEventProcess = "update";
    }

    componentDidMount() {
        this.setState({documentData: this.props.documentData});
    }

    componentWillUnmount = () => {
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    updateDataPreviewerState = (data) => {
        this.props.updateDataPreviewerState(data);
    }

    updateDocumentData = (data) => {
        this.setState({documentData: null});
    }

    render() {
        let that = this;
        let definition = this.props.documentDefinition;
        let documentData = this.props.documentData;
        documentData = LDH.ConvertToDocumentEditorCompatibleJSON(documentData, false);

        let schema = Generate.jsonSchema(documentData);
        if (!LDH.IsObjectNull(definition.documentEditorDataSchema) &&
            !LDH.IsValueEmpty(definition.documentEditorDataSchema)) {
            try {
                schema = JSON.parse(definition.documentEditorDataSchema);
            } catch (ex) {
                schema = Generate.jsonSchema(documentData);
            }
        }

        let uischema = Generate.uiSchema(documentData);
        if (!LDH.IsObjectNull(definition.documentEditorUISchema) &&
            !LDH.IsValueEmpty(definition.documentEditorUISchema)) {
            try {
                uischema = JSON.parse(definition.documentEditorUISchema);
            } catch (ex) {
                uischema = Generate.uiSchema(documentData);
            }
        }

        let jsonFormsAttrs = {schema: schema, uischema: uischema};
        if (LDH.IsObjectNull(uischema) === true) {
            jsonFormsAttrs = {schema: schema};
        }

        if (definition.documentEditorDataFormat === "json") {
            return (
                <React.Fragment>
                    <div ref={() => this.props.setUIInstance({e: that, name: "leopard_document_data_editor"})}
                         className={"leopard-document-editor-container"}>
                        <JsonForms
                            {...jsonFormsAttrs} data={documentData} config={{definition: definition}}
                            renderers={[...materialRenderers,
                                {tester: LeopardDocTextTester, renderer: LeopardDocTextRenderer},
                                {tester: LeopardDocNumberTester, renderer: LeopardDocNumberRenderer},
                                {tester: LeopardDocDropdownTester, renderer: LeopardDocDropdownRenderer},
                                {tester: LeopardDocDateTimeTester, renderer: LeopardDocDateTimeRenderer},
                                {tester: LeopardDocDateTester, renderer: LeopardDocDateRenderer},
                                {tester: LeopardDocColorPickerTester, renderer: LeopardDocColorPickerRenderer},
                                {tester: LeopardDocAutoCompleteTester, renderer: LeopardDocAutoCompleteRenderer}
                            ]}
                            cells={[...materialCells,
                                {tester: LeopardDocTextTester, cell: LeopardDocTextRendererCell},
                                {tester: LeopardDocNumberTester, cell: LeopardDocNumberRendererCell},
                                {tester: LeopardDocDropdownTester, cell: LeopardDocDropdownRendererCell},
                                {tester: LeopardDocDateTimeTester, cell: LeopardDocDateTimeRendererCell},
                                {tester: LeopardDocDateTester, cell: LeopardDocDateRendererCell},
                                {tester: LeopardDocColorPickerTester, cell: LeopardDocColorPickerRendererCell},
                                {tester: LeopardDocAutoCompleteTester, renderer: LeopardDocAutoCompleteRendererCell}
                            ]}
                            onChange={({data}) => {
                                if (that.onChangeEventProcess === "disabled") {
                                    that.updateDataPreviewerState({
                                        readOnly: false
                                    });
                                } else if (that.onChangeEventProcess === "update") {
                                    that.documentDataJSON = data;
                                    that.updateDataPreviewerState({
                                        documentDataString: JSON.stringify(data, null, 2),
                                        readOnly: true
                                    });
                                } else if (that.onChangeEventProcess === "undo") {
                                    let dataJSON = LDH.DeepClone(that.documentDataJSON);
                                    that.updateDataPreviewerState({
                                        documentDataString: JSON.stringify(dataJSON, null, 2),
                                        readOnly: true
                                    });
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            )
        }
        return null;
    }
}

export default LeopardDocumentEditorEngine;