import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocTextControl} from './LeopardDocTextControl';

const LeopardDocTextRendererCell = (props) => {
    return React.createElement(LeopardDocTextControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocTextRendererCell);