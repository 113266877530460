import React from 'react';
import LDH from '../helpers/LeopardDataHelper';
import websocket from 'websocket';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";
import aws4 from "aws4";
import momenttz from "moment-timezone";
import $ from "jquery";
import Notify from 'devextreme/ui/notify';

class LeopardWebsocketHelper extends React.Component {
    static IsWebsocketEnabled = () => {
        if (window.location.hostname === "localhost" || window.location.hostname === "192.168.180.153") {
            LeopardStaticUIConfig.ControlCentreWebsocketEnabled = true;
        }
        return LDH.IsValueTrue(LeopardStaticUIConfig.ControlCentreWebsocketEnabled);
    }

    static OpenConnection = (app) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        let endpoint = LDH.WebsocketEndpointAdapter();
        let endpointWithoutPrefix = endpoint.replace("wss://", "");
        let W3CWebSocket = websocket.w3cwebsocket;

        let accessInfo = {
            accessKeyId: window.userCredential.accessKeyId,
            secretAccessKey: window.userCredential.secretAccessKey,
            sessionToken: window.userCredential.sessionToken
        };

        console.log(endpointWithoutPrefix);
        let signedReq = aws4.sign({
            host: endpointWithoutPrefix,
            path: `/?X-Amz-Security-Token=${encodeURIComponent(accessInfo.sessionToken)}`,
            region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
            service: "execute-api",
            signQuery: true
        }, accessInfo);

        let client = new W3CWebSocket(`${endpoint}${signedReq.path}`);
        client.onerror = function (err) {
            console.log('Websocket client error', err);
            client.close();
        };

        client.onopen = function (e) {
            console.log('Websocket client connected', e);
            if (client.readyState === 1) {
                client.send({});
            }
        };

        client.onclose = function () {
            console.log('Websocket client disconnected');

            setTimeout(function () {
                console.log('Websocket client reconnecting');
                LeopardWebsocketHelper.OpenConnection(app);
            }, 1000);
        };

        client.onmessage = function (e) {
            let dateDisplayFormat = "DD/MM/YYYY HH:mm:ss";
            console.log('Websocket client message', e);

            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data) &&
                e.data.indexOf("Internal server error") > -1 &&
                e.data.indexOf("connectionId") > -1) {
                let value = JSON.parse(e.data);
                window.websocketConnectionId = value.connectionId;
            }

            if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.data) &&
                !LDH.IsValueEmpty(e.data)) {
                let jsonData = JSON.parse(e.data);
                let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
                if (!LDH.IsObjectNull(jsonData) && jsonData.type === "leopardsystems.athena.results" &&
                    jsonData.data.reportMetadata.renderingMethod === "browser" &&
                    loginUserId === jsonData.data.reportMetadata.loginUserId) {
                    LeopardWebsocketHelper.SendAdHocReportNotification(jsonData, app, dateDisplayFormat, true);
                    return;
                }
                if (!LDH.IsObjectNull(jsonData) && jsonData.type === "leopardsystems.athena.results" &&
                    jsonData.data.reportMetadata.renderingMethod === "stimulsoft" &&
                    loginUserId === jsonData.data.reportMetadata.loginUserId) {
                    LeopardWebsocketHelper.SendGeneratedReportNotification(jsonData, app, dateDisplayFormat, true);
                    return;
                }

                if (!LDH.IsObjectNull(jsonData) && !LDH.IsObjectNull(jsonData.eventkey) &&
                    !LDH.IsValueEmpty(jsonData.eventkey)) {
                    LeopardWebsocketHelper.UpdatePendingEvent(jsonData.eventkey, "completed", jsonData);
                }

                if (!LDH.IsObjectNull(jsonData) && !LDH.IsObjectNull(jsonData.data) &&
                    LDH.IsObjectNull(jsonData.eventkey)) {
                    for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
                        let event = window.pendingWebsocketEventKeys[i];
                        if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.isSubscription) ||
                            !event.isSubscription || event.eventType !== jsonData.type) {
                            continue;
                        }
                        if (!LDH.IsObjectNull(event.callback)) event.callback(jsonData);
                    }
                }
            }
        };
        window.websocketInstance = client;
    };

    static SendMessage = (message) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.websocketInstance)) {
            return;
        }
        let client = window.websocketInstance;
        if (client.readyState === 1) {
            client.send(message);
        }
    };

    static CloseConnection = () => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.websocketInstance)) {
            return;
        }
        window.websocketInstance.close();
    };

    static GetMessageTemplate = (type, connectionId, data, eventKey, responseRouteType) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.browserTabId) || LDH.IsValueEmpty(window.browserTabId)) {
            window.browserTabId = LDH.GenerateGuid();
        }
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let responseRoute = "";
        if (responseRouteType === "connectionId") {
            responseRoute = "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}";
        }
        if (responseRouteType === "userId") {
            responseRoute = "{\"type\":\"map\",\"mapping\": [ { \"type\": \"user-sub\", " +
                "\"subs\": [ \"" + userId + "\"] } ] }";
        }

        return {
            "specversion": "1.0",
            "type": type,
            "source": "urn:" + window.browserTabId + ":tbd:websocket",
            "id": eventKey,
            "time": new Date().toISOString(),
            "responseroute": responseRoute,
            "data": data
        }
    }

    static GetMessageTemplateForSubscription = (type, connectionId, subscribeTo, eventKey, responseRouteType) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.browserTabId) || LDH.IsValueEmpty(window.browserTabId)) {
            window.browserTabId = LDH.GenerateGuid();
        }
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let responseRoute = "";
        if (responseRouteType === "connectionId") {
            responseRoute = "{\"type\":\"direct\",\"connectionid\":\"" + connectionId + "\"}";
        }
        if (responseRouteType === "userId") {
            responseRoute = "{\"type\":\"map\",\"mapping\": [ { \"type\": \"user-sub\", " +
                "\"subs\": [ \"" + userId + "\"] } ] }";
        }

        return {
            "specversion": "1.0",
            "type": type,
            "source": "urn:" + window.browserTabId + ":tbd:websocket",
            "id": eventKey,
            "time": new Date().toISOString(),
            "responseroute": responseRoute,
            "data": {
                "subscriptions": [
                    {
                        "type": "event",
                        "value": subscribeTo
                    }
                ]
            }
        }
    }

    static AddPendingEvent = (eventKey, uiObjectId, dataViewId, eventType, callback, isSubscription) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.pendingWebsocketEventKeys)) {
            window.pendingWebsocketEventKeys = [];
        }
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.eventKey)) {
                continue;
            }
            if (event.eventKey === eventKey) {
                delete window.pendingWebsocketEventKeys[i];
                break;
            }
        }
        if (LDH.IsObjectNull(callback)) callback = null;
        if (LDH.IsObjectNull(isSubscription)) isSubscription = false;

        window.pendingWebsocketEventKeys.push({
            eventKey: eventKey,
            status: "sent",
            dataViewId: dataViewId,
            eventType: eventType,
            uiObjectId: uiObjectId,
            data: null,
            callback: callback,
            isSubscription: isSubscription
        });
    }

    static UpdatePendingEvent = (eventKey, status, response) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        let event = LeopardWebsocketHelper.FindPendingEventByKey(eventKey);
        if (LDH.IsObjectNull(event)) return;
        event.status = status;
        event.data = response;
    }

    static RemovePendingEventByDataViewId = (dataViewId) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        if (LDH.IsObjectNull(window.pendingWebsocketEventKeys)) {
            window.pendingWebsocketEventKeys = [];
        }
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.dataViewId)) {
                continue;
            }
            if (event.dataViewId === dataViewId) {
                delete window.pendingWebsocketEventKeys[i];
                i--;
            }
        }
    }

    static FindPendingEventByKey = (eventKey) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            return;
        }
        for (let i = 0; i < window.pendingWebsocketEventKeys.length; i++) {
            let event = window.pendingWebsocketEventKeys[i];
            if (LDH.IsObjectNull(event) || LDH.IsObjectNull(event.eventKey)) {
                continue;
            }
            if (event.eventKey === eventKey) return event;
        }
        return null;
    }

    static WaitForWebsocketResponse = (eventKey, callback) => {
        if (!LeopardWebsocketHelper.IsWebsocketEnabled()) {
            callback("no-websocket");
            return;
        }
        let loopCount = 0;
        let pendingCallback = setInterval(function () {
            loopCount++;

            if (loopCount > 600) {
                callback("aborted");
                clearInterval(pendingCallback);
                return;
            }

            let eventObj = LeopardWebsocketHelper.FindPendingEventByKey(eventKey);
            if (LDH.IsObjectNull(eventObj)) {
                callback("aborted");
                clearInterval(pendingCallback);
                return;
            }

            if (eventObj.status !== "completed") {
                return;
            }

            if (!LDH.IsObjectNull(eventObj) && !LDH.IsObjectNull(eventObj.data)) {
                callback("completed", eventObj.data);
            } else {
                callback("completed", null);
            }
            clearInterval(pendingCallback);
        }, 50);
        return pendingCallback;
    }

    static SendAdHocReportNotification(jsonData, app, dateDisplayFormat, showPopupNotify) {
        let reportMetadata = jsonData.data.reportMetadata;
        window.reportAthenaResults = {action: "render", data: jsonData.data};

        let datasource = app.notificationPanelInstance.option("dataSource");
        if (typeof datasource === "undefined" || datasource === null) {
            datasource = [];
        }
        let notificationText = "\"" + reportMetadata.reportName + "\" is ready for preview.";
        datasource.unshift({
            text: notificationText,
            time: momenttz(jsonData.time).tz("Australia/Sydney").format(dateDisplayFormat),
            groupText: "Reports",
            isClicked: false,
            isHidden: false,
            id: LDH.GenerateGuid(),
            dataViewId: reportMetadata.dataViewId,
            menuFolderId: reportMetadata.menuFolderId,
            actionType: "preview",
            responseData: jsonData.data,
            notificationType: "report",
            orderIndex: datasource.length + 1
        });
        app.notificationPanelInstance.option("dataSource", datasource);
        app.notificationPanelInstance.option("visible", true);
        $(".leopard-notification-panel-container .no-message-container").removeClass("shown");
        $(".leopard-topmenu-bar-notification-indicator").addClass("shown");

        if (showPopupNotify) {
            Notify({
                    message: notificationText,
                    height: 45,
                    width: 250,
                    type: "success",
                    displayTime: 4000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400,
                            from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "bottom right",
                    direction: "up-stack"
                },
            );
        }
    };

    static SendGeneratedReportNotification(jsonData, app, dateDisplayFormat, showPopupNotify) {
        let reportMetadata = jsonData.data.reportMetadata;
        window.reportAthenaResults = {action: "stop", data: jsonData.data};

        let datasource = app.notificationPanelInstance.option("dataSource");
        if (typeof datasource === "undefined" || datasource === null) {
            datasource = [];
        }
        let notificationText = "\"" + reportMetadata.reportName + "\" is ready for download.";

        datasource.unshift({
            text: notificationText,
            time: momenttz(jsonData.time).tz("Australia/Sydney").format(dateDisplayFormat),
            groupText: "Reports",
            isClicked: false,
            isHidden: false,
            id: LDH.GenerateGuid(),
            actionType: "download",
            responseData: jsonData.data,
            notificationType: "report",
            orderIndex: datasource.length + 1,
            s3ReportPathInternal: reportMetadata.s3ReportPathInternal
        });
        app.notificationPanelInstance.option("dataSource", datasource);
        app.notificationPanelInstance.option("visible", true);
        $(".leopard-notification-panel-container .no-message-container").removeClass("shown");
        $(".leopard-topmenu-bar-notification-indicator").addClass("shown");

        if (showPopupNotify) {
            Notify({
                    message: notificationText,
                    height: 45,
                    width: 250,
                    type: "success",
                    displayTime: 4000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400,
                            from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "bottom right",
                    direction: "up-stack"
                },
            );
        }
    };
}

export default LeopardWebsocketHelper;