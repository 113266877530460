import React, {Component} from 'react';
import LDH from "../helpers/LeopardDataHelper";
import LeopardSingleDataView from "../pages/LeopardSingleDataView";

class LeopardTabbedViewTabItemEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (LDH.IsObjectNull(this.props.tabbedViewDefinition) ||
            LDH.IsObjectNull(this.props.tabbedViewDefinition.tabParameters) ||
            this.props.tabbedViewDefinition.tabParameters.length === 0) {
            return (
                <div style={{width: "100%", height: "100%"}}></div>
            );
        }

        let tabParameter = null;
        let definition = this.props.tabbedViewDefinition;
        let tabParameters = definition.tabParameters;
        for (let i = 0; i < tabParameters.length; i++) {
            if (tabParameters[i].id === this.props.tabId) {
                tabParameter = tabParameters[i];
            }
        }

        return (
            <LeopardSingleDataView
                windowHeight={0}
                setGridViewInstanceForChildView={this.props.setGridViewInstanceForChildView}
                dataSourceUrl={""}
                menuItemName={""}
                menuItemId={""}
                isDataView={false}
                dataViewId={tabParameter.dataViewIdForTabPage}
                dataViewNote={null}
                dataViewType={"datagrid"}
                isLoadForTabbedView={true}
                relationships={this.props.relationships}
                updateWindowDimensionsRequired={(e) => this.props.updateWindowDimensionsRequired(e)}
                parentMenuItemName={""}>
            </LeopardSingleDataView>
        )
    };
}

export default React.memo(LeopardTabbedViewTabItemEngine);
