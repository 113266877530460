import React from 'react';
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import LDH from "./LeopardDataHelper";
import LRH from "./LeopardReactHelper";
import LeopardAjaxHelper from "./LeopardAjaxHelper";

class LeopardQueryHelper extends React.Component {
    constructor(props) {
        super(props);

        momentDurationFormatSetup(moment);
        if (typeof moment.duration.fn.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
        if (typeof moment.duration.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
    }

    static ExecuteQueriesForReport = (apiUrl, parentQuery1, parentQuery2, parentQuery3, query, postDataQuery, ajaxRequestObj, callbackSuccess, callbackFail) => {
        let ajaxTotalCount = 0;
        query = LDH.ReplaceAll(query, "&ignoreValidation=true", "");

        if (!LDH.IsValueEmpty(parentQuery1)) {
            parentQuery1 = LDH.ReplaceAll(parentQuery1, "&ignoreValidation=true", "");
        }
        if (!LDH.IsValueEmpty(parentQuery2)) {
            parentQuery2 = LDH.ReplaceAll(parentQuery2, "&ignoreValidation=true", "");
        }
        if (!LDH.IsValueEmpty(parentQuery3)) {
            parentQuery3 = LDH.ReplaceAll(parentQuery3, "&ignoreValidation=true", "");
        }

        let executedAjaxCountInProc = 0;
        let totalAjaxCountInProc = 0;
        let callbackResult = [];

        if (LDH.IsValueEmpty(parentQuery1) === false) {
            ajaxRequestObj.push(
                LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + parentQuery1, function (data) {
                    if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                        data = data.value;
                    }
                    ajaxTotalCount++;

                    let dataParent1 = {parentLevel1: data};
                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                        dataParent1 = LRH.ExecuteClientScriptWithIdentifier(dataParent1, postDataQuery, "data", true, "parentQuery1");
                    }

                    if (!LDH.IsObjectNull(dataParent1.parentLevel1) && typeof dataParent1.parentLevel1 === "object") {
                        executedAjaxCountInProc = 0;
                        totalAjaxCountInProc = dataParent1.parentLevel1.length;
                        callbackResult = [];

                        if (dataParent1.parentLevel1.length === 0) {
                            ajaxTotalCount += 3;
                            callbackSuccess(null, "customQuery", ajaxTotalCount);
                        }

                        for (let i1 = 0; i1 < dataParent1.parentLevel1.length; i1++) {
                            let parentQueryRowData1 = dataParent1.parentLevel1[i1];

                            if (LDH.IsValueEmpty(parentQuery2) === false) {
                                let keys = Object.keys(parentQueryRowData1);
                                for (let i2 = 0; i2 < keys.length; i2++) {
                                    parentQuery2 = LDH.ReplaceAll(parentQuery2, "{" + keys[i2] + "}", parentQueryRowData1[keys[i2]]);
                                }

                                LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + parentQuery2, function (data) {
                                    if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                                        data = data.value;
                                    }
                                    ajaxTotalCount++;

                                    let dataParent2 = {
                                        parentLevel1: parentQueryRowData1,
                                        parentLevel2: data
                                    };

                                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                        dataParent2 = LRH.ExecuteClientScriptWithIdentifier(dataParent2, postDataQuery, "data", true, "parentQuery2");
                                    }

                                    if (!LDH.IsObjectNull(dataParent2.parentLevel2) && typeof dataParent2.parentLevel2 === "object") {
                                        executedAjaxCountInProc = 0;
                                        totalAjaxCountInProc = dataParent2.parentLevel2.length;
                                        callbackResult = [];

                                        if (dataParent2.parentLevel2.length === 0) {
                                            ajaxTotalCount += 2;
                                            callbackSuccess(null, "customQuery", ajaxTotalCount);
                                        }

                                        for (let i3 = 0; i3 < dataParent2.parentLevel2.length; i3++) {
                                            let parentQueryRowData2 = dataParent2.parentLevel2[i3];

                                            if (LDH.IsValueEmpty(parentQuery3) === false) {
                                                let keys = Object.keys(parentQueryRowData2);
                                                for (let i4 = 0; i4 < keys.length; i4++) {
                                                    parentQuery3 = LDH.ReplaceAll(parentQuery3, "{" + keys[i4] + "}", parentQueryRowData2[keys[i4]]);
                                                }

                                                LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + parentQuery3, function (data) {
                                                    if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                                                        data = data.value;
                                                    }
                                                    ajaxTotalCount++;

                                                    let dataParent3 = {
                                                        parentLevel1: parentQueryRowData1,
                                                        parentLevel2: parentQueryRowData2,
                                                        parentLevel3: data
                                                    };

                                                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                                        dataParent3 = LRH.ExecuteClientScriptWithIdentifier(dataParent3, postDataQuery, "data", true, "parentQuery3");
                                                    }

                                                    if (!LDH.IsObjectNull(dataParent3.parentLevel3) && typeof dataParent3.parentLevel3 === "object") {
                                                        executedAjaxCountInProc = 0;
                                                        totalAjaxCountInProc = dataParent3.parentLevel3.length;
                                                        callbackResult = [];

                                                        if (dataParent3.parentLevel3.length === 0) {
                                                            ajaxTotalCount += 1;
                                                            callbackSuccess(null, "customQuery", ajaxTotalCount);
                                                        }

                                                        for (var i5 = 0; i5 < dataParent3.parentLevel3.length; i5++) {
                                                            let parentQueryRowData3 = dataParent3.parentLevel3[i5];
                                                            let finalQuery = query;

                                                            for (let i6 = 0; i6 < keys.length; i6++) {
                                                                finalQuery = LDH.ReplaceAll(finalQuery, "{" + keys[i6] + "}", parentQueryRowData3[keys[i6]]);
                                                            }

                                                            ajaxRequestObj.push(
                                                                LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + finalQuery, function (data) {
                                                                    if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                                                                        data = data.value;
                                                                    }
                                                                    if (!LDH.IsObjectNull(data.message) && data.message.statusCode === "404") {
                                                                        data = [];
                                                                    }
                                                                    let dataFinal = {
                                                                        parentLevel1: parentQueryRowData1,
                                                                        parentLevel2: parentQueryRowData2,
                                                                        parentLevel3: parentQueryRowData3,
                                                                        finalData: data
                                                                    };

                                                                    if (LDH.IsObjectNull(dataFinal.finalData) || typeof dataFinal.finalData !== "object") {
                                                                        dataFinal.finalData = [];
                                                                    }
                                                                    ajaxTotalCount++;
                                                                    executedAjaxCountInProc++;

                                                                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                                                        dataFinal = LRH.ExecuteClientScript(dataFinal, postDataQuery, "data", true);
                                                                    }

                                                                    if (dataFinal.finalData.length === 0) {
                                                                        callbackSuccess(null, "customQuery", ajaxTotalCount);
                                                                    }

                                                                    for (let b3 = 0; b3 < dataFinal.finalData.length; b3++) {
                                                                        callbackResult.push(dataFinal.finalData[b3]);
                                                                    }
                                                                    if (typeof dataFinal.finalData.message !== "undefined" && dataFinal.finalData.length > 0) {
                                                                        for (let c3 = 0; c3 < dataFinal.finalData.length; c3++) {
                                                                            callbackResult.push(dataFinal.finalData[c3]);
                                                                        }
                                                                    }
                                                                    if (executedAjaxCountInProc === totalAjaxCountInProc) {
                                                                        callbackSuccess(callbackResult, "customQuery", ajaxTotalCount);
                                                                    }
                                                                }, function () {
                                                                    callbackFail(null, "customQuery");
                                                                })
                                                            );
                                                        }
                                                    } else {
                                                        callbackSuccess(null, "customQuery", ajaxTotalCount);
                                                    }
                                                }, function () {
                                                    LRH.ShowToast("Failed to generate your report. " +
                                                        "Please check your network connection status and try again.", "error", 5000);
                                                })
                                            } else {
                                                let keys = Object.keys(parentQueryRowData2);
                                                let finalQuery = query;

                                                for (let v2 = 0; v2 < keys.length; v2++) {
                                                    finalQuery = LDH.ReplaceAll(finalQuery, "{" + keys[v2] + "}", parentQueryRowData2[keys[v2]]);
                                                }

                                                ajaxRequestObj.push(
                                                    LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + finalQuery, function (data) {
                                                        if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                                                            data = data.value;
                                                        }
                                                        if (!LDH.IsObjectNull(data.message) && data.message.statusCode === "404") {
                                                            data = [];
                                                        }
                                                        let dataFinal = {
                                                            parentLevel1: parentQueryRowData1,
                                                            parentLevel2: parentQueryRowData2,
                                                            parentLevel3: null,
                                                            finalData: data
                                                        };

                                                        if (LDH.IsObjectNull(dataFinal.finalData) || typeof dataFinal.finalData !== "object") {
                                                            dataFinal.finalData = [];
                                                        }
                                                        ajaxTotalCount++;
                                                        executedAjaxCountInProc++;

                                                        if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                                            dataFinal = LRH.ExecuteClientScript(dataFinal, postDataQuery, "data", true);
                                                        }
                                                        for (let b2 = 0; b2 < dataFinal.finalData.length; b2++) {
                                                            callbackResult.push(dataFinal.finalData[b2]);
                                                        }
                                                        if (typeof dataFinal.finalData.message !== "undefined" && dataFinal.finalData.length > 0) {
                                                            for (let c2 = 0; c2 < dataFinal.finalData.length; c2++) {
                                                                callbackResult.push(dataFinal.finalData[c2]);
                                                            }
                                                        }
                                                        if (executedAjaxCountInProc === totalAjaxCountInProc) {
                                                            callbackSuccess(callbackResult, "customQuery", ajaxTotalCount);
                                                        }
                                                    }, function () {
                                                        callbackFail(null, "customQuery");
                                                    })
                                                );
                                            }
                                        }
                                    } else {
                                        callbackSuccess(null, "customQuery", ajaxTotalCount);
                                    }
                                }, function () {
                                    LRH.ShowToast("Failed to generate your report. " +
                                        "Please check your network connection status and try again.", "error", 5000);
                                })
                            } else {
                                let keys = Object.keys(parentQueryRowData1);
                                let finalQuery = query;

                                for (let v1 = 0; v1 < keys.length; v1++) {
                                    finalQuery = LDH.ReplaceAll(finalQuery, "{" + keys[v1] + "}", parentQueryRowData1[keys[v1]]);
                                }

                                ajaxRequestObj.push(
                                    LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + finalQuery, function (data) {
                                        if (!LDH.IsObjectNull(data.value) && Array.isArray(data.value)) {
                                            data = data.value;
                                        }
                                        if (!LDH.IsObjectNull(data.message) && data.message.statusCode === "404") {
                                            data = [];
                                        }
                                        let dataFinal = {
                                            parentLevel1: parentQueryRowData1,
                                            parentLevel2: null,
                                            parentLevel3: null,
                                            finalData: data
                                        };

                                        if (LDH.IsObjectNull(dataFinal.finalData) || typeof dataFinal.finalData !== "object") {
                                            dataFinal.finalData = [];
                                        }
                                        ajaxTotalCount++;
                                        executedAjaxCountInProc++;

                                        if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                            dataFinal = LRH.ExecuteClientScript(dataFinal, postDataQuery, "data", true);
                                        }
                                        for (let b1 = 0; b1 < dataFinal.finalData.length; b1++) {
                                            callbackResult.push(dataFinal.finalData[b1]);
                                        }
                                        if (typeof dataFinal.finalData.message !== "undefined" && dataFinal.finalData.length > 0) {
                                            for (let c1 = 0; c1 < dataFinal.finalData.length; c1++) {
                                                callbackResult.push(dataFinal.finalData[c1]);
                                            }
                                        }
                                        if (executedAjaxCountInProc === totalAjaxCountInProc) {
                                            callbackSuccess(callbackResult, "customQuery", ajaxTotalCount);
                                        }
                                    }, function () {
                                        callbackFail(null, "customQuery");
                                    })
                                );
                            }
                        }
                    } else {
                        callbackSuccess(null, "customQuery", ajaxTotalCount);
                    }
                }, function () {
                    LRH.ShowToast("Failed to generate your report. " +
                        "Please check your network connection status and try again.", "error", 5000);
                })
            );
        } else if (LDH.IsValueEmpty(query) === false) {
            ajaxRequestObj.push(
                LeopardAjaxHelper.SimpleHttpGetRequest(apiUrl + "/" + query, function (dataFinal) {
                    if (!LDH.IsObjectNull(dataFinal.value) && Array.isArray(dataFinal.value)) {
                        dataFinal = dataFinal.value;
                    }
                    if (LDH.IsObjectNull(dataFinal) || typeof dataFinal !== "object") {
                        dataFinal = [];
                    }
                    ajaxTotalCount++;

                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                        dataFinal = LRH.ExecuteClientScript(dataFinal, postDataQuery, "data", true);
                    }
                    callbackSuccess(dataFinal, "customQuery", ajaxTotalCount);
                }, function () {
                    callbackFail(null, "customQuery");
                })
            );
        }
    };
}

export default LeopardQueryHelper;
