import {Component} from 'react';
import $ from 'jquery';
import 'url-search-params-polyfill';
import LRH from './LeopardReactHelper';
import {API, Amplify} from "aws-amplify";
import {Signer} from "aws-amplify-legacy-v4";
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LDH from './LeopardDataHelper';
import LeopardDataHelper from './LeopardDataHelper';
import LeopardAPIGatewayConfig from '../foundation/LeopardAPIGatewayConfig';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";
import SparkMD5 from "spark-md5";

class LeopardAjaxHelper extends Component {
    static IsResponseContainsError = (response, callbackError) => {
        if (LDH.IsObjectNull(response) || response === "null") {
            callbackError(response, false);
            return true;
        } else if (!LDH.IsObjectNull(response.message) &&
            !LDH.IsObjectNull(response.message.statusCode) &&
            response.message.statusCode === 400 &&
            !LDH.IsObjectNull(response.message.error) &&
            response.message.error.indexOf("Item not found") !== -1) {
            return false;
        } else if (!LDH.IsObjectNull(response.message) &&
            !LDH.IsObjectNull(response.message.statusCode) &&
            response.message.statusCode === 400) {
            callbackError(response, false);
            return true;
        } else if (!LDH.IsObjectNull(response.errorMessage) &&
            response.errorMessage !== "") {
            callbackError(response, false);
            return true;
        } else if (!LDH.IsObjectNull(response.message) &&
            response.message === "async sent to destination/s") {
            return false;
        } else if (!LDH.IsObjectNull(response.message) &&
            !LDH.IsObjectNull(response.message.error) &&
            response.message.error !== "") {
            callbackError(response, false);
            return true;
        } else if (LeopardAjaxHelper.HandleSessionTimeoutEvent(response)) {
            callbackError(response, true);
            return true;
        }
        return false;
    };

    static HandleSessionTimeoutEvent = (error) => {
        try {
            let errorMessage = "";
            if (typeof error !== 'object' && !LDH.IsValueEmpty(error)) {
                errorMessage = error.toString().toLowerCase();
            } else if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                !LDH.IsValueEmpty(error.message)) {
                errorMessage = error.message.toString().toLowerCase();
            }
            if (LDH.IsValueEmpty(errorMessage) === false && (
                errorMessage.indexOf("not authenticated") > -1 ||
                errorMessage.indexOf("security token") > -1 ||
                errorMessage.indexOf("request is expired") > -1 ||
                errorMessage === "null" ||
                errorMessage.indexOf("authentication Token") > -1 ||
                errorMessage.indexOf("is forbidden") > -1 ||
                errorMessage.indexOf("unauthenticated access") > -1)) {
                LeopardSecurity.UserLogout();
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    };

    static SimpleHttpGetRequest = (url, callbackSuccess, callbackError) => {
        return API["get"]("aws-api-gateway", url, {
            headers: LeopardAjaxHelper.GenericRequestHeaders()
        }).then(data => {
            return callbackSuccess(data);
        }).catch(error => {
            return callbackError(error);
        })
    };

    static GenericRequestHeaders = () => {
        return {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            "Expires": "Sat, 01 Jan 2000 00:00:00 GMT"
        };
    };

    // Ajax Function Version: 2.0
    static ApiGatewayInvoker = (
        method, url, appConfig, callbackSuccess, callbackError, customResponseData, customBodyData, includeAppConfig, handleJsonDataNullValues) => {
        let bodyData = {};

        if (LDH.IsObjectNull(includeAppConfig) || includeAppConfig === true) {
            if ((LDH.IsObjectNull(handleJsonDataNullValues) || handleJsonDataNullValues === true) &&
                (LDH.IsObjectNull(customResponseData) || LDH.IsObjectNull(customResponseData.dataViewType) ||
                    customResponseData.dataViewType !== "report")) {
                bodyData = {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)};
            } else {
                bodyData = {AppConfig: appConfig};
            }
        } else {
            if ((LDH.IsObjectNull(handleJsonDataNullValues) || handleJsonDataNullValues === true) &&
                (LDH.IsObjectNull(customResponseData) || LDH.IsObjectNull(customResponseData.dataViewType) ||
                    customResponseData.dataViewType !== "report")) {
                bodyData = LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false);
            } else {
                bodyData = {AppConfig: appConfig};
            }
        }

        let requestBody = {
            headers: LeopardAjaxHelper.GenericRequestHeaders(),
            body: LDH.IsObjectNull(customBodyData) ? bodyData : customBodyData
        };

        if (method.toString().toLowerCase() === "get") delete requestBody.body;
        API[method]("aws-api-gateway", url, requestBody).then(response => {
            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                return;
            }
            if (!LDH.IsObjectNull(callbackSuccess) && !LDH.IsObjectNull(customResponseData)) {
                callbackSuccess(customResponseData);
                return;
            }
            if (!LDH.IsObjectNull(callbackSuccess) && LDH.IsObjectNull(customResponseData)) {
                callbackSuccess(response);

            }
        }).catch(error => {
            LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
        });
    };

    // Ajax Function Version: 2.0
    static GenericHttpRequest = (method, url, requestBody, callbackSuccess, callbackError, includeAppConfig, handleJsonDataNullValues) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            LeopardAjaxHelper.ApiGatewayInvoker(method, url, requestBody, callbackSuccess,
                callbackError, null, null, includeAppConfig, handleJsonDataNullValues);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    static GenericXMLHttpRequest = (method, url, body, callbackSuccess, callbackError, requestType, headers) => {
        try {
            let request = new XMLHttpRequest();
            request.open(method, url);

            if (!LDH.IsObjectNull(headers) && headers.length > 0) {
                for (let i = 0; i < headers.length; i++) {
                    request.setRequestHeader(headers[i].name, headers[i].value);
                }
            }
            request.onreadystatechange = function () {
                if (this.readyState === 4) callbackSuccess(this.responseText);
            };
            request.send(body);
        } catch (ex) {
            callbackError(ex);
        }
    };

    // Ajax Function Version: 2.0
    static APIGatewayErrorHandler = (error, callbackError) => {
        if (!LDH.IsObjectNull(callbackError)) {
            let timeout = LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            callbackError(error, timeout);
        }
    }

    // Ajax Function Version: 2.0
    static ConfigSettingsVersionHandler = (callbackError) => {
        if (!LDH.IsObjectNull(callbackError)) {
            callbackError("version-out-of-date");
        }
    }

    // Ajax Function Version: 2.0
    static GetLeftMenuItems = (userId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Main_LeftMenuItems"
                }, {
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = {};
                    let version = null;

                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        for (let i = 0; i < responseData.length; i++) {
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                config = responseData[i].AppConfig;
                            }
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                !LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                version = responseData[i].AppConfig.currentVersion;
                            }
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(version)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    window.ccProfileCache = config;
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static CreateLeftMenuItemForDashboard = (
        userId, organizationId, menuItemId, menuItemName, dashboardId, callbackSuccess,
        callbackError, menuItemParentId, dataViewUseExistingIds) => {
        if (dataViewUseExistingIds) {
            callbackSuccess();
            return;
        }
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                        menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    menuItemsObj.push({
                        contentPageType: "dashboard-view",
                        menuItemName,
                        menuItemId,
                        dashboardId,
                        menuItemType: "dashboard",
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: "Workspaces",
                        isDirectory: false,
                        expanded: false,
                        expandByDefault: false,
                        menuItemSubCategory: "dashboard",
                        menuItemCategory: "workspace",
                        isHidden: false,
                        menuItemParentId
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, null, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateLeftMenuItemForDashboard = (
        userId, organizationId, menuItemName, dashboardId, callbackSuccess, callbackError,
        confirmationMessageText, autoManualMode, hideMenuItem) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                        menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }

                    let dashboardItem = null;
                    let menuItemsIndex = 0;
                    for (let i = 0; i < menuItemsObj.length; i++) {
                        if (menuItemsObj[i].menuItemType !== "dashboard") {
                            continue;
                        }
                        if (menuItemsObj[i].dashboardId === dashboardId) {
                            dashboardItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }

                    if (LDH.IsObjectNull(dashboardItem) && !LDH.IsObjectNull(callbackError)) {
                        callbackError({error: "Workspace item not found"});
                    }
                    if (LDH.IsObjectNull(dashboardItem)) return;

                    dashboardItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dashboardItem.menuItemName = menuItemName;
                    dashboardItem.confirmationMessageText = confirmationMessageText;
                    dashboardItem.autoManualMode = autoManualMode;
                    dashboardItem.hideMenuItem = hideMenuItem;
                    menuItemsObj[menuItemsIndex] = dashboardItem;
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, dashboardItem, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static CreateLeftMenuItemForDataView = (
        userId, organizationId, menuItemId, menuItemName, menuItemType, dataViewType,
        dataViewNote, dataTableName, dataViewId, dataViewPersistentId, callbackSuccess,
        callbackError, menuItemParentId, dataViewUseExistingIds) => {
        if (dataViewUseExistingIds) {
            callbackSuccess();
            return;
        }
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                        menuItemsObj.length === 0 || menuItemsObj.length === undefined) {
                        menuItemsObj = [];
                    }
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let parentMenuItemName = "Data Views";
                    let menuItemCategory = "dataview";
                    if (LDH.IsObjectNull(menuItemType) || LDH.IsValueEmpty(menuItemType)) {
                        menuItemType = "data-view"
                    } else if (menuItemType === "system-view") {
                        parentMenuItemName = "System Views";
                    } else if (menuItemType === "default-view") {
                        parentMenuItemName = "Default Views";
                    } else if (menuItemType === "report") {
                        parentMenuItemName = "Reports";
                        menuItemCategory = "report";
                    } else if (menuItemType === "analytics-dashboard") {
                        parentMenuItemName = "Analytics";
                        menuItemCategory = "analytics";
                    }

                    menuItemsObj.push({
                        contentPageType: "single-data-view",
                        menuItemName,
                        menuItemId,
                        dataViewId,
                        dataTableName,
                        dataViewType,
                        dataViewNote,
                        menuItemType,
                        dataViewPersistentId,
                        isDirectory: false,
                        expandByDefault: false,
                        expanded: false,
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName,
                        menuItemSubCategory: menuItemType,
                        menuItemCategory,
                        isHidden: false,
                        menuItemParentId
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, null, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateLeftMenuItemForDataView = (
        userId, organizationId, menuItemName, dataViewId, dataViewPersistentId,
        callbackSuccess, callbackError, dataViewMenuCategory, hideMenuItem) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                        menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }

                    let dataViewItem = null;
                    let menuItemsIndex = 0;
                    for (let i = 0; i < menuItemsObj.length; i++) {
                        if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                            menuItemsObj[i].menuItemType !== "data-view" &&
                            menuItemsObj[i].menuItemType !== "system-view" &&
                            menuItemsObj[i].menuItemType !== "default-view" &&
                            menuItemsObj[i].menuItemType !== "report" &&
                            menuItemsObj[i].menuItemType !== "analytics-dashboard") {
                            continue;
                        }

                        if (menuItemsObj[i].dataViewId === dataViewId) {
                            dataViewItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }
                    if (LDH.IsObjectNull(dataViewItem) && !LDH.IsObjectNull(callbackError)) {
                        callbackError({error: "Data view item not found"});
                    }
                    if (LDH.IsObjectNull(dataViewItem)) return;

                    if (!LDH.IsValueEmpty(dataViewItem.menuItemType) &&
                        (dataViewItem.menuItemType === "data-view" ||
                            dataViewItem.menuItemType === "system-view" ||
                            dataViewItem.menuItemType === "default-view")) {
                        dataViewItem.menuItemType = dataViewMenuCategory;
                    }

                    dataViewItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dataViewItem.menuItemName = menuItemName;
                    dataViewItem.hideMenuItem = hideMenuItem;
                    dataViewItem.dataViewPersistentId = dataViewPersistentId;
                    dataViewItem.visible = !hideMenuItem;
                    menuItemsObj[menuItemsIndex] = dataViewItem;
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, dataViewItem, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuStructure = (userId, organizationId, callbackSuccess, callbackError, menuItemsObj) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let appConfig = LDH.DeepClone(menuItemsObj);

                for (let i = 0; i < appConfig.length; i++) {
                    if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_defaultviews") {
                        appConfig[i].menuItemCategory = "dataview";
                        appConfig[i].parentMenuItemName = "Default Views";
                        appConfig[i].menuItemSubCategory = "default-view";
                        appConfig[i].menuItemType = "default-view";
                    } else if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_dataviews") {
                        appConfig[i].menuItemCategory = "dataview";
                        appConfig[i].parentMenuItemName = "Data Views";
                        appConfig[i].menuItemSubCategory = "data-view";
                        appConfig[i].menuItemType = "data-view";
                    } else if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_systemviews") {
                        appConfig[i].menuItemCategory = "dataview";
                        appConfig[i].parentMenuItemName = "System Views";
                        appConfig[i].menuItemSubCategory = "system-view";
                        appConfig[i].menuItemType = "system-view";
                    } else if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_workspaces") {
                        appConfig[i].menuItemCategory = "workspace";
                        appConfig[i].parentMenuItemName = "Workspaces";
                        appConfig[i].menuItemSubCategory = "dashboard";
                        appConfig[i].menuItemType = "dashboard";
                    } else if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_reports") {
                        appConfig[i].menuItemCategory = "report";
                        appConfig[i].parentMenuItemName = "Reports";
                        appConfig[i].menuItemSubCategory = "report";
                        appConfig[i].menuItemType = "report";
                    } else if (!LDH.IsObjectNull(appConfig[i].menuItemParentId) &&
                        appConfig[i].menuItemParentId === "directory_predefined_analytics") {
                        appConfig[i].menuItemCategory = "analytics";
                        appConfig[i].parentMenuItemName = "Analytics";
                        appConfig[i].menuItemSubCategory = "analytics-dashboard";
                        appConfig[i].menuItemType = "analytics-dashboard";
                    }
                }

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess, callbackError, null, null);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static DeleteLeftMenuItemForDataView = (
        userId, organizationId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                            menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (let i = 0; i < menuItemsObj.length; i++) {
                            if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                                menuItemsObj[i].menuItemType !== "data-view" &&
                                menuItemsObj[i].menuItemType !== "system-view" &&
                                menuItemsObj[i].menuItemType !== "default-view" &&
                                menuItemsObj[i].menuItemType !== "report" &&
                                menuItemsObj[i].menuItemType !== "analytics-dashboard") {
                                continue;
                            }
                            if (menuItemsObj[i].dataViewId === dataViewId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                        LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                            callbackError, null, null);
                    }, function (error) {
                        LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                    });
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static DeleteLeftMenuItemForDashboard = (
        userId, organizationId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (LDH.IsObjectNull(menuItemsObj) || menuItemsObj === "" ||
                        menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    for (let i = 0; i < menuItemsObj.length; i++) {
                        if (menuItemsObj[i].menuItemType !== "dashboard") {
                            continue;
                        }
                        if (menuItemsObj[i].dashboardId === dashboardId) {
                            menuItemsObj.splice(i, 1);
                            break;
                        }
                    }
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, null, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static InsertDataEntry = (url, appConfig, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                    callbackError, null, null);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static CreateDashboard = (
        organizationId, dashboardName, dashboardNote, dashboardId, dashboardLayout, relationships,
        callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);
                let appConfig = {
                    relationships, dashboardName, dashboardId, dashboardLayout, dashboardNote,
                    contentPageType: "dashboard-view",
                    dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion,
                    settingsVersion: 1
                };
                if (!LDH.IsObjectNull(appConfig["dashboardVersion"])) {
                    delete appConfig["dashboardVersion"];
                }
                LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                    callbackError, null, null);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateDashboard = (
        userId, organizationId, dashboardId, dashboardLayout, dashboardName,
        callbackSuccess, callbackError, isResetRelationship, hasDataViewDeleted,
        confirmationMessageText, autoManualMode, hideMenuItem) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                let dashboardLayoutFromDb = dashboard.dashboardLayout;
                let settingsVersionOnClient = parseInt($(".dashboard-settings-version")
                    .attr("settingsversion"));
                if (!LDH.IsObjectNull(dashboard.settingsVersion) &&
                    settingsVersionOnClient < dashboard.settingsVersion) {
                    LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                    return;
                }

                let xValue = -72;
                let yValue = 0;

                if (hasDataViewDeleted) {
                    dashboardLayoutFromDb = [];
                    for (let i = 0; i < dashboardLayout.length; i++) {
                        if (xValue + 72 > 144) {
                            xValue = 0;
                            yValue += 1;
                        } else {
                            xValue += 72;
                        }
                        dashboardLayoutFromDb.push({
                            i: i.toString(), x: xValue, y: yValue, w: 72, h: 36,
                            minW: 18, minH: 1,
                            definition: dashboardLayout[i].definition
                        });
                    }
                } else {
                    for (let i = 0; i < dashboardLayout.length; i++) {
                        if (xValue + 72 > 144) {
                            xValue = 0;
                            yValue += 1;
                        } else {
                            xValue += 72;
                        }
                        let foundId = false;
                        for (let j = 0; j < dashboardLayoutFromDb.length; j++) {
                            if (dashboardLayoutFromDb[j].i === dashboardLayout[i].i) {
                                dashboardLayoutFromDb[j].definition = dashboardLayout[i].definition;
                                foundId = true;
                                break;
                            }
                        }
                        if (foundId === false) {
                            dashboardLayoutFromDb.push({
                                i: dashboardLayout[i].i.toString(), x: xValue,
                                y: yValue, w: 72, h: 36,
                                minW: 18, minH: 1,
                                definition: dashboardLayout[i].definition
                            });
                        }
                    }
                }

                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                let relationships = isResetRelationship ? [] : dashboard.relationships;

                let appConfig = {
                    relationships, dashboardName, dashboardId: dashboard.dashboardId,
                    dashboardLayout: dashboardLayoutFromDb,
                    dashboardNote: dashboard.dashboardNote,
                    contentPageType: dashboard.contentPageType,
                    confirmationMessageText: confirmationMessageText,
                    autoManualMode: autoManualMode,
                    hideMenuItem: hideMenuItem,
                    dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion,
                    settingsVersion: !LDH.IsObjectNull(dashboard.settingsVersion) ?
                        dashboard.settingsVersion + 1 : 1,
                };
                if (!LDH.IsObjectNull(appConfig["dashboardVersion"])) {
                    delete appConfig["dashboardVersion"];
                }
                LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                    callbackError, null, null);
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static UpdateDashboardRelationship = (
        userId, organizationId, dashboardId, relationships, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (appConfig) {
                    let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                    if (!LDH.IsObjectNull(appConfig.settingsVersion) &&
                        settingsVersionOnClient < appConfig.settingsVersion) {
                        LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                        return;
                    }
                    Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + appConfig.dashboardId);
                    appConfig.relationships = relationships;
                    appConfig.settingsVersion = !LDH.IsObjectNull(appConfig.settingsVersion) ?
                        appConfig.settingsVersion + 1 : 1;
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                        callbackError, null, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateDashboardLayout = (userId, organizationId, dashboardId, dashboardLayout,
                                    callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                if (!LDH.IsObjectNull(dashboard.settingsVersion) &&
                    settingsVersionOnClient < dashboard.settingsVersion) {
                    LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                    return;
                }
                for (let i = 0; i < dashboard.dashboardLayout.length; i++) {
                    for (let j = 0; j < dashboardLayout.length; j++) {
                        if (dashboard.dashboardLayout[i].i === dashboardLayout[j].i) {
                            dashboardLayout[j].definition = dashboard.dashboardLayout[i].definition;
                            break;
                        }
                    }
                }
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                let appConfig = {
                    relationships: dashboard.relationships, dashboardName: dashboard.dashboardName,
                    dashboardId: dashboard.dashboardId,
                    dashboardLayout, dashboardNote: dashboard.dashboardNote,
                    contentPageType: dashboard.contentPageType,
                    dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion,
                    settingsVersion: !LDH.IsObjectNull(dashboard.settingsVersion) ?
                        dashboard.settingsVersion + 1 : 1
                };
                if (!LDH.IsObjectNull(appConfig["dashboardVersion"])) {
                    delete appConfig["dashboardVersion"];
                }
                LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                    callbackError, null, null);
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GetDashboardDefinitionInsideLayout = (userId, organizationId, dashboardId, dashboardLayout,
                                                 callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                for (let i = 0; i < dashboard.dashboardLayout.length; i++) {
                    for (let j = 0; j < dashboardLayout.length; j++) {
                        if (dashboard.dashboardLayout[i].i === dashboardLayout[j].i) {
                            dashboardLayout[j].definition = dashboard.dashboardLayout[i].definition;
                            break;
                        }
                    }
                }
                let appConfig = {
                    relationships: dashboard.relationships, dashboardName: dashboard.dashboardName,
                    dashboardId: dashboard.dashboardId,
                    dashboardLayout, dashboardNote: dashboard.dashboardNote,
                    contentPageType: dashboard.contentPageType,
                    dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion,
                    settingsVersion: !LDH.IsObjectNull(dashboard.settingsVersion) ?
                        dashboard.settingsVersion + 1 : 1
                };
                if (!LDH.IsObjectNull(appConfig["dashboardVersion"])) {
                    delete appConfig["dashboardVersion"];
                }
                if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(appConfig);
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static SetControlCentreVersion = (organizationId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
            url = url.replace("{configName}", "CC_Version");
            let appConfig = {currentVersion: LeopardStaticUIConfig.ControlCentreVersion};
            LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess,
                callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static CreateDataView = (
        organizationId, dataViewName, dataViewType, dataViewNote, dataSourceUrl,
        dataViewId, dataViewPersistentId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
            url = url.replace("{configName}", "CC_DataView_" + dataViewId);
            let appConfig = {
                dataSourceUrl, dataViewName, dataViewId, dataViewType,
                dataViewNote, contentPageType: "single-data-view", dataViewPersistentId,
                dataViewConfigVersion: LeopardStaticUIConfig.DataViewConfigVersion,
                settingsVersion: 1
            };
            LeopardAjaxHelper.ApiGatewayInvoker("put", url, appConfig, callbackSuccess(appConfig),
                callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static UpdateDataViewDefinition = (
        userId, organizationId, dataViewId, definition, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {

            LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                let version = parseInt($(".dataview-settings-version").attr("settingsversion"));
                if (!LDH.IsObjectNull(dataView.settingsVersion) && version < dataView.settingsVersion) {
                    LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                    return;
                }
                dataView.dataViewNote = definition;
                dataView.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;
                dataView.settingsVersion = !LDH.IsObjectNull(dataView.settingsVersion) ?
                    dataView.settingsVersion + 1 : 1;

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);
                LeopardAjaxHelper.ApiGatewayInvoker("put", url, dataView, callbackSuccess,
                    callbackError, dataView, null);
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static UpdateDataView = (
        userId, organizationId, dataViewName, dataViewId, definition, callbackSuccess, callbackError, tempAppConfigId, hideMenuItem, genericRandomGuid) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                let tabbedView = false;
                if (typeof definition.tabbedViewDefinition !== "undefined") {
                    tabbedView = true;
                }
                let version = parseInt($(".dataview-settings-version").attr("settingsversion"));
                if (!tabbedView && !LDH.IsObjectNull(dataView.settingsVersion) && version < dataView.settingsVersion) {
                    LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                    return;
                }

                dataView.dataViewName = dataViewName;
                dataView.hideMenuItem = hideMenuItem;
                dataView.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;
                dataView.settingsVersion = !LDH.IsObjectNull(dataView.settingsVersion) ?
                    dataView.settingsVersion + 1 : 1;

                if (dataView.dataViewType === "chart") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.chartDefinition.clientSideQueryEditor =
                        definition.chartDefinition.clientSideQueryEditor;
                    dataViewNote.chartDefinition.dataViewPersistentId =
                        definition.chartDefinition.dataViewPersistentId;
                    dataViewNote.chartDefinition.dataViewCustomComments =
                        definition.chartDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "timeline") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.timelineDefinition.timelineResourceDefinition =
                        definition.timelineDefinition.timelineResourceDefinition;
                    dataViewNote.timelineDefinition.timelineOdataQueryForGrouping =
                        definition.timelineDefinition.timelineOdataQueryForGrouping;
                    dataViewNote.timelineDefinition.timelinePostDataProcessingForGrouping =
                        definition.timelineDefinition.timelinePostDataProcessingForGrouping;
                    dataViewNote.timelineDefinition.timelineOdataQueryForResources =
                        definition.timelineDefinition.timelineOdataQueryForResources;
                    dataViewNote.timelineDefinition.timelinePostDataProcessingForResources =
                        definition.timelineDefinition.timelinePostDataProcessingForResources;
                    dataViewNote.timelineDefinition.timelineDataKeyForResources =
                        definition.timelineDefinition.timelineDataKeyForResources;
                    dataViewNote.timelineDefinition.dataViewPersistentId =
                        definition.timelineDefinition.dataViewPersistentId;
                    dataViewNote.timelineDefinition.dataViewCustomComments =
                        definition.timelineDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "tabbed-view") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.tabbedViewDefinition.dataViewPersistentId =
                        definition.tabbedViewDefinition.dataViewPersistentId;
                    dataViewNote.tabbedViewDefinition.dataViewCustomComments =
                        definition.tabbedViewDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "diagram") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.diagramDefinition.dataViewPersistentId =
                        definition.diagramDefinition.dataViewPersistentId;
                    dataViewNote.diagramDefinition.dataViewCustomComments =
                        definition.diagramDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "photo") {
                    let dataViewNote = definition;
                    dataViewNote.photoDefinition.dataViewPersistentId =
                        definition.photoDefinition.dataViewPersistentId;
                    dataViewNote.photoDefinition.dataViewCustomComments =
                        definition.photoDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "notepad") {
                    let dataViewNote = definition;
                    dataViewNote.notepadDefinition.dataViewPersistentId =
                        definition.notepadDefinition.dataViewPersistentId;
                    dataViewNote.notepadDefinition.dataViewCustomComments =
                        definition.notepadDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "form-editor") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.formDefinition.dataViewPersistentId =
                        definition.formDefinition.dataViewPersistentId;
                    dataViewNote.formDefinition.dataViewCustomComments =
                        definition.formDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "html-form-builder") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.formDefinition.dataViewPersistentId =
                        definition.formDefinition.dataViewPersistentId;
                    dataViewNote.formDefinition.dataViewCustomComments =
                        definition.formDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "map") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.mapDefinition.dataViewPersistentId =
                        definition.mapDefinition.dataViewPersistentId;
                    dataViewNote.mapDefinition.dataViewCustomComments =
                        definition.mapDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "document-editor") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.documentDefinition.documentEditorAPIGatewayGetUrl =
                        definition.documentDefinition.documentEditorAPIGatewayGetUrl;
                    dataViewNote.documentDefinition.documentEditorAPIGatewayPostUrl =
                        definition.documentDefinition.documentEditorAPIGatewayPostUrl;
                    dataViewNote.documentDefinition.documentEditorDataFormat =
                        definition.documentDefinition.documentEditorDataFormat;
                    dataViewNote.documentDefinition.documentEditorAllowStandaloneAccess =
                        definition.documentDefinition.documentEditorAllowStandaloneAccess;
                    dataViewNote.documentDefinition.documentEditorDataSchema =
                        definition.documentDefinition.documentEditorDataSchema;
                    dataViewNote.documentDefinition.documentEditorUISchema =
                        definition.documentDefinition.documentEditorUISchema;
                    dataViewNote.documentDefinition.documentEditorDirectory =
                        definition.documentDefinition.documentEditorDirectory;
                    dataViewNote.documentDefinition.documentEditorFileName =
                        definition.documentDefinition.documentEditorFileName;
                    dataViewNote.documentDefinition.documentEditorOwnerUserId =
                        definition.documentDefinition.documentEditorOwnerUserId;
                    dataViewNote.documentDefinition.documentEditorDataReceivingLogic =
                        definition.documentDefinition.documentEditorDataReceivingLogic;
                    dataViewNote.documentDefinition.documentEditorDataSendingLogic =
                        definition.documentDefinition.documentEditorDataSendingLogic;
                    dataViewNote.documentDefinition.dataViewPersistentId =
                        definition.documentDefinition.dataViewPersistentId;
                    dataViewNote.documentDefinition.dataViewCustomComments =
                        definition.documentDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "datagrid") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.gridViewDefinition.clientSideQuery = definition.gridViewDefinition.clientSideQuery;
                    dataViewNote.gridViewDefinition.clientSidePreProcessingScript = definition.gridViewDefinition.clientSidePreProcessingScript;
                    dataViewNote.gridViewDefinition.hideRefreshButton = definition.gridViewDefinition.hideRefreshButton;
                    dataViewNote.gridViewDefinition.enableTextHighlight = definition.gridViewDefinition.enableTextHighlight;
                    dataViewNote.gridViewDefinition.textHighlightColumns = definition.gridViewDefinition.textHighlightColumns;
                    dataViewNote.gridViewDefinition.textHighlightWords = definition.gridViewDefinition.textHighlightWords;
                    dataViewNote.gridViewDefinition.textHighlightWordsOnly = definition.gridViewDefinition.textHighlightWordsOnly;
                    dataViewNote.gridViewDefinition.clearDataWhenLoading = definition.gridViewDefinition.clearDataWhenLoading;
                    dataViewNote.gridViewDefinition.uploadText = definition.gridViewDefinition.uploadText;
                    dataViewNote.gridViewDefinition.createNewFormViaPopup = definition.gridViewDefinition.createNewFormViaPopup;
                    dataViewNote.gridViewDefinition.popupFileUploaderList = definition.gridViewDefinition.popupFileUploaderList;
                    dataViewNote.gridViewDefinition.sendDataOnDataSourceChangedPersistentId = definition.gridViewDefinition.sendDataOnDataSourceChangedPersistentId;
                    dataViewNote.gridViewDefinition.dataViewPersistentId = definition.gridViewDefinition.dataViewPersistentId;
                    dataViewNote.gridViewDefinition.dataViewCustomComments = definition.gridViewDefinition.dataViewCustomComments;
                } else if (dataView.dataViewType === "report") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.reportName = dataViewName;
                    dataViewNote.tableName = definition.tableName;
                    dataViewNote.oDataParametersForNormalReport = definition.oDataParametersForNormalReport;
                    dataViewNote.oDataParametersParent1ForNormalReport = definition.oDataParametersParent1ForNormalReport;
                    dataViewNote.oDataParametersParent2ForNormalReport = definition.oDataParametersParent2ForNormalReport;
                    dataViewNote.oDataParametersParent3ForNormalReport = definition.oDataParametersParent3ForNormalReport;
                    dataViewNote.oDataParametersForScheduledReport = definition.oDataParametersForScheduledReport;
                    dataViewNote.fileNamePrefixForScheduledReport = definition.fileNamePrefixForScheduledReport;
                    dataViewNote.oDataParametersParent1ForScheduledReport = definition.oDataParametersParent1ForScheduledReport;
                    dataViewNote.oDataParametersParent2ForScheduledReport = definition.oDataParametersParent2ForScheduledReport;
                    dataViewNote.oDataParametersParent3ForScheduledReport = definition.oDataParametersParent3ForScheduledReport;
                    dataViewNote.scheduledReportLambdaRequestConfigForParentQuery1 = definition.scheduledReportLambdaRequestConfigForParentQuery1;
                    dataViewNote.scheduledReportLambdaRequestConfigForParentQuery2 = definition.scheduledReportLambdaRequestConfigForParentQuery2;
                    dataViewNote.scheduledReportLambdaRequestConfigForParentQuery3 = definition.scheduledReportLambdaRequestConfigForParentQuery3;
                    dataViewNote.clientSideQueryForReport = definition.clientSideQueryForReport;
                    dataViewNote.clientSideUILogicForReport = definition.clientSideUILogicForReport;
                    dataViewNote.inputValuesInitLogic = definition.inputValuesInitLogic;
                    dataViewNote.renderingMethod = definition.renderingMethod;
                    dataViewNote.notificationEnabled = definition.notificationEnabled;
                    dataViewNote.scriptShapingForReport = definition.scriptShapingForReport;
                    dataViewNote.filterValidationsForReport = definition.filterValidationsForReport;
                    dataViewNote.reportDataViewVersion = genericRandomGuid;
                    dataViewNote.reportCustomDataSources = definition.reportCustomDataSources;
                    dataViewNote.reportCustomSchedules = definition.reportCustomSchedules;
                    dataViewNote.deletedScheduleIds = definition.deletedScheduleIds;
                    dataViewNote.reportScheduledCustomDataSources = definition.reportScheduledCustomDataSources;
                    dataViewNote.cronExpression = definition.cronExpression;
                    dataViewNote.recipients = definition.recipients;
                    dataViewNote.sender = LeopardStaticUIConfig.SourceEmailAddress;
                    dataViewNote.scheduledReportEnabled = definition.scheduledReportEnabled;
                    dataViewNote.scheduledReportFileFormat = definition.scheduledReportFileFormat;
                    dataViewNote.scheduledReportExportExcelBandFilter = definition.scheduledReportExportExcelBandFilter;
                    dataViewNote.scheduledReportLambdaRequestConfig = definition.scheduledReportLambdaRequestConfig;
                    dataViewNote.timezone = definition.timezone;
                    dataViewNote.timezoneName = definition.timezoneName;
                    dataViewNote.daylightSaving = definition.daylightSaving;
                    dataViewNote.operation = !definition.scheduledReportEnabled ? "DELETE" : "MODIFY";
                    dataViewNote.dataSourceVersion = definition.dataSourceVersion;
                    dataViewNote.dataViewCustomComments = definition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "aws-quicksight-dashboard") {
                    let dataViewNote = dataView.dataViewNote;
                    dataViewNote.dashboardName = dataViewName;
                    dataViewNote.quicksightDashboardId = definition.quicksightDashboardId;
                    dataViewNote.quicksightDashboardRegion = definition.quicksightDashboardRegion;
                    dataViewNote.dataViewPersistentId = definition.dataViewPersistentId;
                    dataViewNote.dataViewCustomComments = definition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                } else if (dataView.dataViewType === "regex-tester") {
                    let dataViewNote = definition;
                    dataViewNote.regexDefinition.dataViewPersistentId =
                        definition.regexDefinition.dataViewPersistentId;
                    dataViewNote.regexDefinition.dataViewCustomComments =
                        definition.regexDefinition.dataViewCustomComments;
                    dataView.dataViewNote = dataViewNote;
                }
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);
                window[tempAppConfigId] = dataView;

                LeopardAjaxHelper.ApiGatewayInvoker("put", url, dataView, callbackSuccess,
                    callbackError, dataView, null);
            }, function (error) {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
        });
    };

    // Ajax Function Version: 2.0
    static UpdateDataViewDefinitionByType = (
        userId, organizationId, dataViewId, definition, callbackSuccess, callbackError, dataViewType) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version")
                        .attr("settingsversion"));

                    if (dataViewType !== "tabbed-view" && !LDH.IsObjectNull(dataView.settingsVersion) &&
                        settingsVersionOnClient < dataView.settingsVersion) {
                        LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                        return;
                    }
                    let dataViewNoteObj = dataView.dataViewNote;
                    dataView.settingsVersion = !LDH.IsObjectNull(dataView.settingsVersion) ?
                        dataView.settingsVersion + 1 : 1;

                    if (dataViewType === "gridview") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.gridViewDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.gridViewDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.gridViewDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.gridViewDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.gridViewDefinition = definition;
                    } else if (dataViewType === "chart") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.chartDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.chartDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.chartDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.chartDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.chartDefinition = definition;
                    } else if (dataViewType === "timeline") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.timelineDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.timelineDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.timelineDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.timelineDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.timelineDefinition = definition;
                    } else if (dataViewType === "tabbed-view") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.tabbedViewDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.tabbedViewDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.tabbedViewDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.tabbedViewDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.tabbedViewDefinition = definition;
                    } else if (dataViewType === "diagram") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.diagramDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.diagramDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.diagramDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.diagramDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.diagramDefinition = definition;
                    } else if (dataViewType === "map") {
                        definition["dataViewPersistentId"] = dataViewNoteObj.mapDefinition.dataViewPersistentId;
                        if (!LDH.IsObjectNull(dataViewNoteObj.mapDefinition.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(dataViewNoteObj.mapDefinition.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = dataViewNoteObj.mapDefinition.dataViewCustomComments;
                        }
                        dataViewNoteObj.mapDefinition = definition;
                    } else if (dataViewType === "document-editor") {
                        let def = dataViewNoteObj;
                        if (!LDH.IsObjectNull(dataViewNoteObj.documentDefinition)) {
                            def = dataViewNoteObj.documentDefinition;
                        }
                        definition["dataViewPersistentId"] = def.dataViewPersistentId;
                        if (!LDH.IsObjectNull(def.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(def.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = def.dataViewCustomComments;
                        }
                        dataViewNoteObj["documentDefinition"] = definition;
                    } else if (dataViewType === "form-editor") {
                        let def = dataViewNoteObj;
                        if (!LDH.IsObjectNull(dataViewNoteObj.formDefinition)) {
                            def = dataViewNoteObj.formDefinition;
                        }
                        definition["dataViewPersistentId"] = def.dataViewPersistentId;
                        if (!LDH.IsObjectNull(def.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(def.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = def.dataViewCustomComments;
                        }
                        dataViewNoteObj["formDefinition"] = definition;
                    } else if (dataViewType === "html-form-builder") {
                        let def = dataViewNoteObj;
                        if (!LDH.IsObjectNull(dataViewNoteObj.formDefinition)) {
                            def = dataViewNoteObj.formDefinition;
                        }
                        definition["dataViewPersistentId"] = def.dataViewPersistentId;
                        if (!LDH.IsObjectNull(def.dataViewCustomComments) &&
                            !LDH.IsValueEmpty(def.dataViewCustomComments)) {
                            definition["dataViewCustomComments"] = def.dataViewCustomComments;
                        }
                        dataViewNoteObj["formDefinition"] = definition;
                    }
                    dataView.dataViewNote = dataViewNoteObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, dataView, callbackSuccess,
                        callbackError, dataView, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateGridViewStyle = (
        userId, organizationId, dataViewId, styleDefinition, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version")
                        .attr("settingsversion"));
                    if (!LDH.IsObjectNull(dataView.settingsVersion) &&
                        settingsVersionOnClient < dataView.settingsVersion) {
                        LeopardAjaxHelper.ConfigSettingsVersionHandler(callbackError);
                        return;
                    }
                    let dataViewNoteObj = dataView.dataViewNote;
                    dataViewNoteObj.styleDefinition = styleDefinition;
                    dataView.dataViewNote = dataViewNoteObj;
                    dataView.settingsVersion = !LDH.IsObjectNull(dataView.settingsVersion) ?
                        dataView.settingsVersion + 1 : 1;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);
                    LeopardAjaxHelper.ApiGatewayInvoker("put", url, dataView, callbackSuccess,
                        callbackError, dataView, null);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static InsertOrUpdateDocumentToS3 = (jsonDataToUpload, fileName, directory, callbackSuccess, callbackError, ownerType,
                                         documentListType, documentModifyType, documentCreateType, metadata) => {
        let contentType = "application/json";
        let jsonDataStr = JSON.stringify(jsonDataToUpload);

        let hash = SparkMD5.hash(jsonDataStr, false);
        let binHash = LDH.HexToBin(hash);
        let buff = new Buffer(binHash);
        let md5b64 = buff.toString('base64');

        let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
        templateList.id = LDH.GenerateGuid();
        templateList.type = "leopardsystems.document.list";
        if (!LDH.IsValueEmpty(documentListType)) {
            templateList.type = documentListType;
        }
        let requestListData = {name: fileName, type: directory};

        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            let foundDoc = false;
            let documentData = null;

            if (!LDH.IsObjectNull(response.body) &&
                !LDH.IsObjectNull(response.body.data) &&
                response.body.data.length > 0) {
                for (let i = 0; i < response.body.data.length; i++) {
                    if (response.body.data[i].name === requestListData.name &&
                        response.body.data[i].type === requestListData.type) {
                        documentData = response.body.data[i];
                        foundDoc = true;
                    }
                }
            }

            if (foundDoc) {
                let template = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
                template.id = LDH.GenerateGuid();
                template.type = "leopardsystems.document.modify";
                if (!LDH.IsValueEmpty(documentModifyType)) {
                    template.type = documentModifyType;
                }

                let requestData = {
                    name: fileName,
                    type: directory,
                    contentType: contentType,
                    md5b64: md5b64,
                    version: documentData.version,
                    documentid: documentData.documentid,
                };

                if (typeof metadata !== "undefined") {
                    requestData.metadata = metadata;
                }

                if (ownerType === "userid") {
                    requestData["owner"] = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile).toUpperCase();
                } else if (ownerType === "orgid") {
                    requestData["owner"] = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile).toUpperCase();
                }

                LeopardAjaxHelper.SendRequestByEventSync(function (data) {
                    let formData = new FormData();
                    let responseEvent = data.body;

                    Object.keys(responseEvent.data.fields).forEach(key => {
                        formData.append(key, responseEvent.data.fields[key]);
                    });
                    formData.append("x-amz-meta-content-md5", responseEvent.data.fields["Content-MD5"]);
                    formData.append("x-amz-meta-requestid", responseEvent.data["x-amz-meta-requestid"]);

                    formData.append("file", new Blob([jsonDataStr], {type: contentType}));
                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4) {
                            if (!LDH.IsObjectNull(callbackSuccess)) {
                                callbackSuccess(xhr.response);
                            }
                        }
                    }
                    xhr.open("POST", responseEvent.data.url, true);
                    xhr.send(formData);
                }, function (error) {
                    if (!LDH.IsObjectNull(callbackError)) {
                        callbackError(error);
                    }
                }, template, requestData);
            } else {
                let template = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
                template.id = LDH.GenerateGuid();
                template.type = "leopardsystems.document.create";

                if (!LDH.IsValueEmpty(documentCreateType)) {
                    template.type = documentCreateType;
                }

                let requestData = {
                    name: fileName,
                    type: directory,
                    contentType: contentType,
                    md5b64: md5b64,
                    version: "1",
                    documentid: LDH.GenerateGuid()
                };

                if (typeof metadata !== "undefined") {
                    requestData.metadata = metadata;
                }

                if (ownerType === "userid") {
                    requestData["owner"] = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile).toUpperCase();
                } else if (ownerType === "orgid") {
                    requestData["owner"] = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile).toUpperCase();
                }

                LeopardAjaxHelper.SendRequestByEventSync(function (data) {
                    let formData = new FormData();
                    let responseEvent = data.body;

                    Object.keys(responseEvent.data.fields).forEach(key => {
                        formData.append(key, responseEvent.data.fields[key]);
                    });
                    formData.append("x-amz-meta-content-md5", responseEvent.data.fields["Content-MD5"]);
                    formData.append("x-amz-meta-requestid", responseEvent.data["x-amz-meta-requestid"]);

                    formData.append("file", new Blob([jsonDataStr], {type: contentType}));
                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4) {
                            if (!LDH.IsObjectNull(callbackSuccess)) {
                                callbackSuccess(xhr.response);
                            }
                        }
                    }
                    xhr.open("POST", responseEvent.data.url, true);
                    xhr.send(formData);
                }, function (error) {
                    if (!LDH.IsObjectNull(callbackError)) {
                        callbackError(error);
                    }
                }, template, requestData);
            }
        }, function (error) {
            if (!LDH.IsObjectNull(callbackError)) {
                callbackError(error);
            }
        }, templateList, requestListData);
    };

    static ListDocumentToS3 = (ownerId, callbackSuccess, callbackError, documentListType) => {
        let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
        templateList.id = LDH.GenerateGuid();
        templateList.type = "leopardsystems.document.list";
        if (!LDH.IsValueEmpty(documentListType)) {
            templateList.type = documentListType;
        }
        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            if (!LDH.IsObjectNull(callbackSuccess)) {
                callbackSuccess(response);
            }
        }, function (error) {
            if (!LDH.IsObjectNull(callbackError)) {
                callbackError(error);
            }
        }, templateList, [{"owner": ownerId}]);
    };

    static RetrieveDocumentFromS3 = (userId, fileName, directory, callbackSuccess, callbackError, documentRetrieveType) => {
        let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
        templateList.id = LDH.GenerateGuid();
        templateList.type = "leopardsystems.document.retrieve";
        if (!LDH.IsValueEmpty(documentRetrieveType)) {
            templateList.type = documentRetrieveType;
        }
        let requestListData = {name: fileName, type: directory, owner: userId};

        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            let request = new XMLHttpRequest();
            let url = response.body.data[0].url;
            request.open("GET", url);
            request.send();

            request.onreadystatechange = function () {
                if (request.readyState == 4) {
                    if (!LDH.IsObjectNull(callbackSuccess)) {
                        try {
                            if (LDH.IsJsonString(request.responseText)) {
                                callbackSuccess(request.responseText);
                            } else {
                                callbackSuccess(JSON.parse(request.responseText));
                            }
                        } catch (ex) {
                            callbackSuccess(request.responseText);
                        }
                    }
                }
            }
        }, function (error) {
            if (!LDH.IsObjectNull(callbackError)) {
                callbackError(error);
            }
        }, templateList, requestListData);
    };

    static SendEventToReportService = (data, callbackSuccess, callbackError, serviceRequestType) => {
        let templateList = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
        templateList.id = LDH.GenerateGuid();
        templateList.type = serviceRequestType;
        let requestListData = data;

        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
            callbackSuccess(response);
        }, function (error) {
            if (!LDH.IsObjectNull(callbackError)) {
                callbackError(error);
            }
        }, templateList, requestListData);
    };

    // Ajax Function Version: 2.0
    static GetDataByEventSync = (userId, callbackSuccess, callbackError, queryData) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let postData = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                postData.source = postData.source.replace("{0}", LDH.GenerateGuid());
                postData.data = queryData;

                let genericHeaders = LeopardAjaxHelper.GenericRequestHeaders();
                let requestData = {headers: genericHeaders, body: postData};

                API.post("aws-api-gateway", window.EventSyncUrlPath, requestData).then(response => {
                    let configList = [];
                    try {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError) ||
                            LDH.IsObjectNull(callbackSuccess)) {
                            return;
                        }
                        if (!LDH.IsObjectNull(response) && !LDH.IsObjectNull(response.message) &&
                            !LDH.IsObjectNull(response.message.data) && response.message.data.length > 0) {
                            let messageData = response.message.data;

                            for (let i = 0; i < messageData.length; i++) {
                                if (!LDH.IsObjectNull(messageData[i].AppConfig)) {
                                    let appConfig = messageData[i].AppConfig;
                                    appConfig = LDH.UnconvertJsonDataNullOrEmptyValues(appConfig, false);
                                    messageData[i].AppConfig = appConfig;
                                }
                                configList.push(messageData[i]);
                            }
                        }
                    } catch (ex) {
                        configList = [];
                    }
                    callbackSuccess(configList);
                }).catch(error => {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static SendRequestByEventSync = (callbackSuccess, callbackError, postTemplate, postData, urlOverride) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                if (postData !== "not-required") {
                    postTemplate.data = postData;
                }
                let genericHeaders = LeopardAjaxHelper.GenericRequestHeaders();
                let requestData = {headers: genericHeaders, body: postTemplate};

                let url = window.EventSyncUrlPath;
                if (!LDH.IsObjectNull(urlOverride) && !LDH.IsValueEmpty(urlOverride)) {
                    url = urlOverride;
                }
                API.post("aws-api-gateway", url, requestData).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError) ||
                        LDH.IsObjectNull(callbackSuccess)) {
                        return;
                    }
                    callbackSuccess(response.message);
                }).catch(error => {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static SendAPIPostRequest = (callbackSuccess, callbackError, postData, url) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let genericHeaders = LeopardAjaxHelper.GenericRequestHeaders();
                let requestData = {headers: genericHeaders, body: postData};

                API.post("aws-api-gateway", url, requestData).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError) ||
                        LDH.IsObjectNull(callbackSuccess)) {
                        return;
                    }
                    callbackSuccess(response.message);
                }).catch(error => {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetUserProfile = (userId, callbackSuccess, callbackError, userProfileCache, queryType, queryData) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = null;

                if (LDH.IsObjectNull(queryType) || LDH.IsValueEmpty(queryType) || queryType === "full") {
                    url = window.UserProfileUrlPath.replace("{userId}", userId);

                    if (LDH.IsObjectNull(userProfileCache) === false) {
                        let versionData = [];
                        for (let i = 0; i < userProfileCache.ParentGroup.Configs.length; i++) {
                            if (userProfileCache.ParentGroup.Configs[i].Name === "CC_Version") {
                                versionData = userProfileCache.ParentGroup.Configs[i].AppConfig;
                                break;
                            }
                        }
                        if (!LDH.IsControlCentreLatestVersion(versionData)) {
                            $(".leopard-application-loading-cover").empty().show();
                            $(".cc-update-notice-text").show();
                        }
                        callbackSuccess(userProfileCache);
                        return;
                    }

                    API.get("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders()
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (!LDH.IsObjectNull(callbackSuccess)) {
                            let msg = LDH.UnconvertJsonDataNullOrEmptyValues(response.message, false);
                            let versionData = [];
                            for (let i = 0; i < msg.ParentGroup.Configs.length; i++) {
                                if (msg.ParentGroup.Configs[i].Name === "CC_Version") {
                                    versionData = msg.ParentGroup.Configs[i].AppConfig;
                                    break;
                                }
                            }
                            if (!LDH.IsControlCentreLatestVersion(versionData)) {
                                $(".leopard-application-loading-cover").empty().show();
                                $(".cc-update-notice-text").show();
                            }
                            callbackSuccess(msg);
                        }
                    }).catch(error => {
                        LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                    });
                } else if (queryType === "data" && !LDH.IsObjectNull(queryData) && !LDH.IsValueEmpty(queryData)) {
                    url = window.EventSyncUrlPath;
                    let postData = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                    postData.source = postData.source.replace("{0}", LDH.GenerateGuid());
                    postData.data = queryData;

                    API.post("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: postData,
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (!LDH.IsObjectNull(callbackSuccess)) {
                            let msg = LDH.UnconvertJsonDataNullOrEmptyValues(response.message, false);
                            callbackSuccess(msg);
                        }
                    }).catch(error => {
                        LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                    });
                } else if (queryType === "config" && (LDH.IsObjectNull(queryData) || LDH.IsValueEmpty(queryData))) {
                    url = window.UserProfileUrlPath.replace("{userId}", userId) + "?summary=config";

                    API.get("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders()
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (!LDH.IsObjectNull(callbackSuccess)) {
                            let msg = LDH.UnconvertJsonDataNullOrEmptyValues(response.message, false);
                            callbackSuccess(msg);
                        }
                    }).catch(error => {
                        LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                    });
                }
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GetAttachmentsByItemId = (itemId, allowErrorMessage, callbackSuccess, callbackError,
                                     urlOverride, postDataProcessingLogic, allowUploadPhotos) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.AttachmentUrlPath + "/" + itemId;
            if (!LDH.IsObjectNull(urlOverride) && !LDH.IsValueEmpty(urlOverride)) {
                url = urlOverride;
            }
            API.get('aws-api-gateway', url, {
                headers: LeopardAjaxHelper.GenericRequestHeaders()
            }).then(response => {
                if (!LDH.IsObjectNull(response) && !LDH.IsObjectNull(response.message) &&
                    !LDH.IsObjectNull(response.message.statusCode) &&
                    response.message.statusCode === "404") {
                    response = {message: []};

                    if (LDH.IsObjectNull(allowUploadPhotos)) allowUploadPhotos = false;
                    if (allowErrorMessage === true && !allowUploadPhotos) {
                        LRH.ShowToast("No attachment found on the server. " +
                            "The Reporting database might be out-of-sync with the Attachment service.", "error", 8000);
                    }
                }
                if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) return;
                if (!LDH.IsObjectNull(postDataProcessingLogic) && !LDH.IsValueEmpty(postDataProcessingLogic)) {
                    let javascript = postDataProcessingLogic;
                    let dataName = "data";
                    let dataValue = response.message;
                    response.message = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, "", null);
                }

                response.message.sort(function (a, b) {
                    let orderA = LDH.IsValueEmpty(a.Order) ? "0" : a.Order.toString().toUpperCase();
                    let orderB = LDH.IsValueEmpty(b.Order) ? "0" : b.Order.toString().toUpperCase();
                    if (orderA < orderB) return -1;
                    if (orderA > orderB) return 1;
                    return 0;
                });
                if (!LDH.IsObjectNull(callbackSuccess)) {
                    callbackSuccess(response);
                }
            }).catch(error => {
                LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
            });
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GetDataSourceList = (menuItemType, callbackSuccess, callbackError) => {
        let url = LDH.GetODataAPIGatewayUrl("/$metadata&authentication=true");
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [{
                        id: "custom-query",
                        name: "Custom Query",
                        group: "Other",
                        category: "data-table"
                    }];
                    for (let i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models" &&
                            elemList.attributes.Namespace !== "Leopard.Data.Models") {
                            continue;
                        }
                        for (let j = 0; j < elemList.elements.length; j++) {
                            if (LDH.IsObjectNull(elemList.elements[j].attributes) ||
                                LDH.IsObjectNull(elemList.elements[j].attributes.Name) ||
                                LDH.IsValueEmpty(elemList.elements[j].attributes.Name)) {
                                continue;
                            }
                            response.push({
                                id: elemList.elements[j].attributes.Name,
                                name: elemList.elements[j].attributes.Name,
                                group: "Reporting Services",
                                category: "data-table"
                            });
                        }
                    }
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (!LDH.IsObjectNull(callbackError)) {
                            callbackError(response);
                        }
                        return;
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) {
                        callbackSuccess(response);
                    }
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GetDataViewById = (userId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_DataView_" + dataViewId
                }, {
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = {};
                    let version = null;

                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        for (let i = 0; i < responseData.length; i++) {
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                config = responseData[i].AppConfig;
                            }
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                !LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                version = responseData[i].AppConfig.currentVersion;
                            }
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(version)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GetDataViewLayoutById = (userId, dataViewPersistentId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_DataView_Layout_" + userId + "_" + dataViewPersistentId
                }, {
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = {};
                    let version = null;

                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        for (let i = 0; i < responseData.length; i++) {
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                config = responseData[i].AppConfig;
                            }
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                !LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                version = responseData[i].AppConfig.currentVersion;
                            }
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(version)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static UpdateDataViewLayoutById = (
        userId, organizationId, dataViewPersistentId, layoutConfigJSON, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
            url = url.replace("{configName}", "CC_DataView_Layout_" + userId + "_" + dataViewPersistentId);
            LeopardAjaxHelper.ApiGatewayInvoker("put", url, layoutConfigJSON, callbackSuccess,
                callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GetDataViewByIds = (userId, dataViewIds, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                for (let i = 0; i < dataViewIds.length; i++) {
                    queryData.push({
                        configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                        configName: "CC_DataView_" + dataViewIds[i]
                    });
                }
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = [];
                    let version = null;

                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        for (let i = 0; i < responseData.length; i++) {
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                config.push(responseData[i].AppConfig);
                            }
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                !LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                version = responseData[i].AppConfig.currentVersion;
                            }
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(version)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GetControlCentreVersion = (userId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = {};
                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        config = responseData[0].AppConfig;
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GetDashboardById = (userId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                let queryData = [{
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Dashboard_" + dashboardId
                }, {
                    configId: LeopardAPIGatewayConfig.ProfileAPI_ConfigId,
                    configName: "CC_Version"
                }];
                LeopardAjaxHelper.GetDataByEventSync(userId, function (responseData) {
                    let config = {};
                    let version = null;

                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        for (let i = 0; i < responseData.length; i++) {
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                config = responseData[i].AppConfig;
                            }
                            if (!LDH.IsObjectNull(responseData[i].AppConfig) &&
                                !LDH.IsObjectNull(responseData[i].AppConfig.currentVersion)) {
                                version = responseData[i].AppConfig.currentVersion;
                            }
                        }
                    }
                    if (!LDH.IsControlCentreLatestVersion(version)) {
                        $(".leopard-application-loading-cover").empty().show();
                        $(".cc-update-notice-text").show();
                    }
                    if (!LDH.IsObjectNull(callbackSuccess)) callbackSuccess(config);
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                }, queryData);
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    // Ajax Function Version: 2.0
    static GridViewCRUD_InsertData = (url, jsonData, callbackSuccess, callbackError, hasJsonProperty) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let dataKeys = Object.keys(jsonData);
            for (let i = 0; i < dataKeys.length; i++) {
                let index = dataKeys[i].indexOf("#");
                if (index > -1) {
                    let newKeyName = dataKeys[i].substring(index + 3, dataKeys[i].length);
                    jsonData[newKeyName] = jsonData[dataKeys[i]];
                    delete jsonData[dataKeys[i]];
                }
            }
            let data = [jsonData];
            if (LDH.IsObjectNull(hasJsonProperty) || hasJsonProperty === true) {
                data = {"userData": [jsonData]};
            }
            LeopardAjaxHelper.ApiGatewayInvoker("post", url, null, callbackSuccess,
                callbackError, null, data);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GridViewCRUD_InsertDataWithoutUserData = (url, jsonData, callbackSuccess, callbackError, hasJsonProperty) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let dataKeys = Object.keys(jsonData);
            for (let i = 0; i < dataKeys.length; i++) {
                let index = dataKeys[i].indexOf("#");
                if (index > -1) {
                    let newKeyName = dataKeys[i].substring(index + 3, dataKeys[i].length);
                    jsonData[newKeyName] = jsonData[dataKeys[i]];
                    delete jsonData[dataKeys[i]];
                }
            }
            let data = [jsonData];
            if (LDH.IsObjectNull(hasJsonProperty) || hasJsonProperty === true) {
                data = [jsonData];
            }
            LeopardAjaxHelper.ApiGatewayInvoker("post", url, null, callbackSuccess,
                callbackError, null, data);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GridViewCRUD_UpdateData = (url, jsonData, keyId, callbackSuccess, callbackError, isCustomUrl) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let dataKeys = Object.keys(jsonData);
            for (let i = 0; i < dataKeys.length; i++) {
                let index = dataKeys[i].indexOf("#");
                if (index > -1) {
                    let newKeyName = dataKeys[i].substring(index + 3, dataKeys[i].length);
                    jsonData[newKeyName] = jsonData[dataKeys[i]];
                    delete jsonData[dataKeys[i]];
                }
            }
            let urlToAccess = url + "/" + keyId;
            if (!LDH.IsObjectNull(isCustomUrl) && isCustomUrl === true) {
                urlToAccess = url;
            }
            LeopardAjaxHelper.ApiGatewayInvoker("put", urlToAccess, null,
                callbackSuccess, callbackError, null, jsonData);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GridViewCRUD_DeleteData = (url, keyId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            LeopardAjaxHelper.ApiGatewayInvoker("del", url + "/" + keyId, null,
                callbackSuccess, callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GridViewCRUD_ResetPassword = (jsonData, keyId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.APIGatewayUsersUrl + "/" + keyId + "/password";
            LeopardAjaxHelper.ApiGatewayInvoker("put", url, null, callbackSuccess,
                callbackError, null, jsonData);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static DeleteDataView = (organizationId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
            url = url.replace("{configName}", "CC_DataView_" + dataViewId);
            LeopardAjaxHelper.ApiGatewayInvoker("del", url, null, callbackSuccess,
                callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static DeleteDashboard = (organizationId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
            Amplify.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
            let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
            url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);
            LeopardAjaxHelper.ApiGatewayInvoker("del", url, null, callbackSuccess,
                callbackError, null, null);
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    // Ajax Function Version: 2.0
    static GetDataTableColumnsByDataSourceUrl = (
        tableName, limitDataColumns, callbackSuccess, callbackError, dataSourceCustomQueryTableSchema,
        customComputedColumns, overrideMetaDataUrl) => {
        let url = LDH.GetODataAPIGatewayUrl("/$metadata&authentication=true");
        if (!LDH.IsObjectNull(overrideMetaDataUrl) && !LDH.IsValueEmpty(overrideMetaDataUrl)) {
            url = overrideMetaDataUrl + "&authentication=true";
        }
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [];
                    for (let i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models" &&
                            elemList.attributes.Namespace !== "Leopard.Data.Models") {
                            continue;
                        }
                        let tableNameArray = [tableName];
                        if (LDH.IsValueEmpty(dataSourceCustomQueryTableSchema) === false) {
                            let schemas = dataSourceCustomQueryTableSchema.split("|");
                            tableNameArray = [];
                            for (let s = 0; s < schemas.length; s++) {
                                let schemaTableName = schemas[s].trim();
                                let schemaPrefix = "";
                                if (schemaTableName.indexOf("{") > -1 &&
                                    schemaTableName.indexOf("}") > -1) {
                                    let start = schemaTableName.indexOf("{");
                                    let end = schemaTableName.indexOf("}") + 1;
                                    schemaPrefix = schemaTableName.substring(start, end);
                                    schemaPrefix = schemaPrefix.replace("{", "");
                                    schemaPrefix = schemaPrefix.replace("}", "");

                                    let length = schemaTableName.length;
                                    schemaTableName = schemaTableName.substring(end, length);
                                }
                                tableNameArray.push({table: schemaTableName, prefix: schemaPrefix});
                            }
                        }

                        for (let k = 0; k < tableNameArray.length; k++) {
                            let tableNameInSchema = tableName;
                            let prefixName = "";
                            if (LDH.IsObjectNull(tableNameArray[k].table) === false &&
                                LDH.IsValueEmpty(tableNameArray[k].table) === false) {
                                tableNameInSchema = tableNameArray[k].table.trim();
                                prefixName = tableNameArray[k].prefix.trim();
                            }

                            for (let j = 0; j < elemList.elements.length; j++) {
                                if (elemList.elements[j].attributes.Name !== tableNameInSchema) {
                                    continue;
                                }
                                for (let k = 0; k < elemList.elements[j].elements.length; k++) {
                                    let propertyElem = elemList.elements[j].elements[k];
                                    let attrName = "";
                                    if (propertyElem.name === "Key") {
                                        attrName = propertyElem.elements[0].attributes.Name;
                                    }
                                    if (propertyElem.name === "Property") {
                                        attrName = propertyElem.attributes.Name;
                                    }
                                    if (LDH.IsValueEmpty(attrName)) {
                                        continue;
                                    }
                                    let foundElem = false;
                                    for (let n = 0; n < response.length; n++) {
                                        let attrLower = attrName.toLowerCase();
                                        if (response[n].toLowerCase() === attrLower) {
                                            foundElem = true;
                                            break;
                                        }
                                    }
                                    if (foundElem === false) {
                                        attrName = prefixName === "" ? attrName : prefixName + "#" + attrName;
                                        response.push(attrName);
                                    }
                                }
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                        for (let k = 0; k < customComputedColumns.length; k++) {
                            response.push(customComputedColumns[k].name);
                        }
                    }

                    let newData = [];
                    if (!LDH.IsObjectNull(limitDataColumns) && limitDataColumns.length > 0) {
                        for (let c = 0; c < response.length; c++) {
                            let found = false;
                            for (let v = 0; v < limitDataColumns.length; v++) {
                                if (limitDataColumns[v] === response[c]) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found === false) {
                                newData.push(response[c]);
                            }
                        }
                    } else {
                        newData = response;
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError({fullColumns: response, limitedColumns: newData}, false);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess({fullColumns: response, limitedColumns: newData});
                    }
                }, function (error) {
                    LeopardAjaxHelper.APIGatewayErrorHandler(error, callbackError);
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static AddSecurityHeadersToAjaxRequestForOdata() {
        XMLHttpRequest.prototype.open = (function (open) {
            return function (method, url, async) {
                try {
                    if (!LDH.IsObjectNull(method) && !LDH.IsObjectNull(url) &&
                        url.toString().toLowerCase().indexOf("authentication=true") > -1) {
                        let resultUrl = LDH.FilterMacro(url);
                        let resultUrlSplit = resultUrl.split("?");

                        if (resultUrlSplit.length > 1) {
                            let urlParams = resultUrlSplit[1];
                            let params = new URLSearchParams("?" + urlParams);
                            let newUrlParams = "";
                            let paramCount = 0;
                            for (let key of params.keys()) {
                                let dkey = key;
                                let dvalue = encodeURIComponent(params.get(key));

                                let symbol = "";
                                if (paramCount > 0 && dkey.toString().indexOf("$") === 0) {
                                    symbol = "&";
                                }
                                dkey = encodeURIComponent(dkey);
                                newUrlParams += symbol + dkey + "=" + dvalue;
                                paramCount++;
                            }
                            let finalUrl = resultUrlSplit[0] + "?" + newUrlParams;
                            let argumentUrl = finalUrl.replace("?authentication=true", "");
                            argumentUrl = argumentUrl.replace("&authentication=true", "");
                            argumentUrl = argumentUrl.replace("authentication=true", "");
                            arguments[1] = argumentUrl;
                            window.httpRequestCache = {method, url: finalUrl};
                        } else {
                            let finalUrl2 = resultUrlSplit[0];
                            let argumentUrl2 = finalUrl2.replace("?authentication=true", "");
                            argumentUrl2 = argumentUrl2.replace("&authentication=true", "");
                            argumentUrl2 = argumentUrl2.replace("authentication=true", "");
                            arguments[1] = argumentUrl2;
                            window.httpRequestCache = {method, url: finalUrl2};
                        }
                    }
                    if (!LDH.IsObjectNull(url) && url.indexOf("http://") > -1) {
                        LDH.ReplaceAll(url, "http://", "https://");
                    }
                    open.apply(this, arguments);
                } catch (ex) {

                }
            };
        })(XMLHttpRequest.prototype.open);

        let send = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function (data) {
            try {
                if (!LDH.IsObjectNull(window.userCredential) && !LDH.IsObjectNull(window.httpRequestCache) &&
                    window.httpRequestCache.url.toLowerCase().indexOf("authentication=true") &&
                    window.httpRequestCache.url.indexOf("virtualearth.net") === -1) {
                    let argumentUrl = window.httpRequestCache.url.replace("?authentication=true", "");
                    argumentUrl = argumentUrl.replace("&authentication=true", "");
                    argumentUrl = argumentUrl.replace("authentication=true", "");
                    let that = this;

                    let cred = {
                        accessKeyId: window.userCredential.accessKeyId,
                        region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
                        secretAccessKey: window.userCredential.secretAccessKey,
                        token: window.userCredential.sessionToken,
                        url: argumentUrl,
                        method: window.httpRequestCache.method.toUpperCase()
                    };
                    window.httpRequestCache = null;

                    let signedByAws = Signer.sign({
                        method: cred.method,
                        url: cred.url,
                        data: {},
                        headers: LeopardAjaxHelper.GenericRequestHeaders()
                    }, {
                        access_key: cred.accessKeyId,
                        secret_key: cred.secretAccessKey,
                        session_token: cred.token
                    }, {
                        service: "execute-api",
                        region: cred.region
                    });

                    Object.keys(signedByAws.headers).forEach(key => {
                        if (key.toLowerCase() === "host") return;
                        that.setRequestHeader(key, signedByAws.headers[key]);
                    });
                }
                send.call(this, data);
            } catch (ex) {

            }
        };
    }
}

export default LeopardAjaxHelper;
