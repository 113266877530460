import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import momenttz from 'moment-timezone';
import DataSource from 'devextreme/data/data_source';
import {RequiredRule, Validator} from 'devextreme-react/validator';
import LeopardMenuDataViewList from '../components/LeopardMenuDataViewList';
import LeopardMenuTemplateList from '../components/LeopardMenuTemplateList';
import {KeepChartDataById, KeepGridViewDataById, SetLeftMenuItem} from '../foundation/LeopardActionCreators';
import List from 'devextreme-react/list';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {Button, FileUploader, NumberBox, SelectBox, Switch, TagBox, TextArea, TextBox} from 'devextreme-react';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import LeopardMenuConfigItemBox from '../components/LeopardMenuConfigItemBox';
import LPH from "../helpers/LeopardPermissionHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";
import TreeView from 'devextreme-react/tree-view';
import Sortable from 'devextreme-react/sortable';

class LeopardMasterLeftMenu extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);
        this.state = this.initializeDefaultState(false, false, [], [], [],
            true, false, false, false, false, []);

        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.analyticsDashboardMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.uiObjectInstance = [];
        this.isDataSourceLoaded = false;
        this.numberOfSeriesForChartText = "";
        this.customComputedColumnsText = "";
        this.scheduledReportDisabled = true;
        this.isConfigItemBoxValueOnChangeDisabled = false;
        this.batchProcessCompletedForWorkspaces = true;
        this.batchProcessCompletedForDataViews = true;
        this.timezoneDropdownList = [];
        this.guessedLocalTimezone = "";
        this.userProfileLocalCache = null;
        this.reportCustomDataSources = [];
        this.reportScheduledCustomDataSources = [];
        this.reportCustomSchedules = [];
        this.deletedScheduleIds = [];
        this.leftMenuScrollPosition = null;
        this.hideCurrentFolder = false;
        this.expandCurrentFolderByDefault = false;
        this.newSubFolderName = "";
        this.currentFolderName = "";
        this.currentFolderObject = null;
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidUpdate = () => {
        let that = this;
        let userProfile = window.userProfile;

        if (userProfile !== undefined && userProfile !== null &&
            this.state.isUserProfileLoaded === false) {
            this.setState({isUserProfileLoaded: true}, function () {
                that.loadLeftMenuItems(true, null, false);
            });
        }
        this.setLeftMenuItemToBeSelected();
    };

    componentDidMount = () => {
        this.timezoneDropdownList = LeopardDropdownHelper.DropdownSelectionTimeZone;
        this.guessedLocalTimezone = momenttz.tz.guess();
        if (LDH.IsValueEmpty(this.guessedLocalTimezone)) {
            this.guessedLocalTimezone = "Australia/Sydney";
        }
    }

    componentWillUnmount = () => {
        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.analyticsDashboardMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.userProfileLocalCache = null;
        this.resetOptionPanelStates(false, false, [], [], [], true,
            false, false, false, false, false, [], []);
    };

    initializeDefaultState = (
        leftMenuItemsInitialized, isUserProfileLoaded,
        availableDataViewListForCreate, availableDataViewListForEdit,
        availableReportListForEdit, categoryWorkspacesVisibility,
        categorySystemViewsVisibility, categoryDataViewsVisibility,
        categoryDefaultViewsVisibility, categoryReportsVisibility,
        categoryAnalyticsDashboardsVisibility, availableAnalyticsListForEdit,
        dxMenuItemData) => {
        return {
            showMenuOptionPanel: null,
            showChartTypeOption: false,
            showLoadingProgress: false,
            switchDisabledForNotification: true,
            availableColumnNamesFromDataSourceWithoutBlank: [],
            validationInput: "",
            createDataViewType: "datagrid",
            createDataViewDataSourceUrl: 0,
            createDataViewDataSourceVersion: "v1",
            createDataViewSelectMapProvider: "",
            dataSourceCustomQueryInput: "",
            dataSourceOverrideMetaInput: "",
            timelineResourceDefinitionInput: "",
            timelineOdataQueryForGroupingInput: "",
            timelinePostDataProcessingForGroupingInput: "",
            timelineOdataQueryForResourcesInput: "",
            timelinePostDataProcessingForResourcesInput: "",
            timelineDataKeyForResourcesInput: "",
            dataSourceCustomQueryTableSchemaInput: "",
            dataViewNamePrefixForImport: "Imported_",
            dataViewUseExistingIds: false,
            createDataViewDataSourceId: 0,
            createDataViewName: "",
            createDataViewChartType: "bar-chart",
            createDataViewChartValueField: null,
            createDataViewChartArgumentField: null,
            createDashboardName: "",
            availableDataSourceList: [],
            selectedDataSourceObject: null,
            createDataViewDataSourceURI: null,
            selectDateRangeForCreateChart: null,
            httpMethodForCreateChart: "get",
            showHttpDataPostForChart: false,
            httpDataPostForCreateChart: null,
            createDataViewChartDateRangeField: null,
            customQueryAttributesForChart: null,
            heightOfThumbnailForPhotoGallery: 140,
            widthOfThumbnailForPhotoGallery: 211,
            photoGalleryDefaultTab: "photo",
            photoGalleryDisabledTabs: null,
            photoGalleryReportPayloadSourceId: "",
            photoGalleryReportDataSourceId: "",
            notepadButtonText: "Send",
            notepadDataSubmissionLogic: "",
            notepadAPIGatewayUrl: "",
            notepadOdataQueryString: "",
            notepadRequestLogic: "",
            documentEditorAPIGatewayGetUrl: null,
            documentEditorAPIGatewayPostUrl: null,
            documentEditorDataFormat: "json",
            documentEditorAllowStandaloneAccess: true,
            documentEditorDataSchema: JSON.stringify(LeopardStaticUIConfig.SampleData_DocumentEditorDataSchema),
            documentEditorUISchema: JSON.stringify(LeopardStaticUIConfig.SampleData_DocumentEditorUISchema),
            documentEditorDataReceivingLogic: "",
            documentEditorDirectory: "cc_dataview_config",
            documentEditorFileName: "",
            documentEditorOwnerUserId: "",
            documentEditorDataSendingLogic: null,
            parentDataSourceIdForPhotoGallery: "",
            topLevelFilteringField: "",
            oDataParametersForNormalReport: "",
            oDataParametersParent1ForNormalReport: "",
            oDataParametersParent2ForNormalReport: "",
            oDataParametersParent3ForNormalReport: "",
            oDataParametersForScheduledReport: "",
            fileNamePrefixForScheduledReport: "",
            oDataParametersParent1ForScheduledReport: "",
            oDataParametersParent2ForScheduledReport: "",
            oDataParametersParent3ForScheduledReport: "",
            scheduledReportLambdaRequestConfigForParentQuery1: "",
            scheduledReportLambdaRequestConfigForParentQuery2: "",
            scheduledReportLambdaRequestConfigForParentQuery3: "",
            scheduledReportFileFormat: "PDF",
            scheduledReportExportExcelBandFilter: "data-and-headers",
            scheduledReportLambdaRequestConfig: "",
            timezoneForReport: "",
            timezoneNameForReport: "",
            daylightSavingForReport: 0,
            clientSideQueryForReport: "",
            clientSideUILogicForReport: "",
            filterValidationsForReport: "",
            reportDataViewVersion: LDH.GenerateGuid(),
            inputValuesInitLogic: "",
            renderingMethod: "browser",
            notificationEnabled: true,
            scriptShapingForReport: "",
            reportCustomDataSources: [],
            reportCustomSchedules: [],
            deletedScheduleIds: [],
            reportScheduledCustomDataSources: [],
            cronExpressionForReportPart1: "",
            cronExpressionForReportPart2: "",
            cronExpressionForReportPart3: "",
            cronExpressionForReportPart4: "",
            cronExpressionForReportPart5: "",
            cronExpressionForReportPart6: "",
            cronExpressionForReport: "",
            recipientsForReport: "",
            disableScheduledReportOption: true,
            dataViewMenuCategorySelect: "",
            dataViewCustomComments: "",
            numberOfDataViewsToImportVisible: false,
            showProtectiveCoverForEditWorkspace: false,
            quicksightDashboardIdInput: null,
            gridViewEngine: "odata-engine",
            jsonDataSourceType: "document",
            dataSourceRequestType: "leopardsystems.document.list",

            editDataViewType: "",
            editDataViewName: "",
            confirmationMessageText: "",
            autoManualMode: "auto-mode",
            hideMenuItem: false,
            clientSideQueryEditor: "",
            dataViewPersistentId: "",
            editDataViewId: "",
            disableEditingConfigurationFields: true,
            dataViewJSONToExport: null,
            dataViewListToImport: [],
            numberOfSeriesOnChart: [],
            numberOfDataViewsToImport: [],
            customComputedColumns: [],
            dashboardEditManageRelationship: [],
            availableDataViewsOnDashboard: [],
            isResetDashboardRelationshipsOnSave: false,
            hasDataViewDeletedInWorkspace: false,
            categoryWorkspacesVisibility,
            categorySystemViewsVisibility,
            categoryDataViewsVisibility,
            categoryDefaultViewsVisibility,
            categoryReportsVisibility,
            categoryAnalyticsDashboardsVisibility,

            editDashboardId: "",
            editDashboardInitialized: false,
            selectedDataViewsForDashboard: [],
            isUserProfileLoaded,
            leftMenuItemsInitialized,
            availableDataViewListForCreate,
            availableDataViewListForEdit,
            availableReportListForEdit,
            availableAnalyticsListForEdit,
            dxMenuItemData
        };
    };

    resetOptionPanelStates = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit,
                              availableReportListForEdit, categoryWorkspacesVisibility,
                              categorySystemViewsVisibility, categoryDataViewsVisibility,
                              categoryDefaultViewsVisibility, categoryReportsVisibility,
                              categoryAnalyticsDashboardsVisibility, availableAnalyticsListForEdit,
                              dxMenuItemData) => {
        this.isDataSourceLoaded = false;
        this.userProfileLocalCache = null;
        this.setState(this.initializeDefaultState(leftMenuItemsInitialized, isUserProfileLoaded,
            availableDataViewListForCreate, availableDataViewListForEdit, availableReportListForEdit,
            categoryWorkspacesVisibility, categorySystemViewsVisibility, categoryDataViewsVisibility,
            categoryDefaultViewsVisibility, categoryReportsVisibility, categoryAnalyticsDashboardsVisibility,
            availableAnalyticsListForEdit, dxMenuItemData));
    };

    loadLeftMenuItems = (selectDefaultMenuItem, callbackSuccess, forceNotSelectMenu) => {
        let that = this;
        let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

        LeopardAjaxHelper.GetLeftMenuItems(userId, function (items) {
            let filteredDataViewsList = [];
            let filteredSystemViewsList = [];
            let filteredDefaultViewsList = [];
            let filteredTemplateList = [];
            let filteredReportsList = [];
            let filteredAnalyticsDashboardList = [];

            let availableDataViewListForCreate = [{
                id: 0, name: "--- Empty View ---", group: "1. Blank"
            }];
            let availableDataViewListForEdit = [];
            let availableReportListForEdit = [];
            let availableAnalyticsListForEdit = [];

            for (let i = 0; i < items.length; i++) {
                if (!LDH.IsValueEmpty(items[i].isDirectory) && items[i].isDirectory) {
                    continue;
                }
                if (LDH.IsValueEmpty(items[i].menuItemType) ||
                    items[i].menuItemType === "data-view") {
                    filteredDataViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "2. Data Views",
                        category: "data-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 2
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "2. Data Views",
                        category: "data-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 2
                    });
                } else if (items[i].menuItemType === "system-view") {
                    filteredSystemViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "5. System Views",
                        category: "system-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 3
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "5. System Views",
                        category: "system-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 3
                    });
                } else if (items[i].menuItemType === "default-view") {
                    filteredDefaultViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "3. Default Views",
                        category: "default-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 4
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "3. Default Views",
                        category: "default-view",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 4
                    });
                } else if (items[i].menuItemType === "report") {
                    filteredReportsList.push(items[i]);

                    availableReportListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Reports",
                        category: "report",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 5
                    });
                } else if (items[i].menuItemType === "dashboard") {
                    filteredTemplateList.push(items[i]);

                    availableDataViewListForEdit.push({
                        id: items[i].dashboardId,
                        name: items[i].menuItemName,
                        group: "Workspaces",
                        category: "dashboard",
                        type: "dashboard",
                        item: items[i],
                        index: 1
                    });
                } else if (items[i].menuItemType === "analytics-dashboard") {
                    filteredAnalyticsDashboardList.push(items[i]);

                    availableAnalyticsListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "4. Analytics",
                        category: "analytics-dashboard",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 6
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "4. Analytics",
                        category: "analytics-dashboard",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 5
                    });

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "4. Analytics",
                        category: "analytics-dashboard",
                        type: items[i].dataViewType,
                        item: items[i],
                        index: 5
                    });
                }
            }

            let isNewFolderStructure = false;
            for (let i = 0; i < filteredTemplateList.length; i++) {
                if (filteredTemplateList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredTemplateList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }
            for (let i = 0; i < filteredDataViewsList.length; i++) {
                if (filteredDataViewsList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredDataViewsList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }
            for (let i = 0; i < filteredDefaultViewsList.length; i++) {
                if (filteredDefaultViewsList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredDefaultViewsList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }
            for (let i = 0; i < filteredReportsList.length; i++) {
                if (filteredReportsList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredReportsList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }
            for (let i = 0; i < filteredAnalyticsDashboardList.length; i++) {
                if (filteredAnalyticsDashboardList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredAnalyticsDashboardList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }
            for (let i = 0; i < filteredSystemViewsList.length; i++) {
                if (filteredSystemViewsList[i].menuItemVersion === 2 && !LDH.IsObjectNull(filteredSystemViewsList[i].isDirectory)) {
                    isNewFolderStructure = true;
                }
            }

            let menuData = items;
            if (!isNewFolderStructure) {
                menuData = [];
                menuData = that.createDefaultMenuStructure();

                let tempMenuArray = that.convertToDXTreeViewMenu(filteredTemplateList, "directory_predefined_workspaces", "workspace", "dashboard");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }

                tempMenuArray = that.convertToDXTreeViewMenu(filteredDataViewsList, "directory_predefined_dataviews", "dataview", "data-view");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }

                tempMenuArray = that.convertToDXTreeViewMenu(filteredDefaultViewsList, "directory_predefined_defaultviews", "dataview", "default-view");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }

                tempMenuArray = that.convertToDXTreeViewMenu(filteredReportsList, "directory_predefined_reports", "report", "report");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }

                tempMenuArray = that.convertToDXTreeViewMenu(filteredAnalyticsDashboardList, "directory_predefined_analytics", "analytics", "analytics-dashboard");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }

                tempMenuArray = that.convertToDXTreeViewMenu(filteredSystemViewsList, "directory_predefined_systemviews", "dataview", "system-view");
                for (let i = 0; i < tempMenuArray.length; i++) {
                    menuData.push(tempMenuArray[i]);
                }
            }

            for (let i = 0; i < menuData.length; i++) {
                if (LDH.IsObjectNull(menuData[i].expandByDefault)) {
                    menuData[i].expanded = false;
                } else {
                    menuData[i].expanded = menuData[i].expandByDefault;
                }
            }

            if (window.userRoles !== LeopardStaticUIConfig.UserRoleCCAdmin) {
                for (let i = 0; i < menuData.length; i++) {
                    if (window.userRoles !== LeopardStaticUIConfig.UserRoleCCAdmin) {
                        if (menuData[i].isHidden) {
                            menuData.splice(i, 1);
                        }
                    }
                }
            }

            if (window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin) {
                for (let b = 0; b < menuData.length; b++) {
                    menuData[b].visible = true;
                }
            } else {
                for (let b = 0; b < menuData.length; b++) {
                    let hideMenu = menuData[b].hideMenuItem;
                    if (LDH.IsObjectNull(hideMenu)) {
                        hideMenu = false;
                    }
                    menuData[b].visible = !hideMenu;
                }
            }

            that.setState({
                availableDataViewListForCreate,
                availableDataViewListForEdit,
                availableReportListForEdit,
                availableAnalyticsListForEdit,
                dxMenuItemData: menuData,
                leftMenuItemsInitialized: true
            });

            that.dataViewMenuItems = filteredDataViewsList;
            that.systemViewMenuItems = filteredSystemViewsList;
            that.defaultViewMenuItems = filteredDefaultViewsList;
            that.reportMenuItems = filteredReportsList;
            that.analyticsDashboardMenuItems = filteredAnalyticsDashboardList;
            that.templateMenuItems = filteredTemplateList;

            // ------------------------------------------------------------------------------------------
            // Delete the following code if want to load a default data view after logged in.
            let firstDefaultItem = Object.create(null);

            // Uncomment the following code.
            // let firstDefaultItem = null;
            // if (!LDH.IsObjectNull(filteredTemplateList) && filteredTemplateList.length > 0) {
            //     firstDefaultItem = filteredTemplateList[0];
            // } else if (!LDH.IsObjectNull(filteredDataViewsList) && filteredDataViewsList.length > 0) {
            //     firstDefaultItem = filteredDataViewsList[0];
            // } else if (!LDH.IsObjectNull(filteredSystemViewsList) && filteredSystemViewsList.length > 0) {
            //     firstDefaultItem = filteredSystemViewsList[0];
            // } else if (!LDH.IsObjectNull(filteredReportsList) && filteredReportsList.length > 0) {
            //     firstDefaultItem = filteredReportsList[0];
            // }
            // ------------------------------------------------------------------------------------------

            if (LDH.IsObjectNull(forceNotSelectMenu) || forceNotSelectMenu === false) {
                if (selectDefaultMenuItem === undefined || selectDefaultMenuItem) {
                    if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                        that.props.SetLeftMenuItem(firstDefaultItem);
                    } else {
                        localStorage.setItem("LoadFirstMenuItem", "true");
                        that.props.SetLeftMenuItem(Object.create(null));
                    }
                } else {
                    let selectedItem = that.props.state.selectedLeftMenuItem;
                    if (selectedItem !== undefined && selectedItem !== null) {
                        that.props.SetLeftMenuItem(selectedItem);
                    } else {
                        if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                            that.props.SetLeftMenuItem(firstDefaultItem);
                        } else {
                            localStorage.setItem("LoadFirstMenuItem", "true");
                            that.props.SetLeftMenuItem(Object.create(null));
                        }
                    }
                }
            }
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess();
            }
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to get left menu items.", "error", 5000);
            }
            console.log("Error log", error);
            that.setState({leftMenuItemsInitialized: true});
        });
    };

    setLeftMenuItemToBeSelected = () => {
        let item = this.leftMenuItemToBeSelected;
        if (this.setLeftMenu && this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null && this.leftMenuItemToBeSelected !== null &&
            this.props.state.selectedLeftMenuItem.menuItemId === null) {
            this.setLeftMenu = false;
            this.props.SetLeftMenuItem(item);
            this.leftMenuItemToBeSelected = null;
        }
    };

    menuItemSettingsButtonOnClick_GridEvent(that, option) {
        let result = window.cachedSingleDataViewConfig;
        let definition = result.dataViewNote;
        let dataViewPersistentId = "";

        let settingsVersion = result.settingsVersion;
        if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
        $(".dataview-settings-version").attr("settingsversion", settingsVersion);

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.gridViewDefinition) &&
            !LDH.IsObjectNull(definition.gridViewDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(definition.gridViewDefinition.dataViewPersistentId)) {
            dataViewPersistentId = definition.gridViewDefinition.dataViewPersistentId;
        }

        let dataViewCustomComments = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.gridViewDefinition) &&
            !LDH.IsObjectNull(definition.gridViewDefinition.dataViewCustomComments) &&
            !LDH.IsValueEmpty(definition.gridViewDefinition.dataViewCustomComments)) {
            dataViewCustomComments = definition.gridViewDefinition.dataViewCustomComments;
        }

        let gridViewEngine = "odata-engine";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.gridViewDefinition) &&
            !LDH.IsObjectNull(definition.gridViewDefinition.gridViewEngine) &&
            !LDH.IsValueEmpty(definition.gridViewDefinition.gridViewEngine)) {
            gridViewEngine = definition.gridViewDefinition.gridViewEngine;
        }

        if (!LDH.IsObjectNull(definition.gridViewDefinition.clientSideQuery) &&
            !LDH.IsValueEmpty(definition.gridViewDefinition.clientSideQuery)) {
            that.setState({
                clientSideQueryEditor: definition.gridViewDefinition.clientSideQuery,
                gridViewEngine: gridViewEngine,
                jsonDataSourceType: definition.gridViewDefinition.jsonDataSourceType,
                dataSourceRequestType: definition.gridViewDefinition.dataSourceRequestType,
                dataViewPersistentId: dataViewPersistentId,
                dataViewCustomComments: dataViewCustomComments,
                dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                hideMenuItem: result.hideMenuItem
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        } else {
            that.setState({
                dataViewPersistentId: dataViewPersistentId,
                dataViewCustomComments: dataViewCustomComments,
                gridViewEngine: gridViewEngine,
                jsonDataSourceType: definition.gridViewDefinition.jsonDataSourceType,
                dataSourceRequestType: definition.gridViewDefinition.dataSourceRequestType,
                dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                hideMenuItem: result.hideMenuItem
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    menuItemSettingsButtonOnClick_ChartEvent(that, option) {
        let result = window.cachedSingleDataViewConfig;
        let definition = result.dataViewNote;
        let dataViewPersistentId = "";

        let settingsVersion = result.settingsVersion;
        if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
        $(".dataview-settings-version").attr("settingsversion", settingsVersion);

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.chartDefinition) &&
            !LDH.IsObjectNull(definition.chartDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(definition.chartDefinition.dataViewPersistentId)) {
            dataViewPersistentId = definition.chartDefinition.dataViewPersistentId;
        }

        let dataViewCustomComments = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.chartDefinition) &&
            !LDH.IsObjectNull(definition.chartDefinition.dataViewCustomComments) &&
            !LDH.IsValueEmpty(definition.chartDefinition.dataViewCustomComments)) {
            dataViewCustomComments = definition.chartDefinition.dataViewCustomComments;
        }

        if (!LDH.IsObjectNull(definition.chartDefinition.clientSideQueryEditor) &&
            !LDH.IsValueEmpty(definition.chartDefinition.clientSideQueryEditor)) {
            that.setState({
                clientSideQueryEditor: definition.chartDefinition.clientSideQueryEditor,
                dataViewPersistentId: dataViewPersistentId,
                dataViewCustomComments: dataViewCustomComments,
                dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                hideMenuItem: result.hideMenuItem
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        } else {
            that.setState({
                dataViewPersistentId: dataViewPersistentId,
                dataViewCustomComments: dataViewCustomComments,
                dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                hideMenuItem: result.hideMenuItem
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    menuItemSettingsButtonOnClick_TimelineEvent(that, option) {
        let result = window.cachedSingleDataViewConfig;
        let definition = result.dataViewNote;
        let dataViewPersistentId = "";

        let settingsVersion = result.settingsVersion;
        if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
        $(".dataview-settings-version").attr("settingsversion", settingsVersion);

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.dataViewPersistentId)) {
            dataViewPersistentId = definition.timelineDefinition.dataViewPersistentId;
        }

        let dataViewCustomComments = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.dataViewCustomComments) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.dataViewCustomComments)) {
            dataViewCustomComments = definition.timelineDefinition.dataViewCustomComments;
        }

        let timelineResourceDefinition = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelineResourceDefinition) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelineResourceDefinition)) {
            timelineResourceDefinition = definition.timelineDefinition.timelineResourceDefinition;
        }

        let timelineOdataQueryForGrouping = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelineOdataQueryForGrouping) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelineOdataQueryForGrouping)) {
            timelineOdataQueryForGrouping = definition.timelineDefinition.timelineOdataQueryForGrouping;
        }

        let timelinePostDataProcessingForGrouping = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelinePostDataProcessingForGrouping) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelinePostDataProcessingForGrouping)) {
            timelinePostDataProcessingForGrouping = definition.timelineDefinition.timelinePostDataProcessingForGrouping;
        }

        let timelineOdataQueryForResources = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelineOdataQueryForResources) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelineOdataQueryForResources)) {
            timelineOdataQueryForResources = definition.timelineDefinition.timelineOdataQueryForResources;
        }

        let timelinePostDataProcessingForResources = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelinePostDataProcessingForResources) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelinePostDataProcessingForResources)) {
            timelinePostDataProcessingForResources = definition.timelineDefinition.timelinePostDataProcessingForResources;
        }

        let timelineDataKeyForResources = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.timelineDefinition) &&
            !LDH.IsObjectNull(definition.timelineDefinition.timelineDataKeyForResources) &&
            !LDH.IsValueEmpty(definition.timelineDefinition.timelineDataKeyForResources)) {
            timelineDataKeyForResources = definition.timelineDefinition.timelineDataKeyForResources;
        }

        that.setState({
            timelineResourceDefinitionInput: timelineResourceDefinition,
            timelineOdataQueryForGroupingInput: timelineOdataQueryForGrouping,
            timelinePostDataProcessingForGroupingInput: timelinePostDataProcessingForGrouping,
            timelineOdataQueryForResourcesInput: timelineOdataQueryForResources,
            timelinePostDataProcessingForResourcesInput: timelinePostDataProcessingForResources,
            timelineDataKeyForResourcesInput: timelineDataKeyForResources,
            dataViewPersistentId: dataViewPersistentId,
            dataViewCustomComments: dataViewCustomComments,
            dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
            hideMenuItem: result.hideMenuItem
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick_TabbedViewEvent(that, option) {
        let result = window.cachedSingleDataViewConfig;
        let definition = result.dataViewNote;
        let dataViewPersistentId = "";

        let settingsVersion = result.settingsVersion;
        if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
        $(".dataview-settings-version").attr("settingsversion", settingsVersion);

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.tabbedViewDefinition) &&
            !LDH.IsObjectNull(definition.tabbedViewDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(definition.tabbedViewDefinition.dataViewPersistentId)) {
            dataViewPersistentId = definition.tabbedViewDefinition.dataViewPersistentId;
        }

        let dataViewCustomComments = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.tabbedViewDefinition) &&
            !LDH.IsObjectNull(definition.tabbedViewDefinition.dataViewCustomComments) &&
            !LDH.IsValueEmpty(definition.tabbedViewDefinition.dataViewCustomComments)) {
            dataViewCustomComments = definition.tabbedViewDefinition.dataViewCustomComments;
        }

        that.setState({
            dataViewPersistentId: dataViewPersistentId,
            dataViewCustomComments: dataViewCustomComments,
            dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
            hideMenuItem: result.hideMenuItem
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick_DiagramEvent(that, option) {
        let result = window.cachedSingleDataViewConfig;
        let definition = result.dataViewNote;
        let dataViewPersistentId = "";

        let settingsVersion = result.settingsVersion;
        if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
        $(".dataview-settings-version").attr("settingsversion", settingsVersion);

        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.diagramDefinition) &&
            !LDH.IsObjectNull(definition.diagramDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(definition.diagramDefinition.dataViewPersistentId)) {
            dataViewPersistentId = definition.diagramDefinition.dataViewPersistentId;
        }

        let dataViewCustomComments = "";
        if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.diagramDefinition) &&
            !LDH.IsObjectNull(definition.diagramDefinition.dataViewCustomComments) &&
            !LDH.IsValueEmpty(definition.diagramDefinition.dataViewCustomComments)) {
            dataViewCustomComments = definition.diagramDefinition.dataViewCustomComments;
        }

        that.setState({
            dataViewPersistentId: dataViewPersistentId,
            dataViewCustomComments: dataViewCustomComments,
            dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
            hideMenuItem: result.hideMenuItem
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick_AnalyticsDashboardEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    quicksightDashboardIdInput: definition.quicksightDashboardId,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_ReportEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            LeopardAjaxHelper.GetDataSourceList(option.item.menuItemType, function (sourceList) {
                let list = [];
                for (let j = 0; j < sourceList.length; j++) {
                    list.push(sourceList[j]);
                }

                let result = window.cachedSingleDataViewConfig;
                let definition = result.dataViewNote;
                let stateHasBeenSet = false;
                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                let reportCustomSchedulesClone = LDH.DeepClone(definition.reportCustomSchedules);
                if (LDH.IsObjectNull(reportCustomSchedulesClone)) reportCustomSchedulesClone = [];

                let deletedScheduleIdsClone = LDH.DeepClone(definition.deletedScheduleIds);
                if (LDH.IsObjectNull(deletedScheduleIdsClone)) deletedScheduleIdsClone = [];

                if (LDH.IsObjectNull(definition.renderingMethod)) {
                    definition.renderingMethod = "browser";
                }
                if (LDH.IsObjectNull(definition.notificationEnabled)) {
                    definition.notificationEnabled = true;
                }

                for (let i = 0; i < deletedScheduleIdsClone.length; i++) {
                    let scheduleId = deletedScheduleIdsClone[i];
                    let found = false;
                    for (let j = 0; j < reportCustomSchedulesClone.length; j++) {
                        if (reportCustomSchedulesClone[j].id === scheduleId) {
                            found = true;
                        }
                    }
                    if (found === false) {
                        deletedScheduleIdsClone.splice(i, 1);
                        i--;
                    }
                }

                if (LDH.IsObjectNull(deletedScheduleIdsClone)) {
                    deletedScheduleIdsClone = [];
                }
                if (!LDH.IsObjectNull(definition.cronExpression) && !LDH.IsValueEmpty(definition.cronExpression)) {
                    let cronExp = definition.cronExpression.split(" ");
                    if (!LDH.IsObjectNull(cronExp) && cronExp.length === 6) {
                        stateHasBeenSet = true;
                        that.scheduledReportDisabled = !definition.scheduledReportEnabled;
                        that.setState({
                            oDataParametersForNormalReport: definition.oDataParametersForNormalReport,
                            oDataParametersParent1ForNormalReport: definition.oDataParametersParent1ForNormalReport,
                            oDataParametersParent2ForNormalReport: definition.oDataParametersParent2ForNormalReport,
                            oDataParametersParent3ForNormalReport: definition.oDataParametersParent3ForNormalReport,
                            oDataParametersForScheduledReport: definition.oDataParametersForScheduledReport,
                            fileNamePrefixForScheduledReport: definition.fileNamePrefixForScheduledReport,
                            oDataParametersParent1ForScheduledReport: definition.oDataParametersParent1ForScheduledReport,
                            oDataParametersParent2ForScheduledReport: definition.oDataParametersParent2ForScheduledReport,
                            oDataParametersParent3ForScheduledReport: definition.oDataParametersParent3ForScheduledReport,
                            scheduledReportLambdaRequestConfigForParentQuery1: definition.scheduledReportLambdaRequestConfigForParentQuery1,
                            scheduledReportLambdaRequestConfigForParentQuery2: definition.scheduledReportLambdaRequestConfigForParentQuery2,
                            scheduledReportLambdaRequestConfigForParentQuery3: definition.scheduledReportLambdaRequestConfigForParentQuery3,
                            scheduledReportFileFormat: LDH.IsObjectNull(definition.scheduledReportFileFormat) ||
                            LDH.IsValueEmpty(definition.scheduledReportFileFormat) ? "PDF" :
                                definition.scheduledReportFileFormat,
                            scheduledReportExportExcelBandFilter: LDH.IsObjectNull(definition.scheduledReportExportExcelBandFilter) ||
                            LDH.IsValueEmpty(definition.scheduledReportExportExcelBandFilter) ? "data-and-headers" :
                                definition.scheduledReportExportExcelBandFilter,
                            scheduledReportLambdaRequestConfig: LDH.IsObjectNull(definition.scheduledReportLambdaRequestConfig) ||
                            LDH.IsValueEmpty(definition.scheduledReportLambdaRequestConfig) ? "" :
                                definition.scheduledReportLambdaRequestConfig,
                            timezoneForReport: LDH.IsObjectNull(definition.timezone) ||
                            LDH.IsValueEmpty(definition.timezone) ? 0 : definition.timezone,
                            timezoneNameForReport: LDH.IsObjectNull(definition.timezoneName) ||
                            LDH.IsValueEmpty(definition.timezoneName) ? that.guessedLocalTimezone :
                                definition.timezoneName,
                            daylightSavingForReport: LDH.IsObjectNull(definition.daylightSaving) ||
                            LDH.IsValueEmpty(definition.daylightSaving) ? 0 : definition.daylightSaving,
                            clientSideQueryForReport: definition.clientSideQueryForReport,
                            clientSideUILogicForReport: definition.clientSideUILogicForReport,
                            filterValidationsForReport: definition.filterValidationsForReport,
                            reportDataViewVersion: definition.reportDataViewVersion,
                            inputValuesInitLogic: definition.inputValuesInitLogic,
                            renderingMethod: definition.renderingMethod,
                            switchDisabledForNotification: definition.renderingMethod === "browser" ? true : false,
                            notificationEnabled: definition.notificationEnabled,
                            scriptShapingForReport: definition.scriptShapingForReport,
                            reportCustomDataSources: definition.reportCustomDataSources,
                            reportCustomSchedules: definition.reportCustomSchedules,
                            deletedScheduleIds: deletedScheduleIdsClone,
                            reportScheduledCustomDataSources: definition.reportScheduledCustomDataSources,
                            cronExpressionForReport: definition.cronExpression,
                            recipientsForReport: definition.recipients,
                            createDataViewDataSourceUrl: definition.tableName,
                            createDataViewDataSourceVersion: definition.dataSourceVersion,
                            gridViewEngine: definition.gridViewEngine,
                            dataSourceRequestType: definition.dataSourceRequestType,
                            jsonDataSourceType: definition.jsonDataSourceType,
                            createDataViewSelectMapProvider: definition.mapProvider,
                            availableDataSourceList: list,
                            disableScheduledReportOption: !definition.scheduledReportEnabled,
                            showLoadingProgress: false,
                            cronExpressionForReportPart1: cronExp[0],
                            cronExpressionForReportPart2: cronExp[1],
                            cronExpressionForReportPart3: cronExp[2],
                            cronExpressionForReportPart4: cronExp[3],
                            cronExpressionForReportPart5: cronExp[4],
                            cronExpressionForReportPart6: cronExp[5],
                            dataViewCustomComments: definition.dataViewCustomComments
                        }, function () {
                            that.props.updateWindowDimensionsRequired();
                        });
                    }
                }

                if (stateHasBeenSet === false) {
                    that.setState({
                        oDataParametersForNormalReport: definition.oDataParametersForNormalReport,
                        oDataParametersParent1ForNormalReport: definition.oDataParametersParent1ForNormalReport,
                        oDataParametersParent2ForNormalReport: definition.oDataParametersParent2ForNormalReport,
                        oDataParametersParent3ForNormalReport: definition.oDataParametersParent3ForNormalReport,
                        oDataParametersForScheduledReport: definition.oDataParametersForScheduledReport,
                        fileNamePrefixForScheduledReport: definition.fileNamePrefixForScheduledReport,
                        oDataParametersParent1ForScheduledReport: definition.oDataParametersParent1ForScheduledReport,
                        oDataParametersParent2ForScheduledReport: definition.oDataParametersParent2ForScheduledReport,
                        oDataParametersParent3ForScheduledReport: definition.oDataParametersParent3ForScheduledReport,
                        scheduledReportLambdaRequestConfigForParentQuery1: definition.scheduledReportLambdaRequestConfigForParentQuery1,
                        scheduledReportLambdaRequestConfigForParentQuery2: definition.scheduledReportLambdaRequestConfigForParentQuery2,
                        scheduledReportLambdaRequestConfigForParentQuery3: definition.scheduledReportLambdaRequestConfigForParentQuery3,
                        scheduledReportFileFormat: LDH.IsObjectNull(definition.scheduledReportFileFormat) ||
                        LDH.IsValueEmpty(definition.scheduledReportFileFormat) ? "PDF" :
                            definition.scheduledReportFileFormat,
                        scheduledReportExportExcelBandFilter: LDH.IsObjectNull(definition.scheduledReportExportExcelBandFilter) ||
                        LDH.IsValueEmpty(definition.scheduledReportExportExcelBandFilter) ? "data-and-headers" :
                            definition.scheduledReportExportExcelBandFilter,
                        scheduledReportLambdaRequestConfig: LDH.IsObjectNull(definition.scheduledReportLambdaRequestConfig) ||
                        LDH.IsValueEmpty(definition.scheduledReportLambdaRequestConfig) ? "" :
                            definition.scheduledReportLambdaRequestConfig,
                        timezoneForReport: LDH.IsObjectNull(definition.timezone) ||
                        LDH.IsValueEmpty(definition.timezone) ? 0 : definition.timezone,
                        timezoneNameForReport: LDH.IsObjectNull(definition.timezoneName) ||
                        LDH.IsValueEmpty(definition.timezoneName) ? that.guessedLocalTimezone :
                            definition.timezoneName,
                        daylightSavingForReport: LDH.IsObjectNull(definition.daylightSaving) ||
                        LDH.IsValueEmpty(definition.daylightSaving) ? 0 : definition.daylightSaving,
                        clientSideQueryForReport: definition.clientSideQueryForReport,
                        clientSideUILogicForReport: definition.clientSideUILogicForReport,
                        filterValidationsForReport: definition.filterValidationsForReport,
                        reportDataViewVersion: definition.reportDataViewVersion,
                        inputValuesInitLogic: definition.inputValuesInitLogic,
                        renderingMethod: definition.renderingMethod,
                        switchDisabledForNotification: definition.renderingMethod === "browser" ? true : false,
                        notificationEnabled: definition.notificationEnabled,
                        scriptShapingForReport: definition.scriptShapingForReport,
                        reportCustomDataSources: definition.reportCustomDataSources,
                        reportCustomSchedules: definition.reportCustomSchedules,
                        deletedScheduleIds: deletedScheduleIdsClone,
                        reportScheduledCustomDataSources: definition.reportScheduledCustomDataSources,
                        cronExpressionForReport: definition.cronExpression,
                        recipientsForReport: definition.recipients,
                        createDataViewDataSourceUrl: definition.tableName,
                        createDataViewDataSourceVersion: definition.dataSourceVersion,
                        gridViewEngine: definition.gridViewEngine,
                        dataSourceRequestType: definition.dataSourceRequestType,
                        jsonDataSourceType: definition.jsonDataSourceType,
                        createDataViewSelectMapProvider: definition.mapProvider,
                        availableDataSourceList: list,
                        disableScheduledReportOption: !definition.scheduledReportEnabled,
                        dataViewCustomComments: definition.dataViewCustomComments,
                        showLoadingProgress: false
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }
            });
        });
    };

    menuItemSettingsButtonOnClick_PhotoEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.photoDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    heightOfThumbnailForPhotoGallery: definition.heightOfThumbnail,
                    widthOfThumbnailForPhotoGallery: definition.widthOfThumbnail,
                    parentDataSourceIdForPhotoGallery: definition.parentDataSourceId,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    photoGalleryDefaultTab: definition.photoGalleryDefaultTab,
                    photoGalleryDisabledTabs: definition.photoGalleryDisabledTabs,
                    photoGalleryReportPayloadSourceId: definition.photoGalleryReportPayloadSourceId,
                    photoGalleryReportDataSourceId: definition.photoGalleryReportDataSourceId,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_RegexTesterEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.regexDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_NotepadEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.notepadDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    notepadButtonText: definition.notepadButtonText,
                    notepadDataSubmissionLogic: definition.notepadDataSubmissionLogic,
                    notepadAPIGatewayUrl: definition.notepadAPIGatewayUrl,
                    notepadOdataQueryString: definition.notepadOdataQueryString,
                    notepadRequestLogic: definition.notepadRequestLogic,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_FormEditorEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.formDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_MapEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.mapDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    createDataViewSelectMapProvider: definition.mapProvider,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    menuItemSettingsButtonOnClick_DocumentEditorEvent(that, option) {
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                let definition = result.dataViewNote.documentDefinition;
                let dataViewPersistentId = "";

                let settingsVersion = result.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dataview-settings-version").attr("settingsversion", settingsVersion);

                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewPersistentId) &&
                    !LDH.IsValueEmpty(definition.dataViewPersistentId)) {
                    dataViewPersistentId = definition.dataViewPersistentId;
                }

                let dataViewCustomComments = "";
                if (!LDH.IsObjectNull(definition) && !LDH.IsObjectNull(definition.dataViewCustomComments) &&
                    !LDH.IsValueEmpty(definition.dataViewCustomComments)) {
                    dataViewCustomComments = definition.dataViewCustomComments;
                }

                that.setState({
                    documentEditorAPIGatewayGetUrl: definition.documentEditorAPIGatewayGetUrl,
                    documentEditorAPIGatewayPostUrl: definition.documentEditorAPIGatewayPostUrl,
                    documentEditorDataFormat: definition.documentEditorDataFormat,
                    documentEditorAllowStandaloneAccess: definition.documentEditorAllowStandaloneAccess,
                    documentEditorDataSchema: definition.documentEditorDataSchema,
                    documentEditorUISchema: definition.documentEditorUISchema,
                    documentEditorDirectory: definition.documentEditorDirectory,
                    documentEditorFileName: definition.documentEditorFileName,
                    documentEditorOwnerUserId: definition.documentEditorOwnerUserId,
                    documentEditorDataReceivingLogic: definition.documentEditorDataReceivingLogic,
                    documentEditorDataSendingLogic: definition.documentEditorDataSendingLogic,
                    showLoadingProgress: false,
                    dataViewPersistentId,
                    dataViewCustomComments,
                    dataViewMenuCategorySelect: that.state.showMenuOptionPanel.dataViewCategory,
                    hideMenuItem: result.hideMenuItem
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    displayMenuOptionByType(data) {
        let that = this;

        if (data !== undefined && data !== null && data.option === "add_template_options") {
            return this.buildMenuOptionPanelCreateWorkspaceOptions(data);
        }

        if (data !== undefined && data !== null && data.option === "add_template") {
            return this.buildMenuOptionPanelCreateTemplate();
        }

        if (data !== undefined && data !== null && (data.option === "edit_template" ||
            data.option === "manage_dashboard_relationship")) {
            if (that.state.editDashboardInitialized === false) {
                let profile = window.userProfile;
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);
                let dashboardId = that.state.editDashboardId;
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dataViewList = [];
                    if (dashboard === [] || dashboard.length === 0) {
                        that.setState({
                            selectedDataViewsForDashboard: dataViewList,
                            editDashboardInitialized: true,
                            dashboardEditManageRelationship: []
                        }, function () {
                            that.props.updateWindowDimensionsRequired();
                        });
                        return;
                    }
                    if (LDH.IsObjectNull(dashboard.relationships)) {
                        dashboard.relationships = [];
                    }

                    let settingsVersion = dashboard.settingsVersion;
                    if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                    $(".dashboard-settings-version").attr("settingsversion", settingsVersion);

                    for (let i = 0; i < dashboard.dashboardLayout.length; i++) {
                        let definition = dashboard.dashboardLayout[i].definition;
                        let menuItem = null;

                        for (let j = 0; j < that.dataViewMenuItems.length; j++) {
                            if (that.dataViewMenuItems[j].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.dataViewMenuItems[j];
                                break;
                            }
                        }

                        for (let v = 0; v < that.systemViewMenuItems.length; v++) {
                            if (that.systemViewMenuItems[v].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.systemViewMenuItems[v];
                                break;
                            }
                        }

                        for (let x = 0; x < that.defaultViewMenuItems.length; x++) {
                            if (that.defaultViewMenuItems[x].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.defaultViewMenuItems[x];
                                break;
                            }
                        }

                        for (let w = 0; w < that.reportMenuItems.length; w++) {
                            if (that.reportMenuItems[w].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.reportMenuItems[w];
                                break;
                            }
                        }

                        for (let w = 0; w < that.analyticsDashboardMenuItems.length; w++) {
                            if (that.analyticsDashboardMenuItems[w].dataViewPersistentId === definition.dataViewPersistentId) {
                                menuItem = that.analyticsDashboardMenuItems[w];
                                break;
                            }
                        }

                        let dataViewId = (menuItem === null) ? "empty" : menuItem.dataViewId;
                        let combinedId = definition.dashboardItemId + ":" + ((menuItem === null)
                            ? "empty" : menuItem.dataViewPersistentId);

                        let clonedList = LDH.DeepClone(that.state.availableDataViewListForEdit);
                        for (let j = 0; j < clonedList.length; j++) {
                            if (!LDH.IsObjectNull(menuItem) && menuItem.dataViewId === clonedList[j].id) {
                                definition.dataViewName = clonedList[j].name;
                            }
                        }

                        let dataViewItem = {
                            dataViewName: definition.dataViewName,
                            dataViewId: dataViewId,
                            dashboardItemId: definition.dashboardItemId,
                            combinedId: combinedId,
                            dropdownFieldToDisplay: LDH.IsObjectNull(definition.customDataViewTitle) ||
                            LDH.IsValueEmpty(definition.customDataViewTitle) ? definition.dataViewName :
                                definition.customDataViewTitle,
                            customDataViewTitle: definition.customDataViewTitle,
                            dataViewPersistentId: definition.dataViewPersistentId,
                            index: parseInt(dashboard.dashboardLayout[i].i),
                            item: menuItem
                        };
                        dataViewList.push(dataViewItem);
                    }

                    that.setState({
                        selectedDataViewsForDashboard: dataViewList,
                        editDashboardInitialized: true,
                        editDataViewName: dashboard.dashboardName,
                        confirmationMessageText: dashboard.confirmationMessageText,
                        autoManualMode: dashboard.autoManualMode,
                        hideMenuItem: dashboard.hideMenuItem,
                        dashboardEditManageRelationship: dashboard.relationships
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }, function (error, sessionTimeout) {
                    if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                        !LDH.IsValueEmpty(error.message) &&
                        error.message.indexOf("Cannot read property '") !== -1 &&
                        error.message.indexOf("' of undefined") !== -1) {
                        that.createDashboardDeleteButtonOnClick("Your workspace could not be found. <br/>" +
                            "Do you want to delete the reference(s) to it from the database?");
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to initialize workspace settings. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                        that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                        that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);
                });
            }

            if (data.option === "edit_template") {
                return this.buildMenuOptionPanelEditTemplate();
            }
            if (data.option === "manage_dashboard_relationship") {
                return this.buildMenuOptionPanelManageDashboardRelationship();
            }
            return null;
        }

        if (data !== undefined && data !== null && data.option === "add_dataview_options") {
            return this.buildMenuOptionPanelDataViewOptions(data);
        }

        if (data !== undefined && data !== null && data.option === "edit_dashboard_options") {
            return this.buildMenuOptionPanelDashboardOptions();
        }

        if (data !== undefined && data !== null && data.option === "export_dataview_from_view" ||
            data.option === "export_dataview_from_workspace") {
            return this.buildMenuOptionPanelExportDataView();
        }

        if (data !== undefined && data !== null && (data.option === "import_dataview_from_view" ||
            data.option === "import_dataview_from_workspace")) {
            return this.buildMenuOptionPanelImportDataView();
        }

        if (data !== undefined && data !== null && (data.option === "clone_dataview_from_view" ||
            data.option === "clone_dataview_from_workspace")) {
            return this.buildMenuOptionPanelCloneDataView();
        }

        if (data !== undefined && data !== null && (data.option === "workspace_preferences" ||
            data.option === "workspace_preferences")) {
            return this.buildMenuOptionPanelDataViewPreferences(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "dataview_preferences" ||
            data.option === "dataview_preferences")) {
            return this.buildMenuOptionPanelDataViewPreferences(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "create_workspace_subfolder" ||
            data.option === "create_workspace_subfolder")) {
            return this.buildMenuOptionPanelDataViewCreateFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "rename_workspace_folder" ||
            data.option === "rename_workspace_folder")) {
            return this.buildMenuOptionPanelDataViewRenameFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "delete_workspace_folder" ||
            data.option === "delete_workspace_folder")) {
            return this.buildMenuOptionPanelDataViewDeleteFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "create_dataview_subfolder" ||
            data.option === "create_dataview_subfolder")) {
            return this.buildMenuOptionPanelDataViewCreateFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "rename_dataview_folder" ||
            data.option === "rename_dataview_folder")) {
            return this.buildMenuOptionPanelDataViewRenameFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && (data.option === "delete_dataview_folder" ||
            data.option === "delete_dataview_folder")) {
            return this.buildMenuOptionPanelDataViewDeleteFolder(data.menuData.item);
        }

        if (data !== undefined && data !== null && data.option === "add_dataview") {
            if (that.isDataSourceLoaded === false) {
                that.isDataSourceLoaded = true;
                setTimeout(function () {
                    that.setState({showLoadingProgress: true}, function () {
                        let category = that.state.showMenuOptionPanel.dataViewCategory;
                        LeopardAjaxHelper.GetDataSourceList(category, function (result) {
                            let list = [];
                            if (category === "default-view" || category === "report" || category === "analytics-dashboard") {
                                for (let j = 0; j < result.length; j++) {
                                    list.push(result[j]);
                                }
                            } else {
                                for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                                    let view = that.state.availableDataViewListForEdit[i];
                                    if (view.category === "default-view" &&
                                        view.type === that.state.createDataViewType) {
                                        list.push(that.state.availableDataViewListForEdit[i]);
                                    }
                                }
                            }
                            that.setState({availableDataSourceList: list, showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve data sources from the database. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                    });
                }, 100);
            }
            return this.buildMenuOptionPanelCreateDataView();
        }

        if (data !== undefined && data !== null && data.option === "edit_dataview") {
            return this.buildMenuOptionPanelEditDataView();
        }
        return null;
    }

    showMenuOptionPanelStatusOnChange = (option) => {
        let that = this;
        if (!LDH.IsObjectNull(option.disabled) && option.disabled) {
            return;
        }

        if (!LDH.IsObjectNull(option.menuData) && !LDH.IsObjectNull(option.menuData.item)) {
            that.currentFolderObject = option.menuData.item;
        } else if (!LDH.IsObjectNull(option.item)) {
            that.currentFolderObject = option.item;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "edit_template") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "workspace_preferences") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "create_workspace_subfolder") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "rename_workspace_folder") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "delete_workspace_folder") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "manage_dashboard_relationship") {
            if (option.currentOption === option.option) return;

            this.setState({
                showMenuOptionPanel: option,
                showProtectiveCoverForEditWorkspace: true
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (option.dataViewCategory === "analytics-dashboard") {
            this.setState({
                showMenuOptionPanel: option,
                selectedDataViewsForDashboard: [{
                    dataViewName: "none", index: 0, item: null,
                    dataViewId: null,
                    dashboardItemId: LDH.GenerateGuid(),
                    dataViewPersistentId: null,
                    customDataViewTitle: ""
                }],
                createDataViewType: "aws-quicksight-dashboard"
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        } else if (option.dataViewCategory === "report") {
            this.setState({
                showMenuOptionPanel: option,
                selectedDataViewsForDashboard: [{
                    dataViewName: "none", index: 0, item: null,
                    dataViewId: null,
                    dashboardItemId: LDH.GenerateGuid(),
                    dataViewPersistentId: null,
                    customDataViewTitle: ""
                }],
                createDataViewType: "report"
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        } else {
            if (LDH.IsValueEmpty(this.state.createDataViewType)) {
                this.setState({
                    showMenuOptionPanel: option,
                    selectedDataViewsForDashboard: [{
                        dataViewName: "none", index: 0, item: null,
                        dataViewId: null,
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewPersistentId: null,
                        customDataViewTitle: ""
                    }],
                    createDataViewType: "datagrid"
                }, function () {
                    that.props.updateWindowDimensionsRequired();
                });
                return;
            }
        }

        this.setState({
            showMenuOptionPanel: option,
            selectedDataViewsForDashboard: [{
                dataViewName: "none", index: 0, item: null,
                dataViewId: null,
                dashboardItemId: LDH.GenerateGuid(),
                dataViewPersistentId: null,
                customDataViewTitle: ""
            }]
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick = (option) => {
        let that = this;

        if (option.option === "edit_dataview") {
            this.setState({
                showMenuOptionPanel: option,
                editDataViewId: option.item.dataViewId,
                editDataViewName: option.item.menuItemName,
                editDataViewType: option.item.dataViewType,
                heightOfThumbnailForPhotoGallery: null,
                widthOfThumbnailForPhotoGallery: null,
                photoGalleryDefaultTab: "photo",
                photoGalleryDisabledTabs: null,
                photoGalleryReportPayloadSourceId: "",
                photoGalleryReportDataSourceId: "",
                parentDataSourceIdForPhotoGallery: "",
                notepadButtonText: "",
                notepadDataSubmissionLogic: "",
                notepadAPIGatewayUrl: "",
                notepadOdataQueryString: "",
                notepadRequestLogic: "",
                documentEditorAPIGatewayGetUrl: "",
                documentEditorAPIGatewayPostUrl: "",
                documentEditorDataFormat: "",
                documentEditorAllowStandaloneAccess: "",
                documentEditorDataSchema: JSON.stringify(LeopardStaticUIConfig.SampleData_DocumentEditorDataSchema),
                documentEditorUISchema: JSON.stringify(LeopardStaticUIConfig.SampleData_DocumentEditorUISchema),
                documentEditorDataReceivingLogic: "",
                documentEditorDirectory: "cc_dataview_config",
                documentEditorFileName: "",
                documentEditorOwnerUserId: "",
                documentEditorDataSendingLogic: "",
            }, function () {
                that.props.updateWindowDimensionsRequired();

                if (option.item.dataViewType === "photo") {
                    this.menuItemSettingsButtonOnClick_PhotoEvent(that, option);
                } else if (option.item.dataViewType === "report") {
                    this.menuItemSettingsButtonOnClick_ReportEvent(that, option);
                } else if (option.item.dataViewType === "chart") {
                    this.menuItemSettingsButtonOnClick_ChartEvent(that, option);
                } else if (option.item.dataViewType === "datagrid") {
                    this.menuItemSettingsButtonOnClick_GridEvent(that, option);
                } else if (option.item.dataViewType === "notepad") {
                    this.menuItemSettingsButtonOnClick_NotepadEvent(that, option);
                } else if (option.item.dataViewType === "form-editor") {
                    this.menuItemSettingsButtonOnClick_FormEditorEvent(that, option);
                } else if (option.item.dataViewType === "html-form-builder") {
                    this.menuItemSettingsButtonOnClick_FormEditorEvent(that, option);
                } else if (option.item.dataViewType === "document-editor") {
                    this.menuItemSettingsButtonOnClick_DocumentEditorEvent(that, option);
                } else if (option.item.dataViewType === "map") {
                    this.menuItemSettingsButtonOnClick_MapEvent(that, option);
                } else if (option.item.dataViewType === "aws-quicksight-dashboard") {
                    this.menuItemSettingsButtonOnClick_AnalyticsDashboardEvent(that, option);
                } else if (option.item.dataViewType === "regex-tester") {
                    this.menuItemSettingsButtonOnClick_RegexTesterEvent(that, option);
                } else if (option.item.dataViewType === "timeline") {
                    this.menuItemSettingsButtonOnClick_TimelineEvent(that, option);
                } else if (option.item.dataViewType === "tabbed-view") {
                    this.menuItemSettingsButtonOnClick_TabbedViewEvent(that, option);
                } else if (option.item.dataViewType === "diagram") {
                    this.menuItemSettingsButtonOnClick_DiagramEvent(that, option);
                }
            });
        } else if (option.option === "edit_dashboard_options") {
            this.setState({
                showMenuOptionPanel: option,
                editDashboardId: option.item.dashboardId,
                editDataViewName: option.item.menuItemName,
                confirmationMessageText: "",
                autoManualMode: "auto-mode",
                hideMenuItem: false
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    dataViewOrTemplateMenuItemOnClick = (data) => {
        let that = this;
        let selectedLeftMenuItem = that.props.state.selectedLeftMenuItem;
        let needConfirm = false;
        if (typeof selectedLeftMenuItem !== "undefined" && selectedLeftMenuItem !== null &&
            !LDH.IsValueEmpty(selectedLeftMenuItem.confirmationMessageText)) {
            needConfirm = true;
        }

        if (needConfirm) {
            LRH.ShowDialog(selectedLeftMenuItem.confirmationMessageText, "Confirmation", function () {
                that.dataViewOrTemplateMenuItemOnClickConfirmed(data);
            }, null);
        } else {
            that.dataViewOrTemplateMenuItemOnClickConfirmed(data);
        }
    };

    dataViewOrTemplateMenuItemOnClickConfirmed = (data) => {
        let that = this;
        that.leftMenuItemToBeSelected = data.item;
        that.setLeftMenu = true;

        let emptyItem = {
            dataSourceUrl: null, contentPageType: null,
            menuItemId: null, dataViewType: null
        };

        that.props.SetLeftMenuItem(emptyItem);

        if (data.item !== undefined && data.item !== null) {
            let id = data.item.menuItemId;
            that.props.KeepGridViewDataById(id);
            that.props.KeepChartDataById(id);
        } else {
            that.props.KeepGridViewDataById("");
            that.props.KeepChartDataById("");
        }
    };

    selectDataViewCustomTitleOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        for (let i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].dashboardItemId === data.item.dashboardItemId) {
                clonedDataViewList[i].customDataViewTitle = data.e.value;
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedDataViewsForDashboard: clonedDataViewList
        });
    };

    reportCustomSchedulesAddOnClick = () => {
        let list = LDH.DeepClone(this.state.reportCustomSchedules);
        if (LDH.IsObjectNull(list)) list = [];

        list.push({
            recipients: "",
            cronExpPart1: "",
            cronExpPart2: "",
            cronExpPart3: "",
            cronExpPart4: "",
            cronExpPart5: "",
            cronExpPart6: "",
            cronExpValue: "",
            id: LDH.GenerateGuid()
        });
        this.setState({reportCustomSchedules: list});
    };

    reportCustomDataSourceAddOnClick = () => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        list.push({name: "", query: "", id: LDH.GenerateGuid()});
        this.setState({reportCustomDataSources: list});
    };

    reportCustomDataSourceRemoveOnClick = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list.splice(i, 1);
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    reportScheduledCustomDataSourceAddOnClick = () => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        list.push({
            name: "", query: "", id: LDH.GenerateGuid(),
            queryShaping: "", lambdaRequestConfig: "",
            postDataQuery: ""
        });

        this.setState({reportScheduledCustomDataSources: list});
    };

    reportScheduledCustomDataSourceRemoveOnClick = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list.splice(i, 1);
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    reportCustomSchedulesRemoveOnClick = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomSchedules);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list.splice(i, 1);
                break;
            }
        }

        let ids = LDH.DeepClone(this.state.deletedScheduleIds);
        if (LDH.IsObjectNull(ids)) ids = [];
        ids.push(data.id);

        this.setState({
            reportCustomSchedules: list,
            deletedScheduleIds: ids
        });
    };

    workspaceDataViewAddOnClick = () => {
        let list = LDH.DeepClone(this.state.selectedDataViewsForDashboard);
        if (LDH.IsObjectNull(list)) list = [];

        list.push({
            dataViewName: "none",
            index: list.length,
            item: null,
            dataViewId: null,
            newDataView: true,
            dashboardItemId: LDH.GenerateGuid()
        });

        this.setState({
            ...this.state,
            selectedDataViewsForDashboard: list
        });
    };

    workspaceDataViewRemoveOnClick = (data) => {
        let that = this;
        if (this.state.selectedDataViewsForDashboard.length === 1) {
            LRH.ShowToast("You cannot delete this Data View. Please make sure you have at least one " +
                "Data View being added to the Workspace.", "error", 5000);
            return;
        }

        let dashboards = LDH.DeepClone(this.state.selectedDataViewsForDashboard);
        if (LDH.IsObjectNull(dashboards)) dashboards = [];

        let removeIndex = -1;
        for (let i = 0; i < dashboards.length; i++) {
            if (data.item.dashboardItemId === dashboards[i].dashboardItemId) {
                removeIndex = i;
                dashboards.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < dashboards.length; i++) {
            dashboards[i].i = i.toString();
        }

        if (removeIndex > -1) {
            that.setState({
                selectedDataViewsForDashboard: dashboards,
                dashboardEditManageRelationship: [],
                isResetDashboardRelationshipsOnSave: true,
                hasDataViewDeletedInWorkspace: true
            });
        }
    };

    customDataSourceNameInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].name = data.value;
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    customDataSourceQueryInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].query = data.value;
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    customDataSourceParentQueryInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i]["parentQuery" + data.parentIndex] = data.value;
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    customDataSourcePostProcessInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].postDataQuery = data.value;
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    customDataSourceQueryShapingInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].queryShaping = data.value;
                break;
            }
        }
        this.setState({reportCustomDataSources: list});
    };

    customScheduleRecipientsInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportCustomSchedules);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].recipients = data.value;
                break;
            }
        }
        this.setState({reportCustomSchedules: list});
    };

    scheduledReportCustomDataSourceNameInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].name = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportCustomDataSourceQueryInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].query = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportCustomDataSourceParentQueryInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i]["parentQuery" + data.parentIndex] = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportCustomDataSourceLambdaConfigInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i]["parentLambdaConfig" + data.parentIndex] = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportCustomDataSourceQueryShapingInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].queryShaping = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportCustomDataSourcePostProcessInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].postDataQuery = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    scheduledReportLambdaRequestConfigInputValueOnChange = (data) => {
        let list = LDH.DeepClone(this.state.reportScheduledCustomDataSources);
        if (LDH.IsObjectNull(list)) list = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.id) {
                list[i].lambdaRequestConfig = data.value;
                break;
            }
        }
        this.setState({reportScheduledCustomDataSources: list});
    };

    selectDataViewValueOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        let addNewEntry = true;

        for (let i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].dashboardItemId === data.item.dashboardItemId) {
                clonedDataViewList[i].dataViewName = "none";
                clonedDataViewList[i].dataViewId = null;
                clonedDataViewList[i].item = null;

                for (let j = 0; j < this.dataViewMenuItems.length; j++) {
                    if (this.dataViewMenuItems[j].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.dataViewMenuItems[j];
                        clonedDataViewList[i].dataViewId = this.dataViewMenuItems[j].dataViewId;
                        clonedDataViewList[i].dataViewName = this.dataViewMenuItems[j].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.dataViewMenuItems[j].dataViewPersistentId;
                        break;
                    }
                }

                for (let v = 0; v < this.systemViewMenuItems.length; v++) {
                    if (this.systemViewMenuItems[v].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.systemViewMenuItems[v];
                        clonedDataViewList[i].dataViewId = this.systemViewMenuItems[v].dataViewId;
                        clonedDataViewList[i].dataViewName = this.systemViewMenuItems[v].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.systemViewMenuItems[v].dataViewPersistentId;
                        break;
                    }
                }

                for (let x = 0; x < this.defaultViewMenuItems.length; x++) {
                    if (this.defaultViewMenuItems[x].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.defaultViewMenuItems[x];
                        clonedDataViewList[i].dataViewId = this.defaultViewMenuItems[x].dataViewId;
                        clonedDataViewList[i].dataViewName = this.defaultViewMenuItems[x].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.defaultViewMenuItems[x].dataViewPersistentId;
                        break;
                    }
                }

                for (let w = 0; w < this.reportMenuItems.length; w++) {
                    if (this.reportMenuItems[w].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.reportMenuItems[w];
                        clonedDataViewList[i].dataViewId = this.reportMenuItems[w].dataViewId;
                        clonedDataViewList[i].dataViewName = this.reportMenuItems[w].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.reportMenuItems[w].dataViewPersistentId;
                        break;
                    }
                }

                for (let w = 0; w < this.analyticsDashboardMenuItems.length; w++) {
                    if (this.analyticsDashboardMenuItems[w].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.analyticsDashboardMenuItems[w];
                        clonedDataViewList[i].dataViewId = this.analyticsDashboardMenuItems[w].dataViewId;
                        clonedDataViewList[i].dataViewName = this.analyticsDashboardMenuItems[w].menuItemName;
                        clonedDataViewList[i].dataViewPersistentId = this.analyticsDashboardMenuItems[w].dataViewPersistentId;
                        break;
                    }
                }
            }
            if (clonedDataViewList[i].dataViewName === "none") {
                addNewEntry = false;
            }
        }

        if (addNewEntry === false || this.state.showMenuOptionPanel.option === "edit_template") {
            if (!LDH.IsObjectNull(data.item.newDataView) && data.item.newDataView === true) {
                this.setState({
                    ...this.state,
                    selectedDataViewsForDashboard: clonedDataViewList
                });
            } else {
                this.setState({
                    ...this.state,
                    selectedDataViewsForDashboard: clonedDataViewList,
                    isResetDashboardRelationshipsOnSave: true
                });
            }
        } else {
            clonedDataViewList.push({
                dataViewName: "none",
                index: clonedDataViewList.length,
                item: null,
                dataViewId: null,
                dashboardItemId: LDH.GenerateGuid()
            });
            this.setState({
                ...this.state,
                selectedDataViewsForDashboard: clonedDataViewList,
                isResetDashboardRelationshipsOnSave: true
            });
        }
    };

    createDataViewSelectDataViewTypeOnValueChanged = (e) => {
        let that = this;
        let showChartTypeOption = false;
        if (e.value !== null && e.value !== "" && e.value === "chart") {
            showChartTypeOption = true;
        }

        let list = [];
        if (that.state.showMenuOptionPanel.dataViewCategory !== "default-view") {
            for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                let view = that.state.availableDataViewListForEdit[i];
                if (view.type === e.value && view.category === "default-view") {
                    list.push(that.state.availableDataViewListForEdit[i]);
                }
            }
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: showChartTypeOption,
                availableDataSourceList: list
            });
        } else {
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: showChartTypeOption
            });
        }

        // ---------- Re-initialize DevExtreme validation -------------
        let random = LDH.GenerateGuid();
        this.uiObjectInstance.validationInput.option("value", random);
        // ------------------------------------------------------------
    };

    createDataViewSelectChartTypeOnValueChanged = (e) => {
        this.setState({createDataViewChartType: e.value});
    };

    renderingMethodOnValueChanged = (e) => {
        if (e.value === "browser") {
            this.setState({renderingMethod: e.value, notificationEnabled: true, switchDisabledForNotification: true});
        } else {
            this.setState({renderingMethod: e.value, switchDisabledForNotification: false});
        }
    };

    notificationEnabledOnValueChanged = (e) => {
        this.setState({notificationEnabled: e.value});
    };

    createDataViewValueFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartValueField: e.value});
    };

    createDataViewChartDateRangeFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartDateRangeField: e.value});
    };

    selectDateRangeForCreateChartOnValueChanged = (e) => {
        this.setState({selectDateRangeForCreateChart: e.value});
    };

    httpMethodForCreateChartOnValueChanged = (e) => {
        let show = e.value === "post";
        this.setState({
            httpMethodForCreateChart: e.value,
            showHttpDataPostForChart: show
        });
    };

    createDataViewArgumentFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartArgumentField: e.value});
    };

    dataViewMenuCategorySelectOnValueChanged = (e) => {
        this.setState({dataViewMenuCategorySelect: e.value});
    };

    createDataViewSelectMapProviderOnValueChanged = (e) => {
        this.setState({createDataViewSelectMapProvider: e.value});
    }

    createDataViewDataSourceVersionOnValueChanged = (e) => {
        this.setState({createDataViewDataSourceVersion: e.value});
    }

    createDataViewDataSourceUrlOnValueChanged = (e) => {
        let that = this;
        let selectedDataSource = null;

        for (let i = 0; i < this.state.availableDataSourceList.length; i++) {
            if (this.state.availableDataSourceList[i].id === e.value) {
                let source = this.state.availableDataSourceList[i];
                selectedDataSource = source;
                this.setState({selectedDataSourceObject: source});
            }
        }

        if (!LDH.IsObjectNull(selectedDataSource) && selectedDataSource.category === "data-table") {
            this.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true});
            let tableName = e.value;

            LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, [], function (data) {
                let dataSourceIds = [];
                for (let i = 0; i < data.fullColumns.length; i++) {
                    dataSourceIds.push({id: data.fullColumns[i], name: data.fullColumns[i]});
                }
                that.setState({
                    availableColumnNamesFromDataSourceWithoutBlank: dataSourceIds,
                    showLoadingProgress: false,
                    dataSourceCustomQueryInput: "",
                    dataSourceCustomQueryTableSchemaInput: ""
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your data source. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            }, null, null);
        } else {
            that.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true}, function () {
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

                LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            });
        }
    };

    createDataViewNameOnValueChanged = (e) => {
        this.setState({createDataViewName: e.value});
    };

    dataViewCreateSubFolderNameOnValueChanged = (e) => {
        this.newSubFolderName = e.value;
    };

    dataViewRenameCurrentFolderNameOnValueChanged = (e) => {
        this.currentFolderName = e.value;
    };

    oDataParametersForNormalReportOnValueChanged = (e) => {
        this.setState({oDataParametersForNormalReport: e.value});
    };

    oDataParametersParent1ForNormalReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent1ForNormalReport: e.value});
    };

    oDataParametersParent2ForNormalReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent2ForNormalReport: e.value});
    };

    oDataParametersParent3ForNormalReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent3ForNormalReport: e.value});
    };

    dataViewCustomCommentsOnValueChanged = (e) => {
        this.setState({dataViewCustomComments: e.value});
    };

    clientSideQueryForReportOnValueChanged = (e) => {
        this.setState({clientSideQueryForReport: e.value});
    };

    scriptShapingForReportOnValueChanged = (e) => {
        this.setState({scriptShapingForReport: e.value});
    };

    clientSideUILogicForReportOnValueChanged = (e) => {
        this.setState({clientSideUILogicForReport: e.value});
    };

    filterValidationsForReportOnValueChanged = (e) => {
        this.setState({filterValidationsForReport: e.value});
    };

    reportDataViewVersionOnValueChanged = (e) => {
        this.setState({reportDataViewVersion: e.value});
    };

    inputValuesInitLogicOnValueChanged = (e) => {
        this.setState({inputValuesInitLogic: e.value});
    };

    dataSourceCustomQueryInputOnValueChanged = (e) => {
        this.setState({dataSourceCustomQueryInput: e.value});
    };

    dataSourceOverrideMetaInputOnValueChanged = (e) => {
        this.setState({dataSourceOverrideMetaInput: e.value});
    };

    timelineResourceDefinitionInputOnValueChanged = (e) => {
        this.setState({timelineResourceDefinitionInput: e.value});
    };

    timelineOdataQueryForGroupingInputOnValueChanged = (e) => {
        this.setState({timelineOdataQueryForGroupingInput: e.value});
    };

    timelinePostDataProcessingForGroupingInputOnValueChanged = (e) => {
        this.setState({timelinePostDataProcessingForGroupingInput: e.value});
    };

    timelineOdataQueryForResourcesInputOnValueChanged = (e) => {
        this.setState({timelineOdataQueryForResourcesInput: e.value});
    };

    timelinePostDataProcessingForResourcesInputOnValueChanged = (e) => {
        this.setState({timelinePostDataProcessingForResourcesInput: e.value});
    };

    dataSourceCustomQueryTableSchemaInputOnValueChanged = (e) => {
        this.setState({dataSourceCustomQueryTableSchemaInput: e.value});
    };

    quicksightDashboardIdInputOnValueChanged = (e) => {
        this.setState({quicksightDashboardIdInput: e.value});
    };

    dataSourceRequestTypeInputOnValueChanged = (e) => {
        this.setState({dataSourceRequestType: e.value});
    };

    timelineDataKeyForResourcesInputOnValueChanged = (e) => {
        this.setState({timelineDataKeyForResourcesInput: e.value});
    };

    gridViewEngineInputOnValueChanged = (e) => {
        this.setState({gridViewEngine: e.value});
    };

    jsonDataSourceTypeInputOnValueChanged = (e) => {
        this.setState({jsonDataSourceType: e.value});
    };

    dataViewNamePrefixForImportOnValueChanged = (e) => {
        this.setState({dataViewNamePrefixForImport: e.value});
    };

    dataViewUseExistingIdsValueOnChange = (e) => {
        this.setState({dataViewUseExistingIds: e.value});
    };

    oDataParametersForScheduledReportOnValueChanged = (e) => {
        this.setState({oDataParametersForScheduledReport: e.value});
    };

    oDataParentQuery1ForScheduledReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent1ForScheduledReport: e.value});
    };

    fileNamePrefixForScheduledReportOnValueChanged = (e) => {
        this.setState({fileNamePrefixForScheduledReport: e.value});
    };

    oDataParentQuery2ForScheduledReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent2ForScheduledReport: e.value});
    };

    oDataParentQuery3ForScheduledReportOnValueChanged = (e) => {
        this.setState({oDataParametersParent3ForScheduledReport: e.value});
    };

    lambdaRequestConfigForScheduledReportOnValueChanged = (e) => {
        this.setState({scheduledReportLambdaRequestConfig: e.value});
    };

    lambdaRequestConfigForScheduledReportParentQuery1OnValueChanged = (e) => {
        this.setState({scheduledReportLambdaRequestConfigForParentQuery1: e.value});
    };

    lambdaRequestConfigForScheduledReportParentQuery2OnValueChanged = (e) => {
        this.setState({scheduledReportLambdaRequestConfigForParentQuery2: e.value});
    };

    lambdaRequestConfigForScheduledReportParentQuery3OnValueChanged = (e) => {
        this.setState({scheduledReportLambdaRequestConfigForParentQuery3: e.value});
    };

    scheduledReportFileFormatOnValueChanged = (e) => {
        this.setState({scheduledReportFileFormat: e.value});
    };

    scheduledReportExportExcelBandFilterOnValueChanged = (e) => {
        this.setState({scheduledReportExportExcelBandFilter: e.value});
    };

    recipientsForReportInputOnValueChanged = (e) => {
        this.setState({recipientsForReport: e.value});
    };

    timezoneForReportSelectOnValueChanged = (e) => {
        this.setState({timezoneForReport: e.value});
    };

    timezoneNameForReportSelectOnValueChanged = (e) => {
        this.setState({timezoneNameForReport: e.value});
    };

    documentEditorDataFormatOnValueChanged = (e) => {
        this.setState({documentEditorDataFormat: e.value});
    };

    documentEditorAllowStandaloneAccessOnValueChanged = (e) => {
        this.setState({documentEditorAllowStandaloneAccess: e.value});
    };

    photoGalleryDefaultTabOnValueChanged = (e) => {
        this.setState({photoGalleryDefaultTab: e.value});
    };

    photoGalleryDisabledTabsOnValueChanged = (e) => {
        this.setState({photoGalleryDisabledTabs: e.value});
    };

    autoManualModeOnValueChanged = (e) => {
        this.setState({autoManualMode: e.value});
    };

    hideMenuItemOnValueChanged = (e) => {
        this.setState({hideMenuItem: e.value});
    };

    daylightSavingForReportInputOnValueChanged = (e) => {
        this.setState({daylightSavingForReport: e.value});
    };

    cronExpressionForReportOnValueChanged = () => {
        let part1 = this.uiObjectInstance.cronExpressionForReportPart1Input.option("value");
        let part2 = this.uiObjectInstance.cronExpressionForReportPart2Input.option("value");
        let part3 = this.uiObjectInstance.cronExpressionForReportPart3Input.option("value");
        let part4 = this.uiObjectInstance.cronExpressionForReportPart4Input.option("value");
        let part5 = this.uiObjectInstance.cronExpressionForReportPart5Input.option("value");
        let part6 = this.uiObjectInstance.cronExpressionForReportPart6Input.option("value");
        let value = part1 + " " + part2 + " " + part3 + " " + part4 + " " + part5 + " " + part6;

        this.setState({
            cronExpressionForReport: value,
            cronExpressionForReportPart1: part1,
            cronExpressionForReportPart2: part2,
            cronExpressionForReportPart3: part3,
            cronExpressionForReportPart4: part4,
            cronExpressionForReportPart5: part5,
            cronExpressionForReportPart6: part6
        });
    };

    customScheduleCronExpressionOnValueChanged = (data) => {
        let part1 = this.uiObjectInstance["customScheduleCronPart1Input_" + data.index].option("value");
        let part2 = this.uiObjectInstance["customScheduleCronPart2Input_" + data.index].option("value");
        let part3 = this.uiObjectInstance["customScheduleCronPart3Input_" + data.index].option("value");
        let part4 = this.uiObjectInstance["customScheduleCronPart4Input_" + data.index].option("value");
        let part5 = this.uiObjectInstance["customScheduleCronPart5Input_" + data.index].option("value");
        let part6 = this.uiObjectInstance["customScheduleCronPart6Input_" + data.index].option("value");
        let value = part1 + " " + part2 + " " + part3 + " " + part4 + " " + part5 + " " + part6;

        let list = this.state.reportCustomSchedules;
        if (LDH.IsObjectNull(list)) {
            list = [];
        } else {
            list = LDH.DeepClone(this.state.reportCustomSchedules);
        }
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === data.item.id) {
                list[i].cronExpPart1 = part1;
                list[i].cronExpPart2 = part2;
                list[i].cronExpPart3 = part3;
                list[i].cronExpPart4 = part4;
                list[i].cronExpPart5 = part5;
                list[i].cronExpPart6 = part6;
                list[i].cronExpValue = value;
                break;
            }
        }
        this.setState({reportCustomSchedules: list});
    };

    heightOfThumbnailOnValueChanged = (e) => {
        this.setState({heightOfThumbnailForPhotoGallery: e.value});
    };

    widthOfThumbnailOnValueChanged = (e) => {
        this.setState({widthOfThumbnailForPhotoGallery: e.value});
    };

    parentDataSourceIdOnValueChanged = (e) => {
        this.setState({parentDataSourceIdForPhotoGallery: e.value});
    };

    photoGalleryReportPayloadSourceIdOnValueChanged = (e) => {
        this.setState({photoGalleryReportPayloadSourceId: e.value});
    };

    photoGalleryReportDataSourceIdOnValueChanged = (e) => {
        this.setState({photoGalleryReportDataSourceId: e.value});
    };

    notepadButtonTextOnValueChanged = (e) => {
        this.setState({notepadButtonText: e.value});
    };

    notepadDataSubmissionLogicOnValueChanged = (e) => {
        this.setState({notepadDataSubmissionLogic: e.value});
    };

    documentEditorUISchemaOnValueChanged = (e) => {
        this.setState({documentEditorUISchema: e.value});
    };

    documentEditorDataSchemaOnValueChanged = (e) => {
        this.setState({documentEditorDataSchema: e.value});
    };

    topLevelFilteringFieldValueChanged = (e) => {
        this.setState({topLevelFilteringField: e.value});
    };

    customQueryAttributesOnValueChanged = (e) => {
        this.setState({customQueryAttributesForChart: e.value});
    };

    httpDataPostForCreateChartOnValueChanged = (e) => {
        this.setState({httpDataPostForCreateChart: e.value});
    };

    numberOfSeriesForChartOnValueChanged = (e) => {
        this.numberOfSeriesForChartText = e.value;
    };

    customComputedColumnsOnValueChanged = (e) => {
        this.customComputedColumnsText = e.value;
    };

    createDashboardNameInputOnValueChanged = (e) => {
        this.setState({createDashboardName: e.value});
    };

    validationInputOnValueChanged = (e) => {
        this.setState({validationInput: e.value});
    };

    editDataViewNameOnValueChanged = (e) => {
        this.setState({editDataViewName: e.value});
    };

    confirmationMessageTextOnValueChanged = (e) => {
        this.setState({confirmationMessageText: e.value});
    };

    clientSideQueryEditorOnValueChanged = (e) => {
        this.setState({clientSideQueryEditor: e.value});
    };

    dataViewPersistentIdOnValueChanged = (e) => {
        this.setState({dataViewPersistentId: e.value});
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    disableScheduledReportOptionOnValueChanged = (e) => {
        this.setState({disableScheduledReportOption: !e.value});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;

        if (data.input === "dxMenuTreeView") {
            this.props.setLeftMenuTreeViewInstance(data.e);
        }
    };

    numberOfSeriesOnChartTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    importDataViewSelectOnValueChanged = (data) => {
        let clonedNumberOfDataViews = LDH.DeepClone(this.state.numberOfDataViewsToImport);
        let dataViewList = clonedNumberOfDataViews[1].items;
        for (let i = 0; i < dataViewList.length; i++) {
            if (dataViewList[i].dataViewId === data.item.dataViewId) {
                dataViewList[i].dataViewCategoryToImport = data.e.value;
                break;
            }
        }

        let clonedDataViewImportList = LDH.DeepClone(this.state.dataViewListToImport);
        for (let j = 0; j < clonedDataViewImportList.length; j++) {
            let jsonData = JSON.parse(clonedDataViewImportList[j]);
            if (LDH.IsObjectNull(jsonData) || LDH.IsObjectNull(jsonData.dataViewId) ||
                LDH.IsValueEmpty(jsonData.dataViewId)) {
                continue;
            }
            if (jsonData.dataViewId === data.item.dataViewId) {
                jsonData["dataViewCategoryToImport"] = data.e.value;
                clonedDataViewImportList[j] = JSON.stringify(jsonData);
                break;
            }
        }

        this.setState({
            numberOfDataViewsToImport: clonedNumberOfDataViews,
            dataViewListToImport: clonedDataViewImportList
        });
    };

    numberOfDataViewsToImportTemplate = (data) => {
        if (!LDH.IsObjectNull(data.item.dashboardName)) {
            return (<div>{data.item.dashboardName}</div>);
        } else {
            let linkedWorkspaces = !LDH.IsObjectNull(data.item.linkedWorkspaces)
                ? data.item.linkedWorkspaces : "";

            if (!LDH.IsObjectNull(data.item.dataViewBelongToWorkspace) &&
                !LDH.IsObjectNull(data.item.linkedWorkspaces) &&
                !LDH.IsValueEmpty(data.item.linkedWorkspaces) &&
                data.item.dataViewBelongToWorkspace && data.option === "import_dataview_from_workspace") {
                if (LDH.IsObjectNull(data.item.dataViewCategoryToImport)) {
                    data.item["dataViewCategoryToImport"] = "default-view";
                }
                return (
                    <React.Fragment>
                        <div style={{display: "flex"}}>
                            <div className={"data-view-to-import dataview-belong-to-workspace leopard-import-list-item"}
                                 linkedworkspaces={linkedWorkspaces} title={data.item.dataViewName}>
                                {data.item.dataViewName}
                            </div>
                            <div style={{marginTop: "auto", marginBottom: "auto"}}>
                                <SelectBox displayExpr={'name'} valueExpr={'id'} width={"110px"}
                                           value={data.item.dataViewCategoryToImport}
                                           dataSource={LeopardDropdownHelper.DropdownSelectionDataViewMenuCategory}
                                           disabled={this.state.showLoadingProgress}
                                           onValueChanged={
                                               (e) => this.importDataViewSelectOnValueChanged({
                                                   e: e, item: data.item
                                               })
                                           }>
                                </SelectBox>
                            </div>
                        </div>
                    </React.Fragment>);
            }
            return (
                <React.Fragment>
                    <div className={"data-view-to-import"} linkedworkspaces={""}>
                        {data.item.dataViewName}
                    </div>
                </React.Fragment>);
        }
    };

    hideOrShowDeleteButtonsForImportList = (data) => {
        setTimeout(function () {
            let $container = $(".leopard-listcontainer");
            $(".data-view-to-import", $container).each(function () {
                if ($(this).hasClass("dataview-belong-to-workspace") &&
                    $(this).attr("linkedworkspaces") !== "") {
                    let $parent = $(this).closest(".dx-list-item");
                    $(".dx-list-static-delete-button-container", $parent).hide();
                } else {
                    if (data.option === "import_dataview_from_workspace") {
                        $(this).closest(".dx-list-item").hide();
                    } else {
                        let $parent = $(this).closest(".dx-list-item");
                        $(".dx-list-static-delete-button-container", $parent).show();
                    }
                }
            });
        }, 100);
    };

    importListOnContentReady = (data) => {
        this.hideOrShowDeleteButtonsForImportList(data);
    };

    numberOfDataViewsToImportOnDeleted = (data) => {
        let that = this;
        let dataList = LDH.DeepClone(this.state.dataViewListToImport);
        for (let i = 0; i < dataList.length; i++) {
            let dataToDelete = JSON.parse(dataList[i]);
            if (LDH.IsObjectNull(dataToDelete)) continue;

            if (!LDH.IsObjectNull(data.e.itemData.dataViewId) &&
                !LDH.IsObjectNull(dataToDelete.dataViewId) &&
                data.e.itemData.dataViewId === dataToDelete.dataViewId) {
                dataList[i] = null;
                break;
            }

            if (!LDH.IsObjectNull(data.e.itemData.dashboardId) &&
                !LDH.IsObjectNull(dataToDelete.dashboardId) &&
                data.e.itemData.dashboardId === dataToDelete.dashboardId) {

                for (let j = 0; j < dataList.length; j++) {
                    if (LDH.IsObjectNull(dataList[j])) {
                        continue;
                    }
                    let parsedData = JSON.parse(dataList[j]);
                    if (!LDH.IsObjectNull(parsedData["linkedWorkspaces"]) &&
                        !LDH.IsValueEmpty(parsedData["linkedWorkspaces"])) {
                        parsedData["linkedWorkspaces"] = LDH.ReplaceAll(
                            parsedData["linkedWorkspaces"],
                            data.e.itemData.dashboardId + ";", ""
                        );

                        let $container = $(".leopard-listcontainer");
                        $(".data-view-to-import", $container).each(function () {
                            if ($(this).hasClass("dataview-belong-to-workspace") &&
                                $(this).attr("linkedworkspaces") !== "") {
                                let linkdata = $(this).attr("linkedworkspaces");
                                linkdata = LDH.ReplaceAll(linkdata,
                                    data.e.itemData.dashboardId + ";", "");
                                $(this).attr("linkedworkspaces", linkdata);
                            }
                        });
                    }
                    if (LDH.IsValueEmpty(parsedData["linkedWorkspaces"]) &&
                        !LDH.IsObjectNull(parsedData.dataViewId) &&
                        !LDH.IsValueEmpty(parsedData.dataViewId)) {
                        dataList[j] = null;
                    } else {
                        dataList[j] = JSON.stringify(parsedData);
                    }
                }
                dataList[i] = null;
                break;
            }
        }
        let visible = this.state.numberOfDataViewsToImportVisible;
        let foundData = false;
        for (let i = 0; i < dataList.length; i++) {
            if (!LDH.IsValueEmpty(dataList[i])) {
                foundData = true;
            }
        }
        if (foundData === false) visible = false;
        let listToUpdate = LDH.DeepClone(this.state.numberOfDataViewsToImport);
        if (data.e.itemData.contentPageType === "dashboard-view") {
            for (let x = 0; x < listToUpdate[0].items.length; x++) {
                if (listToUpdate[0].items[x].dashboardId ===
                    data.e.itemData.dashboardId) {
                    listToUpdate[0].items.splice(x, 1);
                    x--;
                }
            }
            for (let c = 0; c < listToUpdate[1].items.length; c++) {
                let foundItem = false;
                let dataViewIdToDelete = listToUpdate[1].items[c].dataViewId;
                for (let b = 0; b < dataList.length; b++) {
                    let dataJson = JSON.parse(dataList[b]);
                    if (LDH.IsObjectNull(dataJson)) {
                        continue;
                    }
                    if (LDH.IsObjectNull(dataJson.dataViewId) ||
                        LDH.IsValueEmpty(dataJson.dataViewId)) {
                        continue;
                    }
                    if (dataJson.dataViewId === dataViewIdToDelete) {
                        foundItem = true;
                        break;
                    }
                }
                if (foundItem === false) {
                    listToUpdate[1].items.splice(c, 1);
                    c--;
                }
            }
        } else {
            for (let c = 0; c < listToUpdate[1].items.length; c++) {
                if (listToUpdate[1].items[c].dataViewId ===
                    data.e.itemData.dataViewId) {
                    listToUpdate[1].items.splice(c, 1);
                    c--;
                }
            }
        }

        this.setState({
            dataViewListToImport: dataList,
            numberOfDataViewsToImportVisible: visible,
            numberOfDataViewsToImport: listToUpdate
        }, function () {
            that.hideOrShowDeleteButtonsForImportList(data);
        });
    };

    customComputedColumnsTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    // ==================== Data Views =======================

    cloneDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            (LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName) &&
                LDH.IsValueEmpty(this.state.dataViewJSONToExport.dashboardName))) {
            LRH.ShowToast("Please select at least one Data View to export.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
                let dataToImport = that.state.dataViewJSONToExport;
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                if (dataToImport.contentPageType === "dashboard-view") {
                    let relationships = dataToImport.relationships;
                    if (LDH.IsObjectNull(relationships)) relationships = [];

                    dataToImport.dashboardId = LDH.GenerateGuid();
                    LeopardAjaxHelper.CreateDashboard(organizationId, that.state.createDataViewName, dataToImport.dashboardNote,
                        dataToImport.dashboardId, dataToImport.dashboardLayout, relationships, function () {
                            // Then create menu item for that workspace.
                            LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                                that.state.createDataViewName, dataToImport.dashboardId, function () {
                                    LRH.ShowToast("Your Workspace has been successfully cloned.", "success", 5000);
                                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                        that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                        that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                    that.loadLeftMenuItems(false, null, false);
                                    that.setState({showLoadingProgress: false});
                                }, function () {
                                    LRH.ShowToast("Failed to import your Workspace. " +
                                        "Please check your network connection status and try again.", "error", 5000);
                                    that.setState({showLoadingProgress: false});
                                }, that.currentFolderObject.menuItemId, false);
                        });
                } else {
                    let dataViewPersistentId = LDH.GenerateGuid();
                    if (!LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition)) {
                        dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition)) {
                        dataToImport.dataViewNote.photoDefinition.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition)) {
                        dataToImport.dataViewNote.chartDefinition.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition)) {
                        dataToImport.dataViewNote.reportDefinition.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.dashboardName)) {
                        dataToImport.dataViewNote.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.timelineDefinition)) {
                        dataToImport.dataViewNote.timelineDefinition.dataViewPersistentId = dataViewPersistentId;
                    } else if (!LDH.IsObjectNull(dataToImport.dataViewNote.tabbedViewDefinition)) {
                        dataToImport.dataViewNote.tabbedViewDefinition.dataViewPersistentId = dataViewPersistentId;
                    }

                    dataToImport.dataViewId = LDH.GenerateGuid();
                    LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                        dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
                        dataToImport.dataViewId, dataViewPersistentId, function (appConfig) {
                            // Then create menu item for that data view.
                            LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId,
                                LDH.GenerateGuid(), that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                                dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl, dataToImport.dataViewId,
                                dataViewPersistentId, function () {
                                    LRH.ShowToast("Your Data View has been successfully cloned.", "success", 5000);
                                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                        that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                        that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                    that.loadLeftMenuItems(false, null, false);
                                    that.setState({showLoadingProgress: false});
                                }, function () {
                                    LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                        "Please check your network connection status and try again.", "error", 5000);
                                    LeopardAjaxHelper.DeleteDataView(organizationId, dataToImport.dataViewId, function () {

                                    });
                                    that.setState({showLoadingProgress: false});
                                }, that.currentFolderObject.menuItemId, false);
                        }, function () {
                            LRH.ShowToast("Failed to clone your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                        });
                }
            }
        );
    };

    exportIndividualDataViewProcess = (data) => {
        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            this.state.dataViewJSONToExport.length === 0) {
            LRH.ShowToast("Please select at least one Data View to export.", "error", 5000);
            return;
        }
        let dataViewJSONToExportList = [];
        for (let i = 0; i < this.state.dataViewJSONToExport.length; i++) {
            let data = this.state.dataViewJSONToExport[i].data;
            dataViewJSONToExportList.push(data);
        }

        let finalData = {
            data: dataViewJSONToExportList,
            origin: data.option,
            category: data.category
        };
        LRH.DownloadStringToFile("DataViewExport.json", JSON.stringify(finalData));
        if (!LPH.IsLeopardAdminModeEnabled()) {
            this.createDataViewCancelButtonOnClickWithoutConfirm();
        }
        LRH.ShowToast("Your data has been successfully exported.", "success", 5000);
    };

    exportDataViewApplyButtonOnClick = (data) => {
        data.e.preventDefault();
        this.exportIndividualDataViewProcess(data);
    };

    importDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(that.state.dataViewListToImport) ||
            that.state.dataViewListToImport.length === 0) {
            LRH.ShowToast("There is no Data View or Workspace to import.", "error", 5000);
            return;
        }

        let foundDataView = false;
        for (let i = 0; i < that.state.dataViewListToImport.length; i++) {
            if (!LDH.IsValueEmpty(that.state.dataViewListToImport[i])) {
                foundDataView = true;
                break;
            }
        }
        if (foundDataView === false) {
            LRH.ShowToast("There is no Data View or Workspace to import.", "error", 5000);
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            try {
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let dataViewsToImport = [];
                let workspacesToImport = [];
                that.batchProcessCompletedForWorkspaces = true;
                that.batchProcessCompletedForDataViews = true;

                for (let i = 0; i < that.state.dataViewListToImport.length; i++) {
                    let dataToImport = JSON.parse(that.state.dataViewListToImport[i]);
                    if (LDH.IsObjectNull(dataToImport)) continue;

                    if (dataToImport.contentPageType === "dashboard-view") {
                        if (LDH.IsValueEmpty(dataToImport.dashboardName) || LDH.IsValueEmpty(dataToImport.dashboardId)) {
                            LRH.ShowToast("Unable to import Data View. The data format you specified is invalid.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                            return;
                        }
                        workspacesToImport.push(that.state.dataViewListToImport[i]);
                    } else {
                        if (LDH.IsValueEmpty(dataToImport.dataViewName) || LDH.IsValueEmpty(dataToImport.dataViewType) ||
                            LDH.IsValueEmpty(dataToImport.dataSourceUrl) || LDH.IsValueEmpty(dataToImport.dataViewId)) {
                            LRH.ShowToast("Unable to import Data View. The data format you specified is invalid.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                            return;
                        }
                        dataViewsToImport.push(that.state.dataViewListToImport[i]);
                    }
                }

                if (dataViewsToImport.length > 0) that.batchProcessCompletedForDataViews = false;
                if (workspacesToImport.length > 0) that.batchProcessCompletedForWorkspaces = false;

                if (!that.batchProcessCompletedForDataViews || !that.batchProcessCompletedForWorkspaces) {
                    if (!that.batchProcessCompletedForDataViews) {
                        that.recurrsiveDataViewImportProcess(userId, organizationId, 0, dataViewsToImport, true, function () {
                            if (!that.batchProcessCompletedForWorkspaces) {
                                that.recurrsiveWorkspaceImportProcess(userId, organizationId, 0,
                                    workspacesToImport, false, null);
                            }
                        });
                    } else {
                        if (!that.batchProcessCompletedForWorkspaces) {
                            that.recurrsiveWorkspaceImportProcess(userId, organizationId, 0,
                                workspacesToImport, false, null);
                        }
                    }
                }
            } catch (error) {
                LRH.ShowToast("Unable to import Data View. Please check for the data format before proceeding.", "error", 5000);
                that.setState({showLoadingProgress: false});
            }
        });
    };

    recurrsiveDataViewImportProcess = (
        userId, organizationId, processedIndex, dataViewListToImport, checkDependency, callbackComplete) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        let dataViewUseExistingIds = true;

        if (LDH.IsObjectNull(that.state.dataViewUseExistingIds) || !that.state.dataViewUseExistingIds) {
            dataToImport.dataViewId = LDH.GenerateGuid();
            dataViewUseExistingIds = false;
        }
        let dataViewPersistentId = "";
        if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "datagrid" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.gridViewDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "timeline" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.timelineDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.timelineDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.timelineDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.timelineDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "tabbed-view" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.tabbedViewDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.tabbedViewDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.tabbedViewDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.tabbedViewDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "diagram" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.diagramDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.diagramDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.diagramDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.diagramDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "photo" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.photoDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.photoDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.photoDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "chart" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.chartDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.chartDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.chartDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "report" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.reportDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.reportDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.reportDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "aws-quicksight-dashboard" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "notepad" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.notepadDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.notepadDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.notepadDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.notepadDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "form-editor" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.formDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.formDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.formDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.formDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "html-form-builder" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.formDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.formDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.formDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.formDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "document-editor" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.documentDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.documentDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.documentDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.documentDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "map" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.mapDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.mapDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.mapDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.mapDefinition.dataViewPersistentId;
        } else if (!LDH.IsObjectNull(dataToImport.dataViewNote) && dataToImport.dataViewType === "regex-tester" &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.regexDefinition) &&
            !LDH.IsObjectNull(dataToImport.dataViewNote.regexDefinition.dataViewPersistentId) &&
            !LDH.IsValueEmpty(dataToImport.dataViewNote.regexDefinition.dataViewPersistentId)) {
            dataViewPersistentId = dataToImport.dataViewNote.regexDefinition.dataViewPersistentId;
        }
        let dataViewCategory = that.state.showMenuOptionPanel.dataViewCategory;
        if (!LDH.IsObjectNull(dataToImport.dataViewCategoryToImport) &&
            !LDH.IsValueEmpty(dataToImport.dataViewCategoryToImport)) {
            dataViewCategory = dataToImport.dataViewCategoryToImport;
        }
        LeopardAjaxHelper.CreateDataView(organizationId, dataToImport.dataViewName,
            dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
            dataToImport.dataViewId, dataViewPersistentId, function (appConfig) {

                LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                    that.state.dataViewNamePrefixForImport + dataToImport.dataViewName, dataViewCategory,
                    dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl,
                    dataToImport.dataViewId, dataViewPersistentId, function () {

                        if (dataViewListToImport.length === processedIndex + 1) {
                            that.batchProcessCompletedForDataViews = true;

                            if ((LDH.IsObjectNull(checkDependency) || checkDependency === false) || (checkDependency &&
                                that.batchProcessCompletedForWorkspaces && that.batchProcessCompletedForDataViews)) {
                                LRH.ShowToast("Your Data Views / Workspaces have been successfully imported.", "success", 5000);

                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                    that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                that.loadLeftMenuItems(false, null, false);
                                that.setState({showLoadingProgress: false}, function () {
                                    that.batchProcessCompletedForWorkspaces = true;
                                    that.batchProcessCompletedForDataViews = true;
                                });
                            }
                            if (!LDH.IsObjectNull(callbackComplete)) callbackComplete();
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveDataViewImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport, checkDependency, callbackComplete);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDataView(organizationId, dataToImport.dataViewId, function () {
                        });
                        that.setState({showLoadingProgress: false});
                    }, that.currentFolderObject.menuItemId, dataViewUseExistingIds);
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    };

    recurrsiveWorkspaceImportProcess = (
        userId, organizationId, processedIndex, dataViewListToImport, checkDependency, callbackComplete) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        let dataViewUseExistingIds = true;

        if (LDH.IsObjectNull(that.state.dataViewUseExistingIds) || !that.state.dataViewUseExistingIds) {
            dataToImport.dashboardId = LDH.GenerateGuid();
            dataViewUseExistingIds = false;
        }

        let relationships = dataToImport.relationships;
        if (LDH.IsObjectNull(relationships)) relationships = [];

        LeopardAjaxHelper.CreateDashboard(organizationId, dataToImport.dashboardName, dataToImport.dashboardNote,
            dataToImport.dashboardId, dataToImport.dashboardLayout, relationships, function () {
                // Then create menu item for that workspace.
                LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                    that.state.dataViewNamePrefixForImport + dataToImport.dashboardName, dataToImport.dashboardId, function () {
                        if (dataViewListToImport.length === processedIndex + 1) {
                            that.batchProcessCompletedForWorkspaces = true;

                            if ((LDH.IsObjectNull(checkDependency) || checkDependency === false) || (checkDependency &&
                                that.batchProcessCompletedForWorkspaces && that.batchProcessCompletedForDataViews)) {
                                LRH.ShowToast("Your Data Views / Workspaces have been successfully imported.", "success", 5000);
                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                    that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                that.loadLeftMenuItems(false, null, false);
                                that.setState({showLoadingProgress: false}, function () {
                                    that.batchProcessCompletedForWorkspaces = true;
                                    that.batchProcessCompletedForDataViews = true;
                                });
                            }
                            if (!LDH.IsObjectNull(callbackComplete)) callbackComplete();
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveWorkspaceImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport, checkDependency, callbackComplete);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDashboard(organizationId, dataToImport.dashboardId, null);
                        that.setState({showLoadingProgress: false});
                    }, that.currentFolderObject.menuItemId, dataViewUseExistingIds);
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    };

    createDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if ((this.state.createDataViewType === "chart" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
            (that.state.selectedDataSourceObject !== null && that.state.selectedDataSourceObject.category === "data-table" &&
                this.state.createDataViewType === "datagrid") ||
            (this.state.createDataViewType === "timeline" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
            (this.state.createDataViewType === "tabbed-view" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
            (that.state.gridViewEngine === "json-engine" && this.state.createDataViewType === "datagrid") ||
            (that.state.gridViewEngine === "csv-adhoc-engine" && this.state.createDataViewType === "datagrid") ||
            this.state.createDataViewType === "diagram" ||
            this.state.showMenuOptionPanel.dataViewCategory === "report" ||
            this.state.createDataViewType === "form-editor" ||
            this.state.createDataViewType === "html-form-builder" ||
            this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard" || this.state.createDataViewType === "photo" ||
            this.state.createDataViewType === "notepad" || this.state.createDataViewType === "map" ||
            this.state.createDataViewType === "regex-tester" || this.state.createDataViewType === "document-editor") {
            that.setState({showLoadingProgress: true}, function () {
                let dataViewId = LDH.GenerateGuid();
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let definition = null;
                let devExtremeChartType = null;
                let dataViewPersistentId = LDH.GenerateGuid();

                let dataSourceUrl = that.state.createDataViewDataSourceUrl;
                if (LDH.IsValueEmpty(that.state.dataSourceCustomQueryInput) === false) {
                    dataSourceUrl = that.state.dataSourceCustomQueryInput;
                }

                let dataSourceVersion = that.state.createDataViewDataSourceVersion;
                if (LDH.IsValueEmpty(that.state.selectDataSourceVersionInput) === false) {
                    dataSourceVersion = that.state.selectDataSourceVersionInput;
                }

                if (that.state.createDataViewChartType === "bar-chart") {
                    devExtremeChartType = "bar";
                }
                if (that.state.createDataViewChartType === "line-chart") {
                    devExtremeChartType = "line";
                }
                if (that.state.createDataViewChartType === "spline-chart") {
                    devExtremeChartType = "spline";
                }
                if (that.state.createDataViewChartType === "scatter-chart") {
                    devExtremeChartType = "scatter";
                }
                if (that.state.createDataViewChartType === "area-chart") {
                    devExtremeChartType = "area";
                }

                if (that.state.createDataViewType === "chart") {
                    definition = {
                        chartType: that.state.createDataViewChartType,
                        chartDefinition: {
                            ...LeopardStaticUIConfig.ChartDefaultDefinition,
                            chartTitle: that.state.createDataViewName,
                            seriesArgumentField: that.state.createDataViewChartArgumentField,
                            seriesValueField: that.state.createDataViewChartValueField,
                            dateRangeValue: that.state.selectDateRangeForCreateChart,
                            dateRangeField: that.state.createDataViewChartDateRangeField,
                            customQueryAttributes: that.state.customQueryAttributesForChart,
                            seriesTypeOfSeries: devExtremeChartType,
                            numberOfSeriesOnChart: that.state.numberOfSeriesOnChart,
                            dataViewPersistentId: dataViewPersistentId,
                            httpMethod: that.state.httpMethodForCreateChart,
                            httpDataPost: that.state.httpDataPostForCreateChart
                        }
                    };
                }

                if (that.state.createDataViewType === "datagrid") {
                    definition = {
                        gridViewDefinition: LeopardStaticUIConfig.GridViewDefaultDefinition,
                        styleDefinition: null
                    };

                    let userProfile = window.userProfile;
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                    let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

                    if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                        defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
                    }
                    definition.gridViewDefinition.filterBuilderValue = defaultFilter;
                    definition.gridViewDefinition.dataSourceCustomQueryTableSchema =
                        that.state.dataSourceCustomQueryTableSchemaInput;

                    definition.gridViewDefinition.customComputedColumns = that.state.customComputedColumns;
                    definition.gridViewDefinition.dataSourceId = that.state.createDataViewDataSourceId;
                    definition.gridViewDefinition.dataSourceURI = that.state.createDataViewDataSourceURI;
                    definition.gridViewDefinition.topLevelFilteringField = that.state.topLevelFilteringField;
                    definition.gridViewDefinition.gridViewEngine = that.state.gridViewEngine;
                    definition.gridViewDefinition.jsonDataSourceType = that.state.jsonDataSourceType;
                    definition.gridViewDefinition.dataSourceRequestType = that.state.dataSourceRequestType;
                    definition.gridViewDefinition.dataViewPersistentId = dataViewPersistentId;
                    definition.gridViewDefinition.dataSourceOverrideMeta = that.state.dataSourceOverrideMetaInput;
                }

                if (that.state.createDataViewType === "report") {
                    definition = {
                        reportLayout: [],
                        reportName: that.state.createDataViewName,
                        operation: that.state.disableScheduledReportOption ? "DELETE" : "MODIFY",
                        dataViewId: dataViewId,
                        tableName: dataSourceUrl,
                        dataSourceVersion: dataSourceVersion,
                        oDataParametersForNormalReport: that.state.oDataParametersForNormalReport,
                        oDataParametersParent1ForNormalReport: that.state.oDataParametersParent1ForNormalReport,
                        oDataParametersParent2ForNormalReport: that.state.oDataParametersParent2ForNormalReport,
                        oDataParametersParent3ForNormalReport: that.state.oDataParametersParent3ForNormalReport,
                        oDataParametersForScheduledReport: that.state.oDataParametersForScheduledReport,
                        fileNamePrefixForScheduledReport: that.state.fileNamePrefixForScheduledReport,
                        oDataParametersParent1ForScheduledReport: that.state.oDataParametersParent1ForScheduledReport,
                        oDataParametersParent2ForScheduledReport: that.state.oDataParametersParent2ForScheduledReport,
                        oDataParametersParent3ForScheduledReport: that.state.oDataParametersParent3ForScheduledReport,
                        scheduledReportLambdaRequestConfigForParentQuery1: that.state.scheduledReportLambdaRequestConfigForParentQuery1,
                        scheduledReportLambdaRequestConfigForParentQuery2: that.state.scheduledReportLambdaRequestConfigForParentQuery2,
                        scheduledReportLambdaRequestConfigForParentQuery3: that.state.scheduledReportLambdaRequestConfigForParentQuery3,
                        scheduledReportFileFormat: that.state.scheduledReportFileFormat,
                        scheduledReportExportExcelBandFilter: that.state.scheduledReportExportExcelBandFilter,
                        scheduledReportLambdaRequestConfig: that.state.scheduledReportLambdaRequestConfig,
                        timezone: that.state.timezoneForReport,
                        timezoneName: that.state.timezoneNameForReport,
                        daylightSaving: that.state.daylightSavingForReport,
                        clientSideQueryForReport: that.state.clientSideQueryForReport,
                        clientSideUILogicForReport: that.state.clientSideUILogicForReport,
                        filterValidationsForReport: that.state.filterValidationsForReport,
                        reportDataViewVersion: that.state.reportDataViewVersion,
                        inputValuesInitLogic: that.state.inputValuesInitLogic,
                        renderingMethod: that.state.renderingMethod,
                        notificationEnabled: that.state.notificationEnabled,
                        scriptShapingForReport: that.state.scriptShapingForReport,
                        reportCustomDataSources: that.state.reportCustomDataSources,
                        reportCustomSchedules: that.state.reportCustomSchedules,
                        deletedScheduleIds: that.state.deletedScheduleIds,
                        reportScheduledCustomDataSources: that.state.reportScheduledCustomDataSources,
                        cronExpression: that.state.cronExpressionForReport,
                        recipients: that.state.recipientsForReport,
                        sender: LeopardStaticUIConfig.SourceEmailAddress,
                        scheduledReportEnabled: !that.state.disableScheduledReportOption
                    };

                    if (typeof window["ReportLayout_OnPage_" + dataViewId] !== "undefined" &&
                        window["ReportLayout_OnPage_" + dataViewId] !== null) {
                        definition.reportLayout = window["ReportLayout_OnPage_" + dataViewId];
                    }
                }

                if (that.state.createDataViewType === "aws-quicksight-dashboard") {
                    definition = {
                        dashboardName: that.state.createDataViewName,
                        dataViewId: dataViewId,
                        quicksightDashboardId: that.state.quicksightDashboardIdInput,
                        quicksightDashboardRegion: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
                        dataViewPersistentId: dataViewPersistentId
                    };
                }

                if (that.state.createDataViewType === "photo") {
                    definition = {
                        photoDefinition: {
                            heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                            widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                            parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery,
                            photoGalleryDefaultTab: that.state.photoGalleryDefaultTab,
                            photoGalleryDisabledTabs: that.state.photoGalleryDisabledTabs,
                            photoGalleryReportPayloadSourceId: that.state.photoGalleryReportPayloadSourceId,
                            photoGalleryReportDataSourceId: that.state.photoGalleryReportDataSourceId,
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "timeline") {
                    definition = {
                        timelineDefinition: {
                            timelineResourceDefinition: that.state.timelineResourceDefinitionInput,
                            timelineOdataQueryForGrouping: that.state.timelineOdataQueryForGroupingInput,
                            timelinePostDataProcessingForGrouping: that.state.timelinePostDataProcessingForGroupingInput,
                            timelineOdataQueryForResources: that.state.timelineOdataQueryForResourcesInput,
                            timelinePostDataProcessingForResources: that.state.timelinePostDataProcessingForResourcesInput,
                            timelineDataKeyForResources: that.state.timelineDataKeyForResourcesInput,
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "tabbed-view") {
                    definition = {
                        tabbedViewDefinition: {
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "diagram") {
                    definition = {
                        diagramDefinition: {
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "regex-tester") {
                    definition = {
                        regexDefinition: {
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "notepad") {
                    definition = {
                        notepadDefinition: {
                            notepadButtonText: that.state.notepadButtonText,
                            notepadDataSubmissionLogic: that.state.notepadDataSubmissionLogic,
                            notepadAPIGatewayUrl: that.state.notepadAPIGatewayUrl,
                            notepadOdataQueryString: that.state.notepadOdataQueryString,
                            notepadRequestLogic: that.state.notepadRequestLogic,
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "map") {
                    definition = {
                        mapDefinition: {
                            mapProvider: that.state.createDataViewSelectMapProvider,
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "form-editor") {
                    definition = {
                        formDefinition: {
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "html-form-builder") {
                    definition = {
                        formDefinition: {
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                if (that.state.createDataViewType === "document-editor") {
                    definition = {
                        documentDefinition: {
                            documentEditorAPIGatewayGetUrl: that.state.documentEditorAPIGatewayGetUrl,
                            documentEditorAPIGatewayPostUrl: that.state.documentEditorAPIGatewayPostUrl,
                            documentEditorDataFormat: that.state.documentEditorDataFormat,
                            documentEditorAllowStandaloneAccess: that.state.documentEditorAllowStandaloneAccess,
                            documentEditorDataSchema: that.state.documentEditorDataSchema,
                            documentEditorUISchema: that.state.documentEditorUISchema,
                            documentEditorDirectory: that.state.documentEditorDirectory,
                            documentEditorFileName: that.state.documentEditorFileName,
                            documentEditorOwnerUserId: that.state.documentEditorOwnerUserId,
                            documentEditorDataReceivingLogic: that.state.documentEditorDataReceivingLogic,
                            documentEditorDataSendingLogic: that.state.documentEditorDataSendingLogic,
                            dataViewPersistentId: dataViewPersistentId
                        }
                    };
                }

                LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName, that.state.createDataViewType,
                    definition, dataSourceUrl, dataViewId, dataViewPersistentId, function (appConfig) {
                        LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                            that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                            that.state.createDataViewType, {chartType: that.state.createDataViewChartType},
                            dataSourceUrl, dataViewId, dataViewPersistentId, function () {
                                LRH.ShowToast("Your Data View has been successfully created.", "success", 5000);

                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                    that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                    that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                that.loadLeftMenuItems(false, null, false);
                                that.setState({showLoadingProgress: false});
                            }, function () {
                                LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                    "Please check your network connection status and try again.", "error", 5000);

                                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                                    that.setState({showLoadingProgress: false});
                                });
                            }, that.currentFolderObject.menuItemId, false);
                    }, function () {
                        LRH.ShowToast("Failed to create your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        that.setState({showLoadingProgress: false});
                    });
            });
        } else {
            that.cloneDataViewApplyButtonOnClick(e);
        }
    };

    createDataViewDeleteButtonOnClick = () => {
        let that = this;
        if (that.scheduledReportDisabled === false) {
            LRH.ShowToast("The data view cannot be deleted. Please disable the Scheduled Report " +
                "option and save the settings, then try again.", "error", 5000);
            return false;
        }

        LRH.ShowDialog("Are you sure you want to delete this Data View? <br/>This will impact any Workspaces that are linked to it.",
            "Confirm Delete", function () {
                that.setState({showLoadingProgress: true}, function () {
                    let dataViewId = that.state.editDataViewId;
                    let userProfile = window.userProfile;
                    let userId = LDH.GetUserIdFromUserProfile(userProfile);
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                    LeopardAjaxHelper.DeleteLeftMenuItemForDataView(userId, organizationId, dataViewId, function () {
                        LRH.ShowToast("Your Data View has been successfully deleted.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                            that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                            that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                        that.loadLeftMenuItems(true, null, false);
                        that.dataViewOrTemplateMenuItemOnClick({item: null});
                        that.setState({showLoadingProgress: false});
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to delete your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    });
                });
            });
    };

    manageDashboardRelationshipApplyOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let isValid = true;

        $(".leopard-option-panel-block.error").each(function () {
            if ($(this).is(":visible")) isValid = false;
        });
        if (isValid === false) {
            LRH.ShowToast("Failed to save your data. Please correct the errors before proceeding.", "error", 5000);
            return;
        }

        for (let i = 0; i < that.state.dashboardEditManageRelationship.length; i++) {
            let relationship = that.state.dashboardEditManageRelationship[i];
            if (LDH.IsValueEmpty(relationship.parentDataViewId) ||
                LDH.IsValueEmpty(relationship.childDataViewId)) {
                LRH.ShowToast("Failed to save your data. Please check for your configuration settings.", "error", 5000);
                return;
            }
        }

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let relationship = that.state.dashboardEditManageRelationship;
            let dashboardId = that.state.editDashboardId;

            LeopardAjaxHelper.UpdateDashboardRelationship(userId, organizationId, dashboardId, relationship, function () {
                let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                $(".dashboard-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                    that.state.editDashboardId, function (menuItem) {
                        LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                            that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                            that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                        that.loadLeftMenuItems(false, function () {
                            that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                            that.setState({showLoadingProgress: false});
                        }, true);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to update your workspace. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    }, that.state.confirmationMessageText, that.state.autoManualMode, that.state.hideMenuItem);
            }, function (error, sessionTimeout) {
                if (error === "version-out-of-date") {
                    LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings " +
                        "cannot be saved due to a newer version found on the server, please refresh your " +
                        "browser. ", "error", true);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your workspace. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    };

    editDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        this.setState({showLoadingProgress: true}, function () {
            let dataViewId = that.state.editDataViewId;
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewPersistentId = that.state.dataViewPersistentId;
            if (LDH.IsObjectNull(dataViewPersistentId) || LDH.IsValueEmpty(dataViewPersistentId)) {
                dataViewPersistentId = LDH.GenerateGuid();
            }

            let definition = null;
            if (that.state.editDataViewType === "photo") {
                definition = {
                    photoDefinition: {
                        heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                        widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                        parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery,
                        photoGalleryDefaultTab: that.state.photoGalleryDefaultTab,
                        photoGalleryDisabledTabs: that.state.photoGalleryDisabledTabs,
                        photoGalleryReportPayloadSourceId: that.state.photoGalleryReportPayloadSourceId,
                        photoGalleryReportDataSourceId: that.state.photoGalleryReportDataSourceId,
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "regex-tester") {
                definition = {
                    regexDefinition: {
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "notepad") {
                definition = {
                    notepadDefinition: {
                        notepadButtonText: that.state.notepadButtonText,
                        notepadDataSubmissionLogic: that.state.notepadDataSubmissionLogic,
                        notepadAPIGatewayUrl: that.state.notepadAPIGatewayUrl,
                        notepadOdataQueryString: that.state.notepadOdataQueryString,
                        notepadRequestLogic: that.state.notepadRequestLogic,
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "form-editor") {
                definition = {
                    formDefinition: {
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "html-form-builder") {
                definition = {
                    formDefinition: {
                        dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "document-editor") {
                definition = {
                    documentDefinition: {
                        documentEditorAPIGatewayGetUrl: that.state.documentEditorAPIGatewayGetUrl,
                        documentEditorAPIGatewayPostUrl: that.state.documentEditorAPIGatewayPostUrl,
                        documentEditorDataFormat: that.state.documentEditorDataFormat,
                        documentEditorAllowStandaloneAccess: that.state.documentEditorAllowStandaloneAccess,
                        documentEditorDataSchema: that.state.documentEditorDataSchema,
                        documentEditorUISchema: that.state.documentEditorUISchema,
                        documentEditorDirectory: that.state.documentEditorDirectory,
                        documentEditorFileName: that.state.documentEditorFileName,
                        documentEditorOwnerUserId: that.state.documentEditorOwnerUserId,
                        documentEditorDataReceivingLogic: that.state.documentEditorDataReceivingLogic,
                        documentEditorDataSendingLogic: that.state.documentEditorDataSendingLogic,
                        dataViewPersistentId: dataViewPersistentId,
                        dataViewCustomComments: that.state.dataViewCustomComments
                    }
                };
            } else if (that.state.editDataViewType === "report") {
                definition = {
                    reportName: that.state.createDataViewName,
                    operation: that.state.disableScheduledReportOption ? "DELETE" : "MODIFY",
                    tableName: that.state.createDataViewDataSourceUrl,
                    dataSourceVersion: that.state.createDataViewDataSourceVersion,
                    oDataParametersForNormalReport: that.state.oDataParametersForNormalReport,
                    oDataParametersParent1ForNormalReport: that.state.oDataParametersParent1ForNormalReport,
                    oDataParametersParent2ForNormalReport: that.state.oDataParametersParent2ForNormalReport,
                    oDataParametersParent3ForNormalReport: that.state.oDataParametersParent3ForNormalReport,
                    oDataParametersForScheduledReport: that.state.oDataParametersForScheduledReport,
                    fileNamePrefixForScheduledReport: that.state.fileNamePrefixForScheduledReport,
                    oDataParametersParent1ForScheduledReport: that.state.oDataParametersParent1ForScheduledReport,
                    oDataParametersParent2ForScheduledReport: that.state.oDataParametersParent2ForScheduledReport,
                    oDataParametersParent3ForScheduledReport: that.state.oDataParametersParent3ForScheduledReport,
                    scheduledReportLambdaRequestConfigForParentQuery1: that.state.scheduledReportLambdaRequestConfigForParentQuery1,
                    scheduledReportLambdaRequestConfigForParentQuery2: that.state.scheduledReportLambdaRequestConfigForParentQuery2,
                    scheduledReportLambdaRequestConfigForParentQuery3: that.state.scheduledReportLambdaRequestConfigForParentQuery3,
                    scheduledReportFileFormat: that.state.scheduledReportFileFormat,
                    scheduledReportExportExcelBandFilter: that.state.scheduledReportExportExcelBandFilter,
                    scheduledReportLambdaRequestConfig: that.state.scheduledReportLambdaRequestConfig,
                    timezone: that.state.timezoneForReport,
                    timezoneName: that.state.timezoneNameForReport,
                    daylightSaving: that.state.daylightSavingForReport,
                    clientSideQueryForReport: that.state.clientSideQueryForReport,
                    clientSideUILogicForReport: that.state.clientSideUILogicForReport,
                    filterValidationsForReport: that.state.filterValidationsForReport,
                    reportDataViewVersion: that.state.reportDataViewVersion,
                    inputValuesInitLogic: that.state.inputValuesInitLogic,
                    renderingMethod: that.state.renderingMethod,
                    notificationEnabled: that.state.notificationEnabled,
                    scriptShapingForReport: that.state.scriptShapingForReport,
                    reportCustomDataSources: that.state.reportCustomDataSources,
                    reportCustomSchedules: that.state.reportCustomSchedules,
                    deletedScheduleIds: that.state.deletedScheduleIds,
                    reportScheduledCustomDataSources: that.state.reportScheduledCustomDataSources,
                    cronExpression: that.state.cronExpressionForReport,
                    recipients: that.state.recipientsForReport,
                    sender: LeopardStaticUIConfig.SourceEmailAddress,
                    scheduledReportEnabled: !that.state.disableScheduledReportOption,
                    dataViewCustomComments: that.state.dataViewCustomComments
                };

                if (typeof window["ReportLayout_OnPage_" + dataViewId] !== "undefined" &&
                    window["ReportLayout_OnPage_" + dataViewId] !== null) {
                    definition.reportLayout = window["ReportLayout_OnPage_" + dataViewId];
                }
            } else if (that.state.editDataViewType === "aws-quicksight-dashboard") {
                definition = {
                    dashboardName: that.state.createDataViewName,
                    quicksightDashboardId: that.state.quicksightDashboardIdInput,
                    quicksightDashboardRegion: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
                    dataViewCustomComments: that.state.dataViewCustomComments,
                    dataViewPersistentId
                };
            } else if (that.state.editDataViewType === "chart") {
                definition = {
                    chartDefinition: {
                        clientSideQueryEditor: that.state.clientSideQueryEditor,
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "datagrid") {
                definition = {
                    gridViewDefinition: {
                        clientSideQuery: that.state.clientSideQueryEditor,
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "map") {
                definition = {
                    mapDefinition: {
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "timeline") {
                definition = {
                    timelineDefinition: {
                        timelineResourceDefinition: that.state.timelineResourceDefinitionInput,
                        timelineOdataQueryForGrouping: that.state.timelineOdataQueryForGroupingInput,
                        timelinePostDataProcessingForGrouping: that.state.timelinePostDataProcessingForGroupingInput,
                        timelineOdataQueryForResources: that.state.timelineOdataQueryForResourcesInput,
                        timelinePostDataProcessingForResources: that.state.timelinePostDataProcessingForResourcesInput,
                        timelineDataKeyForResources: that.state.timelineDataKeyForResourcesInput,
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "tabbed-view") {
                definition = {
                    tabbedViewDefinition: {
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            } else if (that.state.editDataViewType === "diagram") {
                definition = {
                    diagramDefinition: {
                        dataViewCustomComments: that.state.dataViewCustomComments,
                        dataViewPersistentId
                    }
                };
            }

            let tempAppConfigId = "temp_appconfig_" + LDH.GenerateGuid();
            let genericRandomGuid = LDH.GenerateGuid();
            window[tempAppConfigId] = [];

            LeopardAjaxHelper.UpdateDataView(userId, organizationId, that.state.editDataViewName, dataViewId,
                definition, function () {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                    $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LeopardAjaxHelper.UpdateLeftMenuItemForDataView(userId, organizationId,
                        that.state.editDataViewName, dataViewId, dataViewPersistentId, function (menuItem) {
                            if (that.state.editDataViewType === "report" && definition.dataSourceVersion === "v2-athena") {
                                let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
                                let documentModifyType = "leopardsystems.report.modify";
                                let documentCreateType = "leopardsystems.report.create";
                                let documentListType = "leopardsystems.report.list";

                                definition.reportDataViewVersion = genericRandomGuid;
                                definition.reportName = that.state.editDataViewName;

                                let dataToPost = {
                                    isScheduledReport: false,
                                    defaultDataSource: {},
                                    dataViewId: dataViewId,
                                    menuFolderId: menuItem.menuItemId,
                                    customDataSources: [],
                                    loginUserId: loginUserId,
                                    reportArgs: {},
                                    reportConfig: LDH.DeepClone(definition)
                                };
                                let directory = "report";
                                let fileName = that.state.editDataViewName;
                                let ownerType = "userid";

                                LeopardAjaxHelper.InsertOrUpdateDocumentToS3(dataToPost, fileName, directory, function (e) {
                                    LRH.ShowToast("Your Data View has been successfully updated.", "success", 5000);
                                    delete window[tempAppConfigId];
                                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                        that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                        that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                                    that.loadLeftMenuItems(false, function () {
                                        that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                        that.setState({showLoadingProgress: false});
                                    }, false);
                                }, function () {
                                    LRH.ShowToast("Failed to send your request to the reporting service.", "error", 5000);
                                }, ownerType, documentListType, documentModifyType, documentCreateType);
                                return;
                            }

                            LRH.ShowToast("Your Data View has been successfully updated.", "success", 5000);
                            delete window[tempAppConfigId];
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            }, false);
                        }, function () {
                            LRH.ShowToast("Failed to update your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                        }, that.state.dataViewMenuCategorySelect, that.state.hideMenuItem);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot " +
                            "be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                }, tempAppConfigId, that.state.hideMenuItem, genericRandomGuid);
        });
    };

    createDataViewCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                    that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                    that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);
            });
    };

    createDataViewCancelButtonOnClickWithoutConfirm = () => {
        let that = this;
        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
            that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
            that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);
    };

// ==================== Dashboards =======================

    createDashboardCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                    that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                    that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                    that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                    that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);
            });
    };

    createDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let dashboardId = LDH.GenerateGuid();
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            let dataViewIds = [];
            let dashboardLayout = [];
            let xValue = -72;
            let yValue = 0;

            for (let i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.dataViewName === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.dataViewId});

                if (xValue + 72 > 144) {
                    xValue = 0;
                    yValue += 1;
                } else {
                    xValue += 72;
                }

                dashboardLayout.push({
                    i: i.toString(), x: xValue, y: yValue, w: 72, h: 36, minW: 18, minH: 1, definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewPersistentId: dataView.item.dataViewPersistentId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.CreateDashboard(organizationId, that.state.createDashboardName, null,
                dashboardId, dashboardLayout, [], function () {
                    // Then create menu item for that data view.
                    LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                        that.state.createDashboardName, dashboardId, function () {
                            LRH.ShowToast("Your workspace has been successfully created.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                            that.loadLeftMenuItems(false, null, false);
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to create your workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                                that.setState({showLoadingProgress: false});
                            }, function () {
                                that.setState({showLoadingProgress: false});
                            });
                        }, that.currentFolderObject.menuItemId, false);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to create your workspace. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    editDashboardSaveSettings = () => {
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewIds = [];
            let dashboardLayout = [];

            for (let i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.dataViewName === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.dataViewId});

                if (LDH.IsObjectNull(dataView.dashboardItemId) || LDH.IsValueEmpty(dataView.dashboardItemId)) {
                    dataView.dashboardItemId = LDH.GenerateGuid();
                }

                dashboardLayout.push({
                    i: i.toString(), definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: dataView.dashboardItemId,
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dataViewPersistentId: dataView.item.dataViewPersistentId,
                        dashboardConfigVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.UpdateDashboard(userId, organizationId, that.state.editDashboardId, dashboardLayout,
                that.state.editDataViewName, function () {
                    let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                    $(".dashboard-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                        that.state.editDashboardId, function (menuItem) {
                            LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                                that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                                that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                                that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                                that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            }, true);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to update your workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        }, that.state.confirmationMessageText, that.state.autoManualMode, that.state.hideMenuItem);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot " +
                            "be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your workspace. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                }, that.state.isResetDashboardRelationshipsOnSave,
                that.state.hasDataViewDeletedInWorkspace, that.state.confirmationMessageText,
                that.state.autoManualMode, that.state.hideMenuItem);
        });
    };

    editDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (this.state.isResetDashboardRelationshipsOnSave && !this.state.hasDataViewDeletedInWorkspace) {
            LRH.ShowDialog("You have changed the structure of your Workspace, this will reset all the relationships of the workspace." +
                "<br/>Are you sure you want to continue?", "Confirm Update", function () {
                that.editDashboardSaveSettings(e);
            });
        } else if (this.state.isResetDashboardRelationshipsOnSave && this.state.hasDataViewDeletedInWorkspace) {
            LRH.ShowDialog("You have changed the structure of your Workspace, this will reset the following settings:<br/>" +
                "1. All the relationships of Data Views in the Workspace.<br/>" +
                "2. The entire layout of the Workspace.<br/><br/>" +
                "Are you sure you want to continue?", "Confirm Update", function () {
                that.editDashboardSaveSettings(e);
            });
        } else {
            this.editDashboardSaveSettings(e);
        }
    };

    createDashboardDeleteButtonOnClick = (dialogMessage, dashboardIdToDelete) => {
        let that = this;

        if (LDH.IsValueEmpty(dialogMessage) === true) {
            dialogMessage = "Are you sure you want to delete this workspace?";
        }
        if (LDH.IsValueEmpty(dashboardIdToDelete) === true) {
            dashboardIdToDelete = that.state.editDashboardId;
        }

        LRH.ShowDialog(dialogMessage, "Confirm Delete", function () {
            that.setState({showLoadingProgress: true}, function () {
                let dashboardId = dashboardIdToDelete;
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.DeleteLeftMenuItemForDashboard(userId, organizationId, dashboardId, function () {
                    LRH.ShowToast("Your workspace has been successfully deleted.", "success", 5000);
                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
                        that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
                        that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
                        that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
                        that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);

                    that.loadLeftMenuItems(true, null, false);
                    that.dataViewOrTemplateMenuItemOnClick({item: null});
                    that.setState({showLoadingProgress: false});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to delete your Dashbaord. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
            });
        });
    };

    dataViewPreferencesHideFolderValueOnChange = (e, menuItem) => {
        this.hideCurrentFolder = e.value;

        let panel = LDH.DeepClone(this.state.showMenuOptionPanel);
        panel.menuData.item.isHidden = e.value;
        this.setState({showMenuOptionPanel: panel});
    }

    dataViewPreferencesExpandByDefaultValueOnChange = (e, menuItem) => {
        this.expandCurrentFolderByDefault = e.value;

        let panel = LDH.DeepClone(this.state.showMenuOptionPanel);
        panel.menuData.item.expandByDefault = e.value;
        this.setState({showMenuOptionPanel: panel});
    }

    dataViewPreferencesApply = (e, menuItem) => {
        e.preventDefault();

        let that = this;
        let menuCloned = LDH.DeepClone(this.state.dxMenuItemData);
        for (let i = 0; i < menuCloned.length; i++) {
            if (menuCloned[i].menuItemId !== menuItem.menuItemId) {
                continue;
            }
            menuCloned[i]["isHidden"] = this.hideCurrentFolder;
            menuCloned[i]["expandByDefault"] = this.expandCurrentFolderByDefault;
        }

        let panel = LDH.DeepClone(this.state.showMenuOptionPanel);
        panel.menuData.item.isHidden = this.hideCurrentFolder;
        panel.menuData.item.expandByDefault = this.expandCurrentFolderByDefault;

        this.setState({dxMenuItemData: menuCloned, showMenuOptionPanel: panel}, function () {
            setTimeout(function () {
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                    LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
                }, function () {
                    LRH.ShowToast("Failed to update the menu structure. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }, that.state.dxMenuItemData);
            }, 100);

            that.dataViewPreferencesCancel();
        });
    }

    dataViewRenameCurrentFolderApply = (e, menuItem) => {
        e.preventDefault();

        let that = this;
        let menuCloned = LDH.DeepClone(this.state.dxMenuItemData);

        for (let i = 0; i < menuCloned.length; i++) {
            if (menuCloned[i].menuItemId !== menuItem.menuItemId) {
                continue;
            }
            menuCloned[i].menuItemName = this.currentFolderName;
        }

        this.setState({dxMenuItemData: menuCloned}, function () {
            setTimeout(function () {
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                    LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
                }, function () {
                    LRH.ShowToast("Failed to update the menu structure. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }, that.state.dxMenuItemData);
            }, 100);

            that.dataViewPreferencesCancel();
        });
    }

    dataViewDeleteFolderApply = (e, menuItem) => {
        e.preventDefault();

        let that = this;
        let menuCloned = LDH.DeepClone(this.state.dxMenuItemData);
        let hasChild = false;

        for (let i = 0; i < menuCloned.length; i++) {
            if (menuCloned[i].menuItemParentId === menuItem.menuItemId) {
                hasChild = true;
            }
        }

        if (hasChild) {
            LRH.ShowToast("Validation failed. This folder contains sub folders and items.", "error", 5000);
            return;
        }

        for (let i = 0; i < menuCloned.length; i++) {
            if (menuCloned[i].menuItemId !== menuItem.menuItemId) {
                continue;
            }
            menuCloned.splice(i, 1);
            break;
        }

        this.setState({dxMenuItemData: menuCloned}, function () {
            setTimeout(function () {
                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                    LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
                }, function () {
                    LRH.ShowToast("Failed to update the menu structure. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }, that.state.dxMenuItemData);
            }, 100);

            that.dataViewPreferencesCancel();
        });
    }

    dataViewCreateSubFolderApply = (e, menuItem) => {
        e.preventDefault();

        let that = this;
        let menuCloned = LDH.DeepClone(this.state.dxMenuItemData);
        let id = LDH.GenerateGuid();

        menuCloned.push({
            menuItemId: id,
            menuItemParentId: menuItem.menuItemId,
            menuItemName: this.newSubFolderName,
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: menuItem.menuItemCategory,
            menuItemSubCategory: menuItem.menuItemSubCategory,
            isHidden: false
        });

        this.setState({dxMenuItemData: menuCloned}, function () {
            setTimeout(function () {
                let instance = that.uiObjectInstance["dxMenuTreeView"];
                instance.expandItem(menuItem.menuItemId);

                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                    LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
                }, function () {
                    LRH.ShowToast("Failed to update the menu structure. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }, that.state.dxMenuItemData);
            }, 100);

            that.dataViewPreferencesCancel();
        });
    }

    dataViewPreferencesCancel = () => {
        let that = this;
        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit,
            that.state.categoryWorkspacesVisibility, that.state.categorySystemViewsVisibility,
            that.state.categoryDataViewsVisibility, that.state.categoryDefaultViewsVisibility,
            that.state.categoryReportsVisibility, that.state.categoryAnalyticsDashboardsVisibility,
            that.state.availableAnalyticsListForEdit, that.state.dxMenuItemData);
    };

    cloneDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
            let dataViewId = e.value;
            let viewObject = null;
            for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                let dataView = that.state.availableDataViewListForEdit[i];
                if (dataView.id === e.value) {
                    dataViewId = dataView.id;
                    viewObject = dataView;
                }
            }
            for (let j = 0; j < that.state.availableReportListForEdit.length; j++) {
                let dataView = that.state.availableReportListForEdit[j];
                if (dataView.id === e.value) {
                    dataViewId = dataView.id;
                    viewObject = dataView;
                }
            }
            for (let j = 0; j < that.state.availableAnalyticsListForEdit.length; j++) {
                let dataView = that.state.availableAnalyticsListForEdit[j];
                if (dataView.id === e.value) {
                    dataViewId = dataView.id;
                    viewObject = dataView;
                }
            }
            if (LDH.IsObjectNull(viewObject) || LDH.IsValueEmpty(dataViewId)) {
                LRH.ShowToast("Failed to retrieve Data View details. " +
                    "Your Data View could not be found.", "error", 5000);
                return;
            }

            if (viewObject.category !== "dashboard") {
                LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            } else {
                LeopardAjaxHelper.GetDashboardById(userId, dataViewId, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            }
        });
    };

    getSelectedTagCountForExport = (datasource, dropdownValues) => {
        let count = -1;
        for (let i = 0; i < dropdownValues.length; i++) {
            for (let j = 0; j < datasource.length; j++) {
                let dataViewId = "";
                let visible = true;

                if (!LDH.IsObjectNull(datasource[j].item.dashboardId) &&
                    !LDH.IsValueEmpty(datasource[j].item.dashboardId)) {
                    dataViewId = datasource[j].item.dashboardId;
                }
                if (!LDH.IsObjectNull(datasource[j].item.dataViewId) &&
                    !LDH.IsValueEmpty(datasource[j].item.dataViewId)) {
                    dataViewId = datasource[j].item.dataViewId;
                }
                if (!LDH.IsObjectNull(datasource[j].visible) &&
                    datasource[j].visible === false) {
                    visible = false;
                }
                if (dropdownValues[i] === dataViewId && visible) {
                    count++;
                }
            }
        }
        return count;
    };

    exportDataViewSelectInputOnClosed = (data) => {
        let dropdownValues = data.e.component.option("value");
        let count = this.getSelectedTagCountForExport(data.datasource, dropdownValues);
        let $root = $("#exportDataViewSelectInput");
        if (count > 0) {
            $(".dx-tagbox-multi-tag .dx-tag-content", $root).text(count + " more");
        } else {
            $(".dx-tagbox-multi-tag", $root).hide();
        }
    };

    exportDataViewSelectInputOnOpened = (data) => {
        let that = this;
        let dropdownValues = data.e.component.option("value");
        let clonedList = LDH.DeepClone(that.state.availableDataViewListForEdit);

        for (var i = 0; i < clonedList.length; i++) {
            clonedList[i]["occupiedCount"] = 0;
            clonedList[i]["disabled"] = false;
        }

        if (LDH.IsObjectNull(dropdownValues) || dropdownValues.length === 0) {
            that.setState({availableDataViewListForEdit: clonedList}, function () {
                setTimeout(function () {
                    let count = that.getSelectedTagCountForExport(data.datasource, dropdownValues);
                    let $root = $("#exportDataViewSelectInput");
                    if (count > 0) {
                        $(".dx-tagbox-multi-tag .dx-tag-content", $root).text(count + " more");
                    } else {
                        $(".dx-tagbox-multi-tag", $root).hide();
                    }

                    if (data.option === "export_dataview_from_workspace") {
                        $('.dx-popup-content .dx-list-select-all').hide();
                    }
                }, 100);
            });
            return;
        }

        for (let i = 0; i < dropdownValues.length; i++) {
            let dashboardObj = [];
            let configName = "CC_Dashboard_" + dropdownValues[i];
            for (let c = 0; c < that.userProfileLocalCache.ParentGroup.Configs.length; c++) {
                if (that.userProfileLocalCache.ParentGroup.Configs[c].Name === configName) {
                    dashboardObj = that.userProfileLocalCache.ParentGroup.Configs[c].AppConfig;
                    break;
                }
            }
            if (LDH.IsObjectNull(dashboardObj) || dashboardObj.length === 0 ||
                LDH.IsObjectNull(dashboardObj.dashboardLayout) ||
                dashboardObj.dashboardLayout.length === 0) {
                continue;
            }

            for (let j = 0; j < dashboardObj.dashboardLayout.length; j++) {
                let dataViewId = dashboardObj.dashboardLayout[j].definition.dataViewId;

                let dataViewIdMatches = false;
                let newDataViewId = null;
                for (let s = 0; s < clonedList.length; s++) {
                    if (clonedList[s].item.contentPageType !== "single-data-view") {
                        continue;
                    }
                    if (dataViewId === clonedList[s].item.dataViewId) {
                        dataViewIdMatches = true;
                    }
                    if (dashboardObj.dashboardLayout[j].definition.dataViewPersistentId ==
                        clonedList[s].item.dataViewPersistentId) {
                        newDataViewId = clonedList[s].item.dataViewId;
                    }
                }
                if (!dataViewIdMatches && !LDH.IsValueEmpty(newDataViewId)) {
                    dataViewId = newDataViewId;
                }

                for (let k = 0; k < clonedList.length; k++) {
                    if (clonedList[k].id === dataViewId) {
                        if (LDH.IsObjectNull(clonedList[k]["occupiedCount"])) {
                            clonedList[k]["occupiedCount"] = 1;
                        } else {
                            clonedList[k]["occupiedCount"] += 1;
                        }
                        clonedList[k]["disabled"] = true;
                    }
                }
            }
        }
        that.setState({availableDataViewListForEdit: clonedList}, function () {
            setTimeout(function () {
                let count = that.getSelectedTagCountForExport(data.datasource, dropdownValues);
                let $root = $("#exportDataViewSelectInput");
                if (count > 0) {
                    $(".dx-tagbox-multi-tag .dx-tag-content", $root).text(count + " more");
                } else {
                    $(".dx-tagbox-multi-tag", $root).hide();
                }

                if (data.option === "export_dataview_from_workspace") {
                    $('.dx-popup-content .dx-list-select-all').hide();
                }
            }, 100);
        });
    };

    exportDataViewSelectInputOnSelectionChanged = (e) => {
        let that = this;
        let dropdownValues = e.component.option("value");
        let clonedList = LDH.DeepClone(that.state.availableDataViewListForEdit);
        let profile = that.userProfileLocalCache;
        let saveValuesToControl = false;

        if (!LDH.IsObjectNull(e.addedItems) && e.addedItems.length > 0) {
            let itemsToProcess = e.addedItems;
            for (let i = 0; i < itemsToProcess.length; i++) {
                if (itemsToProcess[i].category !== "dashboard" ||
                    LDH.IsObjectNull(itemsToProcess[i].id)) {
                    continue;
                }
                let dashboardObj = [];
                let configName = "CC_Dashboard_" + itemsToProcess[i].id;
                for (let c = 0; c < profile.ParentGroup.Configs.length; c++) {
                    if (profile.ParentGroup.Configs[c].Name === configName) {
                        dashboardObj = profile.ParentGroup.Configs[c].AppConfig;
                        break;
                    }
                }
                if (LDH.IsObjectNull(dashboardObj) || dashboardObj.length === 0 ||
                    LDH.IsObjectNull(dashboardObj.dashboardLayout) ||
                    dashboardObj.dashboardLayout.length === 0) {
                    continue;
                }

                for (let j = 0; j < dashboardObj.dashboardLayout.length; j++) {
                    let dataViewIdToAdd = dashboardObj.dashboardLayout[j].definition.dataViewId;
                    let dataViewIdMatches = false;
                    let newDataViewId = null;
                    for (let s = 0; s < clonedList.length; s++) {
                        if (clonedList[s].item.contentPageType !== "single-data-view") {
                            continue;
                        }
                        if (dataViewIdToAdd === clonedList[s].item.dataViewId) {
                            dataViewIdMatches = true;
                        }
                        if (dashboardObj.dashboardLayout[j].definition.dataViewPersistentId ==
                            clonedList[s].item.dataViewPersistentId) {
                            newDataViewId = clonedList[s].item.dataViewId;
                        }
                    }
                    if (!dataViewIdMatches && !LDH.IsValueEmpty(newDataViewId)) {
                        dataViewIdToAdd = newDataViewId;
                    }

                    let dataViewIdAlreadyAdded = false;
                    for (let k = 0; k < dropdownValues.length; k++) {
                        if (dropdownValues[k] === dataViewIdToAdd) {
                            dataViewIdAlreadyAdded = true;
                            break;
                        }
                    }

                    if (dataViewIdAlreadyAdded === false) {
                        dropdownValues.push(dataViewIdToAdd);
                        saveValuesToControl = true;
                    }

                    for (let v = 0; v < clonedList.length; v++) {
                        if (clonedList[v].id === dataViewIdToAdd) {
                            if (LDH.IsObjectNull(clonedList[v]["linkedWorkspaces"])) {
                                clonedList[v]["linkedWorkspaces"] = "";
                            }
                            if (LDH.IsObjectNull(clonedList[v]["occupiedCount"])) {
                                clonedList[v]["occupiedCount"] = 1;
                                if (!LDH.IsObjectNull(itemsToProcess[i].id) &&
                                    !LDH.IsValueEmpty(itemsToProcess[i].id)) {
                                    clonedList[v]["linkedWorkspaces"] = itemsToProcess[i].id + ";";
                                }
                            } else {
                                clonedList[v]["occupiedCount"] += 1;
                                if (!LDH.IsObjectNull(itemsToProcess[i].id) &&
                                    !LDH.IsValueEmpty(itemsToProcess[i].id)) {
                                    clonedList[v]["linkedWorkspaces"] += itemsToProcess[i].id + ";";
                                }
                            }
                            clonedList[v]["dataViewBelongToWorkspace"] = true;
                            break;
                        }
                    }
                }
            }
        }

        if (!LDH.IsObjectNull(e.removedItems) && e.removedItems.length > 0) {
            let itemsToProcess2 = e.removedItems;
            for (let i = 0; i < itemsToProcess2.length; i++) {
                if (itemsToProcess2[i].category !== "dashboard" ||
                    LDH.IsObjectNull(itemsToProcess2[i].id)) {
                    continue;
                }
                let dashboardObj2 = [];
                let configName2 = "CC_Dashboard_" + itemsToProcess2[i].id;
                for (let c = 0; c < profile.ParentGroup.Configs.length; c++) {
                    if (profile.ParentGroup.Configs[c].Name === configName2) {
                        dashboardObj2 = profile.ParentGroup.Configs[c].AppConfig;
                        break;
                    }
                }
                if (LDH.IsObjectNull(dashboardObj2) || dashboardObj2.length === 0 ||
                    LDH.IsObjectNull(dashboardObj2.dashboardLayout) ||
                    dashboardObj2.dashboardLayout.length === 0) {
                    continue;
                }

                for (let j = 0; j < dashboardObj2.dashboardLayout.length; j++) {
                    let dataViewIdToRemove = dashboardObj2.dashboardLayout[j].definition.dataViewId;
                    let dataViewIdMatches = false;
                    let newDataViewId = null;
                    for (let s = 0; s < clonedList.length; s++) {
                        if (clonedList[s].item.contentPageType !== "single-data-view") {
                            continue;
                        }
                        if (dataViewIdToRemove === clonedList[s].item.dataViewId) {
                            dataViewIdMatches = true;
                        }
                        if (dashboardObj2.dashboardLayout[j].definition.dataViewPersistentId ==
                            clonedList[s].item.dataViewPersistentId) {
                            newDataViewId = clonedList[s].item.dataViewId;
                        }
                    }
                    if (!dataViewIdMatches && !LDH.IsValueEmpty(newDataViewId)) {
                        dataViewIdToRemove = newDataViewId;
                    }

                    let dataViewIdAlreadyAdded = false;
                    let removeIndex = -1;
                    for (let k = 0; k < dropdownValues.length; k++) {
                        if (dropdownValues[k] === dataViewIdToRemove) {
                            dataViewIdAlreadyAdded = true;
                            removeIndex = k;
                            break;
                        }
                    }

                    if (dataViewIdAlreadyAdded && removeIndex > -1) {
                        for (let v = 0; v < clonedList.length; v++) {
                            if (clonedList[v].id === dataViewIdToRemove) {
                                if (LDH.IsObjectNull(clonedList[v]["occupiedCount"])) {
                                    clonedList[v]["occupiedCount"] = 0;
                                } else {
                                    clonedList[v]["occupiedCount"] -= 1;
                                    clonedList[v]["dataViewBelongToWorkspace"] = true;

                                    let workspaces = clonedList[v]["linkedWorkspaces"];
                                    if (!LDH.IsObjectNull(workspaces) &&
                                        !LDH.IsObjectNull(itemsToProcess2[i].id) &&
                                        !LDH.IsValueEmpty(itemsToProcess2[i].id)) {
                                        let linkedWorkspaces2 = LDH.ReplaceAll(workspaces,
                                            itemsToProcess2[i].id + ";", "");
                                        clonedList[v]["linkedWorkspaces"] = linkedWorkspaces2;
                                    }
                                }

                                if (clonedList[v]["occupiedCount"] === 0) {
                                    clonedList[v]["dataViewBelongToWorkspace"] = false;
                                    clonedList[v]["linkedWorkspaces"] = "";
                                    dropdownValues.splice(removeIndex, 1);
                                }
                                break;
                            }
                        }
                        saveValuesToControl = true;
                    }
                }
            }
        }

        if (saveValuesToControl) {
            that.setState({availableDataViewListForEdit: clonedList}, function () {
                e.component.option("value", dropdownValues);
            });
        }
    };

    exportDataViewSelectInputOnChanged = (e) => {
        let that = this;
        let dataViewList = e.value;
        let dataViewsToExport = [];
        if (LDH.IsValueEmpty(dataViewList) || dataViewList.length === 0) {
            that.setState({dataViewJSONToExport: null});
            return;
        }
        that.setState({showLoadingProgress: true}, function () {
            let dataViewProcessedCount = 0;
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);

            for (let v = 0; v < dataViewList.length; v++) {
                let dataViewId = dataViewList[v];
                let viewObject = null;
                for (let i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                    let dataView = that.state.availableDataViewListForEdit[i];
                    if (dataView.id === dataViewId) {
                        viewObject = dataView;
                    }
                }
                for (let j = 0; j < that.state.availableReportListForEdit.length; j++) {
                    let dataView = that.state.availableReportListForEdit[j];
                    if (dataView.id === dataViewId) {
                        viewObject = dataView;
                    }
                }
                for (let j = 0; j < that.state.availableAnalyticsListForEdit.length; j++) {
                    let dataView = that.state.availableAnalyticsListForEdit[j];
                    if (dataView.id === dataViewId) {
                        viewObject = dataView;
                    }
                }
                if (LDH.IsObjectNull(viewObject) || LDH.IsValueEmpty(dataViewId)) {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Your Data View could not be found.", "error", 5000);
                    that.setState({showLoadingProgress: false});
                    return;
                }
                if (viewObject.category !== "dashboard") {
                    LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (result) {
                        dataViewProcessedCount++;
                        if (!LDH.IsObjectNull(viewObject["dataViewBelongToWorkspace"])) {
                            result["dataViewBelongToWorkspace"] = viewObject["dataViewBelongToWorkspace"];
                        }
                        if (!LDH.IsObjectNull(viewObject["linkedWorkspaces"])) {
                            result["linkedWorkspaces"] = viewObject["linkedWorkspaces"];
                        }
                        dataViewsToExport.push({id: dataViewId, data: result});

                        if (dataViewProcessedCount >= dataViewList.length) {
                            that.setState({showLoadingProgress: false, dataViewJSONToExport: dataViewsToExport});
                        }
                    }, function (error, sessionTimeout) {
                        dataViewProcessedCount++;
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve Data View details. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        if (dataViewProcessedCount >= dataViewList.length) {
                            that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                        }
                    });
                } else {
                    LeopardAjaxHelper.GetDashboardById(userId, dataViewId, function (result) {
                        dataViewProcessedCount++;
                        dataViewsToExport.push({id: dataViewId, data: result});

                        if (dataViewProcessedCount >= dataViewList.length) {
                            that.setState({showLoadingProgress: false, dataViewJSONToExport: dataViewsToExport});
                        }
                    }, function (error, sessionTimeout) {
                        dataViewProcessedCount++;
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve Data View details. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        if (dataViewProcessedCount >= dataViewList.length) {
                            that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                        }
                    });
                }
            }
        });
    };

    menuCategoryOnClick = (data) => {
        if (data.category === "workspaces") {
            let visible = this.state.categoryWorkspacesVisibility;
            this.setState({categoryWorkspacesVisibility: !visible});
        } else if (data.category === "default-view") {
            let visible = this.state.categoryDefaultViewsVisibility;
            this.setState({categoryDefaultViewsVisibility: !visible});
        } else if (data.category === "data-view") {
            let visible = this.state.categoryDataViewsVisibility;
            this.setState({categoryDataViewsVisibility: !visible});
        } else if (data.category === "system-view") {
            let visible = this.state.categorySystemViewsVisibility;
            this.setState({categorySystemViewsVisibility: !visible});
        } else if (data.category === "report") {
            let visible = this.state.categoryReportsVisibility;
            this.setState({categoryReportsVisibility: !visible});
        } else if (data.category === "analytics-dashboard") {
            let visible = this.state.categoryAnalyticsDashboardsVisibility;
            this.setState({categoryAnalyticsDashboardsVisibility: !visible});
        }
    };

// =======================================================================
// ============================ UI Rendering =============================
// =======================================================================
    buildRecurrenceSettingFields = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-block">
                    <div className={"leopard-leftmenu-settings-section"}>
                        <div style={{paddingLeft: "24px"}}>
                            <span>Recurrence settings</span>
                            <span style={{marginLeft: "5px"}}>
                        <LeopardTooltipWithIcon
                            elementId={"cronExpressionForReportInput_help"}
                            title={"Recurrence settings"}
                            text={"Specify a cron expression for setting up a recurring scheduled report."}/>
                        </span>
                        </div>
                    </div>
                    <div>
                        <div style={{paddingLeft: "24px", display: "flex"}}>
                        <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart1Input"}
                                     placeholder={"Min"} width={45}
                                     value={this.state.cronExpressionForReportPart1}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart1Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart1Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                            <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart2Input"}
                                     placeholder={"Hr"} width={45}
                                     value={this.state.cronExpressionForReportPart2}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart2Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart2Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                            <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart3Input"}
                                     placeholder={"DoM"} width={45}
                                     value={this.state.cronExpressionForReportPart3}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart3Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart3Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                            <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart4Input"}
                                     placeholder={"Mth"} width={45}
                                     value={this.state.cronExpressionForReportPart4}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart4Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart4Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                            <span style={{marginRight: "5px"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart5Input"}
                                     placeholder={"DoW"} width={45}
                                     value={this.state.cronExpressionForReportPart5}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart5Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart5Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                            </TextBox>
                        </span>
                            <span style={{float: "left"}}>
                            <TextBox disabled={this.state.showLoadingProgress}
                                     id={"cronExpressionForReportPart6Input"}
                                     placeholder={"Yr"} width={60}
                                     value={this.state.cronExpressionForReportPart6}
                                     ref={(e) => this.setInputFieldInstance({
                                         e,
                                         input: "cronExpressionForReportPart6Input"
                                     })}
                                     onValueChanged={(e) => this.cronExpressionForReportOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom"
                                                  validationCallback={
                                                      (e) => this.customValidationRuleCallback({
                                                          e,
                                                          input: "cronExpressionForReportPart6Input",
                                                          rules: [
                                                              {rule: "safeinput"},
                                                              {rule: "required"}
                                                          ]
                                                      })
                                                  }/>
                                </Validator>
                                <TextBoxButton
                                    name={"cronExpressionForReportPart6Input_MiniButton"}
                                    location={'after'}
                                    options={{
                                        icon: "fas fa-question",
                                        type: 'default',
                                        onClick: () => {
                                            window.open(LeopardStaticUIConfig.AWSCronExpressionUrl);
                                        }
                                    }}
                                />
                            </TextBox>
                        </span>
                        </div>
                    </div>
                </div>
                <div className="leopard-option-panel-block">
                    <div className={"leopard-leftmenu-settings-section"}>
                        <div style={{paddingLeft: "24px"}}>
                            <span>Specify recipients</span>
                            <span style={{marginLeft: "5px"}}>
                                <LeopardTooltipWithIcon
                                    elementId={"recipientsForReportInput_help"}
                                    title={"Specify recipients"}
                                    text={"Specify a list of recipients (email addresses) for receiving the scheduled report."}/>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div style={{paddingLeft: "24px"}}>
                            <TextArea disabled={this.state.showLoadingProgress}
                                      height={"80px"}
                                      id={"recipientsForReportInput"}
                                      value={this.state.recipientsForReport}
                                      placeholder={"email1@company.com.au ; email2@company.com.au ;"}
                                      ref={(e) => this.setInputFieldInstance({
                                          e,
                                          input: "recipientsForReportInput"
                                      })}
                                      onValueChanged={(e) => this.recipientsForReportInputOnValueChanged(e)}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => this.customValidationRuleCallback({
                                            e,
                                            input: "recipientsForReportInput",
                                            rules: [
                                                {rule: "safeinput"},
                                                {rule: "required"},
                                                {rule: "emailaddress-list"}
                                            ]
                                        })
                                    }/>
                                </Validator>
                            </TextArea>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    };

    buildCustomRecurrenceSettingFields = () => {
        let that = this;
        if (LDH.IsObjectNull(that.state.reportCustomSchedules)) {
            return null;
        }
        return that.state.reportCustomSchedules.map(function (item, i) {
            return (
                <React.Fragment key={"CustomRecurrenceSettingFields_" + i}>
                    <div className={"leopard-menupanel-splitter-container"}>
                        <div className={"leopard-menupanel-splitter-counter"}>{i + 1}</div>
                    </div>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-leftmenu-settings-section"}>
                            <div style={{paddingLeft: "24px"}}>
                                <span>Recurrence settings</span>
                                <span style={{marginLeft: "5px"}}>
                            <LeopardTooltipWithIcon
                                elementId={"customScheduleCronExpressionInput_help_" + i}
                                title={"Recurrence settings"}
                                text={"Specify a cron expression for setting up a recurring scheduled report."}/>
                            </span>
                            </div>
                        </div>
                        <div>
                            <div style={{paddingLeft: "24px", display: "flex"}}>
                            <span style={{marginRight: "5px"}}>
                                <TextBox disabled={that.state.showLoadingProgress}
                                         id={"customScheduleCronPart1Input_" + i}
                                         placeholder={"Min"} width={45}
                                         value={item.cronExpPart1}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "customScheduleCronPart1Input_" + i
                                         })}
                                         onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                             e: e, index: i, item: item
                                         })}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={
                                                          (e) => that.customValidationRuleCallback({
                                                              e,
                                                              input: "cronExpressionForReportPart1Input",
                                                              rules: [
                                                                  {rule: "safeinput"},
                                                                  {rule: "required"}
                                                              ]
                                                          })
                                                      }/>
                                    </Validator>
                                </TextBox>
                            </span>
                                <span style={{marginRight: "5px"}}>
                                <TextBox disabled={that.state.showLoadingProgress}
                                         id={"customScheduleCronPart12nput_" + i}
                                         placeholder={"Hr"} width={45}
                                         value={item.cronExpPart2}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "customScheduleCronPart2Input_" + i
                                         })}
                                         onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                             e: e, index: i, item: item
                                         })}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={
                                                          (e) => that.customValidationRuleCallback({
                                                              e,
                                                              input: "cronExpressionForReportPart2Input",
                                                              rules: [
                                                                  {rule: "safeinput"},
                                                                  {rule: "required"}
                                                              ]
                                                          })
                                                      }/>
                                    </Validator>
                                </TextBox>
                            </span>
                                <span style={{marginRight: "5px"}}>
                                <TextBox disabled={that.state.showLoadingProgress}
                                         id={"customScheduleCronPart3Input_" + i}
                                         placeholder={"DoM"} width={45}
                                         value={item.cronExpPart3}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "customScheduleCronPart3Input_" + i
                                         })}
                                         onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                             e: e, index: i, item: item
                                         })}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={
                                                          (e) => that.customValidationRuleCallback({
                                                              e,
                                                              input: "cronExpressionForReportPart3Input",
                                                              rules: [
                                                                  {rule: "safeinput"},
                                                                  {rule: "required"}
                                                              ]
                                                          })
                                                      }/>
                                    </Validator>
                                </TextBox>
                            </span>
                                <span style={{marginRight: "5px"}}>
                                 <TextBox disabled={that.state.showLoadingProgress}
                                          id={"customScheduleCronPart4Input_" + i}
                                          placeholder={"Mth"} width={45}
                                          value={item.cronExpPart4}
                                          ref={(e) => that.setInputFieldInstance({
                                              e,
                                              input: "customScheduleCronPart4Input_" + i
                                          })}
                                          onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                              e: e, index: i, item: item
                                          })}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={
                                                          (e) => that.customValidationRuleCallback({
                                                              e,
                                                              input: "cronExpressionForReportPart4Input",
                                                              rules: [
                                                                  {rule: "safeinput"},
                                                                  {rule: "required"}
                                                              ]
                                                          })
                                                      }/>
                                    </Validator>
                                </TextBox>
                            </span>
                                <span style={{marginRight: "5px"}}>
                                <TextBox disabled={that.state.showLoadingProgress}
                                         id={"customScheduleCronPart5Input_" + i}
                                         placeholder={"DoW"} width={45}
                                         value={item.cronExpPart5}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "customScheduleCronPart5Input_" + i
                                         })}
                                         onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                             e: e, index: i, item: item
                                         })}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={
                                                          (e) => that.customValidationRuleCallback({
                                                              e,
                                                              input: "cronExpressionForReportPart5Input",
                                                              rules: [
                                                                  {rule: "safeinput"},
                                                                  {rule: "required"}
                                                              ]
                                                          })
                                                      }/>
                                    </Validator>
                                </TextBox>
                            </span>
                                <span style={{float: "left"}}>
                                     <TextBox disabled={that.state.showLoadingProgress}
                                              id={"customScheduleCronPart6Input_" + i}
                                              placeholder={"Yr"} width={60}
                                              value={item.cronExpPart6}
                                              ref={(e) => that.setInputFieldInstance({
                                                  e,
                                                  input: "customScheduleCronPart6Input_" + i
                                              })}
                                              onValueChanged={(e) => that.customScheduleCronExpressionOnValueChanged({
                                                  e: e, index: i, item: item
                                              })}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => that.customValidationRuleCallback({
                                                e,
                                                input: "cronExpressionForReportPart6Input",
                                                rules: [
                                                    {rule: "safeinput"},
                                                    {rule: "required"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                           <TextBoxButton
                                               name={"cronExpressionForReportPart6Input_MiniButton"}
                                               location={'after'}
                                               options={{
                                                   icon: "fas fa-question",
                                                   type: 'default',
                                                   onClick: () => {
                                                       window.open(LeopardStaticUIConfig.AWSCronExpressionUrl);
                                                   }
                                               }}
                                           />
                                </TextBox>
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-leftmenu-settings-section"}>
                            <div style={{paddingLeft: "24px"}}>
                                <span>Specify recipients</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customScheduleRecipients_Input__help"}
                                        title={"Specify recipients"}
                                        text={"Specify a list of recipients (email addresses) for receiving the scheduled report."}/>
                                </span>
                            </div>
                        </div>
                        <div>
                            <div style={{paddingLeft: "24px"}}>
                                <TextArea disabled={that.state.showLoadingProgress}
                                          height={"80px"}
                                          id={"recipientsForReportInput"}
                                          value={item.recipients}
                                          placeholder={"email1@company.com.au ; email2@company.com.au ;"}
                                          ref={(e) => that.setInputFieldInstance({
                                              e,
                                              input: "customScheduleRecipients_" + i
                                          })}
                                          onValueChanged={(e) => that.customScheduleRecipientsInputValueOnChange({
                                              value: e.value, id: item.id
                                          })}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => that.customValidationRuleCallback({
                                                e,
                                                input: "customScheduleRecipients_" + i,
                                                rules: [
                                                    {rule: "safeinput"},
                                                    {rule: "required"},
                                                    {rule: "emailaddress-list"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingLeft: "24px"}}>
                        <a href={"#_"} className={"leopard-dataview-report-addsource"}
                           onClick={() => that.reportCustomSchedulesRemoveOnClick({id: item.id})}>
                            Remove
                        </a>
                    </div>
                </React.Fragment>
            );
        });
    };

    buildLeopardMenuDataViews = (dataViewMenuItems, dataViewCategory) => {
        if (dataViewMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            let categoryVisible = false;
            if (dataViewCategory === "default-view") {
                categoryVisible = this.state.categoryDefaultViewsVisibility;
            } else if (dataViewCategory === "data-view") {
                categoryVisible = this.state.categoryDataViewsVisibility;
            } else if (dataViewCategory === "system-view") {
                categoryVisible = this.state.categorySystemViewsVisibility;
            } else if (dataViewCategory === "report") {
                categoryVisible = this.state.categoryReportsVisibility;
            } else if (dataViewCategory === "analytics-dashboard") {
                categoryVisible = this.state.categoryAnalyticsDashboardsVisibility;
            }

            return (
                <React.Fragment>
                    <div className={"dataViewCategory_" + dataViewCategory}>
                        <LeopardMenuDataViewList menuItems={dataViewMenuItems} categoryVisible={categoryVisible}
                                                 dataViewCategory={dataViewCategory}
                                                 menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                                 selectedMenuItem={this.props.state.selectedMenuItem}
                                                 menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                                 selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    buildLeopardMenuTemplates = (dataViewCategory) => {
        if (this.templateMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            let categoryVisible = this.state.categoryWorkspacesVisibility;
            return (
                <React.Fragment>
                    <div
                        className={"dataViewCategory_" + dataViewCategory}>
                        <LeopardMenuTemplateList menuItems={this.templateMenuItems} categoryVisible={categoryVisible}
                                                 menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                                 selectedMenuItem={this.props.state.selectedMenuItem}
                                                 menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                                 selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    buildMenuOptionPanelEditDataView = () => {
        let nameToDisplay = "";
        if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            nameToDisplay = "Data View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            nameToDisplay = "System View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            nameToDisplay = "Default View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            nameToDisplay = "Analytics";
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">Edit {nameToDisplay}</div>
                <form onSubmit={this.editDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        {
                            this.state.showMenuOptionPanel.dataViewCategory !== "report" ? "" :
                                <React.Fragment>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">1.</i>
                                            <span>Select data source</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"selectChartTypeInput_help"}
                                                    title={"Select data source"}
                                                    text={"Select a data source for the " + nameToDisplay + " to access, " +
                                                        "so the user will be able to use the data in the data source."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectDataSourceInput"}
                                                       grouped={true}
                                                       value={this.state.createDataViewDataSourceUrl}
                                                       dataSource={new DataSource({
                                                           store: this.state.availableDataSourceList,
                                                           key: 'id',
                                                           group: 'group'
                                                       })}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectDataSourceInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataSourceInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <div style={{marginTop: "5px"}}>
                                                    <SelectBox displayExpr={'name'} id={"selectDataSourceVersionInput"}
                                                               grouped={true}
                                                               value={this.state.createDataViewDataSourceVersion}
                                                               dataSource={new DataSource({
                                                                   store: LeopardDropdownHelper.DropdownSelectionODataEngineVersionForReport,
                                                                   key: 'id',
                                                                   group: 'group'
                                                               })}
                                                               ref={(e) => this.setInputFieldInstance({
                                                                   e,
                                                                   input: "selectDataSourceVersionInput"
                                                               })}
                                                               valueExpr={'id'}
                                                               disabled={this.state.showLoadingProgress}
                                                               onValueChanged={
                                                                   (e) => this.createDataViewDataSourceVersionOnValueChanged(e)
                                                               }>
                                                        <Validator>
                                                            <RequiredRule type="custom" validationCallback={
                                                                (e) => this.customValidationRuleCallback({
                                                                    e, input: "selectDataSourceVersionInput", rules: [
                                                                        {rule: "required"}
                                                                    ]
                                                                })
                                                            }/>
                                                        </Validator>
                                                    </SelectBox>
                                                </div> :
                                                <div style={{marginTop: "5px"}}>
                                                    <SelectBox displayExpr={'name'} id={"selectDataSourceVersionInput"}
                                                               grouped={true}
                                                               value={this.state.createDataViewDataSourceVersion}
                                                               dataSource={new DataSource({
                                                                   store: LeopardDropdownHelper.DropdownSelectionODataEngineVersionForChart,
                                                                   key: 'id',
                                                                   group: 'group'
                                                               })}
                                                               ref={(e) => this.setInputFieldInstance({
                                                                   e,
                                                                   input: "selectDataSourceVersionInput"
                                                               })}
                                                               valueExpr={'id'}
                                                               disabled={this.state.showLoadingProgress}
                                                               onValueChanged={
                                                                   (e) => this.createDataViewDataSourceVersionOnValueChanged(e)
                                                               }>
                                                        <Validator>
                                                            <RequiredRule type="custom" validationCallback={
                                                                (e) => this.customValidationRuleCallback({
                                                                    e, input: "selectDataSourceVersionInput", rules: [
                                                                        {rule: "required"}
                                                                    ]
                                                                })
                                                            }/>
                                                        </Validator>
                                                    </SelectBox>
                                                </div>
                                        }
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Parent OData query (level 1)</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersParent1ForNormalReportInput_help"}
                                                    title={"Parent OData query"}
                                                    text={"Specify parent OData query for retrieving data from the database."}/>
                                            </span>
                                        </div>
                                        <div style={{marginTop: "5px"}}>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"oDataParametersParent1ForNormalReportInput"}
                                                      value={this.state.oDataParametersParent1ForNormalReport}
                                                      placeholder={"$filter=...&$apply=..."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "oDataParametersParent1ForNormalReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.oDataParametersParent1ForNormalReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                        <div style={{marginTop: "5px"}}>
                                            <span>Parent OData query (level 2)</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersParent2ForNormalReportInput_help"}
                                                    title={"Parent OData query"}
                                                    text={"Specify parent OData query for retrieving data from the database."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"oDataParametersParent2ForNormalReportInput"}
                                                      value={this.state.oDataParametersParent2ForNormalReport}
                                                      placeholder={"$filter=...&$apply=..."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "oDataParametersParent2ForNormalReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.oDataParametersParent2ForNormalReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                        <div style={{marginTop: "5px"}}>
                                            <span>Parent OData query (level 3)</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersParent3ForNormalReportInput_help"}
                                                    title={"Parent OData query"}
                                                    text={"Specify parent OData query for retrieving data from the database."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"oDataParametersParent3ForNormalReportInput"}
                                                      value={this.state.oDataParametersParent3ForNormalReport}
                                                      placeholder={"$filter=...&$apply=..."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "oDataParametersParent3ForNormalReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.oDataParametersParent3ForNormalReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                        <div style={{marginTop: "5px"}}>
                                            <span>OData query</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersForNormalReportInput_help"}
                                                    title={"OData query"}
                                                    text={"Specify OData query for retrieving data from the database."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"oDataParametersForNormalReportInput"}
                                                      value={this.state.oDataParametersForNormalReport}
                                                      placeholder={"$filter=...&$apply=..."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "oDataParametersForNormalReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.oDataParametersForNormalReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                        <div style={{marginTop: "5px"}}>
                                            <a href={"#_"} className={"leopard-dataview-report-addsource"}
                                               onClick={(e) => this.reportCustomDataSourceAddOnClick(e)}>
                                                Add Data Source
                                            </a>
                                        </div>
                                    </div>
                                    <React.Fragment>
                                        {this.renderReportCustomDataSourceConfigurationPanel()}
                                    </React.Fragment>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex" style={{float: "left"}}>3.</i>
                                            <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                <span>Scheduled report</span>
                                                <span style={{float: "right"}}>
                                                    <Switch value={!this.state.disableScheduledReportOption}
                                                            width={"60px"}
                                                            switchedOffText={"OFF"} switchedOnText={"ON"}
                                                            id={"disableScheduledReportOption"}
                                                            disabled={this.state.showLoadingProgress}
                                                            onValueChanged={(e) => this.disableScheduledReportOptionOnValueChanged(e)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            this.state.disableScheduledReportOption === true ? "" :
                                                <React.Fragment>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>File name prefix:</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"fileNamePrefixForScheduledReportInput_help"}
                                                                        title={"File name prefix"}
                                                                        text={"Specify a prefix for the file name."}/>
                                                                </span>
                                                            </div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextBox
                                                                    defaultValue={this.state.fileNamePrefixForScheduledReport}
                                                                    disabled={this.state.showLoadingProgress}
                                                                    id={"fileNamePrefixForScheduledReportInput"}
                                                                    ref={(e) => this.setInputFieldInstance({
                                                                        e,
                                                                        input: "fileNamePrefixForScheduledReportInput"
                                                                    })}
                                                                    onValueChanged={(e) => this.fileNamePrefixForScheduledReportOnValueChanged(e)}>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Parent OData query (level 1)</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParentQuery1ForScheduledReportInput_help"}
                                                                        title={"Parent OData query"}
                                                                        text={"Specify a parent query for the data source."}/>
                                                                </span>
                                                            </div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"oDataParentQuery1ForScheduledReportInput"}
                                                                          value={this.state.oDataParametersParent1ForScheduledReport}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "oDataParentQuery1ForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.oDataParentQuery1ForScheduledReportOnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Lambda request for parent query 1</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"lambdaRequestConfigForScheduledReportParent1Input_help"}
                                                                        title={"Lambda request for parent query 1"}
                                                                        text={"Add configurtion to the Lambda request."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"lambdaRequestConfigForScheduledReportParent1Input"}
                                                                          value={this.state.scheduledReportLambdaRequestConfigForParentQuery1}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "lambdaRequestConfigForScheduledReportParent1Input"
                                                                          })}
                                                                          onValueChanged={(e) => this.lambdaRequestConfigForScheduledReportParentQuery1OnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Parent OData query (level 2)</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParentQuery2ForScheduledReportInput__help"}
                                                                        title={"Parent OData query"}
                                                                        text={"Specify a parent query for the data source."}/>
                                                                </span>
                                                            </div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"oDataParentQuery2ForScheduledReportInput"}
                                                                          value={this.state.oDataParametersParent2ForScheduledReport}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "oDataParentQuery2ForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.oDataParentQuery2ForScheduledReportOnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Lambda request for parent query 2</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"lambdaRequestConfigForScheduledReportParent2Input_help"}
                                                                        title={"Lambda request for parent query 2"}
                                                                        text={"Add configurtion to the Lambda request."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"lambdaRequestConfigForScheduledReportParent2Input"}
                                                                          value={this.state.scheduledReportLambdaRequestConfigForParentQuery2}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "lambdaRequestConfigForScheduledReportParent2Input"
                                                                          })}
                                                                          onValueChanged={(e) => this.lambdaRequestConfigForScheduledReportParentQuery2OnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Parent OData query (level 3)</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParentQuery3ForScheduledReportInput__help"}
                                                                        title={"Parent OData query"}
                                                                        text={"Specify a parent query for the data source."}/>
                                                                </span>
                                                            </div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"oDataParentQuery3ForScheduledReportInput"}
                                                                          value={this.state.oDataParametersParent3ForScheduledReport}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "oDataParentQuery3ForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.oDataParentQuery3ForScheduledReportOnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Lambda request for parent query 3</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"lambdaRequestConfigForScheduledReportParent3Input_help"}
                                                                        title={"Lambda request for parent query 3"}
                                                                        text={"Add configurtion to the Lambda request."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"lambdaRequestConfigForScheduledReportParent3Input"}
                                                                          value={this.state.scheduledReportLambdaRequestConfigForParentQuery3}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "lambdaRequestConfigForScheduledReportParent3Input"
                                                                          })}
                                                                          onValueChanged={(e) => this.lambdaRequestConfigForScheduledReportParentQuery3OnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>OData query for scheduled report</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParametersForScheduledReportInput_help"}
                                                                        title={"OData query"}
                                                                        text={"Specify OData query for retrieving data from the database."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"oDataParametersForScheduledReportInput"}
                                                                          value={this.state.oDataParametersForScheduledReport}
                                                                          placeholder={"$filter=...&$apply=..."}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "oDataParametersForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.oDataParametersForScheduledReportOnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="leopard-option-panel-block">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Lambda request configuration</span>
                                                                <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"lambdaRequestConfigForScheduledReportInput_help"}
                                                                        title={"Lambda request configuration"}
                                                                        text={"Add configurtion to the Lambda request."}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <TextArea disabled={this.state.showLoadingProgress}
                                                                          height={"80px"}
                                                                          id={"lambdaRequestConfigForScheduledReportInput"}
                                                                          value={this.state.scheduledReportLambdaRequestConfig}
                                                                          ref={(e) => this.setInputFieldInstance({
                                                                              e,
                                                                              input: "lambdaRequestConfigForScheduledReportInput"
                                                                          })}
                                                                          onValueChanged={(e) => this.lambdaRequestConfigForScheduledReportOnValueChanged(e)}>
                                                                </TextArea>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                                                            <a href={"#_"}
                                                               className={"leopard-dataview-report-addsource"}
                                                               onClick={(e) => this.reportScheduledCustomDataSourceAddOnClick(e)}>
                                                                Add Data Source
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <React.Fragment>
                                                        {this.renderScheduledReportCustomDataSourceConfigurationPanel()}
                                                    </React.Fragment>

                                                    <div className="leopard-option-panel-block export-excel-options">
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <span>Rendering option for Excel (XLSX)</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <SelectBox displayExpr={'text'}
                                                                           id={"scheduledReportExportExcelBandFilterInput"}
                                                                           value={this.state.scheduledReportExportExcelBandFilter}
                                                                           dataSource={LeopardDropdownHelper.DropdownSelectionExportExcelBandFilter}
                                                                           valueExpr={'value'}
                                                                           disabled={this.state.showLoadingProgress}
                                                                           onValueChanged={
                                                                               (e) => this.scheduledReportExportExcelBandFilterOnValueChanged(e)
                                                                           }>
                                                                </SelectBox>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.createDataViewDataSourceVersion === "v2-athena" ? null :
                                                        <React.Fragment>
                                                            <div className="leopard-option-panel-block">
                                                                <div className={"leopard-leftmenu-settings-section"}>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <span>File format</span>
                                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"scheduledReportFileFormatInput_help"}
                                                                    title={"File format"}
                                                                    text={"Specify a file format for the scheduled report."}/>
                                                                </span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <SelectBox displayExpr={'text'}
                                                                                   id={"scheduledReportFileFormatInput"}
                                                                                   value={this.state.scheduledReportFileFormat}
                                                                                   dataSource={LeopardDropdownHelper.DropdownSelectionScheduledReportFileFormat}
                                                                                   valueExpr={'value'}
                                                                                   disabled={this.state.showLoadingProgress}
                                                                                   onValueChanged={
                                                                                       (e) => this.scheduledReportFileFormatOnValueChanged(e)
                                                                                   }>
                                                                        </SelectBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="leopard-option-panel-block"
                                                                 style={{display: "none"}}>
                                                                <div className={"leopard-leftmenu-settings-section"}>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <span>Timezone</span>
                                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"timezoneForReportInput_help"}
                                                                    title={"Timezone"}
                                                                    text={"Specify a timezone for the scheduled report."}/>
                                                                </span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <SelectBox displayExpr={'name'}
                                                                                   id={"timezoneForReportSelect"}
                                                                                   value={this.state.timezoneForReport}
                                                                                   dataSource={LeopardDropdownHelper.DropdownSelectionTimeZone}
                                                                                   ref={(e) => this.setInputFieldInstance({
                                                                                       e,
                                                                                       input: "timezoneForReportSelect"
                                                                                   })}
                                                                                   valueExpr={'id'}
                                                                                   disabled={this.state.showLoadingProgress}
                                                                                   onValueChanged={
                                                                                       (e) => this.timezoneForReportSelectOnValueChanged(e)
                                                                                   }>
                                                                        </SelectBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="leopard-option-panel-block"
                                                                 style={{display: "none"}}>
                                                                <div className={"leopard-leftmenu-settings-section"}>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <span>Daylight saving offset</span>
                                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"daylightSavingForReportInput_help"}
                                                                    title={"Daylight saving offset"}
                                                                    text={"Specify a daylight saving offset for the scheduled report."}/>
                                                                </span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <NumberBox
                                                                            disabled={this.state.showLoadingProgress}
                                                                            min={-10} max={10}
                                                                            value={this.state.daylightSavingForReport}
                                                                            id={"daylightSavingForReportInput"}
                                                                            showSpinButtons={true}
                                                                            ref={(e) => this.setInputFieldInstance({
                                                                                e,
                                                                                input: "daylightSavingForReportInput"
                                                                            })}
                                                                            onValueChanged={(e) => this.daylightSavingForReportInputOnValueChanged(e)}>
                                                                        </NumberBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="leopard-option-panel-block">
                                                                <div className={"leopard-leftmenu-settings-section"}>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <span>Timezone</span>
                                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"timezoneNameForReportInput_help"}
                                                                    title={"Timezone"}
                                                                    text={"Specify a timezone for the scheduled report."}/>
                                                                </span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{paddingLeft: "24px"}}>
                                                                        <SelectBox displayExpr={'name'}
                                                                                   id={"timezoneNameForReportSelect"}
                                                                                   value={this.state.timezoneNameForReport}
                                                                                   dataSource={this.timezoneDropdownList}
                                                                                   ref={(e) => this.setInputFieldInstance({
                                                                                       e,
                                                                                       input: "timezoneNameForReportSelect"
                                                                                   })}
                                                                                   valueExpr={'id'}
                                                                                   disabled={this.state.showLoadingProgress}
                                                                                   onValueChanged={
                                                                                       (e) => this.timezoneNameForReportSelectOnValueChanged(e)
                                                                                   }>
                                                                            <Validator>
                                                                                <RequiredRule type="custom"
                                                                                              validationCallback={
                                                                                                  (e) => this.customValidationRuleCallback({
                                                                                                      e,
                                                                                                      input: "timezoneNameForReportSelect",
                                                                                                      rules: [
                                                                                                          {rule: "required"}
                                                                                                      ]
                                                                                                  })
                                                                                              }/>
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.buildRecurrenceSettingFields()
                                                            }
                                                            <div style={{paddingLeft: "24px"}}>
                                                                <a href={"#_"}
                                                                   className={"leopard-dataview-report-addsource"}
                                                                   onClick={(e) => this.reportCustomSchedulesAddOnClick(e)}>
                                                                    Add Schedule
                                                                </a>
                                                            </div>
                                                            {
                                                                this.buildCustomRecurrenceSettingFields()
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                        }
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Query shaping</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"scriptShapingForReportInput_help"}
                                                    title={"Query shaping"}
                                                    text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"scriptShapingForReportInput"}
                                                      value={this.state.scriptShapingForReport}
                                                      placeholder={"Use the variable \"odataParams\", \"parameters\" and \"window.report_input_variables\" to access data and user inputs."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "scriptShapingForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.scriptShapingForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">5.</i>
                                            <span>Post data processing</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideQueryForReportInput_help"}
                                                    title={"Post data processing"}
                                                    text={"Write JavaScript code to manipulate data on the client-side."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"clientSideQueryForReportInput"}
                                                      value={this.state.clientSideQueryForReport}
                                                      placeholder={"Use the variable \"data\" to access the data."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "clientSideQueryForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.clientSideQueryForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">6.</i>
                                            <span>UI component processing</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideUILogicForReportInput_help"}
                                                    title={"UI component processing"}
                                                    text={"Write JavaScript code to change report's UI on the client-side."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"clientSideUILogicForReportInput"}
                                                      value={this.state.clientSideUILogicForReport}
                                                      placeholder={"Use the variable \"report\" to access the data."}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "clientSideUILogicForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.clientSideUILogicForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">7.</i>
                                            <span>Report dataview version (read only)</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"reportDataViewVersionInput_help"}
                                                    title={"Report dataview version"}
                                                    text={"Specify a version for the dataview (read only)."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox value={this.state.reportDataViewVersion} maxLength={50}
                                                     readOnly={true} id={"reportDataViewVersionInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "reportDataViewVersionInput"
                                                     })}
                                                     onValueChanged={(e) => this.reportDataViewVersionOnValueChanged(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">8.</i>
                                            <span>Filter validations</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"filterValidationsForReportInput_help"}
                                                    title={"Filter validations"}
                                                    text={"Write JavaScript code to create validations for filters."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"filterValidationsForReportInput"}
                                                      value={this.state.filterValidationsForReport}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "filterValidationsForReportInput"
                                                      })}
                                                      onValueChanged={(e) => this.filterValidationsForReportOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">9.</i>
                                            <span>Input values initialise logic</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"inputValuesInitLogicInput_help"}
                                                    title={"Input values initialise logic"}
                                                    text={"Write JavaScript code to initialise input values."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                      id={"inputValuesInitLogicInput"}
                                                      value={this.state.inputValuesInitLogic}
                                                      ref={(e) => this.setInputFieldInstance({
                                                          e,
                                                          input: "inputValuesInitLogicInput"
                                                      })}
                                                      onValueChanged={(e) => this.inputValuesInitLogicOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                                    {
                                        this.state.createDataViewDataSourceVersion !== "v2-athena" ? null :
                                            <div className="leopard-option-panel-block">
                                                <div>
                                                    <i className="leopard-option-panel-stepindex">10.</i>
                                                    <span>Rendering method</span>
                                                    <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"renderingMethodInput_help"}
                                                    title={"Rendering method"}
                                                    text={"Specify a rendering method for ad-hoc reports."}/>
                                            </span>
                                                </div>
                                                <div>
                                                    <SelectBox
                                                        dataSource={LeopardDropdownHelper.DropdownSelectionReportRenderingMethod}
                                                        displayExpr={'name'}
                                                        disabled={this.state.showLoadingProgress}
                                                        id={"renderingMethodInput"}
                                                        ref={(e) => this.setInputFieldInstance({
                                                            e,
                                                            input: "renderingMethodInput"
                                                        })}
                                                        valueExpr={'id'}
                                                        value={this.state.renderingMethod}
                                                        onValueChanged={
                                                            (e) => this.renderingMethodOnValueChanged(e)
                                                        }>
                                                    </SelectBox>
                                                </div>
                                            </div>
                                    }
                                    {
                                        this.state.createDataViewDataSourceVersion !== "v2-athena" ? null :
                                            <div className="leopard-option-panel-block">
                                                <div>
                                                    <i className="leopard-option-panel-stepindex"
                                                       style={{float: "left"}}>11.</i>
                                                    <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                        <span>Notification</span>
                                                        <span style={{float: "right"}}>
                                                <Switch value={this.state.notificationEnabled}
                                                        width={"60px"}
                                                        switchedOffText={"OFF"} switchedOnText={"ON"}
                                                        id={"notificationEnabled"}
                                                        disabled={this.state.switchDisabledForNotification || (!this.state.switchDisabledForNotification && this.state.showLoadingProgress)}
                                                        onValueChanged={(e) => this.notificationEnabledOnValueChanged(e)}
                                                />
                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </React.Fragment>
                        }
                        <React.Fragment>
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {this.state.showMenuOptionPanel.dataViewCategory !== "report" ? "1." :
                                            this.state.createDataViewDataSourceVersion !== "v2-athena" ? "10." : "12."}
                                    </i>
                                    <span>Rename {nameToDisplay}</span>
                                    <span style={{marginLeft: "5px"}}>
                                         <LeopardTooltipWithIcon
                                             elementId={"dataViewNameInput_help"}
                                             title={"Rename " + nameToDisplay}
                                             text={"Give a name for your " + nameToDisplay + ". Maximum 50 characters."}>
                                         </LeopardTooltipWithIcon>
                                    </span>
                                </div>
                                <div>
                                    <TextBox defaultValue={this.state.editDataViewName} maxLength={50}
                                             disabled={this.state.showLoadingProgress} id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({e, input: "dataViewNameInput"})}
                                             onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            {
                                (this.state.editDataViewType !== "timeline") ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">2.</i>
                                                <span>Color definition for resources</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"timelineResourceDefinitionInput_help"}
                                                    title={"Color definition for resources"}
                                                    text={"Create a color definition for resources."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineResourceDefinitionInput"}
                                                          value={this.state.timelineResourceDefinitionInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineResourceDefinitionInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineResourceDefinitionInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">3.</i>
                                                <span>OData query for grouping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"timelineOdataQueryForGroupingInput_help"}
                                                    title={"OData query for grouping"}
                                                    text={"Create an odata query for grouping."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineOdataQueryForGroupingInput"}
                                                          value={this.state.timelineOdataQueryForGroupingInput}
                                                          placeholder={"reports/odata/Transaction?$filter..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineOdataQueryForGroupingInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineOdataQueryForGroupingInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">4.</i>
                                                <span>Post data processing for grouping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelinePostDataProcessingForGroupingInput_help"}
                                                        title={"Post data processing for grouping"}
                                                        text={"Write a JavaScript code to process data that was retrieved from the backend services."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelinePostDataProcessingForGroupingInput"}
                                                          value={this.state.timelinePostDataProcessingForGroupingInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelinePostDataProcessingForGroupingInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelinePostDataProcessingForGroupingInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">5.</i>
                                                <span>OData query for timeline view</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelineOdataQueryForResourcesInput_help"}
                                                        title={"OData query for timeline view"}
                                                        text={"Create an odata query for timeline view."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineOdataQueryForResourcesInput"}
                                                          value={this.state.timelineOdataQueryForResourcesInput}
                                                          placeholder={"reports/odata/Transaction?$filter..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineOdataQueryForResourcesInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineOdataQueryForResourcesInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">6.</i>
                                                <span>Post data processing for timeline view</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelinePostDataProcessingForResourcesInput_help"}
                                                        title={"Post data processing for timeline view"}
                                                        text={"Write a JavaScript code to process data that was retrieved from the backend services."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelinePostDataProcessingForResourcesInput"}
                                                          value={this.state.timelinePostDataProcessingForResourcesInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelinePostDataProcessingForResourcesInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelinePostDataProcessingForResourcesInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">7.</i>
                                                <span>Resource data key</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelineDataKeysInput_help"}
                                                        title={"Resource data key"}
                                                        text={"Specify a data key for a resource."}/>
                                                </span>
                                            </div>
                                            <div style={{marginTop: "5px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={500}
                                                         value={this.state.timelineDataKeyForResourcesInput}
                                                         id={"timelineDataKeyForResourcesInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "timelineDataKeyForResourcesInput"
                                                         })}
                                                         onValueChanged={(e) => this.timelineDataKeyForResourcesInputOnValueChanged(e)}>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                (this.state.editDataViewType !== "datagrid") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Data source interface</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"gridViewEngineInput_help"}
                                                    title={"Data source interface"}
                                                    text={"Specify a data source interface."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"gridViewEngineInput"}
                                                       value={this.state.gridViewEngine}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionGridViewEngine}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "gridViewEngineInput"
                                                       })}
                                                       valueExpr={'id'} disabled={true}
                                                       onValueChanged={
                                                           (e) => this.gridViewEngineInputOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "gridViewEngineInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.editDataViewType !== "datagrid" || this.state.gridViewEngine !== "json-engine") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Data source type</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"jsonDataSourceTypeInput_help"}
                                                    title={"Data source type"}
                                                    text={"Specify a data source type for the JSON data."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"jsonDataSourceTypeInput"}
                                                       value={this.state.jsonDataSourceType}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionJSONDataSource}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "jsonDataSourceTypeInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.jsonDataSourceTypeInputOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "jsonDataSourceTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.editDataViewType !== "datagrid" || this.state.gridViewEngine !== "json-engine") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Data source request type</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"dataSourceRequestTypeInput_help"}
                                                    title={"Data source request type"}
                                                    text={"Specify a data source request type."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={500}
                                                     defaultValue={this.state.dataSourceRequestType}
                                                     id={"docTypeOverrideInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "dataSourceRequestTypeInput"
                                                     })}
                                                     onValueChanged={(e) => this.dataSourceRequestTypeInputOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "dataSourceRequestTypeInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {(this.state.showMenuOptionPanel.dataViewCategory === "report" ||
                                this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard" ||
                                this.state.editDataViewType === "photo" || this.state.editDataViewType === "notepad" ||
                                this.state.editDataViewType === "form-editor" || this.state.editDataViewType === "timeline" ||
                                this.state.editDataViewType === "tabbed-view" || this.state.editDataViewType === "html-form-builder" ||
                                this.state.editDataViewType === "regex-tester" || this.state.editDataViewType === "map" ||
                                this.state.editDataViewType === "diagram" || this.state.editDataViewType === "document-editor") ? null :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">
                                            {
                                                this.state.editDataViewType === "chart" ? "2." :
                                                    (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "3." : "5."
                                            }
                                        </i>
                                        <span>Post data processing</span>
                                        <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"clientSideQueryEditor_help"}
                                                title={"Post data processing"}
                                                text={"Write a custom logic in Javascript to process the data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                    </div>
                                    <div>
                                        <TextArea disabled={this.state.showLoadingProgress} height={"250px"}
                                                  id={"clientSideQueryEditorInput"}
                                                  value={this.state.clientSideQueryEditor}
                                                  placeholder={"Use the variable \"data\" to access the data."}
                                                  ref={(e) => this.setInputFieldInstance({
                                                      e,
                                                      input: "clientSideQueryEditorInputInput"
                                                  })}
                                                  onValueChanged={(e) => this.clientSideQueryEditorOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "clientSideQueryEditorInput",
                                                        rules: [
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextArea>
                                    </div>
                                </div>
                            }
                            {
                                this.state.showMenuOptionPanel.dataViewCategory !== "analytics-dashboard" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>QuickSight dashboard ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"quicksightDashboardIdInput_help"}
                                                    title={"QuickSight dashboard ID"}
                                                    text={"Specify a QuickSight dashboard ID."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                                     value={this.state.quicksightDashboardIdInput}
                                                     id={"quicksightDashboardIdInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "quicksightDashboardIdInput"
                                                     })}
                                                     onValueChanged={(e) => this.quicksightDashboardIdInputOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "quicksightDashboardIdInput",
                                                            rules: [
                                                                {rule: "required"},
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                        </React.Fragment>
                        {
                            this.state.editDataViewType !== "notepad" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0px"}}>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Text on submit button:</span>
                                        <span style={{marginLeft: "5px"}}>
                                               <LeopardTooltipWithIcon
                                                   elementId={"notepadSubmitButtonTextInput_help"}
                                                   title={"Text on submit button"}
                                                   text={"Specify a custom text on the submit button."}/>
                                        </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.notepadButtonText}
                                                 id={"notepadSubmitButtonTextInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e,
                                                     input: "notepadSubmitButtonTextInput"
                                                 })}
                                                 onValueChanged={(e) => this.notepadButtonTextOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "notepadSubmitButtonTextInput",
                                                        rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "notepad" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0px"}}>
                                        <i className="leopard-option-panel-stepindex">3.</i>
                                        <span>Data submission logic:</span>
                                        <span style={{marginLeft: "5px"}}>
                                               <LeopardTooltipWithIcon
                                                   elementId={"notepadSubmitLogicInput_help"}
                                                   title={"Data submission logic"}
                                                   text={"Specify a custom javacript logic for data submission."}/>
                                        </span>
                                    </div>
                                    <div>
                                        <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                  id={"notepadSubmitLogicInput"}
                                                  value={this.state.notepadDataSubmissionLogic}
                                                  onValueChanged={(e) => this.notepadDataSubmissionLogicOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "document-editor" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0px"}}>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Document format</span>
                                        <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"documentEditorDataFormatInput_help"}
                                                title={"Document format"}
                                                text={"Specify the format of document that will be retrieved via API Gateway."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                    </div>
                                    <div>
                                        <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                   id={"documentEditorDataFormatInput"}
                                                   value={this.state.documentEditorDataFormat}
                                                   dataSource={LeopardDropdownHelper.DropdownSelectionDocumentEditorDataFormat}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "documentEditorDataFormatInput"
                                                   })}
                                                   disabled={this.state.showLoadingProgress}
                                                   onValueChanged={
                                                       (e) => this.documentEditorDataFormatOnValueChanged(e)
                                                   }>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "documentEditorDataFormatInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </SelectBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "document-editor" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0px"}}>
                                        <i className="leopard-option-panel-stepindex">3.</i>
                                        <span>Allow standalone access:</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"documentEditorAllowStandaloneAccessInput_help"}
                                                        title={"Allow standalone access"}
                                                        text={"If the standalone access is allowed, then the user can access the view directly in a Data View, " +
                                                            "otherwise this view can only be accessed through a Workspace."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                   id={"documentEditorAllowStandaloneAccessInput"}
                                                   value={this.state.documentEditorAllowStandaloneAccess}
                                                   dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "documentEditorAllowStandaloneAccessInput"
                                                   })}
                                                   disabled={this.state.showLoadingProgress}
                                                   onValueChanged={
                                                       (e) => this.documentEditorAllowStandaloneAccessOnValueChanged(e)
                                                   }>
                                        </SelectBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "document-editor" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0"}}>
                                        <i className="leopard-option-panel-stepindex">4.</i>
                                        <span>Data schema:</span>
                                        <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"documentEditorDataSchemaInput_help"}
                                                title={"Data schema"}
                                                text={"Specify a data schema for the document editor."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                  id={"documentEditorDataSchemaInput"}
                                                  value={this.state.documentEditorDataSchema}
                                                  onValueChanged={(e) => this.documentEditorDataSchemaOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "document-editor" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div style={{marginBottom: "0px"}}>
                                        <i className="leopard-option-panel-stepindex">5.</i>
                                        <span>UI schema:</span>
                                        <span style={{marginLeft: "5px"}}>
                                        <LeopardTooltipWithIcon
                                            elementId={"documentEditorUISchemaInput_help"}
                                            title={"UI schema"}
                                            text={"Specify an UI schema for the document editor."}/>
                                        </span>
                                    </div>
                                    <div>
                                        <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                  id={"documentEditorUISchemaInput"}
                                                  value={this.state.documentEditorUISchema}
                                                  onValueChanged={(e) => this.documentEditorUISchemaOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Height of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.heightOfThumbnailForPhotoGallery}
                                                   id={"heightOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "heightOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">3.</i>
                                        <span>Width of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.widthOfThumbnailForPhotoGallery}
                                                   id={"widthOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "widthOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">4.</i>
                                        <span>Default tab</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetDefaultTabInput_help"}
                                                        title={"Default tab"}
                                                        text={"Specify a default tab for the data view."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                   id={"photoWidgetDefaultTabInput"}
                                                   value={this.state.photoGalleryDefaultTab}
                                                   dataSource={LeopardDropdownHelper.DropdownSelectionPhotoGalleryDefaultTab}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e,
                                                       input: "photoWidgetDefaultTabInput"
                                                   })}
                                                   disabled={this.state.showLoadingProgress}
                                                   onValueChanged={
                                                       (e) => this.photoGalleryDefaultTabOnValueChanged(e)
                                                   }>
                                        </SelectBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">5.</i>
                                        <span>Disabled tabs</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetDisabledTabsInput_help"}
                                                        title={"Disabled tabs"}
                                                        text={"Specify disabled tabs for the data view."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TagBox items={LeopardDropdownHelper.DropdownSelectionPhotoGalleryDefaultTab}
                                                id={"photoWidgetDisabledTabsInput"}
                                                showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                value={this.state.photoGalleryDisabledTabs}
                                                onValueChanged={
                                                    (e) => this.photoGalleryDisabledTabsOnValueChanged(e)
                                                }
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "photoWidgetDisabledTabsInput"
                                                })}
                                                disabled={this.state.showLoadingProgress}
                                                applyValueMode="useButtons"/>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">6.</i>
                                        <span>Report payload source ID</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetReportPayloadSourceIdInput_help"}
                                                        title={"Report payload source ID"}
                                                        text={"Specify a report payload source ID for the data view."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.photoGalleryReportPayloadSourceId}
                                                 id={"photoWidgetReportPayloadSourceIdInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e,
                                                     input: "photoWidgetReportPayloadSourceIdInput"
                                                 })}
                                                 onValueChanged={(e) => this.photoGalleryReportPayloadSourceIdOnValueChanged(e)}>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">7.</i>
                                        <span>Report data source ID</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetReportDataSourceIdInput_help"}
                                                        title={"Report data source ID"}
                                                        text={"Specify a report data source ID for the data view."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.photoGalleryReportDataSourceId}
                                                 id={"photoWidgetReportDataSourceIdInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e,
                                                     input: "photoWidgetReportDataSourceIdInput"
                                                 })}
                                                 onValueChanged={(e) => this.photoGalleryReportDataSourceIdOnValueChanged(e)}>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">8.</i>
                                        <span>Photo data source ID</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Photo data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.parentDataSourceIdForPhotoGallery}
                                                 id={"parentDataSourceIdForPhotoInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e,
                                                     input: "parentDataSourceIdForPhotoInput"
                                                 })}
                                                 onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                        {this.state.showMenuOptionPanel.dataViewCategory === "report" ? null :
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {
                                            (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "4." :
                                                (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "json-engine") ? "6." :
                                                    this.state.editDataViewType === "photo" ? "9." :
                                                        this.state.editDataViewType === "chart" ? "3." :
                                                            this.state.editDataViewType === "regex-tester" ? "2." :
                                                                this.state.editDataViewType === "map" ? "2." :
                                                                    this.state.editDataViewType === "document-editor" ? "6." :
                                                                        this.state.editDataViewType === "timeline" ? "8." :
                                                                            this.state.editDataViewType === "tabbed-view" ? "2." :
                                                                                this.state.editDataViewType === "notepad" ? "4." :
                                                                                    this.state.editDataViewType === "diagram" ? "2." : "3."
                                        }
                                    </i>
                                    <span>Persistent ID</span>
                                    <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewPersistentId_help"}
                                                title={"Persistent ID"}
                                                text={"The Persistent ID is used for associating each Data View in a Workspace."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                </div>
                                <div>
                                    <TextBox value={this.state.dataViewPersistentId} id={"dataViewPersistentIdInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e,
                                                 input: "dataViewPersistentIdInput"
                                             })} disabled={this.state.showLoadingProgress}
                                             onValueChanged={(e) => this.dataViewPersistentIdOnValueChanged(e)}>
                                        <TextBoxButton
                                            name={"dataViewPersistentIdInput_MiniButton"}
                                            location={'after'} options={{
                                            icon: "fas fa-sync",
                                            type: 'default',
                                            onClick: () => {
                                                this.setState({dataViewPersistentId: LDH.GenerateGuid()});
                                            }
                                        }}
                                        />
                                    </TextBox>
                                </div>
                            </div>
                        }
                        {this.state.showMenuOptionPanel.dataViewCategory === "report" ? null :
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {
                                            (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "5." :
                                                (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "json-engine") ? "7." :
                                                    this.state.editDataViewType === "photo" ? "10." :
                                                        this.state.editDataViewType === "chart" ? "4." :
                                                            this.state.editDataViewType === "regex-tester" ? "3." :
                                                                this.state.editDataViewType === "map" ? "3." :
                                                                    this.state.editDataViewType === "document-editor" ? "7." :
                                                                        this.state.editDataViewType === "timeline" ? "9." :
                                                                            this.state.editDataViewType === "tabbed-view" ? "3." :
                                                                                this.state.editDataViewType === "notepad" ? "5." :
                                                                                    this.state.editDataViewType === "diagram" ? "3." : "4."
                                        }
                                    </i>
                                    <span>Data view ID (read only)</span>
                                    <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewIdReadOnly_help"}
                                                title={"Data view ID (read only)"}
                                                text={"This is the ID of the data view, the value cannot be changed by user."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                </div>
                                <div>
                                    <TextBox value={this.props.state.selectedLeftMenuItem.dataViewId}
                                             id={"dataViewIdReadOnlyInput"}
                                             disabled={this.state.showLoadingProgress} readOnly={true}>
                                    </TextBox>
                                </div>
                            </div>
                        }
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">
                                    {
                                        (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "6." :
                                            (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "json-engine") ? "8." :
                                                this.state.editDataViewType === "photo" ? "11." :
                                                    this.state.editDataViewType === "chart" ? "5." :
                                                        this.state.editDataViewType === "regex-tester" ? "4." :
                                                            this.state.editDataViewType === "map" ? "4." :
                                                                this.state.editDataViewType === "document-editor" ? "8." :
                                                                    this.state.editDataViewType === "timeline" ? "10." :
                                                                        this.state.editDataViewType === "tabbed-view" ? "4." :
                                                                            this.state.editDataViewType === "diagram" ? "4." :
                                                                                this.state.editDataViewType === "notepad" ? "6." :
                                                                                    this.state.editDataViewType === "report" ? (
                                                                                        this.state.createDataViewDataSourceVersion !== "v2-athena" ? "11." : "13."
                                                                                    ) : "5."
                                    }
                                </i>
                                <span>Hide menu item</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"hideMenuItemInput_help"}
                                                            title={"Hide menu item"}
                                                            text={"Hide or show the menu item."}/>
                                </span>
                            </div>
                            <div>
                                <SelectBox displayExpr={'name'} valueExpr={'id'}
                                           id={"hideMenuItemInput"}
                                           value={this.state.hideMenuItem}
                                           dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "hideMenuItemInput"
                                           })}
                                           disabled={this.state.showLoadingProgress}
                                           onValueChanged={
                                               (e) => this.hideMenuItemOnValueChanged(e)
                                           }>
                                </SelectBox>
                            </div>
                        </div>
                        {this.state.showMenuOptionPanel.dataViewCategory === "report" ||
                        this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard" ? null :
                            <div className="leopard-option-panel-block" style={{display: "none"}}>
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {
                                            (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "5." :
                                                (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "json-engine") ? "7." :
                                                    this.state.editDataViewType === "photo" ? "6." :
                                                        this.state.editDataViewType === "regex-tester" ? "3." :
                                                            this.state.editDataViewType === "map" ? "3." :
                                                                this.state.editDataViewType === "document-editor" ? "7." :
                                                                    this.state.editDataViewType === "timeline" ? "9." :
                                                                        this.state.editDataViewType === "tabbed-view" ? "3." :
                                                                            this.state.editDataViewType === "diagram" ? "3." :
                                                                                this.state.editDataViewType === "notepad" ? "5." : "4."
                                        }
                                    </i>
                                    <span>Menu category</span>
                                    <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewMenuCategorySelect_help"}
                                                title={"Menu category"}
                                                text={"Move the Data View to the selected category."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                </div>
                                <div>
                                    <SelectBox displayExpr={'name'} id={"dataViewMenuCategorySelect"}
                                               value={this.state.dataViewMenuCategorySelect}
                                               dataSource={LeopardDropdownHelper.DropdownSelectionDataViewMenuCategory}
                                               ref={(e) => this.setInputFieldInstance({
                                                   e,
                                                   input: "dataViewMenuCategorySelect"
                                               })}
                                               valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                               onValueChanged={
                                                   (e) => this.dataViewMenuCategorySelectOnValueChanged(e)
                                               }>
                                    </SelectBox>
                                </div>
                            </div>
                        }
                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                            <div>
                                <i className="leopard-option-panel-stepindex">
                                    {
                                        (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "odata-engine") ? "6." :
                                            (this.state.editDataViewType === "datagrid" && this.state.gridViewEngine === "json-engine") ? "8." :
                                                this.state.editDataViewType === "photo" ? "7." :
                                                    this.state.editDataViewType === "document-editor" ? "8." :
                                                        this.state.editDataViewType === "regex-tester" ? "4." :
                                                            this.state.editDataViewType === "map" ? "4." :
                                                                this.state.editDataViewType === "report" ? "9." :
                                                                    this.state.editDataViewType === "timeline" ? "10." :
                                                                        this.state.editDataViewType === "tabbed-view" ? "4." :
                                                                            this.state.editDataViewType === "diagram" ? "4." :
                                                                                this.state.editDataViewType === "notepad" ? "6." : "5."
                                    }
                                </i>
                                <span>Comments / Remarks</span>
                                <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"dataViewCustomComments_help"}
                                                title={"Comments / Remarks"}
                                                text={"You can enter any custom information you want in this field."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                            </div>
                            <div>
                                <TextArea disabled={this.state.showLoadingProgress} height={"200px"}
                                          id={"dataViewCustomCommentsInput"}
                                          value={this.state.dataViewCustomComments}
                                          onValueChanged={(e) => this.dataViewCustomCommentsOnValueChanged(e)}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon editpanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Delete'} id={"createDataViewDeleteButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewDeleteButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'} id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'} id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    dashboardManageRelationshipAddOnClick = () => {
        let passed = true;
        for (let i = 0; i < this.state.dashboardEditManageRelationship.length; i++) {
            let relationship = this.state.dashboardEditManageRelationship[i];
            if (LDH.IsValueEmpty(relationship.parentDataViewId) ||
                LDH.IsValueEmpty(relationship.childDataViewId)) {
                LRH.ShowToast("Please specify all the parents and children before adding a new entry.", "error", 5000);
                passed = false;
                break;
            }
        }
        if (passed === false) return;

        let relationships = this.state.dashboardEditManageRelationship;
        relationships.push({
            parentDataViewId: "",
            childDataViewId: "",
            interactiveFeatures: [],
            dataSourceId: "",
            dataColumns: [],
            relationshipId: LDH.GenerateGuid()
        });
        this.setState({dashboardEditManageRelationship: relationships});
    };

    manageRelationshipDeleteItem = (e) => {
        let that = this;
        LRH.ShowDialog("Are you sure you want to delete this relationship?", "Confirm Delete", function () {
            let list = that.state.dashboardEditManageRelationship;
            let result = [];
            for (let i = 0; i < list.length; i++) {
                if (e.item.relationshipId !== list[i].relationshipId) {
                    result.push(list[i]);
                }
            }
            that.setState({dashboardEditManageRelationship: result});
        });
    };

    configItemBoxValueOnChange = (data) => {
        if (this.isConfigItemBoxValueOnChangeDisabled === true) {
            this.isConfigItemBoxValueOnChangeDisabled = false;
            return;
        }
        let list = this.state.dashboardEditManageRelationship;

        for (let i = 0; i < list.length; i++) {
            let dataItem = list[i];
            if (data.item.relationshipId !== dataItem.relationshipId) {
                continue;
            }

            if (data.inputField === "parent") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.e.value, data.item.childDataViewId,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.parentDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "child") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.item.parentDataViewId, data.e.value,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.childDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "features") {
                dataItem.interactiveFeatures = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            }
        }
    };

    buildMenuOptionPanelManageDashboardRelationship = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Manage Relationship
                </div>
                <form onSubmit={this.manageDashboardRelationshipApplyOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Configure relationship
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon elementId={"dashboardManageRelationship_help"}
                                                                    title={"Manage relationship"}
                                                                    text={"Create and manage relationships between data views."}/>
                                </span>
                                <i className={"leopard-menuoption-add-button fas fa-plus"} onClick={
                                    (e) => this.dashboardManageRelationshipAddOnClick(e)}
                                />
                            </div>
                        </div>
                        <LeopardMenuConfigItemBox relationships={this.state.dashboardEditManageRelationship}
                                                  dataSource={this.state.selectedDataViewsForDashboard}
                                                  onDelete={(e) => this.manageRelationshipDeleteItem(e)}
                                                  setInputFieldInstance={(e) => this.setInputFieldInstance(e)}
                                                  configItemBoxValueOnChange={(e) => this.configItemBoxValueOnChange(e)}
                                                  showLoadingProgress={this.state.showLoadingProgress}/>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon editpanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDashboardOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                {
                    this.state.showProtectiveCoverForEditWorkspace ?
                        <div className={"leopard-editpanel-protective-cover"}></div> : null
                }
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "edit_template" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-editworkspace"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "edit_template",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Manage workspace
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "manage_dashboard_relationship" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 id={"leftmenu-options-editworkspace-relationship"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "manage_dashboard_relationship",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Manage relationship
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "edit_dashboard_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                 <span className={"leopard-loading-icon editpanel"} style={{
                                     marginRight: "15px", marginLeft: "30px",
                                     display: this.state.showLoadingProgress === false ? "none" : "block"
                                 }}>
                                    <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                                </span>
                                <span style={{paddingRight: "15px"}}>
                                    <Button className="leopard-button" text={'Delete'}
                                            disabled={this.state.showLoadingProgress} onClick={
                                        () => this.createDashboardDeleteButtonOnClick()}/>
                                </span>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    disableMenuOptionsForPredefinedFolders = (data) => {
        let isDisabled = false;
        if (data.menuData.item.menuItemId === "directory_predefined_workspaces" ||
            data.menuData.item.menuItemId === "directory_predefined_dataviews" ||
            data.menuData.item.menuItemId === "directory_predefined_defaultviews" ||
            data.menuData.item.menuItemId === "directory_predefined_reports" ||
            data.menuData.item.menuItemId === "directory_predefined_analytics" ||
            data.menuData.item.menuItemId === "directory_predefined_systemviews") {
            isDisabled = true;
        }
        return isDisabled;
    }

    buildMenuOptionPanelCreateWorkspaceOptions = (data) => {
        let isDisabled = this.disableMenuOptionsForPredefinedFolders(data);

        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content"
                     style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "add_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-adddataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "add_template",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Create a new Workspace
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "clone_dataview_from_workspace" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-clonedataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "clone_dataview_from_workspace",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Clone an existing Workspace
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "import_dataview_from_workspace" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-importdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "import_dataview_from_workspace",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Import Workspace(s)
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "export_dataview_from_workspace" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-exportdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "export_dataview_from_workspace",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Export Workspace(s)
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "workspace_preferences" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-workspacesettings"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "workspace_preferences",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Preferences
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "create_workspace_subfolder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-createsubfolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "create_workspace_subfolder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Create Sub Folder
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "rename_workspace_folder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption" + " " + (isDisabled ? "disabled" : "")}
                                 id={"leftmenu-options-renamefolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "rename_workspace_folder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData,
                                     disabled: isDisabled
                                 })}>
                                Rename Current Folder
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "delete_workspace_folder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption" + " " + (isDisabled ? "disabled" : "")}
                                 id={"leftmenu-options-deletefolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "delete_workspace_folder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData,
                                     disabled: isDisabled
                                 })}>
                                Delete Current Folder
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "add_template_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewOptions = (data) => {
        let nameToDisplay = "";
        if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            nameToDisplay = "Data View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            nameToDisplay = "System View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            nameToDisplay = "Default View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            nameToDisplay = "Analytics";
        }

        let isDisabled = this.disableMenuOptionsForPredefinedFolders(data);

        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content"
                     style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "add_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-adddataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "add_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Create a new {nameToDisplay}
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "clone_dataview_from_view" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-clonedataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "clone_dataview_from_view",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Clone an existing {nameToDisplay}
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "import_dataview_from_view" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-importdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "import_dataview_from_view",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                {nameToDisplay === "Analytics" ? "Import " + nameToDisplay : "Import " + nameToDisplay + "(s)"}
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "export_dataview_from_view" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-exportdataview"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "export_dataview_from_view",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                {nameToDisplay === "Analytics" ? "Export " + nameToDisplay : "Export " + nameToDisplay + "(s)"}
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "dataview_preferences" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-workspacesettings"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "dataview_preferences",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Preferences
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "create_dataview_subfolder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"} id={"leftmenu-options-createsubfolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "create_dataview_subfolder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData
                                 })}>
                                Create Sub Folder
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "rename_dataview_folder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption" + " " + (isDisabled ? "disabled" : "")}
                                 id={"leftmenu-options-renamefolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "rename_dataview_folder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData,
                                     disabled: isDisabled
                                 })}>
                                Rename Current Folder
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                            (this.state.showMenuOptionPanel.option === "delete_dataview_folder" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption" + " " + (isDisabled ? "disabled" : "")}
                                 id={"leftmenu-options-deletefolder"}
                                 onClick={() => this.showMenuOptionPanelStatusOnChange({
                                     option: "delete_dataview_folder",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory,
                                     menuData: data.menuData,
                                     disabled: isDisabled
                                 })}>
                                Delete Current Folder
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "add_dataview_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    onSelectedFilesChanged = (data) => {
        let that = this;
        let isBulkImport = false;
        $(".dx-fileuploader-button.dx-fileuploader-upload-button").remove();

        let dataToImport = [];
        let dataToImportDisplay = [{
            key: "Workspaces",
            items: []
        }, {
            key: "Data Views",
            items: []
        }];
        for (let i = 0; i < data.e.value.length; i++) {
            let reader = new FileReader();
            reader.onload = function (event) {
                let fileData = JSON.parse(event.target.result);
                let jsonData = [];
                if (Array.isArray(fileData) === true) {
                    jsonData = fileData;
                } else {
                    jsonData = fileData.data;
                    let stopImport = false;
                    if (data.option === "import_dataview_from_workspace" &&
                        fileData.origin !== "export_dataview_from_workspace") {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        LRH.ShowToast("We're unable to import data from this file. " +
                            "This file cannot be used for importing Workspaces.", "error", 5000);
                        stopImport = true;
                    } else if (data.option === "import_dataview_from_view" &&
                        fileData.origin === "export_dataview_from_workspace") {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        LRH.ShowToast("We're unable to import data from this file. " +
                            "This file cannot be used for importing Data Views or Reports.", "error", 5000);
                        stopImport = true;
                    } else if (data.option === "import_dataview_from_view" && data.category !== "report" &&
                        fileData.origin === "export_dataview_from_view" && fileData.category === "report") {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        LRH.ShowToast("We're unable to import data from this file. " +
                            "This file cannot be used for importing Data Views.", "error", 5000);
                        stopImport = true;
                    } else if (data.option === "import_dataview_from_view" && data.category === "report" &&
                        fileData.origin === "export_dataview_from_view" && fileData.category !== "report") {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        LRH.ShowToast("We're unable to import data from this file. " +
                            "This file cannot be used for importing Reports.", "error", 5000);
                        stopImport = true;
                    }

                    if (stopImport === true) {
                        that.setState({
                            numberOfDataViewsToImportVisible: false,
                            dataViewListToImport: [],
                            numberOfDataViewsToImport: dataToImportDisplay
                        });
                        return;
                    }
                }
                if (!LDH.IsObjectNull(jsonData) && Array.isArray(jsonData)) {
                    for (let j = 0; j < jsonData.length; j++) {
                        dataToImport.push(JSON.stringify(jsonData[j]));
                        if (!LDH.IsObjectNull(jsonData[j].dashboardId)) {
                            dataToImportDisplay[0].items.push(jsonData[j]);
                        } else {
                            jsonData[j]["dataViewCategoryToImport"] = "default-view";
                            dataToImportDisplay[1].items.push(jsonData[j]);
                        }
                        isBulkImport = true;
                    }
                } else {
                    dataToImport.push(event.target.result);
                }
                if (data.e.value.length - 1 === i) {
                    let visible = that.state.numberOfDataViewsToImportVisible;
                    if (isBulkImport) {
                        that.uiObjectInstance["exportDataViewFileUploader"].reset();
                        visible = true;
                    }
                    that.setState({
                        numberOfDataViewsToImportVisible: visible,
                        dataViewListToImport: dataToImport,
                        numberOfDataViewsToImport: dataToImportDisplay
                    }, function () {
                        if (that.state.numberOfDataViewsToImport.length === 0 ||
                            (that.state.numberOfDataViewsToImport[0].items.length === 0 &&
                                that.state.numberOfDataViewsToImport[1].items.length === 0)) {
                            that.setState({numberOfDataViewsToImport: false});
                        }
                    });
                }
            };
            reader.readAsText(data.e.value[i]);
        }
    };

    buildMenuOptionPanelImportDataView = () => {
        let nameToDisplay = "";
        let option = this.state.showMenuOptionPanel.option;
        let category = this.state.showMenuOptionPanel.dataViewCategory;

        if (this.state.showMenuOptionPanel.option === "import_dataview_from_workspace") {
            nameToDisplay = "Workspace";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            nameToDisplay = "Data View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            nameToDisplay = "System View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            nameToDisplay = "Default View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            nameToDisplay = "Analytics";
        }

        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {nameToDisplay === "Analytics" ? "Import " + nameToDisplay : "Import " + nameToDisplay + "(s)"}
                </div>
                <form onSubmit={this.importDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select a file to import</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"selectFilesToImport_help"}
                                        title={"Select a file to import"}
                                        text={"You can import " + nameToDisplay + " from a file."}>
                                    </LeopardTooltipWithIcon>
                                </span>
                            </div>
                            <div>
                                <FileUploader multiple={true} uploadMode={"useButtons"}
                                              readyToUploadMessage={"Ready to import"}
                                              accept={'.json'}
                                              ref={(e) => this.setInputFieldInstance({
                                                  e,
                                                  input: "exportDataViewFileUploader"
                                              })}
                                              onValueChanged={(e) => this.onSelectedFilesChanged({
                                                  e,
                                                  option,
                                                  category
                                              })}/>
                            </div>
                            <div>
                                <div className={"leopard-listcontainer"} style={{marginTop: "5px"}}>
                                    <List allowItemDeleting={true} activeStateEnabled={false}
                                          hoverStateEnabled={false} focusStateEnabled={false}
                                          visible={this.state.numberOfDataViewsToImportVisible}
                                          id={this.state.showMenuOptionPanel.option === "import_dataview_from_workspace"
                                              ? "import_dataview_list_workspaces" : "import_dataview_list_dataviews"}
                                          onContentReady={(e) => this.importListOnContentReady({e, option})}
                                          grouped={true} onItemDeleted={
                                        (e) => this.numberOfDataViewsToImportOnDeleted({e, option})
                                    }
                                          dataSource={this.state.numberOfDataViewsToImport}
                                          ref={(e) => this.setInputFieldInstance({
                                              e,
                                              input: "numberOfDataViewsToImportListControl"
                                          })}
                                          itemRender={(e) => this.numberOfDataViewsToImportTemplate({
                                              item: e, datasource: this.state.numberOfDataViewsToImport,
                                              option: this.state.showMenuOptionPanel.option
                                          })}/>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Data View name prefix</span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                         defaultValue={this.state.dataViewNamePrefixForImport}
                                         id={"dataViewNamePrefixForImportInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewNamePrefixForImportInput"
                                         })}
                                         onValueChanged={(e) => this.dataViewNamePrefixForImportOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e,
                                                input: "dataViewNamePrefixForImportInput",
                                                rules: [
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">3.</i>
                                <span>Use existing IDs (select YES if importing menu structure)</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                           valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"dataViewUseExistingIDsInput"} displayExpr={'name'}
                                           onValueChanged={(e) => this.dataViewUseExistingIdsValueOnChange(e)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "dataViewUseExistingIDsInput"
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon importpanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress || !this.state.numberOfDataViewsToImportVisible}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCloneDataView = () => {
        let nameToDisplay = "";
        let clonedDataViewList = [];

        if (this.state.showMenuOptionPanel.option === "clone_dataview_from_workspace") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Workspace";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "dashboard" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList[i]["visible"] = false;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Data View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "data-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList[i]["visible"] = false;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "System View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "system-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList[i]["visible"] = false;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Default View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "default-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList[i]["visible"] = false;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            clonedDataViewList = LDH.DeepClone(this.state.availableReportListForEdit);
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            clonedDataViewList = LDH.DeepClone(this.state.availableAnalyticsListForEdit);
            nameToDisplay = "Analytics";
        }

        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Clone an existing {nameToDisplay}
                </div>
                <form onSubmit={this.cloneDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Select a {nameToDisplay} to clone
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"selectFilesToClone_help"}
                                         title={"Select " + nameToDisplay + " to clone"}
                                         text={"You can clone a " + nameToDisplay + " from another " + nameToDisplay + "."}/>
                                    </span>
                            </div>
                            <div>
                                <SelectBox dataSource={clonedDataViewList}
                                           valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"cloneDataViewSelectInput"} displayExpr={'name'}
                                           onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "cloneDataViewSelectInput"
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of {nameToDisplay}</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewNameInput_help"}
                                        title={"Name of " + nameToDisplay}
                                        text={"Give a name for your " + nameToDisplay + ". Maximum 50 characters."}/>
                                </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                         id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewNameInput"
                                         })}
                                         onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon clonepanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewPreferences = (menuItem) => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Preferences
                </div>
                <form onSubmit={(e) => this.dataViewPreferencesApply(e, menuItem)}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Hide current folder
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"dataViewPreferencesHideFolder_help"}
                                         title={"Hide current folder"}
                                         text={"Hide the current folder from the left-hand side menu."}/>
                                    </span>
                            </div>
                            <div>
                                <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                           valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"dataViewPreferencesHideFolderInput"} displayExpr={'name'}
                                           value={menuItem.isHidden}
                                           onValueChanged={(e) => this.dataViewPreferencesHideFolderValueOnChange(e, menuItem)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "dataViewPreferencesHideFolderInput"
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>
                                    Expand by default
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"dataViewPreferencesExpandByDefault_help"}
                                         title={"Expand by default"}
                                         text={"Specify whether the folder is expanded by default."}/>
                                    </span>
                            </div>
                            <div>
                                <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                           valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"dataViewPreferencesExpandByDefaultInput"} displayExpr={'name'}
                                           value={menuItem.expandByDefault}
                                           onValueChanged={(e) => this.dataViewPreferencesExpandByDefaultValueOnChange(e, menuItem)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "dataViewPreferencesExpandByDefaultInput"
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon clonepanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"dataViewPreferencesCancelButton"}
                                        onClick={(e) => this.dataViewPreferencesCancel(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"dataViewPreferencesApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewCreateFolder = (menuItem) => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Create Sub Folder
                </div>
                <form onSubmit={(e) => this.dataViewCreateSubFolderApply(e, menuItem)}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Name of sub folder
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"dataViewCreateSubFolderName_help"}
                                         title={"Name of sub folder"}
                                         text={"Specific a name for the sub folder."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={255}
                                         id={"dataViewCreateSubFolderNameInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewCreateSubFolderNameInput"
                                         })}
                                         onValueChanged={(e) => this.dataViewCreateSubFolderNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewCreateSubFolderNameInput", rules: [
                                                    {rule: "required"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon clonepanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"dataViewCreateSubFolderCancelButton"}
                                        onClick={(e) => this.dataViewPreferencesCancel(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"dataViewCreateSubFolderApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewRenameFolder = (menuItem) => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Rename Current Folder
                </div>
                <form onSubmit={(e) => this.dataViewRenameCurrentFolderApply(e, menuItem)}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Folder name
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"dataViewCreateSubFolderName_help"}
                                         title={"Folder name"}
                                         text={"Specific a name for the current folder."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                         id={"dataViewRenameCurrentFolderNameInput"}
                                         defaultValue={menuItem.menuItemName}
                                         ref={(e) => this.setInputFieldInstance({
                                             e,
                                             input: "dataViewRenameCurrentFolderNameInput"
                                         })}
                                         onValueChanged={(e) => this.dataViewRenameCurrentFolderNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewRenameCurrentFolderNameInput", rules: [
                                                    {rule: "required"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon clonepanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"dataViewRenameCurrentFolderCancelButton"}
                                        onClick={(e) => this.dataViewPreferencesCancel(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"dataViewRenameCurrentFolderApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewDeleteFolder = (menuItem) => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Delete Current Folder
                </div>
                <form onSubmit={(e) => this.dataViewDeleteFolderApply(e, menuItem)}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <span>
                                    {"Folder name: " + menuItem.menuItemName}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon clonepanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"dataViewDeleteCurrentFolderCancelButton"}
                                        onClick={(e) => this.dataViewPreferencesCancel(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"dataViewDeleteCurrentFolderApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelExportDataView = () => {
        let option = this.state.showMenuOptionPanel.option;
        let category = this.state.showMenuOptionPanel.dataViewCategory;
        let nameToDisplay = "";
        let clonedDataViewList = [];
        if (this.state.showMenuOptionPanel.option === "export_dataview_from_workspace") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Workspace";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "dashboard" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList[i]["visible"] = false;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Data View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "data-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList.splice(i, 1);
                    i--;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "System View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "system-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList.splice(i, 1);
                    i--;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            clonedDataViewList = LDH.DeepClone(this.state.availableDataViewListForEdit);
            nameToDisplay = "Default View";
            for (let i = 0; i < clonedDataViewList.length; i++) {
                if (clonedDataViewList[i].category !== "default-view" ||
                    (!LDH.IsObjectNull(clonedDataViewList[i].item) &&
                        clonedDataViewList[i].item.isDirectory)) {
                    clonedDataViewList.splice(i, 1);
                    i--;
                }
            }
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            clonedDataViewList = LDH.DeepClone(this.state.availableReportListForEdit);
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            clonedDataViewList = LDH.DeepClone(this.state.availableAnalyticsListForEdit);
            nameToDisplay = "Analytics";
        }

        let that = this;
        setTimeout(function () {
            if (LDH.IsObjectNull(that.userProfileLocalCache)) {
                let profile = window.userProfile;
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);
                that.uiObjectInstance.exportDataViewSelectInput.option("disabled", true);
                $(".leopard-loading-icon").show();

                LeopardAjaxHelper.GetUserProfile(userId, function (userProfile) {
                    that.userProfileLocalCache = userProfile;
                    that.uiObjectInstance.exportDataViewSelectInput.option("disabled", false);
                    $(".leopard-loading-icon").hide();
                }, function (ex) {
                    LRH.ShowToast("Failed to retrieve user profile from the database. " +
                        "Please check your network connection status and try again.", "error", 5000);
                    console.log("Exception", ex);
                    $(".leopard-loading-icon").hide();
                }, null, "full", null);
            }
        }, 100);

        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {nameToDisplay === "Analytics" ? "Export " + nameToDisplay : "Export " + nameToDisplay + "(s)"}
                </div>
                <form onSubmit={(e) => this.exportDataViewApplyButtonOnClick({e, option, category})}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>{nameToDisplay === "Analytics" ? "Select " + nameToDisplay + " to export" : "Select " + nameToDisplay + "(s) to export"}</span>
                                <span style={{marginLeft: "5px"}}>
                                   <LeopardTooltipWithIcon
                                       elementId={"selectFilesToExport_help"}
                                       title={"Select " + nameToDisplay + " to export"}
                                       text={"You can export " + nameToDisplay + " to a file."}>
                                   </LeopardTooltipWithIcon>
                                </span>
                            </div>
                            <div>
                                <TagBox dataSource={clonedDataViewList} showSelectionControls={true}
                                        displayExpr={'name'}
                                        valueExpr={'id'} className={"leopard-grouped-selectbox"}
                                        id={"exportDataViewSelectInput"} multiline={false}
                                        disabled={this.state.showLoadingProgress}
                                        maxDisplayedTags={2} showMultiTagOnly={false}
                                        tagRender={(e) => this.renderTagboxInlineItem({e, option})}
                                        onOpened={(e) => this.exportDataViewSelectInputOnOpened({
                                            e, option, datasource: clonedDataViewList
                                        })}
                                        onClosed={(e) => this.exportDataViewSelectInputOnClosed({
                                            e, option, datasource: clonedDataViewList
                                        })}
                                        onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                        onSelectionChanged={(e) => this.exportDataViewSelectInputOnSelectionChanged(e)}
                                        ref={(e) => this.setInputFieldInstance({
                                            e, input: "exportDataViewSelectInput"
                                        })} applyValueMode={'useButtons'}>
                                </TagBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon exportpanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress ||
                                        LDH.IsObjectNull(this.state.dataViewJSONToExport)}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateDataView = () => {
        let nameToDisplay = "";
        if (this.state.showMenuOptionPanel.dataViewCategory === "data-view") {
            nameToDisplay = "Data View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "system-view") {
            nameToDisplay = "System View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "default-view") {
            nameToDisplay = "Default View";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "report") {
            nameToDisplay = "Report";
        } else if (this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard") {
            nameToDisplay = "Analytics";
        }

        return (
            <React.Fragment>
                <div>
                    <div className="leopard-option-panel-title">Create a new {nameToDisplay}</div>
                    <form onSubmit={this.createDataViewApplyButtonOnClick}>
                        <div className="leopard-option-panel-content">
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">1.</i>
                                    <span>Select a type of view</span>
                                    <span style={{marginLeft: "5px"}}>
                                        <LeopardTooltipWithIcon
                                            elementId={"selectDataViewTypeInput_help"}
                                            title={"Select a type of view"}
                                            text={"There are many kind of views that can be created using Control Centre. " +
                                                "Administrators can create a view with data grids, charts and reports."}>
                                        </LeopardTooltipWithIcon>
                                    </span>
                                </div>
                                <div>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionReportTypeList}
                                                displayExpr={'name'}
                                                valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                id={"selectDataViewTypeInput"}
                                                value={this.state.createDataViewType}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "selectDataViewTypeInput"
                                                })}
                                                onValueChanged={
                                                    (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox> : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "analytics-dashboard" ?
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionAnalyticsTypeList}
                                                displayExpr={'name'}
                                                valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                id={"selectDataViewTypeInput"}
                                                value={this.state.createDataViewType}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "selectDataViewTypeInput"
                                                })}
                                                onValueChanged={
                                                    (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox> : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory !== "report" &&
                                        this.state.showMenuOptionPanel.dataViewCategory !== "analytics-dashboard" ?
                                            <SelectBox
                                                dataSource={LeopardDropdownHelper.DropdownSelectionDataViewTypeList}
                                                displayExpr={'name'}
                                                valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                value={this.state.createDataViewType}
                                                id={"selectDataViewTypeInput"}
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "selectDataViewTypeInput"
                                                })}
                                                onValueChanged={
                                                    (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox> : null
                                    }
                                </div>
                            </div>
                            {
                                (this.state.createDataViewType !== "datagrid" || (this.state.createDataViewType === "datagrid" &&
                                    this.state.showMenuOptionPanel.dataViewCategory !== "default-view")) ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Data source interface</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"gridViewEngineInput_help"}
                                                    title={"Data source interface"}
                                                    text={"Specify a data source interface."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"gridViewEngineInput"}
                                                       value={this.state.gridViewEngine}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionGridViewEngine}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "gridViewEngineInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.gridViewEngineInputOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "gridViewEngineInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "timeline" || (this.state.createDataViewType === "timeline" &&
                                    this.state.showMenuOptionPanel.dataViewCategory !== "default-view")) ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">2.</i>
                                                <span>Color definition for resources</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"timelineResourceDefinitionInput_help"}
                                                    title={"Color definition for resources"}
                                                    text={"Create a color definition for resources."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineResourceDefinitionInput"}
                                                          value={this.state.timelineResourceDefinitionInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineResourceDefinitionInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineResourceDefinitionInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">3.</i>
                                                <span>OData query for grouping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"timelineOdataQueryForGroupingInput_help"}
                                                    title={"OData query for grouping"}
                                                    text={"Create an odata query for grouping."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineOdataQueryForGroupingInput"}
                                                          value={this.state.timelineOdataQueryForGroupingInput}
                                                          placeholder={"reports/odata/Transaction?$filter..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineOdataQueryForGroupingInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineOdataQueryForGroupingInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">4.</i>
                                                <span>Post data processing for grouping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelinePostDataProcessingForGroupingInput_help"}
                                                        title={"Post data processing for grouping"}
                                                        text={"Write a JavaScript code to process data that was retrieved from the backend services."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelinePostDataProcessingForGroupingInput"}
                                                          value={this.state.timelinePostDataProcessingForGroupingInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelinePostDataProcessingForGroupingInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelinePostDataProcessingForGroupingInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">5.</i>
                                                <span>OData query for timeline view</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelineOdataQueryForResourcesInput_help"}
                                                        title={"OData query for timeline view"}
                                                        text={"Create an odata query for timeline view."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelineOdataQueryForResourcesInput"}
                                                          value={this.state.timelineOdataQueryForResourcesInput}
                                                          placeholder={"reports/odata/Transaction?$filter..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelineOdataQueryForResourcesInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelineOdataQueryForResourcesInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">6.</i>
                                                <span>Post data processing for timeline view</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelinePostDataProcessingForResourcesInput_help"}
                                                        title={"Post data processing for timeline view"}
                                                        text={"Write a JavaScript code to process data that was retrieved from the backend services."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"timelinePostDataProcessingForResourcesInput"}
                                                          value={this.state.timelinePostDataProcessingForResourcesInput}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "timelinePostDataProcessingForResourcesInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.timelinePostDataProcessingForResourcesInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">7.</i>
                                                <span>Resource data key</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"timelineDataKeysInput_help"}
                                                        title={"Resource data key"}
                                                        text={"Specify a data key for a resource."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={500}
                                                         value={this.state.timelineDataKeyForResourcesInput}
                                                         id={"timelineDataKeyForResourcesInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "timelineDataKeyForResourcesInput"
                                                         })}
                                                         onValueChanged={(e) => this.timelineDataKeyForResourcesInputOnValueChanged(e)}>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                this.state.createDataViewType !== "datagrid" ||
                                (this.state.gridViewEngine !== "json-engine" || this.state.createDataViewType !== "datagrid") ||
                                (this.state.createDataViewType === "datagrid" &&
                                    this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Data source type</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"jsonDataSourceTypeInput_help"}
                                                    title={"Data source type"}
                                                    text={"Specify a data source type for the JSON data."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"jsonDataSourceTypeInput"}
                                                       value={this.state.jsonDataSourceType}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionJSONDataSource}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "jsonDataSourceTypeInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.jsonDataSourceTypeInputOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "jsonDataSourceTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "datagrid" ||
                                (this.state.gridViewEngine !== "json-engine" || this.state.createDataViewType !== "datagrid") ||
                                (this.state.createDataViewType === "datagrid" &&
                                    this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Data source request type</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"dataSourceRequestTypeInput_help"}
                                                    title={"Data source request type"}
                                                    text={"Specify a data source request type."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={500}
                                                     defaultValue={this.state.dataSourceRequestType}
                                                     id={"docTypeOverrideInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "dataSourceRequestTypeInput"
                                                     })}
                                                     onValueChanged={(e) => this.dataSourceRequestTypeInputOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "dataSourceRequestTypeInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                ((this.state.createDataViewType === "chart" &&
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    (this.state.createDataViewType === "timeline" &&
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    (this.state.createDataViewType === "map" &&
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    (this.state.createDataViewType === "tabbed-view" &&
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    this.state.createDataViewType === "diagram" ||
                                    this.state.createDataViewType === "photo" ||
                                    this.state.createDataViewType === "notepad" ||
                                    this.state.createDataViewType === "aws-quicksight-dashboard" ||
                                    this.state.createDataViewType === "document-editor" ||
                                    this.state.createDataViewType === "regex-tester") ||
                                this.state.createDataViewType === "form-editor" || this.state.createDataViewType === "html-form-builder" ||
                                (this.state.gridViewEngine === "csv-adhoc-engine" && this.state.createDataViewType === "datagrid") ||
                                (this.state.gridViewEngine === "json-engine" && this.state.createDataViewType === "datagrid") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">{
                                                this.state.createDataViewType === "report" ? "2." :
                                                    ((this.state.createDataViewType === "datagrid" &&
                                                            this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ||
                                                        (this.state.createDataViewType === "chart" &&
                                                            this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ||
                                                        (this.state.createDataViewType === "timeline" &&
                                                            this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ||
                                                        (this.state.createDataViewType === "map" &&
                                                            this.state.showMenuOptionPanel.dataViewCategory !== "default-view")) ? "2." :
                                                        (this.state.createDataViewType === "timeline" ||
                                                            this.state.createDataViewType === "tabbed-view" ||
                                                            this.state.createDataViewType === "diagram") ? "2." : "3."
                                            }</i>
                                            <span>Select data source</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"selectChartTypeInput_help"}
                                                    title={"Select data source"}
                                                    text={"Select a data source for the data view to access, " +
                                                        "so the user will be able to view the data from that data source."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectDataSourceInput"}
                                                       grouped={true}
                                                       value={this.state.createDataViewDataSourceUrl}
                                                       dataSource={new DataSource({
                                                           store: this.state.availableDataSourceList,
                                                           key: 'id',
                                                           group: 'group'
                                                       })}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectDataSourceInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectDataSourceInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                        {
                                            this.state.createDataViewType === "report" ?
                                                <div style={{marginTop: "5px"}}>
                                                    <SelectBox displayExpr={'name'} id={"selectDataSourceVersionInput"}
                                                               grouped={true}
                                                               value={this.state.createDataViewDataSourceVersion}
                                                               dataSource={new DataSource({
                                                                   store: LeopardDropdownHelper.DropdownSelectionODataEngineVersionForReport,
                                                                   key: 'id',
                                                                   group: 'group'
                                                               })}
                                                               ref={(e) => this.setInputFieldInstance({
                                                                   e,
                                                                   input: "selectDataSourceVersionInput"
                                                               })}
                                                               valueExpr={'id'}
                                                               disabled={this.state.showLoadingProgress}
                                                               onValueChanged={
                                                                   (e) => this.createDataViewDataSourceVersionOnValueChanged(e)
                                                               }>
                                                        <Validator>
                                                            <RequiredRule type="custom" validationCallback={
                                                                (e) => this.customValidationRuleCallback({
                                                                    e, input: "selectDataSourceVersionInput", rules: [
                                                                        {rule: "required"}
                                                                    ]
                                                                })
                                                            }/>
                                                        </Validator>
                                                    </SelectBox>
                                                </div> :
                                                <div style={{marginTop: "5px"}}>
                                                    <SelectBox displayExpr={'name'} id={"selectDataSourceVersionInput"}
                                                               grouped={true}
                                                               value={this.state.createDataViewDataSourceVersion}
                                                               dataSource={new DataSource({
                                                                   store: LeopardDropdownHelper.DropdownSelectionODataEngineVersionForChart,
                                                                   key: 'id',
                                                                   group: 'group'
                                                               })}
                                                               ref={(e) => this.setInputFieldInstance({
                                                                   e,
                                                                   input: "selectDataSourceVersionInput"
                                                               })}
                                                               valueExpr={'id'}
                                                               disabled={this.state.showLoadingProgress}
                                                               onValueChanged={
                                                                   (e) => this.createDataViewDataSourceVersionOnValueChanged(e)
                                                               }>
                                                        <Validator>
                                                            <RequiredRule type="custom" validationCallback={
                                                                (e) => this.customValidationRuleCallback({
                                                                    e, input: "selectDataSourceVersionInput", rules: [
                                                                        {rule: "required"}
                                                                    ]
                                                                })
                                                            }/>
                                                        </Validator>
                                                    </SelectBox>
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "aws-quicksight-dashboard" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>QuickSight dashboard ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"quicksightDashboardIdInput_help"}
                                                    title={"QuickSight dashboard ID"}
                                                    text={"Specify a QuickSight dashboard ID."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                                     defaultValue={this.state.quicksightDashboardIdInput}
                                                     id={"quicksightDashboardIdInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "quicksightDashboardIdInput"
                                                     })}
                                                     onValueChanged={(e) => this.quicksightDashboardIdInputOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "quicksightDashboardIdInput",
                                                            rules: [
                                                                {rule: "required"},
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.showMenuOptionPanel.dataViewCategory !== "default-view" ||
                                    this.state.createDataViewType === "datagrid" ||
                                    this.state.createDataViewType === "chart" ||
                                    this.state.createDataViewType === "photo" ||
                                    this.state.createDataViewType === "timeline" ||
                                    this.state.createDataViewType === "tabbed-view" ||
                                    this.state.createDataViewType === "diagram" ||
                                    this.state.createDataViewType === "notepad" ||
                                    this.state.createDataViewType === "analytics-dashboard" ||
                                    this.state.createDataViewType === "document-editor" ||
                                    this.state.createDataViewType === "form-editor" ||
                                    this.state.createDataViewType === "html-form-builder" ||
                                    this.state.createDataViewType === "regex-tester") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select a map provider</span>
                                            <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"selectMapProviderInput_help"}
                                                    title={"Select a map provider"}
                                                    text={"Select a map provider. Control Centre currently supports Google Map and Open Street Map."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectMapProviderInput"}
                                                       grouped={false}
                                                       value={this.state.createDataViewSelectMapProvider}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionMapProvider}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectMapProviderInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectMapProviderOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectMapProviderInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.showMenuOptionPanel.dataViewCategory !== "default-view" ||
                                    this.state.createDataViewType !== "datagrid" ||
                                    LDH.IsObjectNull(this.state.selectedDataSourceObject) ||
                                    this.state.selectedDataSourceObject.id !== "custom-query") ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"dataSourceOverrideMetaInput"} maxLength={2000}
                                                          value={this.state.dataSourceOverrideMetaInput}
                                                          placeholder={"/chat/$metadata"}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "dataSourceOverrideMetaInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.dataSourceOverrideMetaInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"dataSourceCustomQueryInput"} maxLength={2000}
                                                          value={this.state.dataSourceCustomQueryInput}
                                                          placeholder={"Transaction?%24expand=ScannedUser&%24top=3"}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "dataSourceCustomQueryInput"
                                                          })}
                                                          onValueChanged={
                                                              (e) => this.dataSourceCustomQueryInputOnValueChanged(e)
                                                          }>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={200}
                                                         defaultValue={this.state.dataSourceCustomQueryTableSchemaInput}
                                                         placeholder={"TableName1 | {PrefixName}TableName2"}
                                                         id={"dataSourceCustomQueryTableSchemaInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "dataSourceCustomQueryTableSchemaInput"
                                                         })}
                                                         onValueChanged={
                                                             (e) => this.dataSourceCustomQueryTableSchemaInputOnValueChanged(e)
                                                         }>
                                                </TextBox>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                this.state.createDataViewType !== "report" ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">3.</i>
                                                <span>OData query</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"oDataParametersForNormalReportInput_help"}
                                                    title={"OData query"}
                                                    text={"Specify OData query for retrieving data from the database."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"oDataParametersForNormalReportInput"}
                                                          value={this.state.oDataParametersForNormalReport}
                                                          placeholder={"$filter=...&$apply=..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "oDataParametersForNormalReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.oDataParametersForNormalReportOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "oDataParametersForNormalReportInput",
                                                                rules: [
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextArea>
                                            </div>
                                            <div style={{marginTop: "5px"}}>
                                                <a href={"#_"} className={"leopard-dataview-report-addsource"}
                                                   onClick={(e) => this.reportCustomDataSourceAddOnClick(e)}>Add Data
                                                    Source</a>
                                            </div>
                                        </div>
                                        <React.Fragment>
                                            {this.renderReportCustomDataSourceConfigurationPanel()}
                                        </React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex"
                                                   style={{float: "left"}}>4.</i>
                                                <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                    <span>Scheduled report</span>
                                                    <span style={{float: "right"}}>
                                                    <Switch value={!this.state.disableScheduledReportOption}
                                                            width={"60px"}
                                                            switchedOffText={"OFF"} switchedOnText={"ON"}
                                                            id={"disableScheduledReportOption"}
                                                            disabled={this.state.showLoadingProgress}
                                                            onValueChanged={(e) => this.disableScheduledReportOptionOnValueChanged(e)}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                            {
                                                this.state.disableScheduledReportOption === true ? "" :
                                                    <React.Fragment>
                                                        <div className="leopard-option-panel-block">
                                                            <div className={"leopard-leftmenu-settings-section"}>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <span>OData query for scheduled report:</span>
                                                                    <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"oDataParametersForScheduledReportInput_help"}
                                                                        title={"OData query"}
                                                                        text={"Specify OData query for retrieving data from the database."}/>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <TextArea disabled={this.state.showLoadingProgress}
                                                                              height={"80px"}
                                                                              id={"oDataParametersForScheduledReportInput"}
                                                                              value={this.state.oDataParametersForScheduledReport}
                                                                              placeholder={"$filter=...&$apply=..."}
                                                                              ref={(e) => this.setInputFieldInstance({
                                                                                  e,
                                                                                  input: "oDataParametersForScheduledReportInput"
                                                                              })}
                                                                              onValueChanged={(e) => this.oDataParametersForScheduledReportOnValueChanged(e)}>
                                                                    </TextArea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="leopard-option-panel-block">
                                                            <div className={"leopard-leftmenu-settings-section"}>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <span>Lambda request configuration</span>
                                                                    <span style={{marginLeft: "5px"}}>
                                                                    <LeopardTooltipWithIcon
                                                                        elementId={"lambdaRequestConfigForScheduledReportInput_help"}
                                                                        title={"Lambda request configuration"}
                                                                        text={"Add configurtion to the Lambda request."}/>
                                                                </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{paddingLeft: "24px"}}>
                                                                    <TextArea disabled={this.state.showLoadingProgress}
                                                                              height={"80px"}
                                                                              id={"lambdaRequestConfigForScheduledReportInput"}
                                                                              value={this.state.scheduledReportLambdaRequestConfig}
                                                                              ref={(e) => this.setInputFieldInstance({
                                                                                  e,
                                                                                  input: "lambdaRequestConfigForScheduledReportInput"
                                                                              })}
                                                                              onValueChanged={(e) => this.lambdaRequestConfigForScheduledReportOnValueChanged(e)}>
                                                                    </TextArea>
                                                                </div>
                                                            </div>
                                                            <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                                                                <a href={"#_"}
                                                                   className={"leopard-dataview-report-addsource"}
                                                                   onClick={(e) => this.reportScheduledCustomDataSourceAddOnClick(e)}>
                                                                    Add Data Source
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.renderScheduledReportCustomDataSourceConfigurationPanel()
                                                        }
                                                        {
                                                            this.state.createDataViewDataSourceVersion === "v2-athena" ? null :
                                                                <React.Fragment>
                                                                    {this.buildRecurrenceSettingFields()}

                                                                    <div
                                                                        style={{paddingLeft: "24px", marginTop: "5px"}}>
                                                                        <a href={"#_"}
                                                                           className={"leopard-dataview-report-addsource"}
                                                                           onClick={(e) => this.reportCustomSchedulesAddOnClick(e)}>
                                                                            Add Schedule
                                                                        </a>
                                                                    </div>
                                                                    {
                                                                        this.buildCustomRecurrenceSettingFields()
                                                                    }
                                                                </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                            }
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">5.</i>
                                                <span>Query shaping</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"scriptShapingForReportInput_help"}
                                                    title={"Query shaping"}
                                                    text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"scriptShapingForReportInput"}
                                                          value={this.state.scriptShapingForReport}
                                                          placeholder={"Use the variable \"odataParams\", \"parameters\" and \"window.report_input_variables\" to access data and user inputs."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "scriptShapingForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.scriptShapingForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">6.</i>
                                                <span>Post data processing</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideQueryForReportInput_help"}
                                                    title={"Post data processing"}
                                                    text={"Write JavaScript code to manipulate data on the client-side."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"clientSideQueryForReportInput"}
                                                          value={this.state.clientSideQueryForReport}
                                                          placeholder={"Use the variable \"data\" to access the data."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "clientSideQueryForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.clientSideQueryForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">7.</i>
                                                <span>UI component processing</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"clientSideUILogicForReportInput_help"}
                                                    title={"UI component processing"}
                                                    text={"Write JavaScript code to change report's UI on the client-side."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"clientSideUILogicForReportInput"}
                                                          value={this.state.clientSideUILogicForReport}
                                                          placeholder={"Use the variable \"report\" to access the report UI."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "clientSideUILogicForReportInput"
                                                          })}
                                                          onValueChanged={(e) => this.clientSideUILogicForReportOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">8.</i>
                                                <span>Input values initialise logic</span>
                                                <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"inputValuesInitLogicInput_help"}
                                                    title={"Input values initialise logic"}
                                                    text={"Write JavaScript code to initialise input values."}/>
                                            </span>
                                            </div>
                                            <div>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"inputValuesInitLogicInput"}
                                                          value={this.state.inputValuesInitLogic}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "inputValuesInitLogicInput"
                                                          })}
                                                          onValueChanged={(e) => this.inputValuesInitLogicOnValueChanged(e)}>
                                                </TextArea>
                                            </div>
                                        </div>
                                        {
                                            this.state.createDataViewDataSourceVersion !== "v2-athena" ? null :
                                                <div className="leopard-option-panel-block">
                                                    <div>
                                                        <i className="leopard-option-panel-stepindex">9.</i>
                                                        <span>Rendering method</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                <LeopardTooltipWithIcon
                                                    elementId={"renderingMethodInput_help"}
                                                    title={"Rendering method"}
                                                    text={"Specify a rendering method for ad-hoc reports."}/>
                                            </span>
                                                    </div>
                                                    <div>
                                                        <SelectBox
                                                            dataSource={LeopardDropdownHelper.DropdownSelectionReportRenderingMethod}
                                                            displayExpr={'name'}
                                                            disabled={this.state.showLoadingProgress}
                                                            id={"renderingMethodInput"}
                                                            ref={(e) => this.setInputFieldInstance({
                                                                e,
                                                                input: "renderingMethodInput"
                                                            })}
                                                            valueExpr={'id'}
                                                            value={this.state.renderingMethod}
                                                            onValueChanged={
                                                                (e) => this.renderingMethodOnValueChanged(e)
                                                            }>
                                                        </SelectBox>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            this.state.createDataViewDataSourceVersion !== "v2-athena" ? null :
                                                <div className="leopard-option-panel-block">
                                                    <div>
                                                        <i className="leopard-option-panel-stepindex"
                                                           style={{float: "left"}}>10.</i>
                                                        <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                            <span>Notification</span>
                                                            <span style={{float: "right"}}>
                                                    <Switch value={this.state.notificationEnabled}
                                                            width={"60px"}
                                                            switchedOffText={"OFF"} switchedOnText={"ON"}
                                                            id={"notificationEnabled"}
                                                            disabled={this.state.switchDisabledForNotification || (!this.state.switchDisabledForNotification && this.state.showLoadingProgress)}
                                                            onValueChanged={(e) => this.notificationEnabledOnValueChanged(e)}
                                                    />
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </React.Fragment>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select type of chart</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"selectTypeOfChart_help"}
                                                title={"Select type of chart"}
                                                text={"Specify what type of chart would you like to create. The Control Centre currently " +
                                                    "supports 6 types of chart, such as bar chart, scatter chart, doughnut chart, bar chart, line chart and area chart."}/>
                                </span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={LeopardDropdownHelper.DropdownSelectionChartTypeList}
                                                       displayExpr={'name'}
                                                       disabled={this.state.showLoadingProgress}
                                                       visible={this.state.showChartTypeOption}
                                                       id={"selectChartTypeInput"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "selectChartTypeInput"
                                                       })}
                                                       valueExpr={'id'}
                                                       value={this.state.createDataViewChartType}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectChartTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "selectChartTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "10px"}}>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Mandatory configuration</span>
                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"mandatoryConfigurationForChart_help"}
                                                                    title={"Mandatory configuration"}
                                                                    text={"You must configure all the settings in this section for a chart to functioning properly."}>
                                                                </LeopardTooltipWithIcon>
                                                            </span>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for x-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForXAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display x-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress}
                                                         defaultValue={this.state.createDataViewChartArgumentField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectArgumentFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "selectArgumentFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewArgumentFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "selectArgumentFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for y-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForYAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display y-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress}
                                                         defaultValue={this.state.createDataViewChartValueField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectValueFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "selectValueFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewValueFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "selectValueFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>HTTP method</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"httpMethodForChart_help"}
                                                                    title={"HTTP method"}
                                                                    text={"Specify an http method for calling the API."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <SelectBox
                                                    dataSource={LeopardDropdownHelper.DropdownSelectionHttpRequestTypeForChart}
                                                    displayExpr={'name'} id={"httpMethodForCreateChartInput"}
                                                    value={this.state.httpMethodForCreateChart}
                                                    ref={(e) => this.setInputFieldInstance({
                                                        e,
                                                        input: "httpMethodForCreateChartInput"
                                                    })}
                                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                    onValueChanged={
                                                        (e) => this.httpMethodForCreateChartOnValueChanged(e)
                                                    }>
                                                </SelectBox>
                                            </div>
                                        </div>

                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Select a date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeForChart_help"}
                                                                    title={"Select a date range"}
                                                                    text={"Specify a date range for the chart. For example, " +
                                                                        "if you want to display a chart with total number of transactions per day for the last 7 days, you should select \"Last 7 Days\" from the dropdown list."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <SelectBox
                                                    dataSource={LeopardDropdownHelper.DropdownSelectionChartDateRange}
                                                    displayExpr={'name'} id={"selectDateRangeForCreateChartInput"}
                                                    visible={false}
                                                    value={this.state.selectDateRangeForCreateChart}
                                                    ref={(e) => this.setInputFieldInstance({
                                                        e,
                                                        input: "selectDateRangeForCreateChartInput"
                                                    })}
                                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                    onValueChanged={
                                                        (e) => this.selectDateRangeForCreateChartOnValueChanged(e)
                                                    }>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "selectDateRangeForCreateChartInput",
                                                                rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeLookupForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for date range lookup."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                                         visible={false}
                                                         defaultValue={this.state.createDataViewChartDateRangeField}
                                                         id={"createDataViewChartDateRangeFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e,
                                                             input: "createDataViewChartDateRangeFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewChartDateRangeFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e,
                                                                input: "createDataViewChartDateRangeFieldInput",
                                                                rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>OData query string</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"customQueryAttributesForChart_help"}
                                                                    title={"OData query string"} customWidth={450}
                                                                    text={"Before you can create a chart, you must write a query to tell what data should be shown on the chart. For more info about how to write a query, please refer to OData V4 protocol."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"customQueryAttributesInput"}
                                                          placeholder={"/Transaction?$filter=...&$apply=..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e,
                                                              input: "customQueryAttributesInput"
                                                          })}
                                                          onValueChanged={(e) => this.customQueryAttributesOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e, input: "customQueryAttributesInput", rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                    <TextBoxButton name={"customQueryAttributesInput_MiniButton"}
                                                                   location={'after'}
                                                                   options={{
                                                                       icon: "fas fa-question",
                                                                       type: 'default',
                                                                       onClick: () => {
                                                                           window.open(LeopardStaticUIConfig.ODataDocUrl);
                                                                       }
                                                                   }}
                                                    />
                                                </TextArea>
                                            </div>
                                        </div>
                                        {
                                            this.state.showHttpDataPostForChart ?
                                                <div className="leopard-option-panel-block">
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <span>HTTP post data</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"httpDataPostForChart_help"}
                                                                    title={"HTTP post data"} customWidth={450}
                                                                    text={"Specific the data that you wish to post to the API endpoint."}/>
                                                            </span>
                                                    </div>
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <TextArea disabled={this.state.showLoadingProgress}
                                                                  height={"80px"}
                                                                  id={"httpDataPostForCreateChartInput"}
                                                                  placeholder={"JSON data only"}
                                                                  ref={(e) => this.setInputFieldInstance({
                                                                      e,
                                                                      input: "httpDataPostForCreateChartInput"
                                                                  })}
                                                                  onValueChanged={(e) => this.httpDataPostForCreateChartOnValueChanged(e)}>
                                                        </TextArea>
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                            this.state.createDataViewChartType === "doughnut-chart" ? "" :
                                                <div className="leopard-option-panel-block">
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <span>Chart series</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"numberOfSeriesForChart_help"}
                                                                    title={"Chart series"} customWidth={450}
                                                                    text={"You must define at least one series to be shown on the chart."}/>
                                                            </span>
                                                    </div>
                                                    <div style={{paddingLeft: "24px"}}>
                                                        <TextBox disabled={this.state.showLoadingProgress}
                                                                 maxLength={4000}
                                                                 id={"numberOfSeriesForChartInput"}
                                                                 ref={(e) => this.setInputFieldInstance({
                                                                     e,
                                                                     input: "numberOfSeriesForChartInput"
                                                                 })}
                                                                 onValueChanged={(e) => this.numberOfSeriesForChartOnValueChanged(e)}>
                                                            <Validator>
                                                                <RequiredRule type="custom" validationCallback={
                                                                    (e) => this.customValidationRuleCallback({
                                                                        e,
                                                                        input: "numberOfSeriesForChartInput",
                                                                        rules: [
                                                                            {rule: "safeinput"}
                                                                        ]
                                                                    })
                                                                }/>
                                                            </Validator>
                                                            <TextBoxButton
                                                                name={"numberOfSeriesForChartInput_MiniButton"}
                                                                location={'after'}
                                                                options={{
                                                                    icon: "fas fa-plus",
                                                                    type: 'default',
                                                                    onClick: () => {
                                                                        if (LDH.IsValueEmpty(this.numberOfSeriesForChartText)) {
                                                                            return;
                                                                        }
                                                                        this.setState({
                                                                            numberOfSeriesOnChart: [
                                                                                ...this.state.numberOfSeriesOnChart,
                                                                                {
                                                                                    id: "series_" + LDH.GenerateGuid(),
                                                                                    name: this.numberOfSeriesForChartText
                                                                                }
                                                                            ]
                                                                        }, function () {
                                                                            this.uiObjectInstance["numberOfSeriesForChartInput"].option("value", "");
                                                                            this.uiObjectInstance["numberOfSeriesForChartInput"].focus();
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </TextBox>
                                                    </div>
                                                    <div className={"leopard-listcontainer"}
                                                         style={{paddingLeft: "24px", marginTop: "5px"}}>
                                                        <List allowItemDeleting={true} activeStateEnabled={false}
                                                              noDataText={"Click the Add button to add a series"}
                                                              hoverStateEnabled={false} focusStateEnabled={false}
                                                              dataSource={this.state.numberOfSeriesOnChart}
                                                              itemRender={this.numberOfSeriesOnChartTemplate}/>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "notepad" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Text on submit button:</span>
                                            <span style={{marginLeft: "5px"}}>
                                               <LeopardTooltipWithIcon
                                                   elementId={"notepadSubmitButtonTextInput_help"}
                                                   title={"Text on submit button"}
                                                   text={"Specify a custom text on the submit button."}/>
                                        </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.notepadButtonText}
                                                     id={"notepadSubmitButtonTextInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "notepadSubmitButtonTextInput"
                                                     })}
                                                     onValueChanged={(e) => this.notepadButtonTextOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "notepadSubmitButtonTextInput",
                                                            rules: [
                                                                {rule: "required"},
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "notepad" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Data submission logic:</span>
                                            <span style={{marginLeft: "5px"}}>
                                               <LeopardTooltipWithIcon
                                                   elementId={"notepadSubmitLogicInput_help"}
                                                   title={"Data submission logic"}
                                                   text={"Specify a custom javacript logic for data submission."}/>
                                        </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                      id={"notepadSubmitLogicInput"}
                                                      value={this.state.notepadDataSubmissionLogic}
                                                      onValueChanged={(e) => this.notepadDataSubmissionLogicOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "document-editor" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Document format</span>
                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"documentEditorDataFormatInput_help"}
                                                                    title={"Document format"}
                                                                    text={"Specify the format of document that will be retrieved via API Gateway."}>
                                                                </LeopardTooltipWithIcon>
                                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                       id={"documentEditorDataFormatInput"}
                                                       value={this.state.documentEditorDataFormat}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionDocumentEditorDataFormat}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "documentEditorDataFormatInput"
                                                       })}
                                                       disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.documentEditorDataFormatOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "documentEditorDataFormatInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "document-editor" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Allow standalone access:</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"documentEditorAllowStandaloneAccessInput_help"}
                                                        title={"Allow standalone access"}
                                                        text={"If the standalone access is allowed, then the user can access the view directly in a Data View, " +
                                                            "otherwise this view can only be accessed through a Workspace."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                       id={"documentEditorAllowStandaloneAccessInput"}
                                                       value={this.state.documentEditorAllowStandaloneAccess}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "documentEditorAllowStandaloneAccessInput"
                                                       })}
                                                       disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.documentEditorAllowStandaloneAccessOnValueChanged(e)
                                                       }>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "document-editor" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Data schema:</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"documentEditorDataSchemaInput_help"}
                                                title={"Data schema"}
                                                text={"Specify a data schema for the document editor."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                      id={"documentEditorDataSchemaInput"}
                                                      value={this.state.documentEditorDataSchema}
                                                      onValueChanged={(e) => this.documentEditorDataSchemaOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "document-editor" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "0px"}}>
                                            <i className="leopard-option-panel-stepindex">5.</i>
                                            <span>UI schema:</span>
                                            <span style={{marginLeft: "5px"}}>
                                        <LeopardTooltipWithIcon
                                            elementId={"documentEditorUISchemaInput_help"}
                                            title={"UI schema"}
                                            text={"Specify an UI schema for the document editor."}/>
                                        </span>
                                        </div>
                                        <div>
                                            <TextArea disabled={this.state.showLoadingProgress} height={"100px"}
                                                      id={"documentEditorUISchemaInput"}
                                                      value={this.state.documentEditorUISchema}
                                                      onValueChanged={(e) => this.documentEditorUISchemaOnValueChanged(e)}>
                                            </TextArea>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Height of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.heightOfThumbnailForPhotoGallery}
                                                       id={"heightOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "heightOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Width of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.widthOfThumbnailForPhotoGallery}
                                                       id={"widthOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "widthOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Default tab</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetDefaultTabInput_help"}
                                                        title={"Default tab"}
                                                        text={"Specify a default tab for the data view."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                                       id={"photoWidgetDefaultTabInput"}
                                                       value={this.state.photoGalleryDefaultTab}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionPhotoGalleryDefaultTab}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e,
                                                           input: "photoWidgetDefaultTabInput"
                                                       })}
                                                       disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.photoGalleryDefaultTabOnValueChanged(e)
                                                       }>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">5.</i>
                                            <span>Disabled tabs</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetDisabledTabsInput_help"}
                                                        title={"Disabled tabs"}
                                                        text={"Specify disabled tabs for the data view."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TagBox
                                                items={LeopardDropdownHelper.DropdownSelectionPhotoGalleryDefaultTab}
                                                id={"photoWidgetDisabledTabsInput"}
                                                showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                value={this.state.photoGalleryDisabledTabs}
                                                onValueChanged={
                                                    (e) => this.photoGalleryDisabledTabsOnValueChanged(e)
                                                }
                                                ref={(e) => this.setInputFieldInstance({
                                                    e,
                                                    input: "photoWidgetDisabledTabsInput"
                                                })}
                                                disabled={this.state.showLoadingProgress}
                                                applyValueMode="useButtons"/>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">6.</i>
                                            <span>Report payload source ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetReportPayloadSourceIdInput_help"}
                                                        title={"Report payload source ID"}
                                                        text={"Specify a report payload source ID for the data view."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.photoGalleryReportPayloadSourceId}
                                                     id={"photoWidgetReportPayloadSourceIdInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "photoWidgetReportPayloadSourceIdInput"
                                                     })}
                                                     onValueChanged={(e) => this.photoGalleryReportPayloadSourceIdOnValueChanged(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">7.</i>
                                            <span>Report data source ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"photoWidgetReportDataSourceIdInput_help"}
                                                        title={"Report data source ID"}
                                                        text={"Specify a report data source ID for the data view."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.photoGalleryReportDataSourceId}
                                                     id={"photoWidgetReportDataSourceIdInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "photoWidgetReportDataSourceIdInput"
                                                     })}
                                                     onValueChanged={(e) => this.photoGalleryReportDataSourceIdOnValueChanged(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">8.</i>
                                            <span>Photo data source ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Photo data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.parentDataSourceIdForPhotoGallery}
                                                     id={"parentDataSourceIdForPhotoInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "parentDataSourceIdForPhotoInput"
                                                     })}
                                                     onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                ((this.state.createDataViewType !== "datagrid" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ||
                                    (this.state.gridViewEngine === "json-engine" && this.state.createDataViewType === "datagrid") ||
                                    (this.state.gridViewEngine === "csv-adhoc-engine" && this.state.createDataViewType === "datagrid")) ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Top level filtering field</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"topLevelFilteringFieldInput_help"}
                                                        title={"Top level filtering field"}
                                                        text={"Specify a top level filtering field to restrict users from accessing other users information in a different group."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.topLevelFilteringField}
                                                     id={"topLevelFilteringFieldInput"}
                                                     placeholder={"UserGroupId|'{user-group-id}'"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "topLevelFilteringFieldInput"
                                                     })}
                                                     onValueChanged={(e) => this.topLevelFilteringFieldValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e,
                                                            input: "topLevelFilteringFieldInput",
                                                            rules: [
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "datagrid" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view" ||
                                    (this.state.gridViewEngine === "csv-adhoc-engine" && this.state.createDataViewType === "datagrid")) ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">5.</i>
                                            <span>Custom computed columns</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon
                                                elementId={"customComputedColumns_help"}
                                                title={"Custom computed columns"}
                                                text={"Specify custom computed columns for the grid view. " +
                                                    "You'll need to write JavaScript code for processing data on the client-side."}>
                                            </LeopardTooltipWithIcon>
                                        </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress} maxLength={4000}
                                                     id={"customComputedColumnsInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e,
                                                         input: "customComputedColumnsInput"
                                                     })}
                                                     onValueChanged={(e) => this.customComputedColumnsOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e, input: "customComputedColumnsInput", rules: [
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                                <TextBoxButton name={"customComputedColumnsInput_MiniButton"}
                                                               location={'after'}
                                                               options={{
                                                                   icon: "fas fa-plus",
                                                                   type: 'default',
                                                                   onClick: () => {
                                                                       if (LDH.IsValueEmpty(this.customComputedColumnsText)) {
                                                                           return;
                                                                       }
                                                                       this.setState({
                                                                           customComputedColumns: [
                                                                               ...this.state.customComputedColumns,
                                                                               {
                                                                                   id: "customColumns_" + (this.state.customComputedColumns.length + 1),
                                                                                   name: "_" + this.customComputedColumnsText
                                                                               }
                                                                           ]
                                                                       }, function () {
                                                                           this.uiObjectInstance["customComputedColumnsInput"].option("value", "");
                                                                           this.uiObjectInstance["customComputedColumnsInput"].focus();
                                                                       });
                                                                   }
                                                               }}
                                                />
                                            </TextBox>
                                        </div>
                                        <div className={"leopard-listcontainer"}
                                             style={{marginTop: "5px"}}>
                                            <List allowItemDeleting={true} activeStateEnabled={false}
                                                  noDataText={"Click the Add button to add a custom column"}
                                                  hoverStateEnabled={false} focusStateEnabled={false}
                                                  dataSource={this.state.customComputedColumns}
                                                  itemRender={this.customComputedColumnsTemplate}/>
                                        </div>
                                    </div>
                            }
                            <div className="leopard-option-panel-block">
                                <div>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            this.state.createDataViewDataSourceVersion !== "v2-athena" ?
                                                <React.Fragment>
                                                    <i className="leopard-option-panel-stepindex">9.</i>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <i className="leopard-option-panel-stepindex">11.</i>
                                                </React.Fragment>
                                            :
                                            (this.state.selectedDataSourceObject !== null &&
                                                this.state.selectedDataSourceObject.category === "data-table" &&
                                                this.state.createDataViewType !== "chart" &&
                                                this.state.createDataViewType !== "photo") ?
                                                <React.Fragment>
                                                    <i className="leopard-option-panel-stepindex">
                                                        {this.state.showChartTypeOption ? "7." : "5."}
                                                    </i>
                                                </React.Fragment>
                                                :
                                                (this.state.createDataViewType === "chart" &&
                                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                    <React.Fragment>
                                                        <i className="leopard-option-panel-stepindex">4.</i>
                                                    </React.Fragment>
                                                    :
                                                    (this.state.createDataViewType === "datagrid" &&
                                                        this.state.gridViewEngine !== "csv-adhoc-engine" &&
                                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                        <React.Fragment>
                                                            <i className="leopard-option-panel-stepindex">6.</i>
                                                        </React.Fragment>
                                                        :
                                                        (this.state.createDataViewType === "timeline" &&
                                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                            <React.Fragment>
                                                                <i className="leopard-option-panel-stepindex">8.</i>
                                                            </React.Fragment>
                                                            :
                                                            (this.state.createDataViewType === "datagrid" &&
                                                                this.state.gridViewEngine === "csv-adhoc-engine" &&
                                                                this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                                <React.Fragment>
                                                                    <i className="leopard-option-panel-stepindex">3.</i>
                                                                </React.Fragment>
                                                                :
                                                                (this.state.createDataViewType === "tabbed-view" &&
                                                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                                    <React.Fragment>
                                                                        <i className="leopard-option-panel-stepindex">2.</i>
                                                                    </React.Fragment>
                                                                    :
                                                                    (this.state.createDataViewType === "diagram" &&
                                                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                                        <React.Fragment>
                                                                            <i className="leopard-option-panel-stepindex">2.</i>
                                                                        </React.Fragment>
                                                                        :
                                                                        this.state.createDataViewType === "photo" ?
                                                                            <React.Fragment>
                                                                                <i className="leopard-option-panel-stepindex">9.</i>
                                                                            </React.Fragment>
                                                                            :
                                                                            this.state.createDataViewType === "tabbed-view" ?
                                                                                <React.Fragment>
                                                                                    <i className="leopard-option-panel-stepindex">3.</i>
                                                                                </React.Fragment>
                                                                                :
                                                                                this.state.createDataViewType === "diagram" ?
                                                                                    <React.Fragment>
                                                                                        <i className="leopard-option-panel-stepindex">3.</i>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    this.state.createDataViewType === "document-editor" ?
                                                                                        <React.Fragment>
                                                                                            <i className="leopard-option-panel-stepindex">6.</i>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        this.state.createDataViewType === "regex-tester" ?
                                                                                            <React.Fragment>
                                                                                                <i className="leopard-option-panel-stepindex">2.</i>
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            this.state.createDataViewType === "notepad" ?
                                                                                                <React.Fragment>
                                                                                                    <i className="leopard-option-panel-stepindex">4.</i>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                this.state.createDataViewType === "form-editor" ?
                                                                                                    <React.Fragment>
                                                                                                        <i className="leopard-option-panel-stepindex">2.</i>
                                                                                                    </React.Fragment>
                                                                                                    :
                                                                                                    this.state.createDataViewType === "html-form-builder" ?
                                                                                                        <React.Fragment>
                                                                                                            <i className="leopard-option-panel-stepindex">2.</i>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        <React.Fragment>
                                                                                                            <i className="leopard-option-panel-stepindex">3.</i>
                                                                                                        </React.Fragment>
                                    }
                                    <span>Name of {nameToDisplay}</span>
                                    <span style={{marginLeft: "5px"}}>
                                       <LeopardTooltipWithIcon
                                           elementId={"dataViewNameInput_help"}
                                           title={"Name of " + nameToDisplay}
                                           text={"Give a name for your " + nameToDisplay + ". Maximum 50 characters."}/>
                                    </span>
                                </div>
                                <div>
                                    <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                             id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e,
                                                 input: "dataViewNameInput"
                                             })}
                                             onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                    <div style={{display: "none"}}>
                                        <TextBox
                                            ref={(e) => this.setInputFieldInstance({
                                                e,
                                                input: "validationInput"
                                            })}
                                            onValueChanged={(e) => this.validationInputOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e, input: "validationInput", rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span className={"leopard-loading-icon createpanel"} style={{
                                    marginRight: "15px", marginLeft: "30px",
                                    display: this.state.showLoadingProgress === false ? "none" : "block"
                                }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                                <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                                <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                          </span>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateTemplate = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Create Workspace
                </div>
                <form onSubmit={this.createDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dashboardNameInput_help"}
                                                            title={"Name of workspace"}
                                                            text={"Give a name for your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox
                                    ref={(e) => this.setInputFieldInstance({e, input: "dashboardNameInput"})}
                                    disabled={this.state.showLoadingProgress} maxLength={50}
                                    id={"dashboardNameInput"}
                                    onValueChanged={(e) => this.createDashboardNameInputOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dashboardNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                           <span className={"leopard-loading-icon createpanel"} style={{
                               marginRight: "15px", marginLeft: "30px",
                               display: this.state.showLoadingProgress === false ? "none" : "block"
                           }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                disabled={this.state.showLoadingProgress}
                                id={"createDashboardCancelButton"}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{paddingRight: "20px"}}>
                        <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                                id={"createDashboardApplyButton"}
                                disabled={this.state.showLoadingProgress}/>
                  </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelEditTemplate = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Manage Workspace
                </div>
                <form onSubmit={this.editDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, " +
                                            "you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                            <div className={"leopard-workspace-dataview-addbutton-wrapper"}>
                                <a href={"#_"} className={"leopard-workspace-dataview-removebutton"}
                                   onClick={(e) => this.workspaceDataViewAddOnClick(e)}>Add Data View</a>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Confirmation text when clicking away</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"confirmationTextInput_help"}
                                        title={"Confirmation text when clicking away"}
                                        text={"Show a confirmation text when clicking away."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox ref={(e) => this.setInputFieldInstance({e, input: "confirmationTextInput"})}
                                         disabled={this.state.showLoadingProgress}
                                         id={"dataViewNameInput"}
                                         defaultValue={this.state.confirmationMessageText}
                                         onValueChanged={(e) => this.confirmationMessageTextOnValueChanged(e)}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">3.</i>
                                <span>Auto/Manual mode</span>
                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"autoManualModeInput_help"}
                                                        title={"Auto/Manual mode"}
                                                        text={"Set a default mode for the Workspace."}/>
                                            </span>
                            </div>
                            <div>
                                <SelectBox displayExpr={'name'} valueExpr={'id'}
                                           id={"autoManualModeInput"}
                                           value={this.state.autoManualMode}
                                           dataSource={LeopardDropdownHelper.DropdownSelectionAutoManualMode}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "autoManualModeInput"
                                           })}
                                           disabled={this.state.showLoadingProgress}
                                           onValueChanged={
                                               (e) => this.autoManualModeOnValueChanged(e)
                                           }>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">4.</i>
                                <span>Rename workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewNameInput_help"}
                                        title={"Rename workspace"}
                                        text={"Give a name for your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox maxLength={50}
                                         ref={(e) => this.setInputFieldInstance({e, input: "dataViewNameInput"})}
                                         disabled={this.state.showLoadingProgress}
                                         id={"dataViewNameInput"}
                                         defaultValue={this.state.editDataViewName}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">5.</i>
                                <span>Hide menu item</span>
                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"hideMenuItemInput_help"}
                                                        title={"Hide menu item"}
                                                        text={"Hide or show the menu item."}/>
                                            </span>
                            </div>
                            <div>
                                <SelectBox displayExpr={'name'} valueExpr={'id'}
                                           id={"hideMenuItemInput"}
                                           value={this.state.hideMenuItem}
                                           dataSource={LeopardDropdownHelper.DropdownSelectionYesNo}
                                           ref={(e) => this.setInputFieldInstance({
                                               e,
                                               input: "hideMenuItemInput"
                                           })}
                                           disabled={this.state.showLoadingProgress}
                                           onValueChanged={
                                               (e) => this.hideMenuItemOnValueChanged(e)
                                           }>
                                </SelectBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            <span className={"leopard-loading-icon editpanel"} style={{
                                marginRight: "15px", marginLeft: "30px",
                                display: this.state.showLoadingProgress === false ? "none" : "block"
                            }}>
                                <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Delete'}
                                id={"createDashboardDeleteButton"}
                                disabled={this.state.showLoadingProgress} onClick={
                            () => this.createDashboardDeleteButtonOnClick()}/>
                  </span>
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                id={"createDashboardCancelButton"}
                                disabled={this.state.showLoadingProgress}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                        id={"createDashboardApplyButton"}
                        disabled={this.state.showLoadingProgress}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    renderDataViewSelectBoxListForCreateOrEditDashboard = () => {
        let that = this;
        if (that.state.availableDataViewListForCreate.length === 0) {
            return null;
        }
        return this.state.selectedDataViewsForDashboard.map(function (item, i) {
            if (that.state.showMenuOptionPanel.option === "add_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + LDH.GenerateGuid()}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: that.state.availableDataViewListForCreate, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress}
                                           grouped={true} defaultValue={item.dataViewId}
                                           id={"selectedDataView_" + LDH.GenerateGuid()}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e, index: i, item
                                           })}/>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress}
                                         id={"customDataViewTitleInput_" + LDH.GenerateGuid()}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e, index: i, item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            if (that.state.showMenuOptionPanel.option === "edit_template") {
                let availableDataViewListForEditCloned = LDH.DeepClone(that.state.availableDataViewListForEdit);
                availableDataViewListForEditCloned = availableDataViewListForEditCloned.filter(c => {
                    return c.group !== "Workspaces";
                });

                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + LDH.GenerateGuid()}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px", display: "flex"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: availableDataViewListForEditCloned, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress} width={"100%"}
                                           grouped={true} value={item.dataViewId}
                                           id={"selectedDataView_" + LDH.GenerateGuid()}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e, index: i, item
                                           })}>
                                </SelectBox>
                                <a href={"#_"} className={"leopard-workspace-dataview-removebutton"}
                                   onClick={(e) => that.workspaceDataViewRemoveOnClick({
                                       e: e, index: i, item
                                   })}>Remove</a>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress}
                                         id={"customDataViewTitleInput_" + LDH.GenerateGuid()}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e, index: i, item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            return null;
        });
    };

    renderReportCustomDataSourceConfigurationPanel = () => {
        let that = this;
        if (LDH.IsObjectNull(this.state.reportCustomDataSources)) {
            return null;
        }
        return this.state.reportCustomDataSources.map(function (item, i) {
            return (
                <React.Fragment key={"reportCustomDataSources_key_" + i}>
                    <div className={"leopard-menupanel-splitter-container"}>
                        <div className={"leopard-menupanel-splitter-counter"}>{i + 1}</div>
                    </div>
                    <div className="leopard-option-panel-block" key={"reportCustomDSKey_" + item.id}>
                        <div>
                            <div>
                                <span>Data source name</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceNameInput_" + item.id + "_help"}
                                        title={"Data source name"}
                                        text={"Specify a name for the data source."}/>
                                    </span>
                            </div>
                            <TextBox maxLength={255}
                                     disabled={that.state.showLoadingProgress} value={item.name}
                                     onValueChanged={(e) => that.customDataSourceNameInputValueOnChange({
                                         index: i, id: item.id, value: e.value
                                     })}
                                     ref={(e) => that.setInputFieldInstance({
                                         e,
                                         input: "customDataSourceNameInput_" + item.id
                                     })}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => that.customValidationRuleCallback({
                                            e, input: "customDataSourceNameInput_" + item.id, rules: [
                                                {rule: "required"},
                                                {rule: "safeinput"}
                                            ]
                                        })
                                    }/>
                                </Validator>
                            </TextBox>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>Parent OData query (level 1)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery1Input_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery1}
                                      onValueChanged={(e) => that.customDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 1
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery1Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>Parent OData query (level 2)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery2Input_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery2}
                                      onValueChanged={(e) => that.customDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 2
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery2Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>Parent OData query (level 3)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery3Input_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery3}
                                      onValueChanged={(e) => that.customDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 3
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery3Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>OData query</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceODataQueryInput_" + item.id + "_help"}
                                        title={"OData query"}
                                        text={"Specify a query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.query}
                                      onValueChanged={(e) => that.customDataSourceQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceODataQueryInput_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>Query shaping</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceQueryShapingInput_" + item.id + "_help"}
                                        title={"Query shaping"}
                                        text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.queryShaping}
                                      placeholder={"Use the variable \"customQuery\" and \"window.report_input_variables\" to access data and user inputs."}
                                      onValueChanged={(e) => that.customDataSourceQueryShapingInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceQueryShapingInput_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <div>
                                <span>Post data processing</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourcePostProcessInput_" + item.id + "_help"}
                                        title={"Post data processing"}
                                        text={"Write JavaScript code to manipulate data on the client-side."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.postDataQuery}
                                      onValueChanged={(e) => that.customDataSourcePostProcessInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourcePostProcessInput_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px"}}>
                            <a href={"#_"} className={"leopard-dataview-report-addsource"}
                               onClick={() => that.reportCustomDataSourceRemoveOnClick({id: item.id})}>
                                Remove
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    };

    renderScheduledReportCustomDataSourceConfigurationPanel = () => {
        let that = this;
        if (LDH.IsObjectNull(this.state.reportScheduledCustomDataSources)) {
            return null;
        }
        return this.state.reportScheduledCustomDataSources.map(function (item, i) {
            return (
                <React.Fragment key={"reportScheduledCustomDataSources_key_" + i}>
                    <div className={"leopard-menupanel-splitter-container"}>
                        <div className={"leopard-menupanel-splitter-counter"}>{i + 1}</div>
                    </div>
                    <div className="leopard-option-panel-block" key={"reportCustomDSKey_Scheduled_" + item.id}>
                        <div style={{paddingLeft: "24px"}}>
                            <div>
                                <span>Data source name</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceNameInput_Scheduled_" + item.id + "_help"}
                                        title={"Data source name"}
                                        text={"Specify a name for the data source."}/>
                                    </span>
                            </div>
                            <TextBox maxLength={255}
                                     disabled={that.state.showLoadingProgress} value={item.name}
                                     onValueChanged={(e) => that.scheduledReportCustomDataSourceNameInputValueOnChange({
                                         index: i, id: item.id, value: e.value
                                     })}
                                     ref={(e) => that.setInputFieldInstance({
                                         e,
                                         input: "customDataSourceNameInput_Scheduled_" + item.id
                                     })}>
                                <Validator>
                                    <RequiredRule type="custom" validationCallback={
                                        (e) => that.customValidationRuleCallback({
                                            e, input: "customDataSourceNameInput_Scheduled_" + item.id, rules: [
                                                {rule: "required"},
                                                {rule: "safeinput"}
                                            ]
                                        })
                                    }/>
                                </Validator>
                            </TextBox>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Parent OData query (level 1)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery1Input_Scheduled_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery1}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 1
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery1Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Lambda request for parent query 1</span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"customDataSourceLambdaRequestConfigParent1Input_" + item.id + "_help"}
                                         title={"Lambda request for parent query 1"}
                                         text={"Add configurtion to the Lambda request."}
                                     />
                                </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress}
                                      height={"80px"}
                                      value={item.parentLambdaConfig1}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceLambdaConfigInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 1
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceLambdaRequestConfigParent1Input_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Parent OData query (level 2)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery2Input_Scheduled_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery2}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 2
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery2Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Lambda request for parent query 2</span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"customDataSourceLambdaRequestConfigParent2Input_" + item.id + "_help"}
                                         title={"Lambda request for parent query 2"}
                                         text={"Add configurtion to the Lambda request."}
                                     />
                                </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress}
                                      height={"80px"}
                                      value={item.parentLambdaConfig2}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceLambdaConfigInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 2
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceLambdaRequestConfigParent2Input_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Parent OData query (level 3)</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceParentODataQuery3Input_Scheduled_" + item.id + "_help"}
                                        title={"Parent OData query"}
                                        text={"Specify a parent query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.parentQuery3}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceParentQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 3
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceParentODataQuery3Input_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Lambda request for parent query 3</span>
                                <span style={{marginLeft: "5px"}}>
                                     <LeopardTooltipWithIcon
                                         elementId={"customDataSourceLambdaRequestConfigParent3Input_" + item.id + "_help"}
                                         title={"Lambda request for parent query 3"}
                                         text={"Add configurtion to the Lambda request."}
                                     />
                                </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress}
                                      height={"80px"}
                                      value={item.parentLambdaConfig3}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceLambdaConfigInputValueOnChange({
                                          index: i, id: item.id, value: e.value, parentIndex: 3
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceLambdaRequestConfigParent3Input_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>OData query</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceODataQueryInput_Scheduled_" + item.id + "_help"}
                                        title={"OData query"}
                                        text={"Specify a query for the data source."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.query}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceQueryInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceODataQueryInput_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Query shaping</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceQueryShapingInput_Scheduled_" + item.id + "_help"}
                                        title={"Query shaping"}
                                        text={"Write JavaScript code to manipulate query before it sends to the server."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.queryShaping}
                                      placeholder={"Use the variable \"customQuery\" and \"window.report_input_variables\" to access data and user inputs."}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourceQueryShapingInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceQueryShapingInput_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Post data processing</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourcePostProcessInput_Scheduled_" + item.id + "_help"}
                                        title={"Post data processing"}
                                        text={"Write JavaScript code to manipulate data on the client-side."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.postDataQuery}
                                      onValueChanged={(e) => that.scheduledReportCustomDataSourcePostProcessInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourcePostProcessInput_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <div>
                                <span>Lambda request configuration</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"customDataSourceLambdaRequestConfigInput_Scheduled_" + item.id + "_help"}
                                        title={"Lambda request configuration"}
                                        text={"Add configurtion to the Lambda request."}/>
                                    </span>
                            </div>
                            <TextArea disabled={that.state.showLoadingProgress} height={"80px"}
                                      value={item.lambdaRequestConfig}
                                      onValueChanged={(e) => that.scheduledReportLambdaRequestConfigInputValueOnChange({
                                          index: i, id: item.id, value: e.value
                                      })}
                                      ref={(e) => that.setInputFieldInstance({
                                          e, input: "customDataSourceLambdaRequestConfigInput_Scheduled_" + item.id
                                      })}>
                            </TextArea>
                        </div>
                        <div style={{marginTop: "5px", paddingLeft: "24px"}}>
                            <a href={"#_"} className={"leopard-dataview-report-addsource"}
                               onClick={() => that.reportScheduledCustomDataSourceRemoveOnClick({id: item.id})}>
                                Remove
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    };

    renderTagboxInlineItem = (tagData) => {
        return (
            <React.Fragment>
                <div className="dx-tag-content">
                    {tagData.e.name}
                </div>
            </React.Fragment>
        );
    }

    renderProgressLoadingPanel = (showPanel) => {
        if (showPanel === false) return null;

        return (
            <React.Fragment>
                <span className={"leopard-loading-icon"} style={{
                    width: "100%", height: "100%", marginLeft: "0px", paddingTop: "40%"
                }}>
                  <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
                  <span className="leopard-largeloading-progress-text">Loading data...</span>
                </span>
            </React.Fragment>
        );
    };

    renderAddDashboardButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }

        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-workspace-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_template_options",
                   item: menuItem,
                   items: menuItems
               })}/>
        );
    };

    renderAddDataViewButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-dataview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "data-view",
                   item: menuItem,
                   items: menuItems
               })}></i>
        );
    };

    renderAddSystemViewButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-systemview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "system-view",
                   item: menuItem,
                   items: menuItems
               })}></i>
        );
    };

    renderAddDefaultViewButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (!showGridViewAdminToolbar || !LPH.IsLeopardAdminModeEnabled(this)) {
            return null;
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-defaultview-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "default-view",
                   item: menuItem,
                   items: menuItems
               })}></i>
        );
    };

    renderAddReportButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-report-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "report",
                   item: menuItem,
                   items: menuItems
               })}></i>
        );
    };

    renderAddAnalyticsDashboardButton = (showGridViewAdminToolbar, menuItem, menuItems) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               id={"leftmenu-report-addnew-button"}
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "analytics-dashboard",
                   item: menuItem,
                   items: menuItems
               })}></i>
        );
    };

    calculateToIndex(e) {
        if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
            return e.toIndex;
        }
        return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
    }

    findNode(treeView, index) {
        const nodeElement = treeView.element().querySelectorAll('.dx-treeview-node')[index];
        if (nodeElement) {
            return this.findNodeById(treeView.getNodes(), nodeElement.getAttribute('data-item-id'));
        }
        return null;
    }

    findNodeById(nodes, id) {
        for (let i = 0; i < nodes.length; i += 1) {
            if (nodes[i].itemData.menuItemId === id) {
                return nodes[i];
            }
            if (nodes[i].children) {
                const node = this.findNodeById(nodes[i].children, id);
                if (node != null) {
                    return node;
                }
            }
        }
        return null;
    }

    moveNode(fromNode, toNode, fromItems, toItems, isDropInsideItem) {
        const fromIndex = fromItems.findIndex((item) => item.menuItemId === fromNode.itemData.menuItemId);
        fromItems.splice(fromIndex, 1);

        const toIndex = toNode === null || isDropInsideItem
            ? toItems.length
            : toItems.findIndex((item) => item.menuItemId === toNode.itemData.menuItemId);
        toItems.splice(toIndex, 0, fromNode.itemData);

        this.moveChildren(fromNode, fromItems, toItems);
        if (isDropInsideItem) {
            fromNode.itemData.menuItemParentId = toNode.itemData.menuItemId;
        } else {
            fromNode.itemData.menuItemParentId = toNode != null
                ? toNode.itemData.menuItemParentId
                : undefined;
        }
    }

    moveChildren(node, fromDataSource, toDataSource) {
        if (!node.itemData.isDirectory) {
            return;
        }

        node.children.forEach((child) => {
            if (child.itemData.isDirectory) {
                this.moveChildren(child, fromDataSource, toDataSource);
            }
            const fromIndex = fromDataSource.findIndex((item) => item.menuItemId === child.itemData.menuItemId);
            fromDataSource.splice(fromIndex, 1);
            toDataSource.splice(toDataSource.length, 0, child.itemData);
        });
    }

    isChildNode(parentNode, childNode) {
        let {parent} = childNode;
        while (parent !== null) {
            if (parent.itemData.menuItemId === parentNode.itemData.menuItemId) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    getTopVisibleNode(component) {
        const treeViewElement = component.element();
        const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
        const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
        for (let i = 0; i < nodes.length; i += 1) {
            const nodeTopPosition = nodes[i].getBoundingClientRect().top;
            if (nodeTopPosition >= treeViewTopPosition) {
                return nodes[i];
            }
        }
        return null;
    }

    menuTreeViewItemComponent(e, items) {
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let count = 0;
        for (let i = 0; i < items.length; i++) {
            if (items[i].menuItemParentId === e.menuItemId && !items[i].isDirectory) {
                count++;
            }
        }

        // Workspaces root folder
        if (e.isDirectory && e.menuItemSubCategory === "dashboard") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddDashboardButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        // Data Views root folder
        if (e.isDirectory && e.menuItemSubCategory === "data-view") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddDataViewButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        // Default Views root folder
        if (e.isDirectory && e.menuItemSubCategory === "default-view") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddDefaultViewButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        // Reports root folder
        if (e.isDirectory && e.menuItemSubCategory === "report") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddReportButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        // Analytics root folder
        if (e.isDirectory && e.menuItemSubCategory === "analytics-dashboard") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddAnalyticsDashboardButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        // System Views root folder
        if (e.isDirectory && e.menuItemSubCategory === "system-view") {
            return (
                <div className={"leopard-leftmenu-category " + (showGridViewAdminToolbar ? "" : "standarduser")}
                     id={e.menuItemId} parentid={e.menuItemParentId}>
                    <div className="leopard-leftmenu-category-text" id={"leftmenu-workspace-category-title"}>
                        {e.menuItemName} (<span className={"menu-item-totalcount"}
                                                category={e.menuItemSubCategory}>{count}</span>)
                        {(!LDH.IsObjectNull(e.isHidden) && e.isHidden) ?
                            <span><i className={"leopard-hidden-menufolder fas fa-eye-slash"}></i></span> : ""}
                    </div>
                    {this.renderAddSystemViewButton(showGridViewAdminToolbar, e, items)}
                </div>
            )
        }

        let selectedClass = this.props.state.selectedLeftMenuItem.menuItemId === e.menuItemId ? "selected" : "";
        if (!e.isDirectory && e.menuItemSubCategory === "dashboard") {
            return (
                <React.Fragment>
                    <div id={"menuitem_dashboard_" + e.menuItemId}
                         parentid={e.menuItemParentId}
                         className={"leopard-leftmenu-item " + (this.state.categoryWorkspacesVisibility ?
                             "" : "hidden") + " " + selectedClass + " " + "data-view-category_dashboard"}>
                        <div className={"leopard-leftmenu-item-text"} title={e.menuItemName}
                             searchabletext={e.menuItemName}
                             onClick={() => this.dataViewOrTemplateMenuItemOnClick({
                                 item: e, selected: selectedClass
                             })}>
                            {e.menuItemName}
                        </div>
                        {
                            !LDH.IsObjectNull(e.hideMenuItem) && e.hideMenuItem ?
                                <span style={{display: "flex"}}>
                                    <i className="leopard-hidden-menufolder fas fa-eye-slash"
                                       style={{paddingLeft: "5px", paddingRight: "11px", margin: "auto"}}></i>
                                </span> : null
                        }
                        {
                            showGridViewAdminToolbar === false ? "" :
                                <div className={"leopard-leftmenu-item-options fas fa-ellipsis-h"}
                                     onClick={
                                         () => this.menuItemSettingsButtonOnClick({
                                             item: e, option: "edit_dashboard_options"
                                         })
                                     }
                                />
                        }
                    </div>
                </React.Fragment>
            )
        }

        if (!e.isDirectory && e.menuItemSubCategory !== "dashboard") {
            return (
                <React.Fragment>
                    <div id={"menuitem_dataview_" + e.dataViewType + "_" + e.menuItemId}
                         parentid={e.menuItemParentId} data_view_id={e.dataViewId}
                         data_view_persistent_id={e.dataViewPersistentId}
                         className={"leopard-leftmenu-item " + selectedClass + " " +
                             "data-view-category_" + e.menuItemCategory + " " +
                             "menuitem_dataview_dvid_" + e.dataViewType + "_" + e.dataViewId}>
                        <div className={"leopard-leftmenu-item-text"} title={e.menuItemName}
                             searchabletext={e.menuItemName}
                             custom_attr_url={e.dataSourceUrl}
                             onClick={() => this.dataViewOrTemplateMenuItemOnClick({
                                 item: e, selected: selectedClass
                             })}>
                            {e.menuItemName}
                        </div>
                        {
                            !LDH.IsObjectNull(e.hideMenuItem) && e.hideMenuItem ?
                                <span style={{display: "flex"}}>
                                    <i className="leopard-hidden-menufolder fas fa-eye-slash"
                                       style={{paddingLeft: "5px", paddingRight: "11px", margin: "auto"}}></i>
                                </span> : null
                        }
                        {
                            showGridViewAdminToolbar === false ? "" :
                                <div className={"leopard-leftmenu-item-options fas fa-ellipsis-h"}
                                     onClick={
                                         () => this.menuItemSettingsButtonOnClick({
                                             item: e, option: "edit_dataview",
                                             dataViewCategory: e.menuItemType
                                         })
                                     }
                                />
                        }
                    </div>
                </React.Fragment>
            )
        }

        return null;
    }

    onDragChange(e, thisComp) {
        let isAdminRole = window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin;
        if (!isAdminRole) e.cancel = true;

        if (e.fromComponent === e.toComponent) {
            let instance = thisComp.uiObjectInstance["dxMenuTreeView"];
            const fromNode = thisComp.findNode(instance, e.fromIndex);
            const toNode = thisComp.findNode(instance, thisComp.calculateToIndex(e));
            if (toNode !== null && thisComp.isChildNode(fromNode, toNode)) {
                e.cancel = true;
            }
        }
    }

    onDragEnd(e, thisComp) {
        let isAdminRole = window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin;
        if (!isAdminRole) e.cancel = true;

        if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
            return;
        }
        let instance = thisComp.uiObjectInstance["dxMenuTreeView"];
        const fromNode = thisComp.findNode(instance, e.fromIndex);
        const toNode = thisComp.findNode(instance, thisComp.calculateToIndex(e));

        if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
            return;
        }
        const toTopVisibleNode = thisComp.getTopVisibleNode(e.toComponent);
        const fromItems = thisComp.state.dxMenuItemData;
        const toItems = thisComp.state.dxMenuItemData;
        thisComp.moveNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);

        thisComp.setState({dxMenuItemData: [...toItems]}, function () {
            instance.scrollToItem(toTopVisibleNode);

            setTimeout(function () {
                thisComp.onDXMenuSelectionChangedLogic(true);
            }, 10);

            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
            }, function () {
                LRH.ShowToast("Failed to update the menu structure. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }, thisComp.state.dxMenuItemData);
        });
    }

    createDefaultMenuStructure = () => {
        let dxTreeViewData = [{
            menuItemId: 'directory_predefined_workspaces',
            menuItemName: 'Workspaces',
            isDirectory: true,
            expanded: true,
            expandByDefault: true,
            menuItemVersion: 2,
            menuItemCategory: "workspace",
            menuItemSubCategory: "dashboard",
            isHidden: false
        }, {
            menuItemId: 'directory_predefined_dataviews',
            menuItemName: 'Data Views',
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: "dataview",
            menuItemSubCategory: "data-view",
            isHidden: false
        }, {
            menuItemId: 'directory_predefined_defaultviews',
            menuItemName: 'Default Views',
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: "dataview",
            menuItemSubCategory: "default-view",
            isHidden: false
        }, {
            menuItemId: 'directory_predefined_reports',
            menuItemName: 'Reports',
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: "report",
            menuItemSubCategory: "report",
            isHidden: false
        }, {
            menuItemId: 'directory_predefined_analytics',
            menuItemName: 'Analytics',
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: "analytics",
            menuItemSubCategory: "analytics-dashboard",
            isHidden: false
        }, {
            menuItemId: 'directory_predefined_systemviews',
            menuItemName: 'System',
            isDirectory: true,
            expanded: false,
            expandByDefault: false,
            menuItemVersion: 2,
            menuItemCategory: "dataview",
            menuItemSubCategory: "system-view",
            isHidden: false
        }];
        return dxTreeViewData;
    }

    convertToDXTreeViewMenu = (menuItemList, parentId, category, subCategory) => {
        let dxTreeViewData = [];
        if (!LDH.IsObjectNull(menuItemList)) {
            for (let i = 0; i < menuItemList.length; i++) {
                let data = menuItemList[i];
                data["menuItemParentId"] = parentId;
                data["expanded"] = false;
                data["isDirectory"] = false;
                data["expandByDefault"] = false;
                data["menuItemVersion"] = 2;
                data["isHidden"] = false;
                data["menuItemSubCategory"] = subCategory;
                data["menuItemCategory"] = category;
                dxTreeViewData.push(data);
            }
        }
        return dxTreeViewData;
    }

    onDXMenuSelectionChangedLogic = (unselectAll) => {
        if (unselectAll) {
            $(".leopard-leftmenu-item").not(".selected").each(function () {
                let $parent = $(this).closest(".dx-treeview-node");
                $parent.removeClass("dx-state-selected");
            });
        }

        let $selected = $(".leopard-leftmenu-item.selected");
        if ($selected !== null && $selected.length > 0) {
            let $root = $selected.closest(".dx-treeview-node");
            $root.addClass("dx-state-selected");
        }

        $(".dx-treeview-node.dx-state-selected").each(function () {
            if ($(".leopard-leftmenu-item", $(this)).length === 0) {
                $(this).removeClass("dx-state-selected");
            }
        });

        // A workaround to prevent from screen flickering.
        if (!unselectAll) {
            $(".leopard-leftmenu-item").not(".selected").each(function () {
                let $parent = $(this).closest(".dx-treeview-node");
                $parent.removeClass("dx-state-selected");
            });
        }
    }

    onDXMenuSelectionChanged = (e) => {
        let that = this;
        that.onDXMenuSelectionChangedLogic(true);

        // A workaround to prevent from screen flickering.
        setTimeout(function () {
            that.onDXMenuSelectionChangedLogic(false);
        }, 10);
    }

    leftMenuOnItemExpanded = (e) => {
        this.leftMenuOnItemCollapsedOrExpandedLogic(e, "expanded");
    }

    leftMenuOnItemCollapsed = (e) => {
        this.leftMenuOnItemCollapsedOrExpandedLogic(e, "collapsed");
    }

    leftMenuOnItemCollapsedOrExpandedLogic = (e, stateToCheck) => {
        let found = false;
        for (let i = 0; i < window.leftMenuUIState.length; i++) {
            if (window.leftMenuUIState[i].state === stateToCheck &&
                window.leftMenuUIState[i].key === e.node.key) {
                found = true;
            }
        }
        if (found === true) return;

        let item = null;
        for (let i = 0; i < window.leftMenuUIState.length; i++) {
            if (window.leftMenuUIState[i].key === e.node.key) {
                item = window.leftMenuUIState[i];
            }
        }
        if (item === null) {
            window.leftMenuUIState.push({
                key: e.node.key,
                state: stateToCheck
            });
        } else {
            item.state = stateToCheck;
        }
    }

    renderLeftMenuItemsPanel = (showPanel) => {
        let that = this;
        if (showPanel === false) return null;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        if (!LDH.IsObjectNull(that.uiObjectInstance["dxMenuTreeView"])) {
            let treeView = that.uiObjectInstance["dxMenuTreeView"];
            let scrollable = treeView.getScrollable();
            let position = scrollable.scrollOffset();
            if (position.left !== -0 || position.top !== -0) {
                that.leftMenuScrollPosition = position;
            }
        }

        setTimeout(function () {
            if (!LDH.IsObjectNull(that.uiObjectInstance["dxMenuTreeView"])) {
                let instance = that.uiObjectInstance["dxMenuTreeView"];
                let value = LeopardStaticUIConfig.Global_MenuItemSearchText;
                LRH.SearchForMenuItems(value);

                for (let i = 0; i < window.leftMenuUIState.length; i++) {
                    let key = window.leftMenuUIState[i].key;
                    if (window.leftMenuUIState[i].state === "collapsed") {
                        instance.collapseItem(key);
                    }
                    if (window.leftMenuUIState[i].state === "expanded") {
                        instance.expandItem(key);
                    }
                }
                // A workaround to prevent from screen flickering.
                setTimeout(function () {
                    that.onDXMenuSelectionChangedLogic(false);

                    if (that.leftMenuScrollPosition !== null) {
                        let scrollable = instance.getScrollable();
                        scrollable.scrollTo(that.leftMenuScrollPosition);
                        that.leftMenuScrollPosition = null;
                    }
                }, 10);
            }
        }, 100);

        let isAdminRole = window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin;
        let isLeopardAdmin = LPH.IsLeopardAdminModeEnabled(this);

        return (
            <React.Fragment>
                <div className="leopard-leftmenu-category-container text-selection-disabled">
                    <div className="leopard-screen-cover" style={{display: "none"}}></div>
                    <span className="leopard-screen-menucover" style={{display: "none"}}></span>
                    <Sortable className={"leopard-leftmenu-devextreme-sortablecontainer"}
                              filter=".dx-treeview-item" allowDropInsideItem={isAdminRole}
                              allowReordering={isAdminRole}
                              onDragChange={(e) => this.onDragChange(e, this)}
                              onDragEnd={(e) => this.onDragEnd(e, this)}>

                        {
                            isLeopardAdmin ?
                                <React.Fragment>
                                    <div className={"leopard-leftmenu-item"}>
                                        <div className={"leopard-leftmenu-item-text"}
                                             style={{padding: "5px 10px 5px 10px", cursor: "pointer"}}
                                             onClick={(e) => this.importSystemMenu(e)}>
                                            Import System Menu
                                        </div>
                                    </div>
                                    <input className={"import-system-menu-filereader"} type="file"
                                           onChange={(e) => this.importSystemMenuLogic(e)}
                                           style={{display: "none"}}/>
                                    <div className={"leopard-leftmenu-item"}>
                                        <div className={"leopard-leftmenu-item-text"}
                                             style={{padding: "5px 10px 5px 10px", cursor: "pointer"}}
                                             onClick={(e) => this.exportSystemMenu(e)}>
                                            Export System Menu
                                        </div>
                                    </div>
                                    <hr style={{marginTop: "8px", marginBottom: "8px"}}/>
                                </React.Fragment> : null
                        }

                        <TreeView id="dxMenuTreeView" animationEnabled={false}
                                  ref={(e) => this.setInputFieldInstance({e, input: "dxMenuTreeView"})}
                                  expandNodesRecursive={false} dataStructure="plain" expandEvent={"click"}
                                  itemComponent={(e) => this.menuTreeViewItemComponent(e, this.state.dxMenuItemData)}
                                  focusStateEnabled={false} onSelectionChanged={this.onDXMenuSelectionChanged}
                                  items={this.state.dxMenuItemData} width={"100%"}
                                  height={isLeopardAdmin ? "calc(100% - 80px)" : "100%"}
                                  parentIdExpr={"menuItemParentId"} keyExpr={"menuItemId"}
                                  virtualModeEnabled={false}
                                  displayExpr={"menuItemName"} selectByClick={true} selectionMode={"single"}
                                  onItemExpanded={this.leftMenuOnItemExpanded} searchMode={"contains"}
                                  onItemCollapsed={this.leftMenuOnItemCollapsed} searchEnabled={false}
                        />
                    </Sortable>
                </div>
            </React.Fragment>
        );
    };

    importSystemMenu = (e) => {
        $(".import-system-menu-filereader").trigger("click");
    };

    importSystemMenuLogic = (e) => {
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            let jsonResult = JSON.parse(e.target.result);

            LeopardAjaxHelper.UpdateLeftMenuStructure(userId, organizationId, function () {
                LRH.ShowToast("The menu structure has been successfully updated. ", "success", 5000);
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }, function () {
                LRH.ShowToast("Failed to update the menu structure. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }, jsonResult);
        };
        reader.readAsText(e.target.files[0])
    };

    exportSystemMenu = () => {
        let menuDataStr = JSON.stringify(this.state.dxMenuItemData);
        LRH.DownloadStringToFile("MenuStructure.json", menuDataStr);
    };

    render() {
        let showOption = this.state.showMenuOptionPanel !== null;
        let showPanel = this.state.leftMenuItemsInitialized;
        LRH.ShowOrHideApplicationBackgroundCover(showOption);

        if (this.state.showMenuOptionPanel === undefined || this.state.showMenuOptionPanel === null ||
            showOption === false) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        let menuData = this.state.showMenuOptionPanel;
        if (!LDH.IsObjectNull(this.state.showMenuOptionPanel.menuData)) {
            menuData = this.state.showMenuOptionPanel.menuData;
        }

        if (!LDH.IsObjectNull(this.state.showMenuOptionPanel) &&
            this.state.showMenuOptionPanel.option === "add_dataview_options" && showOption === true) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                            {this.displayMenuOptionByType({
                                option: "add_dataview_options",
                                menuData: menuData
                            })}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (!LDH.IsObjectNull(this.state.showMenuOptionPanel) &&
            this.state.showMenuOptionPanel.option === "add_template_options" && showOption === true) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                            {this.displayMenuOptionByType({
                                option: "add_template_options",
                                menuData: menuData
                            })}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "add_dataview" ||
                this.state.showMenuOptionPanel.option === "export_dataview_from_view" ||
                this.state.showMenuOptionPanel.option === "import_dataview_from_view" ||
                this.state.showMenuOptionPanel.option === "clone_dataview_from_view" ||
                this.state.showMenuOptionPanel.option === "dataview_preferences" ||
                this.state.showMenuOptionPanel.option === "create_dataview_subfolder" ||
                this.state.showMenuOptionPanel.option === "rename_dataview_folder" ||
                this.state.showMenuOptionPanel.option === "delete_dataview_folder")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({
                                option: "add_dataview_options",
                                menuData: menuData
                            })}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType({
                                option: this.state.showMenuOptionPanel.option,
                                menuData: menuData
                            })}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "add_template" ||
                this.state.showMenuOptionPanel.option === "export_dataview_from_workspace" ||
                this.state.showMenuOptionPanel.option === "import_dataview_from_workspace" ||
                this.state.showMenuOptionPanel.option === "clone_dataview_from_workspace" ||
                this.state.showMenuOptionPanel.option === "workspace_preferences" ||
                this.state.showMenuOptionPanel.option === "create_workspace_subfolder" ||
                this.state.showMenuOptionPanel.option === "rename_workspace_folder" ||
                this.state.showMenuOptionPanel.option === "delete_workspace_folder")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({
                                option: "add_template_options",
                                menuData: menuData
                            })}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType({
                                option: this.state.showMenuOptionPanel.option,
                                menuData: menuData
                            })}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "edit_template" ||
                this.state.showMenuOptionPanel.option === "manage_dashboard_relationship")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({
                                option: "edit_dashboard_options",
                                menuData: menuData
                            })}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType({
                                option: this.state.showMenuOptionPanel.option,
                                menuData: menuData
                            })}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className={"leopard-left-panel-container"}>
                    {
                        showOption === false ? "" :
                            <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                                {this.displayMenuOptionByType({
                                    option: this.state.showMenuOptionPanel.option,
                                    menuData: menuData
                                })}
                            </div>
                    }
                    {this.renderProgressLoadingPanel(!showPanel)}
                    {this.renderLeftMenuItemsPanel(showPanel)}
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetLeftMenuItem: (data) => {
            dispatch(SetLeftMenuItem(data));
        },
        KeepGridViewDataById: (id) => {
            dispatch(KeepGridViewDataById(id));
        },
        KeepChartDataById: (id) => {
            dispatch(KeepChartDataById(id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardMasterLeftMenu);
