import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardRegexTesterEngine from "../foundation/LeopardRegexTesterEngine";

class LeopardRegexTesterConfiguration extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <LeopardRegexTesterEngine
                    regexDefinition={this.props.regexDefinition}
                    dataViewId={this.props.dataViewId} isDataView={this.props.isDataView}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardRegexTesterConfiguration);
