import React, {Component} from 'react';
import LeopardTabbedViewEngine from "./LeopardTabbedViewEngine";

class LeopardChildTabbedView extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardTabbedViewEngine
                    definition={this.props.definition} dashboardLevel={this.props.dashboardLevel}
                    useStateStore={this.props.useStateStore} relationships={this.props.relationships}
                    dataViewId={this.props.dataViewId}
                />
            </React.Fragment>
        );
    }
}

export default LeopardChildTabbedView;
