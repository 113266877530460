import React from 'react';
import LDH from "./LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import {connect} from "react-redux";

class LeopardPermissionHelper extends React.Component {
    static IsEnableAdminUserOnlyForCommandLink(gridDefinition, columnType) {
        let isEnabled = false;
        if (!LDH.IsObjectNull(gridDefinition.customColumnConfiguration) &&
            window.userRoles !== LeopardStaticUIConfig.UserRoleCCAdmin &&
            gridDefinition.customColumnConfiguration.customColumns.length > 0) {
            for (var i = 0; i < gridDefinition.customColumnConfiguration.customColumns.length; i++) {
                let customColumn = gridDefinition.customColumnConfiguration.customColumns[i];
                if (!LDH.IsObjectNull(customColumn.enableAdminUserOnly) &&
                    customColumn.columnType === columnType && customColumn.enableAdminUserOnly) {
                    isEnabled = true;
                    break;
                }
            }
        }
        return isEnabled;
    }

    static SetCommandLinkAccessibility(button, $links, rowIndex, forceDisabled, forceHidden) {
        if (forceHidden) {
            for (let j = 0; j < $links.length; j++) {
                if ($links[j].text.trim() === button.text) {
                    $links[j].id = button.id + "_" + rowIndex;
                    $links[j].remove();
                }
            }
            return true;
        } else if (forceDisabled || ((LDH.IsObjectNull(button.enableAdminUserOnly) === false &&
                button.enableAdminUserOnly === true) && $links.length > 0 &&
            window.userRoles !== LeopardStaticUIConfig.UserRoleCCAdmin)) {
            for (let j = 0; j < $links.length; j++) {
                if ($links[j].text.trim() === button.text) {
                    $links[j].id = button.id + "_" + rowIndex;

                    let className = $links[j].className;
                    $links[j].style.opacity = "0.3";
                    $links[j].className = className + " disabled";
                }
            }
            return true;
        }
        return false;
    }

    static IsLeopardAdminModeEnabled(component) {
        try {
            if (localStorage.getItem("IsLeopardAdmin") === "true") {
                return true;
            }
            if (LDH.GetCookie("IsLeopardAdmin") === "true") {
                return true;
            }
            if (LDH.IsObjectNull(component.props.state) &&
                !LDH.IsObjectNull(component.props.parentComp) &&
                !LDH.IsObjectNull(component.props.parentComp.props) &&
                !LDH.IsObjectNull(component.props.parentComp.props.state)) {
                return component.props.parentComp.props.state.leopardAdminMode.enabled;
            }
            return component.props.state.leopardAdminMode.enabled;
        } catch (ex) {
            return false;
        }
    }

    static IsDebugModeEnabled() {
        try {
            if (localStorage.getItem("IsDebugMode") === "true") {
                return true;
            }
            if (LDH.GetCookie("IsDebugMode") === "true") {
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardPermissionHelper);
