import React from 'react';
import {withJsonFormsCellProps} from '@jsonforms/react';
import {LeopardDocColorPickerControl} from './LeopardDocColorPickerControl';

const LeopardDocColorPickerRendererCell = (props) => {
    return React.createElement(LeopardDocColorPickerControl, {
        rootProps: props,
        onValueChanged: (e) => props.handleChange(props.path, e.value)
    });
};
export default withJsonFormsCellProps(LeopardDocColorPickerRendererCell);