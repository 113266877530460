import React, {Component} from 'react';
import LeopardRegexTesterEngine from "./LeopardRegexTesterEngine";

class LeopardChildRegexTester extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardRegexTesterEngine
                    regexDefinition={this.props.definition.regexDefinition}
                    dataViewId={this.props.dataViewId} isDataView={false}
                />
            </React.Fragment>
        );
    }
}

export default LeopardChildRegexTester;
