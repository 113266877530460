import React, {Component} from 'react';
import LeopardGridView from './LeopardGridViewEngine';

const RenderLeopardGridView =
    ({
         gridViewId, gridViewName, dataSourceUrl, viewOptionsText, gridViewHeight, useStateStore, gridDefinition,
         allowEditing, allowDragging, allowDropping, minHeightToolbar, explicitFilterOption,
         minHeightAdvancedFilter, columnFieldList, setGridViewInstance, columnResizingMode, filterBuilderValue,
         enableAutoRefresh, autoRefreshInterval, customColumnOverallWidth, relationships, dashboardLevel,
         dashboardId, requireReloadDataView, dashboardLayoutDataViewMapping, isGridViewJSONEngine, staticJSONData,
         isLoadForTabbedView
     }) => {

        return (
            <React.Fragment>
                <LeopardGridView GridViewId={gridViewId} GridViewName={gridViewName} GetDataFromUrl={dataSourceUrl}
                                 viewOptionsText={viewOptionsText} gridViewHeight={gridViewHeight}
                                 useStateStore={useStateStore} customColumnOverallWidth={customColumnOverallWidth}
                                 minHeightToolbar={minHeightToolbar} minHeightAdvancedFilter={minHeightAdvancedFilter}
                                 updateParentGridViewDimensionOnDataLoad={true} relationships={relationships}
                                 gridDefinition={gridDefinition} allowEditing={allowEditing}
                                 isLoadForTabbedView={isLoadForTabbedView}
                                 filterBuilderValue={filterBuilderValue} explicitFilterOption={explicitFilterOption}
                                 dashboardLevel={dashboardLevel} enableAutoRefresh={enableAutoRefresh}
                                 autoRefreshInterval={autoRefreshInterval} dashboardId={dashboardId}
                                 setGridViewInstance={setGridViewInstance} columnResizingMode={columnResizingMode}
                                 allowDragging={allowDragging} allowDropping={allowDropping}
                                 isGridViewJSONEngine={isGridViewJSONEngine} staticJSONData={staticJSONData}
                                 columnFieldList={columnFieldList} requireReloadDataView={requireReloadDataView}
                                 dashboardLayoutDataViewMapping={dashboardLayoutDataViewMapping}/>
            </React.Fragment>
        );
    };

class LeopardChildGridView extends Component {
    constructor(props) {
        super(props);

        this.allowDraggingGridView = this.props.gridDefinition.allowDragging;
        this.allowDroppingGridView = this.props.gridDefinition.allowDropping;
        this.filterBuilderValueGridView = this.props.gridDefinition.filterBuilderValue;
        this.columnResizingModeGridView = this.props.gridDefinition.columnResizingMode;
        this.explicitFilterOption = this.props.gridDefinition.explicitFilterOption;
        this.enableAutoRefresh = this.props.gridDefinition.enableAutoRefresh;
        this.autoRefreshInterval = this.props.gridDefinition.autoRefreshInterval;
        this.customColumnOverallWidth = this.props.gridDefinition.customColumnOverallWidth;
    }

    render() {
        return (
            <React.Fragment>
                <RenderLeopardGridView gridViewId={this.props.gridViewId} gridViewName={this.props.gridViewName}
                                       viewOptionsText={this.props.viewOptionsText}
                                       dataSourceUrl={this.props.dataSourceUrl}
                                       gridViewHeight={this.props.gridViewHeight} useStateStore={true}
                                       allowDragging={this.allowDraggingGridView}
                                       allowDropping={this.allowDroppingGridView}
                                       dashboardId={this.props.dashboardId}
                                       isLoadForTabbedView={this.props.isLoadForTabbedView}
                                       minHeightToolbar={this.props.minHeightToolbar}
                                       explicitFilterOption={this.explicitFilterOption}
                                       minHeightAdvancedFilter={this.props.minHeightAdvancedFilter}
                                       columnResizingMode={this.columnResizingModeGridView}
                                       filterBuilderValue={this.filterBuilderValueGridView}
                                       columnFieldList={this.props.columnFieldList}
                                       isGridViewJSONEngine={this.props.isGridViewJSONEngine}
                                       staticJSONData={this.props.staticJSONData}
                                       relationships={this.props.relationships}
                                       dashboardLevel={this.props.dashboardLevel}
                                       setGridViewInstance={this.props.setGridViewInstance}
                                       enableAutoRefresh={this.enableAutoRefresh}
                                       requireReloadDataView={this.props.requireReloadDataView}
                                       customColumnOverallWidth={this.customColumnOverallWidth}
                                       autoRefreshInterval={this.autoRefreshInterval}
                                       dashboardLayoutDataViewMapping={this.props.dashboardLayoutDataViewMapping}
                                       gridDefinition={this.props.gridDefinition}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildGridView;