import {rankWith, schemaMatches} from '@jsonforms/core';

export default rankWith(1000, schemaMatches(schema => {
    if (schema.hasOwnProperty('uiControl')) {
        return schema['uiControl'] === "text";
    }
    if (!schema.hasOwnProperty('uiControl')) {
        return (schema['type'] === "string" ||
            schema['type'] === "boolean");
    }
    if (!schema.hasOwnProperty('uiControl') &&
        !schema.hasOwnProperty('type')) {
        return true;
    }
    if (!schema.hasOwnProperty('uiControl') &&
        schema.hasOwnProperty('type') &&
        schema['type'] !== "text" &&
        schema['type'] !== "boolean" &&
        schema['type'] !== "number" &&
        schema['type'] !== "integer" &&
        schema['type'] !== "datetime" &&
        schema['type'] !== "date") {
        return true;
    }
    return false;
}));