import React, {Component} from 'react';
import LeopardMapEngine from "./LeopardMapEngine";

class LeopardChildMap extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardMapEngine mapDefinition={this.props.definition.mapDefinition}
                                  dashboardLevel={this.props.dashboardLevel}
                                  useStateStore={this.props.useStateStore} relationships={this.props.relationships}
                                  setMapInstance={this.props.setMapInstance}
                                  updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                                  isDataView={this.props.isDataView}
                                  dataViewId={this.props.dataViewId}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildMap;
