import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeopardHtmlFormBuilderEngine from "../foundation/LeopardHtmlFormBuilderEngine";
import LeopardSecurity from "../security/LeopardSecurity";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardHtmlFormBuilderDesigner from "../datashaping/LeopardHtmlFormBuilderDesigner";

class LeopardHtmlFormBuilderConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false
        }
    }

    formDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    initializeFormDesignerPanel() {
        return (
            <div className="leopard-htmlformbuilder-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardHtmlFormBuilderDesigner
                    dataViewId={this.props.dataViewId} formDefinition={this.props.formDefinition}
                    formDesignerButtonOnClick={(e) => this.formDesignerButtonOnClick(e)}>
                </LeopardHtmlFormBuilderDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar() {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"HtmlFormBuilder_Admin_ConfigureForm_" + this.props.dataViewId}
                        labelText={"Configure Form Builder"} width={250} title={"Configure Form Builder"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.formDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the form settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    render() {
        let definition = this.props.formDefinition;
        let showGridViewAdminToolbar = false;

        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;
        return (
            <React.Fragment>
                {!(showGridViewAdminToolbar && !isExpanded) || !this.props.isDataView ? "" : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeFormDesignerPanel()}
                <LeopardHtmlFormBuilderEngine relationships={this.props.relationships}
                                         formDefinition={definition}
                                         dataViewId={this.props.dataViewId}
                                         isDataView={this.props.isDataView}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardHtmlFormBuilderConfiguration);
